import React, { useContext, useEffect } from 'react';
import './helpcenter.scss';

import icon1 from '../../assets/images/helpcenter/stars.svg';
import icon2 from '../../assets/images/helpcenter/wallet.svg';
import icon3 from '../../assets/images/helpcenter/tag.svg';
import icon4 from '../../assets/images/helpcenter/magic.svg';
import icon5 from '../../assets/images/helpcenter/send-back.svg';
import icon6 from '../../assets/images/helpcenter/user.svg';
import icon7 from '../../assets/images/helpcenter/life-ring.svg';
import icon8 from '../../assets/images/helpcenter/shield-alt.svg';
import icon9 from '../../assets/images/helpcenter/code.svg';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import { ThemeContext } from '../context/context';

const HelpCenterMainPage = (props) => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    const titleImgIcon = [
        { "icon": icon1, "title": "Getting Started" },
        { "icon": icon2, "title": "Buying" },
        { "icon": icon3, "title": "Selling" },
        { "icon": icon4, "title": "Create an NFT" },
        { "icon": icon5, "title": "Drop a Collection" },
        { "icon": icon6, "title": "User Content" },
        { "icon": icon7, "title": "FaQ" },
        { "icon": icon8, "title": "User Safety" },
        { "icon": icon9, "title": "Developers" },
    ]

    // const gridview = (props.filterview) ? 'g-col-5' : 'g-col-6';
    const {helpsearch, setHelpsearch} = useContext(ThemeContext)

    const [getData] = useOutletContext()

    const navigate = useNavigate()
    const categoryactive = (data) => {
        navigate("/categoryactive/" + data.name)
        setHelpsearch("")
    }

    const litstedTileLoop = getData && getData.map((item) => {
        return (
            <>
                <div className='col-lg-4 col-md-4 col-sm-6' onClick={() => categoryactive(item)}>
                    <div className='tilebox'>
                        <div className='iconwizard'>
                            <img src={item.image} />
                        </div>
                        <div className='tileName'>
                            {item.name}
                        </div>
                    </div>
                </div>
            </>
        );
    });



    return (
        <>
            <div className="container py-5">
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='tileBoxmain'>
                            <div className='row'>
                                {getData && getData.length > 0 ?
                                    litstedTileLoop
                                    :
                                    <div className="col-md-12">
                                        <div className='noItemFound'>
                                            <div className='text-center'>
                                                <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                            </div>
                                            <div className='nofounttxt mb-4'>
                                                No items were found. Try to broaden your search.
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HelpCenterMainPage;