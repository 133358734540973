import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { FiltersDropdown } from './FiltersDropdown'
import { FiltersSidebar } from './FiltersSidebar'
import { SearchItems } from './SearchItems'

import arrowLeftIcon from '../../../assets/images/collection/arrowLeftIcon.svg'
import filterIcon from '../../../assets/images/collection/filterIcon.svg'

export function Filters({
	itemsViewTabsContent,
	showFilters,
	setShowFilters,
	traits,
	filters,
	handleFiltersChange,
	tab,
	collectionDetails,
	children
}) {
	const [containerOffsetWidth, setcontainerOffsetWidth] = useState('')
	const [WindowInnerWidth, setWindowInnerWidth] = useState([
		window.innerWidth
	])

	const containerRef = useRef(null)

	const filterBtnMargin =
		((WindowInnerWidth[0] - containerOffsetWidth + 30) / 2) * -1 + 17

	useLayoutEffect(() => {
		setcontainerOffsetWidth(containerRef.current.offsetWidth)
		setWindowInnerWidth([window.innerWidth])
	}, [])

	useEffect(() => {
		function handleResize() {
			setcontainerOffsetWidth(containerRef.current.offsetWidth)
			setWindowInnerWidth([window.innerWidth])
		}

		if (containerRef.current)
			window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		function handleResize() {
			setWindowInnerWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			<div
				className='filterbtnshow mb-2 mb-xl-0'
				style={{
					marginLeft: filterBtnMargin
				}}
			>
				{showFilters ? (
					<button
						className='filtershowhideBtn'
						onClick={() => setShowFilters(false)}
					>
						<img src={filterIcon} alt='filterIcon' />
					</button>
				) : (
					<button
						className='filtershowhideBtn'
						onClick={() => setShowFilters(true)}
					>
						<img src={arrowLeftIcon} alt='arrowLeftIcon' />
					</button>
				)}
			</div>
			<div className='mb-5' ref={containerRef}>
				<div className='row'>
					<div className='col-lg-12'>
						<div className='itemWizard'>
							<div
								className={`filterSection ${showFilters ? 'show' : 'hide'
									}`}
							>
								<FiltersSidebar
									traits={traits}
									filters={filters}
									handleFiltersChange={handleFiltersChange}
									tab={tab}
									collectionDetails={collectionDetails}
								/>
							</div>
							<div
								className={`viewItemscard ${showFilters ? 'collapseWidth' : 'fullWidth'
									}`}
							>
								{tab !== '2' ?
									<div className='form-inline'>
										<SearchItems
											filters={filters}
											handleFiltersChange={
												handleFiltersChange
											}
										/>
										{tab === '3' ? null :
											<FiltersDropdown
												filters={filters}
												handleFiltersChange={
													handleFiltersChange
												}
											/>
										}
										{children}
									</div>
									: null}
								{itemsViewTabsContent}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
