import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'

function bid(data) {
	return axios.post('asset/bid', { ...data })
}

export function useBid() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: bid,
		onSuccess: () => {
			queryClient.invalidateQueries(['bids'])
		}
	})
}
