import React, { useContext, useEffect, useState } from 'react'
import alertDanger from '../../assets/images/alertDanger.svg'
import aiPfpClub from '../../assets/images/itemIcons/aiPfpClub.png'
import IconModal from '../../assets/images/IconModal.png'
import walletLoaderCnt from '../../assets/images/walletLoader.png'
import walletLoader from '../../assets/images/loaderCircle.png'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledDropdown,
	Modal,
	UncontrolledCollapse
} from 'reactstrap'
import classnames from 'classnames'
import MyCollectionData from './MyCollectionData'
// import './myCollection.scss';
//import '../../assets/scss/mycollecion_mint.scss'
import { ThemeContext } from '../context/context'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../../utils/toast/customSuccess'
import Web3 from 'web3'
import { ChangeNetwork } from '../Services'
import ContractDetails from '../ContractAddress'
import MinimalproxyEth from '../AbiArray/MinimalproxyEth.json'
import MinimalproxyBnb from '../AbiArray/MinimalproxyBnb.json'
import MinimalproxyPolygon from '../AbiArray/MinimalproxyPolygon.json'
import angleDown from '../../assets/images/angleDown.svg'
import { axios } from '../../lib/axios'
import {
	useGetBnbBalance,
	useGetEthBalance,
	useGetMaticBalance
} from '../../hooks/useBalance'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
	useDisconnect,
	useBalance
} from 'wagmi'
import { useTranslation } from 'react-i18next'
import { chainId } from '../../config/constants/chains'

import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export const ChooseCollection = () => {
	const { t } = useTranslation()
	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const navigate = useNavigate()

	const back = () => {
		navigate('/choosecollection')
		setBlockChain('')
	}

	const { blockchain, setBlockChain } = useContext(ThemeContext)

	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainId = network?.id

	const balance = useBalance({
		address: address
	})

	const { balance: bnbBalance } = useGetBnbBalance()
	const { balance: ethBalance } = useGetEthBalance()
	const { balance: maticBalance } = useGetMaticBalance()

	console.log({ bnbBalance, ethBalance, maticBalance })

	const toast = useToast()

	const [collectionimage, setCollectionIamge] = useState()
	const [collectionname, setCollectionName] = useState('')
	const [collectionsymbol, setCollectionsymbol] = useState('')
	const [description, setDescription] = useState('')
	const [url1, setUrl] = useState('')
	let imageFile

	const imageupload = e => {
		imageFile = e.target.files[0]
		if (!imageFile.name.match(/\.(webp|jpg|jpeg|png|gif)$/)) {
			document.getElementById('uploadimage').innerHTML =
				'Please select valid image for WEBP,PNG,GIF,JPEG'
		} else if (imageFile == '' || imageFile == undefined) {
			setCollectionIamge('')
			document.getElementById('uploadimage').innerHTML = 'Please choose image'
		} else {
			setCollectionIamge(imageFile)
			document.getElementById('uploadimage').innerHTML = ''
		}
	}
	const setCollectionName1 = async e => {
		const regex = /^[A-Za-z0-9 ]+$/
		const chars = e.target.value.split('')
		const char = chars.pop()

		if (!regex.test(char)) {
			e.target.value = chars.join('')
		} else if (e.target.value == '' || e.target.value == undefined) {
			setCollectionName('')
			document.getElementById('name').innerHTML = 'Please enter collection name'
		} else if (e.target.value.length < 3) {
			setCollectionName(e.target.value)
			setCollectionsymbol(e.target.value.slice(0, 1))
			setUrl(e.target.value)
			document.getElementById('name').innerHTML =
				'Collection Name atleast 3 characters'
		} else if (e.target.value.length > 30) {
			setCollectionName(e.target.value)
			setCollectionsymbol(e.target.value.slice(0, 1))
			setUrl(e.target.value)
			document.getElementById('name').innerHTML =
				'Collection Name must be at most 30 characters'
		} else {
			setCollectionName(e.target.value)
			setCollectionsymbol(e.target.value.slice(0, 1))
			setUrl(e.target.value)
			const checkname = await axios.post('user/checkCollection', {
				collectionName: e.target.value
			})
			if (checkname.status == true) {
				document.getElementById('name').innerHTML = ''
				document.getElementById('urls').innerHTML = ''
				document.getElementById('symbol').innerHTML = ''
			} else {
				setCollectionName(e.target.value)
				setCollectionsymbol(e.target.value.slice(0, 1))
				setUrl(e.target.value)
				document.getElementById('name').innerHTML = checkname.message
			}
		}
	}

	const setCollectionsymbol1 = e => {
		const regex = /^[A-Za-z0-9 ]+$/
		const chars = e.target.value.split('')
		const char = chars.pop()
		if (!regex.test(char)) {
			e.target.value = chars.join('')
		} else if (e.target.value == '' || e.target.value == undefined) {
			setCollectionsymbol(e.target.value)
			document.getElementById('symbol').innerHTML =
				'Please enter collection symbol'
		} else {
			setCollectionsymbol(e.target.value)
			document.getElementById('symbol').innerHTML = ''
		}
	}

	const setDescription1 = e => {
		setDescription(e.target.value)
	}

	var res =
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	const setUrl1 = async e => {
		const regex = /^[A-Za-z0-9 ]+$/

		const chars = e.target.value.split('')
		const char = chars.pop()
		if (!regex.test(char)) {
			e.target.value = chars.join('')
		} else if (e.target.value.match(res)) {
			document.getElementById('urls').innerHTML = 'Not allowed url'
		} else if (e.target.value == '' || e.target.value == undefined) {
			document.getElementById('urls').innerHTML = 'Please enter collection url'
		} else {
			setUrl(e.target.value)
			const checkurl = await axios.post('user/checkCollectionUrl', {
				url: `https://localhost/collection/${e.target.value}`
			})
			if (checkurl.status == true) {
				document.getElementById('urls').innerHTML = ''
			} else {
				setUrl(e.target.value)
				document.getElementById('urls').innerHTML = checkurl.data.message
			}
		}
	}

	const [hover, setHover] = useState('')
	const [hover1, setHover1] = useState('collapseCard')
	const [contracttype, setContractType] = useState('Proxy')

	const Proxy = () => {
		setHover('collapseCard1')
		setHover1('collapseCard')
		setContractType('Proxy')
	}
	const Standarad = () => {
		setHover1('collapseCard1')
		setHover('collapseCard')
		setContractType('Standard')
	}



	let providerType = ''
	if (typeof window !== undefined) {
		let web3 = new Web3(window.ethereum)
	}

	const { pro } = useContractProvider()
	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))


	const { theme, setTheme } = useContext(ThemeContext)
	const web3 = new Web3(pro)
	const bnbminimalproxy = new web3.eth.Contract(
		MinimalproxyBnb,
		ContractDetails.Proxy_BNB
	)
	const ethminimalproxy = new web3.eth.Contract(
		MinimalproxyEth,
		ContractDetails.Proxy_ETH
	)
	const polygonminimalproxy = new web3.eth.Contract(
		MinimalproxyPolygon,
		ContractDetails.Proxy_Polygon
	)

	const [loader, setLoader] = useState(false)
	const [loader1, setLoader1] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		getAbi()
		balCheck()
	}, [address])

	const [abiarray, setAbiArray] = useState('')

	const getAbi = async () => {
		const GetAbi = await axios.get('user/getABI')
		if (GetAbi.status == true) {
			setAbiArray(GetAbi.data)
		} else {
			toast.error(GetAbi.message)
		}
	}

	const [showimage, setShowImage] = useState('')

	const [id, setId] = useState('')
	const [checkBalance, setCheckBalance] = useState()

	const balCheck = () => {
		if (sessionStorage.getItem('blockchain') === 'ethereum') {
			setCheckBalance(ethBalance)
		} else if (sessionStorage.getItem('blockchain') === 'binance') {
			setCheckBalance(bnbBalance)
		} else if (sessionStorage.getItem('blockchain') === 'polygon') {
			setCheckBalance(maticBalance)
		}
	}

	const createcollection = async () => {
		setLoader1(true)
		let contract

		if (address) {
			if (sessionStorage.getItem('blockchain') === 'ethereum') {
				contract = ethminimalproxy
				console.log("1")
			} else if (sessionStorage.getItem('blockchain') === 'binance') {
				contract = bnbminimalproxy
				console.log("2")
			} else if (sessionStorage.getItem('blockchain') === 'polygon') {
				contract = polygonminimalproxy
				console.log("3")
			}

			if (
				collectionimage == undefined &&
				collectionname == '' &&
				description == '' &&
				url1 == '' &&
				collectionsymbol == ''
			) {
				document.getElementById('uploadimage').innerHTML = 'Please choose image'
				document.getElementById('name').innerHTML =
					'Please enter collection name'
				document.getElementById('symbol').innerHTML =
					'Please enter collection symbol'
				document.getElementById('urls').innerHTML =
					'Please enter collection url'
				setLoader1(false)
			} else if (collectionimage == undefined || collectionimage == '') {
				document.getElementById('uploadimage').innerHTML = 'Please choose image'
				setLoader1(false)
			} else if (collectionname == '') {
				document.getElementById('name').innerHTML =
					'Please enter collection name'
				setLoader1(false)
			} else if (collectionname.length < 3) {
				document.getElementById('name').innerHTML =
					'Collection Name atleast 3 characters'
				setLoader1(false)
			} else if (collectionname.length > 30) {
				document.getElementById('name').innerHTML =
					'Collection Name must be at most 30 characters'
				setLoader1(false)
			} else if (collectionsymbol == '' || collectionsymbol == undefined) {
				document.getElementById('symbol').innerHTML =
					'Please enter currency symbol'
				setLoader1(false)
			} else if (url1 == '' || collectionname == '') {
				document.getElementById('urls').innerHTML =
					'Please enter collection url'
				setLoader1(false)
			} else {
				toggle()
				const checkname = await axios.post('user/checkCollection', {
					collectionName: collectionname
				})
				if (checkname.status == true) {
					const checkurl = await axios.post('user/checkCollectionUrl', {
						url: `https://localhost/collection/${url1 ? url1 : collectionname
							}`
					})
					if (checkurl.status == true) {
						if (contracttype == 'Proxy') {
							setLoader1(true)

							try {
								try {
									if (walletType === 'okx') {
										if (sessionStorage.getItem('blockchain') !== chainMap[parseInt(window.okxwallet.chainId)]) {
											await switchNetwork(chainIdMap[sessionStorage.getItem('blockchain')])
										}
									} else {
										if (
											sessionStorage.getItem('blockchain') === 'binance' &&
											chainId !== 97
										) {
											await switchNetworkAsync(97)
										} else if (
											sessionStorage.getItem('blockchain') === 'ethereum' &&
											chainId !== 11155111
										) {
											await switchNetworkAsync(11155111)
										} else if (
											sessionStorage.getItem('blockchain') === 'polygon' &&
											chainId !== 80001
										) {
											await switchNetworkAsync(80001)
										}
									}
								} catch (error) {
									toast.error('User rejected the switch network')
									setModal(false)

									return
								}

								console.log(
									'🚀 ~ file: CreateCollection.js:251 ~ ChooseCollection ~ checkBalance:',
									checkBalance, chainId, collectionname,
									collectionsymbol,
									address.toString()
								)

								// var balance = await web3.eth
								// 	.getBalance(address)
								// 	.then(async bal => {
								// 		balanceof = web3.utils.fromWei(bal, 'ether')
								// 		sessionStorage.setItem('balance', balanceof)
								// 	})
								if (Number(checkBalance) > 0) {
									try {
										let create = await contract.methods
											.createClone(
												collectionname,
												collectionsymbol,
												address.toString()
											)
											.send({ from: address.toString() })
											.on('transactionHash', async hash => { })
											.on('receipt', async approveresult => {
												const formData = new FormData()
												formData.append('type', 'user')
												formData.append('creator', address)
												formData.append(
													'transactionHash',
													approveresult.transactionHash
												)
												formData.append(
													'contractAddress',
													approveresult.events.CreateNEWContract.returnValues
														.newNFTContract
												)
												formData.append(
													'chain',
													sessionStorage.getItem('blockchain')
												)
												formData.append('collectionName', collectionname)
												formData.append('description', description)
												formData.append('contractType', contracttype)
												formData.append('logoImage', collectionimage)
												formData.append('collectionSymbol', collectionsymbol)
												formData.append(
													'url',
													`https://localhost/collection/${url1 ? url1 : collectionname
													}`
												)

												const collectioncreate = await axios.post(
													'user/create-collection',
													formData
												)
												if (collectioncreate.status == true) {
													toast.success(collectioncreate.message)
													setId(collectioncreate.data._id)
													setCollectionName('')
													setCollectionIamge('')
													setDescription('')
													setUrl('')
													setShowImage(collectioncreate.data.logoImage)
													setLoader1(false)
													setLoader(true)
												} else {
													toast.error(collectioncreate.message)
													setCollectionName('')
													setCollectionIamge('')
													setDescription('')
													setUrl('')
													setModal(false)
													navigate('/mycollection')
												}
											})
									} catch (err) {
										console.error(err)
										setModal(false)
									}
									document.getElementById('uploadimage').innerHTML = ''
									document.getElementById('name').innerHTML = ''
									document.getElementById('urls').innerHTML = ''
								} else {
									toast.error('Insufficient balance')
									window.location.reload()
									setModal(false)
								}
							} catch (err) {
								// }
								console.error(err)
								setModal(false)
							}
						} else if (contracttype == 'Standard') {
							setLoader1(true)

							try {
								if (walletType === 'okx') {
									if (sessionStorage.getItem('blockchain') !== chainMap[parseInt(window.okxwallet.chainId)]) {
										await switchNetwork(chainIdMap[sessionStorage.getItem('blockchain')])
									}
								} else {
									if (
										sessionStorage.getItem('blockchain') === 'binance' &&
										chainId !== 97
									) {
										await switchNetworkAsync(97)
									} else if (
										sessionStorage.getItem('blockchain') === 'ethereum' &&
										chainId !== 11155111
									) {
										await switchNetworkAsync(11155111)
									} else if (
										sessionStorage.getItem('blockchain') === 'polygon' &&
										chainId !== 80001
									) {
										await switchNetworkAsync(80001)
									}
								}

								// var balance = await web3.eth
								// 	.getBalance(address)
								// 	.then(async bal => {
								// 		balanceof = web3.utils.fromWei(bal, 'ether')
								// 		sessionStorage.setItem('balance', balanceof)
								// 	})
								if (Number(checkBalance) > 0) {
									var nft_tokenContract = new web3.eth.Contract(abiarray.abi)

									if (nft_tokenContract) {
										try {
											await nft_tokenContract
												.deploy({
													data: abiarray.byteCode,
													arguments: [
														String(collectionname),
														String(collectionsymbol),
														''
													]
												})
												.send({
													from: address
												})
												.on('transactionHash', async hash => { })
												.on('receipt', async approveresult => {
													const formData = new FormData()
													formData.append('type', 'user')
													formData.append('creator', address)
													formData.append(
														'transactionHash',
														approveresult?.transactionHash
													)
													formData.append(
														'contractAddress',
														approveresult?.contractAddress
													)
													formData.append(
														'chain',
														sessionStorage.getItem('blockchain')
													)
													formData.append('collectionName', collectionname)
													formData.append('contractType', contracttype)
													formData.append('collectionSymbol', collectionsymbol)
													formData.append('logoImage', collectionimage)
													formData.append('description', description)
													formData.append(
														'url',
														`https://localhost/collection/${url1 ? url1 : collectionname
														}`
													)
													const collectioncreate = await axios.post(
														'user/create-collection',
														formData
													)
													if (collectioncreate.status == true) {
														toast.success(collectioncreate.message)
														setId(collectioncreate.data._id)
														setCollectionName('')
														setCollectionIamge('')
														setDescription('')
														setUrl('')
														setShowImage(collectioncreate.data.logoImage)
														setLoader1(false)
														setLoader(true)
													} else {
														toast.error(collectioncreate.message)
														setCollectionName('')
														setCollectionIamge('')
														setDescription('')
														setUrl('')
														setModal(false)
														navigate('/mycollection')
													}
												})
										} catch (err) {
											console.error(err)
											setModal(false)
										}
									}
								} else {
									toast.error('Insufficient balance')
									window.location.reload()
									setModal(false)
								}

								// }
							} catch (err) {
								console.error(err)
								setModal(false)
							}
						}
					} else {
						setModal(false)
						document.getElementById('urls').innerHTML = checkurl.message
					}
				} else {
					document.getElementById('name').innerHTML = checkname.message
					setModal(false)
				}
			}
		} else {
			setModal(false)
			toast.error('Please Connectwallet')
		}
	}

	const mycollection = () => {
		navigate('/editcollection/' + id)
	}

	return (
		<>
			<div className='row'>
				<div className='col-lg-2 mb-3' onClick={back}>
					<button className='backIcon'>
						<i className='fas fa-angle-left'></i>
					</button>
				</div>
				<div className='col-lg-3 mb-3'>
					<h3 className='collectionSecHeading text-left'>Create Collection</h3>
				</div>
				<div className='col-lg-6'>
					<div className='createCollectionCard'>
						<form>
							{address ? (
								<div className='walletCnt mb-3'>
									<h3>
										{address
											? address.slice(0, 5) + '...' + address.slice(38, 42)
											: ''}
									</h3>
									<span className='walletLabel successLabel ml-auto'>
										Wallet Connected
									</span>
								</div>
							) : (
								<div className='walletCnt mb-3'>
									<h3></h3>
									<span
										className='walletLabel successLabel ml-auto'
										style={{ color: 'red' }}
									>
										Please Connect Wallet
									</span>
								</div>
							)}

							<div className='form-row'>
								<div className='col-12 mb-4'>
									<span className='formLabel'>Logo Image</span>
									<div className='d-flex align-items-center flex-wrap'>
										<div className='imgCnt mr-4'>
											{collectionimage ? (
												<img
													src={URL.createObjectURL(collectionimage)}
													style={{ width: '160px', height: '160px' }}
												/>
											) : (
												<img src='' />
											)}
										</div>
										<div className='d-flex flex-column'>
											<p>PNG,GIF,JPEG,WEBP.</p>
											<p className='fs-12 greyTxt'>
												Max 100mb. At least 300*300 pixels.
											</p>
											<div className='chooseFileBtn mt-2'>
												<input
													type='file'
													id='chooseBtn'
													onChange={e => imageupload(e)}
												/>
												<label for='chooseBtn'>Choose File</label>
											</div>
										</div>
									</div>
									<span id='uploadimage' style={{ color: 'red' }}></span>
								</div>

								<div className='col-6 mb-3 '>
									<div className=' d-flex align-items-center  flex-wrap '>
										<span className='formLabel'>Collection Name</span>
										{/* <label className="text-danger errLabel ml-auto"> </label> */}
									</div>
									<input
										type='text'
										placeholder='Name your collection'
										className='form-control'
										name='collectionname'
										onChange={setCollectionName1}
									/>
									<label className='text-danger errLabel ml-auto'>
										{' '}
										<span id='name' style={{ color: 'red' }}></span>{' '}
									</label>
								</div>

								<div className='col-6 mb-3 '>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>Collection Symbol</span>
										{/* <label className="text-danger errLabel ml-auto"> </label> */}
									</div>
									<input
										type='text'
										placeholder='Collection Symbol'
										className='form-control'
										name='collectionsymbol'
										onChange={e => setCollectionsymbol1(e)}
										value={collectionsymbol}
									/>
									<label className='text-danger errLabel ml-auto'>
										{' '}
										<span id='symbol' style={{ color: 'red' }}></span>{' '}
									</label>
								</div>

								<div className='col-12 mb-3'>
									<span className='formLabel'>Description (Optional)</span>
									<textarea
										className='form-control'
										placeholder='Enter Description'
										name='description'
										onChange={setDescription1}
									></textarea>
									{/* <span id='desc' style={{ color: "red" }}></span> */}
								</div>

								<div className='col-12 mb-3 urlFieldCnt'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>Collection URL</span>
										{/* <label className="text-danger errLabel ml-auto"></label> */}
									</div>
									<div class='input-group mb-2'>
										<div class='input-group-prepend'>
											<div class='input-group-text'>
												localhost/collection/
											</div>
										</div>
										<input
											type='text'
											class='form-control'
											placeholder='Enter URL'
											name='url1'
											onChange={setUrl1}
											defaultValue={collectionname ? collectionname : ''}
										/>
									</div>
									<label className='text-danger errLabel ml-auto'>
										{' '}
										<span id='urls' style={{ color: 'red' }}></span>
									</label>
								</div>
								{/* <div className="col-12 mb-3">
                    <div className="alert alert-danger">
                      <img src={alertDanger} className="mr-2" />Please, fill the missing fields.
                    </div>
                  </div> */}

								<div className='collapseCnt px-0'>
									<div
										className='collapseHead d-flex align-items-center'
										id='collapse'
									>
										<h3 className='collapseHeading'>Advanced Settings</h3>
										<img
											src={angleDown}
											className='ml-auto collapseArrow'
											style={{ cursor: 'pointer' }}
										/>
									</div>
									<UncontrolledCollapse
										toggler='#collapse'
										className='collapseBody'
									>
										<p className='fs-16 mb-1'>Customize your contract type</p>
										<p className='fs-14'>
											<a href='#'>Learn more</a> about each contract type.
										</p>
										<div
											className={`${hover == ''
												? 'collapseCard1'
												: contracttype == 'Proxy'
													? 'collapseCard1'
													: 'collapseCard'
												}`}
											onClick={Proxy}
											style={{ cursor: 'pointer' }}
										>
											<h3>Proxy contract</h3>
											<p>
												Recommended for most creators. This type of contract is
												cheaper to deploy but will require that wallets who
												mint, transfer, and sell items from this collection pay
												additional gas fees.
											</p>
											{/* <p>Estimated cost to deploy contract: $3.37</p> */}
										</div>
										<div
											className={`${hover1 == 'collapseCard'
												? 'collapseCard'
												: 'collapseCard1'
												}`}
											onClick={Standarad}
											style={{ cursor: 'pointer' }}
										>
											<h3>Standard contract</h3>
											<p>
												Recommended for advanced creators. This type of contract
												is more expensive to deploy but minting, transfers, and
												sale interactions will require less extra gas fees.
											</p>
											{/* <p>Estimated cost to deploy contract: $42.1</p> */}
										</div>
									</UncontrolledCollapse>
								</div>

								<div className='col-12 mb-3 pt-3'>
									<button
										type='button'
										className='btn btn-block gradientBtn'
										onClick={createcollection}
									>
										Create Collection
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
			>
				{loader == true ? (
					<div className='createContent'>
						{/* <button className="btn closeBtn" onClick={toggle}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
              </svg>
              <img src={showimage} width="12" height="12" />
            </button> */}
						<div className='d-flex justify-content-center'>
							<img src={showimage} width='100px' header='100px' />
						</div>
						<h3 className='walletHeading my-3'>
							Collection Created <br />
							Successfully
						</h3>
						<button
							type='button'
							className='btn btn-block gradientBtn '
							onClick={mycollection}
						>
							Edit Collection
						</button>
					</div>
				) : (
					''
				)}
				{loader1 == true ? (
					<div>
						<h3 className='walletHeading my-3'>Loading</h3>
						<h3 className='walletSubHeading'>
							To continue send transaction with your wallet.
						</h3>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							<img src={walletLoaderCnt} />
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{error == true ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							<img src={IconModal} />
						</div>
						<h3 className='walletHeading my-3'>Something went wrong</h3>
						<button type='button' className='btn btn-block darkBtn'>
							{t('Okay')}
						</button>
					</div>
				) : (
					''
				)}
			</Modal>
		</>
	)
}

export default ChooseCollection
