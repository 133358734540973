import { useAccount, usePublicClient } from 'wagmi'

import { useMarketplaceContract } from './useContract'
import { useCancelAuctionOrder } from '../features/nft/apis/cancelAuction'

export function useCancelAuction(marketplaceContractAddress) {
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress
	)
	const publicClient = usePublicClient()
	const { address: account } = useAccount()
	const { mutateAsync: cancelAuctionOrder } = useCancelAuctionOrder()

	const cancelAuction = async ({ owner, nftAddress, tokenId, asset }) => {
		try {
			const hash = await marketplaceContract.write.cancelOrder(
				[owner, nftAddress, tokenId],
				{
					account
				}
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status === 'success') {
				const res = await cancelAuctionOrder(asset)
				if (res.status) {
					return transactionReceipt
				}
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			console.error(error)
			throw new Error(error)
		}
	}

	return { cancelAuction }
}
