import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { ThemeContext } from '../../../views/context/context'
import { useContext } from 'react'
function collectionFloorChart(collectionAddress, Analyticsfilter) {
	const url = new URL(BACKEND_URL + 'collection/floor-chart')
	url.searchParams.set('contractAddress', collectionAddress)
	url.searchParams.set('filter', Analyticsfilter)

	return axios.get(url)
}

export function useCollectionFloorChart(collectionAddress) {
	const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)

	return useQuery({
		queryKey: ['floor', collectionAddress,Analyticsfilter],
		queryFn: () => collectionFloorChart(collectionAddress,Analyticsfilter),
		enabled: !!(collectionAddress)
	})
}
