import React, {
	Fragment,
	useState,
	useEffect,
} from 'react'
import {
	useNavigate
} from 'react-router-dom'
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap'
import classnames from 'classnames'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import HomeBanner from '../../components/HomeBanner/HomeBanner'

import badgeCheck from '../../assets/images/badgeCheck.svg'
import ethIconSm from '../../assets/images/ethIconSm.png'
import maticIconSm from '../../assets/images/maticIconSm.png'
import bnbIconSm from '../../assets/images/bnbIconSm.png'
import clock from '../../assets/images/clock.svg'
import list from '../../assets/images/tag.png'
import cart from '../../assets/images/cart.png'
import homeCollectionBanner1 from '../../assets/images/banners/homeCollectionBanner1.png'
import angleDown from '../../assets/images/angleDown.svg'
import bannerSliderImage from '../../assets/images/bannerSliderImage.png'
import bannerItemIcon from '../../assets/images/bannerItemIcon.png'
import { useToast } from '../../utils/toast/customSuccess'
import { axios } from '../../lib/axios'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import { useTranslation } from 'react-i18next'
import { UsefetchNotable, GetFetchViewCount, UsefetchFeatured } from './api'
import loader from '../../assets/images/Loader.png'
import { hoursToGo } from '../../utils/formatRoundTime'

const Home = props => {
	const { className } = props
	const { t } = useTranslation()

	// const [modal, setModal] = useState(false);
	// const toggle = () => setModal(!modal);

	const [loading, setLoading] = useState(false);

	const [collections, setCollections] = useState(null)
	const [category, setCategory] = useState(null)
	const [fetchCollections, setFetchCollections] = useState(null)
	const [nfts, setNfts] = useState(null)
	const [activeCategoryTab, setActiveCategoryTab] = useState('All')
	const [activeTab3, setActiveTab3] = useState('1')
	const [getNetwork, setGetNetwork] = useState('all')
	const [getDays, setGetDays] = useState('7')
	const [getType, setGetType] = useState('trending')

	const { data: notable } = UsefetchNotable()

	const { data: featured } = UsefetchFeatured()

	const viewCountMutation = GetFetchViewCount()

	const [activeTab, setActiveTab] = useState('1')
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const [activeTab1, setActiveTab1] = useState('4')
	const toggle1 = tab => {
		if (activeTab1 !== tab) setActiveTab1(tab)
	}

	const [activeTab2, setActiveTab2] = useState('1')
	const toggle2 = tab => {
		if (activeTab2 !== tab) setActiveTab2(tab)
	}

	let networkArr = [
		{
			chain: 'all',
			name: t('All Chains')
		},
		{
			chain: 'binance',
			name: t('Binance')
		},
		{
			chain: 'ethereum',
			name: t('Ethereum')
		},
		{
			chain: 'polygon',
			name: t('Polygon')
		}
	]

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props
		return (
			<button type='button' class='slick-prev' onClick={onClick}>
				<img src={angleDown} alt='' class='img-fluid' />
			</button>
		)
	}

	function SampleNextArrow(props) {
		const { className, style, onClick } = props
		return (
			<button type='button' class='slick-next' onClick={onClick}>
				<img src={angleDown} alt='' class='img-fluid' />
			</button>
		)
	}

	const toast = useToast()

	const handleCollection = () => {
		navigate('/homecollection')
	}

	const handleActivity = () => {
		navigate('/activity')
	}

	var settings = {
		dots: false,
		infinite: fetchCollections && fetchCollections.length > 3,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: true,
		initialSlide: 0,
		centerMode: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					// slidesToScroll: 1,
					// infinite: true,
					// dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					// slidesToScroll: 1,
					// initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					//slidesToScroll: 1
				}
			}
		]
	}

	var settings1 = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		initialSlide: 0,
		centerMode: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					// slidesToScroll: 1,
					// infinite: true,
					// dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					// slidesToScroll: 1,
					// initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					//slidesToScroll: 1
				}
			}
		]
	}

	var settings2 = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: true,
		initialSlide: 0,
		centerMode: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	async function toggle3(tab) {
		setLoading(true)
		if (activeCategoryTab !== tab) setActiveCategoryTab(tab)
		try {
			if (tab === 'All') {
				const data = await axios.get(`/home/collections?category=${tab}`)
				if (data.status == true) {
					setLoading(false)
					setFetchCollections(data.data)
				} else {
					setLoading(false)
					setFetchCollections("")
					throw new Error(data.message)

				}
			} else {
				const data = await axios.get(`/home/collections?category=${tab}`)
				if (data.status == true) {
					setLoading(false)
					setFetchCollections(data.data)
				} else {
					setLoading(false)
					setFetchCollections("")
					throw new Error(data.message)

				}
			}
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	useEffect(() => {
		getCollections({ day: getDays, chain: networkArr[0].chain, type: getType })
		setGetNetwork(networkArr[0].chain)

		getNfts('all')
		getCategory()

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [])

	const navigate = useNavigate()

	const handleNftRoute = async data => {
		navigate(
			`/collection/${data.contractAddress}`
		)
	}

	const handleFeatured = async data => {
		navigate(
			`/collection/${data.contractAddress}`
		)
	}

	const handleNotable = async data => {
		navigate(
			`/collection/${data.contractAddress}`
		)
	}

	const handleTrending = async data => {
		navigate(
			`/collection/${data.contractAddress}`
		)
	}

	async function getCategory() {
		setLoading(true)

		try {
			const data = await axios.get('/home/categories')

			if (data.status == true) {
				setLoading(false)
				setCategory(data.data)
			} else {
				setLoading(false)
				setCategory("")
				throw new Error(data.message)
			}
		} catch (error) {
			setLoading(false)
			console.error(error)
		}
	}

	async function getCollections(item) {
		setLoading(true)

		try {
			const data = await axios.get(
				`/home/collections?filter=${item.day}&chains=${item.chain}&type=${item.type}`
			)

			if (data.status == true) {
				setLoading(false)
				setCollections(data.data)
			} else {
				setLoading(false)
				setCollections("")
				throw new Error(data.message)
			}
		} catch (error) {
			console.error(error)
			setLoading(false)

		}
	}

	async function getNfts(tab) {
		setLoading(true)

		try {
			// const data = await axios.get(CONST.BACKEND_URL + '/home/nfts')

			const data = await axios.get(`/home/nfts?filtertype=${tab}`)

			if (data.status == true) {
				setLoading(false)
				setNfts(data.data)
			} else {
				setLoading(false)
				setNfts("")
				throw new Error(data.message)
			}

			if (activeCategoryTab === 'All') {
				const data = await axios.get(
					`/home/collections?filter=${getDays}&chains=${getNetwork}`
				)
				if (data.status == true) {
					setLoading(false)
					setFetchCollections(data.data)
				} else {
					setLoading(false)
					setFetchCollections("")
					throw new Error(data.message)
				}
			}
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	const chainImageMap = {
		binance: bnbIconSm,
		polygon: maticIconSm,
		ethereum: ethIconSm
	}

	const coinMap = {
		binance: 'BNB',
		polygon: 'MATIC',
		ethereum: 'ETH'
	}

	const handleCount = async count => {
		await viewCountMutation.mutateAsync({ collectionName: count })
	}

	return (
		<Fragment>

			<div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>

			<HomeBanner />
			<section className='collectionActivitySec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-xl-7'>
							<div className='card my-1'>
								<div className='d-flex align-items-sm-center mb-3 mb-xl-0 flex-sm-row flex-column'>
									<h3 className='gradientHeading order-2 order-sm-1 mb-3'>{t("Collections")}</h3>
									<button
										className='btn gradientBtn ml-auto order-1 order-sm-2 mb-xl-3'
										onClick={() => handleCollection()}
									>
										{t("View All")}
									</button>
								</div>
								<div className='d-flex align-items-center flex-wrap mb-2'>
									<Nav tabs className='mr-2 mr-sm-0 mb-3 mb-sm-0'>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === '1'
												})}
												onClick={() => {
													toggle('1')
													setGetType('trending')
													getCollections({ day: getDays, chain: getNetwork, type: 'trending' })
												}}
											>
												{t("Trending")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === '2'
												})}
												onClick={() => {
													toggle('2')
													setGetType('top')
													getCollections({ day: getDays, chain: getNetwork, type: 'top' })
												}}
											>
												{t("Top")}
											</NavLink>
										</NavItem>
									</Nav>

									<Nav tabs className='ml-sm-auto checkTab mr-0 mr-sm-3 mb-3 mb-sm-0'>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '1'
												})}
												onClick={() => {
													toggle1('1')
													setGetDays('1')
													getCollections({ day: '1', chain: getNetwork, type: getType })
												}}
											>
												{t("1h")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '2'
												})}
												onClick={() => {
													toggle1('2')
													setGetDays('6')
													getCollections({ day: '6', chain: getNetwork, type: getType })
												}}
											>
												{t("6h")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '3'
												})}
												onClick={() => {
													toggle1('3')
													setGetDays('24')
													getCollections({ day: '24', chain: getNetwork, type: getType })
												}}
											>
												{t("24h")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '4'
												})}
												onClick={() => {
													toggle1('4')
													setGetDays('7')
													getCollections({ day: '7', chain: getNetwork, type: getType })
												}}
											>
												{t("7d")}
											</NavLink>
										</NavItem>
									</Nav>
									<select className=''
										onChange={e => {
											setGetNetwork(e.target.value)
											getCollections({ chain: e.target.value, day: getDays, type: getType })
										}}
									>
										{networkArr &&
											networkArr.map(item => (
												<option value={item.chain}>{item.name}</option>
											))}
										{/* <option value='all' >All Chains</option>
										<option value='binance' >Binanace</option>
										<option value='ethereum' >Ethereum</option>
										<option value='polygon' >Polygon</option> */}
									</select>
								</div>
								<div className='w-100 mt-4'>
									<TabContent activeTab={activeTab}>
										<TabPane tabId='1'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t("Collections")}</th>
															<th className='text-right'>{t("Floor Price")}</th>
															<th className='text-right'>{t("Volume")}</th>
														</tr>
													</thead>
													<tbody>
														{collections && collections.length > 0 ? (
															collections
																.slice(0, 10)
																.map((collection, index) => (

																	<tr>
																		<td className='lgTxt ylwTxt altFont'>
																			{index + 1}
																		</td>
																		<td>
																			<div
																				className='d-flex align-items-center' style={{ cursor: 'pointer' }}
																				onClick={() => {
																					handleCount(
																						collection.collectionName
																					)
																					handleTrending(collection)
																				}}
																			>
																				<img
																					src={collection.logoImage}
																					className='itemIcon mr-3'
																					style={{
																						height: '60px',
																						aspectRatio: '1',
																						borderRadius: '8px'
																					}}
																				/>
																				<h3 className='mr-2'>
																					{collection.collectionName}
																				</h3>
																				{/* <img
																							src={
																								badgeCheck
																							}
																							className='mr-2'
																							height='20'
																						/> */}
																				<img
																					src={
																						chainImageMap[collection.chain]
																					}
																					className='mr-2'
																					height='20'
																				/>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection.floorPrice=="0" ? 0 : collection.floorPrice.toFixed(8)}{' '}
																					{coinMap[collection.chain]}
																				</div>
																				<div className={collection.isFloorUp ? 'smTxt text-success boldTxt' : 'smTxt text-danger boldTxt'}>
																					{collection.floorChange}
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection.volume == "0" ? 0 : collection.volume.toFixed(8)}{' '}
																					{coinMap[collection.chain]}
																				</div>
																				<div className={collection.isVolumeUp ? 'smTxt text-success boldTxt' : 'smTxt text-danger boldTxt'}>
																					{collection.volumeChange}
																				</div>
																			</div>
																		</td>
																	</tr>

																))
														) : (
															<tr>
																<td colSpan={4}>
																	<div className='noItemFound'>
																		<div className='text-center'>
																			<img
																				src={noItemfoundImage}
																				alt='noItemfoundImage'
																				className='img-fluid'
																			/>
																		</div>
																		<div className='nofounttxt mb-4'>
																			{t('No items were found.Try to broaden your search.')}
																		</div>
																	</div>
																</td>
															</tr>

														)}
													</tbody>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='2'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t("Collections")}</th>
															<th className='text-right'>{t("Floor Price")}</th>
															<th className='text-right'>{t("Volume")}</th>
														</tr>
													</thead>
													<tbody>
														{collections && collections.length > 0 ? (
															collections
																.slice(0, 10)
																.map((collection, index) => (

																	<tr>
																		<td className='lgTxt ylwTxt altFont'>
																			{index + 1}
																		</td>
																		<td>
																			<div
																				className='d-flex align-items-center' style={{ cursor: 'pointer' }}
																				onClick={() => {
																					handleCount(
																						collection.collectionName
																					)
																					handleTrending(collection)
																				}}
																			>
																				<img
																					src={collection.logoImage}
																					className='itemIcon mr-3'
																					style={{
																						height: '60px',
																						aspectRatio: '1',
																						borderRadius: '8px'
																					}}
																				/>
																				<h3 className='mr-2'>
																					{collection.collectionName}
																				</h3>
																				{/* <img
																							src={
																								badgeCheck
																							}
																							className='mr-2'
																							height='20'
																						/> */}
																				<img
																					src={
																						chainImageMap[collection.chain]
																					}
																					className='mr-2'
																					height='20'
																				/>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection.floorPrice=="0" ? 0 : collection.floorPrice.toFixed(8)}{' '}
																					{coinMap[collection.chain]}
																				</div>
																				<div className={collection.isFloorUp ? 'smTxt text-success boldTxt' : 'smTxt text-danger boldTxt'}>
																					{collection.floorChange}
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection.volume == "0" ? 0 : collection.volume.toFixed(8)}{' '}
																					{coinMap[collection.chain]}
																				</div>
																				<div className={collection.isVolumeUp ? 'smTxt text-success boldTxt' : 'smTxt text-danger boldTxt'}>
																					{collection.volumeChange}
																				</div>
																			</div>
																		</td>
																	</tr>

																))
														) : (
															<tr>
																<td colSpan={4}>
																	<div className='noItemFound'>
																		<div className='text-center'>
																			<img
																				src={noItemfoundImage}
																				alt='noItemfoundImage'
																				className='img-fluid'
																			/>
																		</div>
																		<div className='nofounttxt mb-4'>
																			{t('No items were found.Try to broaden your search.')}
																		</div>
																	</div>
																</td>
															</tr>

														)}
													</tbody>
												</table>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
						<div className='col-xl-5'>
							<div className='card my-1'>
								<div className='d-flex align-items-sm-center mb-3 mb-xl-0 flex-sm-row flex-column'>
									<h3 className='gradientHeading order-2 order-sm-1 mb-3'>{t("Activity")}</h3>
									<button className='btn gradientBtn ml-auto order-1 order-sm-2 mb-xl-3'
										onClick={() => handleActivity()}
									>
										{t("View All")}</button>
								</div>
								<div
									className='d-flex align-items-center mb-2'
								>
									<Nav tabs className='threeTab'>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab2 === '1'
												})}
												onClick={() => {
													toggle2('1')
													getNfts('all')
												}}
											>
												{t("All")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab2 === '2'
												})}
												onClick={() => {
													toggle2('2')
													getNfts('sales')
												}}
											>
												{t("Sales")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab2 === '3'
												})}
												onClick={() => {
													toggle2('3')
													getNfts('listings')
												}}
											>
												{t("Listings")}
											</NavLink>
										</NavItem>
									</Nav>
								</div>
								<div className='w-100 mt-4'>
									<TabContent activeTab={activeTab2}>
										<TabPane tabId='1'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t("Items")}</th>
															<th className='text-right'>{t("Price")}</th>
															<th className='text-right'>
																<img src={clock} />
															</th>
														</tr>
													</thead>
													<tbody>
														{nfts && nfts.length > 0 ? (
															nfts.slice(0, 10).map(nft => (
																<>
																	<tr onClick={() => navigate(`/assets/${nft.chain}/${nft.contractAddress}/${nft.nftId}/${nft.supplyId}`)} style={{ cursor: "pointer" }}>
																		<td>

																			<div className='d-flex align-items-center labelCnt'>
																				<img src={nft.type== 'sale' ? cart : list} className='mr-2' />
																				{nft.type}
																			</div>

																		</td>
																		<td>
																			<div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
																				{nft.imageFormat &&
																					nft.imageFormat.startsWith('image') ? (
																					<img
																						src={nft.asset}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				) : (
																					<video
																						src={nft.asset}
																						className='itemIcon mr-3'
																						controlsList='nodownload'
																						muted
																						loop
																						autoPlay
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				)}

																				<div className='d-flex flex-column'>
																					<h3 className='mr-2'>
																						{nft.nftName}
																					</h3>
																					<div className='d-flex align-items-center smTxt gryTxt'>
																						{nft.collectionName}
																						{/* <img
																							src={
																								badgeCheck
																							}
																							className='ml-2'
																							height='20'
																						/> */}
																					</div>
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{parseFloat(nft.price.toFixed(8))} {nft.currency}
																				</div>
																				<div className='smTxt gryTxt'>
																					${parseFloat((nft.price * nft.usdPrice).toFixed(8))}
																				</div>
																			</div>
																		</td>
																		<td className='gryTxt text-right'>
																			{hoursToGo(nft.createdAt)} 
																			{/* {moment(nft.createdAt).fromNow()} */}
																			{/* {formatDistance(
																				new Date(nft.createdAt),
																				new Date(),
																				{
																					includeSeconds: true,
																					addSuffix: true
																				}
																			)} */}
																		</td>
																	</tr>
																</>
															))
														) : (
															<tr>
																<td colSpan={4}> 
																<div className='noItemFound'>
																<div className='text-center'>
																	<img
																		src={noItemfoundImage}
																		alt='noItemfoundImage'
																		className='img-fluid'
																	/>
																</div>
																<div className='nofounttxt mb-4'>
																	{t("No items were found.Try to broaden your	search.")}
																</div>
															</div>
																</td>
															</tr>
															
														)}
													</tbody>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='2'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t("Items")}</th>
															<th className='text-right'>{t("Price")}</th>
															<th className='text-right'>
																<img src={clock} />
															</th>
														</tr>
													</thead>
													<>
														<tbody>
															{nfts && nfts.length > 0 ? (
																nfts.slice(0, 10).map(nft => (
																	<tr onClick={() => navigate(`/assets/${nft.chain}/${nft.contractAddress}/${nft.nftId}/${nft.supplyId}`)} style={{ cursor: "pointer" }}>
																		<td>
																			<div className='d-flex align-items-center labelCnt'>
																				<img src={cart} className='mr-2' />
																				{nft.type}
																			</div>
																		</td>

																		<td>
																			<div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
																				{nft.imageFormat &&
																					nft.imageFormat.startsWith('image') ? (
																					<img
																						src={nft.asset}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				) : (
																					<video
																						src={nft.asset}
																						className='itemIcon mr-3'
																						controlsList='nodownload'
																						muted
																						loop
																						autoPlay
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				)}

																				{/* <img
																		src={cryptoPankBros}
																		className='itemIcon mr-3'
																	/> */}
																				<div className='d-flex flex-column'>
																					<h3 className='mr-2'>
																						{nft.nftName}
																					</h3>
																					<div className='d-flex align-items-center smTxt gryTxt'>
																						{nft.collectionName}

																						{/* Crypto Pank Bros */}
																					</div>
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{/* 0.007 ETH */}
																					{parseFloat(nft.price.toFixed(8))} {coinMap[nft.chain]}
																				</div>

																				<div className='smTxt gryTxt'>
																					${parseFloat((nft.price * nft.usdPrice).toFixed(8))}
																				</div>
																			</div>
																		</td>
																		<td className='gryTxt text-right'>
																			{/* 10m */}
																			{/* {formatDistance(
																				new Date(nft.listingDate),
																				new Date(),
																				{
																					includeSeconds: true,
																					addSuffix: true
																				}
																			)} */}
																			{/* {moment(nft.createdAt).fromNow()} */}
																			{hoursToGo(nft.createdAt)} 

																		</td>
																	</tr>
																))
															) : (
																<tr>
																	<td colSpan={4}>
																		<div className='noItemFound'>
																			<div className='text-center'>
																				<img
																					src={noItemfoundImage}
																					alt='noItemfoundImage'
																					className='img-fluid'
																				/>
																			</div>
																			<div className='nofounttxt mb-4'>
																				{t("No items were found.Try to broaden your search.")}
																			</div>
																		</div>
																	</td>
																</tr>

															)}
														</tbody>
													</>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='3'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t("Items")}</th>
															<th className='text-right'>{t("Price")}</th>
															<th className='text-right'>
																<img src={clock} />
															</th>
														</tr>
													</thead>
													<>
														<tbody>
															{nfts && nfts.length > 0 ? (
																nfts.slice(0, 10).map(nft => (
																	<tr onClick={() => navigate(`/assets/${nft.chain}/${nft.contractAddress}/${nft.nftId}/${nft.supplyId}`)} style={{ cursor: "pointer" }}>
																		<td>
																			<div className='d-flex align-items-center labelCnt'>
																				<img src={list} className='mr-2' />
																				{nft.type}
																			</div>
																		</td>
																		<td>
																			<div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
																				{nft.imageFormat &&
																					nft.imageFormat.startsWith('image') ? (
																					<img
																						src={nft.asset}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				) : (
																					<video
																						src={nft.asset}
																						className='itemIcon mr-3'
																						controlsList='nodownload'
																						muted
																						loop
																						autoPlay
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				)}

																				{/* <img
																		src={devilFrens}
																		className='itemIcon mr-3'
																	/> */}
																				<div className='d-flex flex-column'>
																					<h3 className='mr-2'>
																						{nft.nftName}
																					</h3>
																					<div className='d-flex align-items-center smTxt gryTxt'>
																						{nft.collectionName}

																						{/* PFP WORLD */}
																					</div>
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{/* 0.005 ETH */}
																					{parseFloat(nft.price.toFixed(8))} {coinMap[nft.chain]}
																				</div>
																				<div className='smTxt gryTxt'>
																					${parseFloat((nft.price * nft.usdPrice).toFixed(8))}
																				</div>
																			</div>
																		</td>
																		<td className='gryTxt text-right'>
																			{/* 1m */}
																			{/* {formatDistance(
																				new Date(nft.createdAt),
																				new Date(),
																				{
																					includeSeconds: true,
																					addSuffix: true
																				}
																			)} */}
																			{/* {moment(nft.createdAt).fromNow()} */}
																			{hoursToGo(nft.createdAt)} 

																		</td>
																	</tr>
																))
															) : (
																<tr>
																	<td colSpan={4}>
																		<div className='noItemFound'>
																			<div className='text-center'>
																				<img
																					src={noItemfoundImage}
																					alt='noItemfoundImage'
																					className='img-fluid'
																				/>
																			</div>
																			<div className='nofounttxt mb-4'>
																				{t("No items were found.Try to broaden your search.")}
																			</div>
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</>
												</table>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='tabSliderSec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeCategoryTab === 'All'
										})}
										onClick={() => {
											toggle3('All')
										}}
									>
										{t("All")}
									</NavLink>
								</NavItem>
								{category &&
									category.map((category, index) => (
										<NavItem>
											<NavLink
												className={classnames({
													active: activeCategoryTab === category?.name
												})}
												onClick={() => {
													toggle3(category?.name)
												}}
											>
												{category?.name}
											</NavLink>
										</NavItem>
									))}
							</Nav>
						</div>
						
					</div>
				</div>
				<div className='mt-36 ml-xl-5 ml-3'>
							<TabContent activeTab={activeCategoryTab}>
								<TabPane tabId={activeCategoryTab}>
									<Slider {...settings}>
										{fetchCollections && fetchCollections.length > 0 ? (
											fetchCollections.map((collection, index) => (

												<div
													className='card' style={{ cursor: 'pointer' }}
													onClick={() => {
														handleNftRoute(collection)
														handleCount(collection.collectionName);
													}}
												>
													<img
														src={collection.logoImage}
														className='banner' alt=''
													/>
													<div className='infoCnt'>
														<div className='d-flex align-items-center mb-1'>
															<h3 className='heading'>
																{collection.collectionName}
															</h3>
															<img
																src={
																	collection.verification === 'Verified'
																		? badgeCheck
																		: ' '
																}
																className='mr-2'
																height='20'
															/>
														</div>
														<div className='d-flex align-items-center flex-wrap'>
															<div className='valueCnt my-1'>
																{t("Volume")} <span>{collection.volume == "0" ? 0 : collection.volume.toFixed(8)}{' '}
																	{collection.chain == 'ethereum'
																		? t('ETH')
																		: collection.chain == 'polygon'
																			? t('MATIC')
																			: collection.chain == 'binance'
																				? t('BNB')
																				: null}</span>
															</div>
															<div className='valueCnt my-1 ml-auto'>
																{t("Floor")} <span>{collection.floorPrice == "0" ? 0 : collection.floorPrice.toFixed(8)}{' '}
																	{collection.chain == 'ethereum'
																		? t('ETH')
																		: collection.chain == 'polygon'
																			? t('MATIC')
																			: collection.chain == 'binance'
																				? t('BNB')
																				: null}</span>
															</div>
														</div>
													</div>
												</div>

											))
										) : (
											<div className='noItemFound'>
												<div className='text-center'>
													<img
														src={noItemfoundImage}
														alt='noItemfoundImage'
														className='img-fluid'
													/>
												</div>
												<div className='nofounttxt mb-4'>
													{t("No items were found.Try to broaden your search.")}
												</div>
											</div>
										)}
									</Slider>
								</TabPane>
							</TabContent>
				</div>
				
			</section>

			<section className='FeaturedSec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='gradientHeading'>{t('Featured')}.</h3>
						</div>
						
					</div>
				</div>
				<div className='ml-xl-5 ml-3'>
							<Slider {...settings1}>
								{featured && featured?.data?.length > 0 ? (
									featured?.data?.map(item => (
										<div
											className='card' style={{ cursor: 'pointer' }}
											onClick={() => {
												handleCount(item.collectionName)
												handleFeatured(item)
											}}
										>


											{item.imageFormat &&
												item.imageFormat.startsWith('image') || item.imageFormat == '' ? (
												<img
													src={
														item.bannerImage != 'undefined'
															? item.bannerImage
															: bannerSliderImage
													}
													className='banner'
													alt='banner'
												/>
											) : (
												<video
													src={
														item.bannerImage != 'undefined'
															? item.bannerImage
															: bannerSliderImage
													}
													className='banner'
													controlsList='nodownload'
													muted
													loop
													autoPlay
													style={{ height: '314px' }}
												/>
											)}

											{/* <img
												src={
													item.bannerImage != 'undefined'
														? item.bannerImage
														: bannerSliderImage
												}
												className='banner'
												alt='banner'
											/> */}

											<div className='infoCnt'>
												<div className='d-flex align-items-end mb-1 infoTop'>
													<img
														src={
															item.logoImage ? item.logoImage : bannerItemIcon
														}
														className='profPic mr-2'
													/>
													<div className='d-flex align-items-center mb-2'>
														<h3 className='heading'>{item.collectionName}</h3>
														<img
															src={
																item.verification === 'Verified'
																	? badgeCheck
																	: ' '
															}
															className='mr-2'
															height='20'
														/>
													</div>
												</div>
												<div className='d-flex align-items-center flex-wrap'>
													<div className='valueCnt my-1'>
														{t("Volume")}{' '}
														<span>
															{item.volume}{' '}
															{item.chain == 'ethereum'
																? t('ETH')
																: item.chain == 'polygon'
																	? t('MATIC')
																	: item.chain == 'binance'
																		? t('BNB')
																		: null}
														</span>
													</div>
													<div className='valueCnt my-1 ml-auto'>
														{t("Floor")}{' '}
														<span>
															{item.floorPrice}{' '}
															{item.chain == 'ethereum'
																? t('ETH')
																: item.chain == 'polygon'
																	? t('MATIC')
																	: item.chain == 'binance'
																		? t('BNB')
																		: null}
														</span>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className='noItemFound'>
										<div className='text-center'>
											<img
												src={noItemfoundImage}
												alt='noItemfoundImage'
												className='img-fluid'
											/>
										</div>
										<div className='nofounttxt mb-4'>
											{t("No items were found.Try to broaden your search.")}
										</div>
									</div>
								)}
							</Slider>
						</div>
			</section>

			<section className='notableSec tabSliderSec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='gradientHeading'>{t('Notable Collections')}.</h3>
						</div>						
					</div>
				</div>
				<div className='ml-xl-5 ml-3'>
							<Slider {...settings2}>
								{notable && notable?.data?.length > 0 ? (
									notable?.data?.map(item => (
										<div>
											<div className='card' style={{ cursor: 'pointer' }}
												onClick={() => handleNotable(item)}
											>
												<img
													src={
														item.logoImage
															? item.logoImage
															: homeCollectionBanner1
													}
													className='banner'
												/>
												<div className='infoCnt'>
													<div className='d-flex align-items-center mb-1'>
														<h3 className='heading'>{item.collectionName}</h3>
														<img
															src={
																item.verification == 'Verified'
																	? badgeCheck
																	: ''
															}
															className='mr-2'
															height='20'
														/>
													</div>
													<div className='d-flex align-items-center flex-wrap'>
														<div className='valueCnt my-1'>
															{t("Volume")}{' '}
															<span>
																{item.volume == 0 ? 0 : item.volume.toFixed(5)}{' '}
																{item.chain == 'ethereum'
																	? t('ETH')
																	: item.chain == 'polygon'
																		? t('MATIC')
																		: item.chain == 'binance'
																			? t('BNB')
																			: null}
															</span>
														</div>
														<div className='valueCnt my-1 ml-auto'>
															Floor{' '}
															<span>
																{item.floorPrice == 0 ? 0 : item.floorPrice.toFixed(5)}{' '}
																{item.chain == 'ethereum'
																	? t('ETH')
																	: item.chain == 'polygon'
																		? t('MATIC')
																		: item.chain == 'binance'
																			? t('BNB')
																			: null}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className='noItemFound'>
										<div className='text-center'>
											<img
												src={noItemfoundImage}
												alt='noItemfoundImage'
												className='img-fluid'
											/>
										</div>
										<div className='nofounttxt mb-4'>
											{t("No items were found.Try to broaden your search.")}
										</div>
									</div>
								)}

							</Slider>
						</div>
			</section>

			{/* <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered modal-lg" modalClassName="fade disclModal">
                <div class="modal-header fliter-header">
                    <div>
                        <h2 class="mb-0 discTitle">Disclaimer</h2>
                    </div>
                    <div class="singup-close" onClick={toggle}>
                        <a href="#">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="11" fill="url(#paint0_linear_1501_302)" />
                                <path d="M7 7L15 15" stroke="black" />
                                <path d="M7 15L15 7" stroke="black" />
                                <defs>
                                    <linearGradient id="paint0_linear_1501_302" x1="10.6233" y1="2.56727"
                                        x2="30.9048" y2="27.5665" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F4C247" />
                                        <stop offset="0.23" stop-color="#EAB144" />
                                        <stop offset="0.68" stop-color="#D1843D" />
                                        <stop offset="1" stop-color="#BD6137" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="modal-body ">
                    <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions</p>
                    <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged It was popularised in the 1960s with the release </p>
                </div>
                <div className="d-flex justify-content-center CmnBtnFrSt text-center my-3">
                    <button class="btn btn-primary m-2">Accept</button>
                </div>
            </Modal> */}

		</Fragment >
	)
}

export default Home
