import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { axios } from '../../../lib/axios'
import { queryClient } from '../../../lib/react-query'
import { useNavigate } from 'react-router-dom'

export const fetchEarnings = ({address, pageParam}) => {
    return axios.get(`/user/earnings?address=${address}&limit=10&page=${pageParam}`)
}

// export const GetFetchEarnings = ({ address }) => {
// 	return useQuery({
// 		queryKey: ['earnings_list', address],
// 		queryFn: () => fetchEarnings({ address }),
// 		enabled: !!address
// 	})
// }

export const GetFetchEarnings = ({ address, pageParam }) => {
	return useInfiniteQuery({
		queryKey: ['earnings_list', address, pageParam],
		queryFn: ({ pageParam = 1 }) => fetchEarnings({ address, pageParam }),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.length ? allPages?.length + 1 : undefined
		},
		enabled: !!address
	})
}


