import { Modal } from 'reactstrap'

import {
	useSomethingWentWrongModalActions,
	useSomethingWentWrongModalState
} from './store'

import IconModal from '../../../assets/images/IconModal.png'
import { useTranslation } from 'react-i18next'

export function SomethingWentWrongModal() {

	const{t}=useTranslation()
	const { isOpen } = useSomethingWentWrongModalState()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggleSomethingWentWrongModal}
			centered='true'
			className='curMdl createScsMdl'
			backdropClassName='selCurBp'
		>
			<div className='createContent'>
				<button
					className='btn closeBtn'
					onClick={toggleSomethingWentWrongModal}
				>
					<svg
						width='12'
						height='12'
						viewBox='0 0 12 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
							fill='#595F6A'
						/>
					</svg>
				</button>
				<div className='d-flex justify-content-center'>
					<img src={IconModal} />
				</div>
				<h3 className='walletHeading my-3'>Something went wrong</h3>
				<button
					type='button'
					className='btn btn-block darkBtn'
					onClick={toggleSomethingWentWrongModal}
				>
					{t("Okay")}
				</button>
			</div>
		</Modal>
	)
}
