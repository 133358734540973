import React, { useContext, useState, useEffect } from 'react';
import './home-explorer.scss';

import loader from '../../assets/images/Loader.png';
import Itemimg1 from '../../assets/images/collection/Itemimg1.png';
import Itemimg2 from '../../assets/images/collection/Itemimg2.png';
import Itemimg3 from '../../assets/images/collection/Itemimg3.png';
import Itemimg4 from '../../assets/images/collection/Itemimg4.png';
import Itemimg5 from '../../assets/images/collection/Itemimg5.png';
import Itemimg6 from '../../assets/images/collection/Itemimg6.png';
import Itemimg7 from '../../assets/images/collection/Itemimg7.png';
import Itemimg8 from '../../assets/images/collection/Itemimg8.png';
import Itemimg9 from '../../assets/images/collection/Itemimg9.png';
import Itemimg10 from '../../assets/images/collection/Itemimg10.png';

import ItemimgSm1 from '../../assets/images/collection/Itemimg-sm1.png';
import ItemimgSm2 from '../../assets/images/collection/Itemimg-sm2.png';
import ItemimgSm3 from '../../assets/images/collection/Itemimg-sm3.png';
import ItemimgSm4 from '../../assets/images/collection/Itemimg-sm4.png';
import ItemimgSm5 from '../../assets/images/collection/Itemimg-sm5.png';
import ItemimgSm6 from '../../assets/images/collection/Itemimg-sm6.png';
import ItemimgSm7 from '../../assets/images/collection/Itemimg-sm7.png';
import ItemimgSm8 from '../../assets/images/collection/Itemimg-sm8.png';
import ItemimgSm9 from '../../assets/images/collection/Itemimg-sm9.png';
import ItemimgSm10 from '../../assets/images/collection/Itemimg-sm10.png';

import ETHIcon from '../../assets/images/collection/ETHIcon.png';
import shoppingcart from '../../assets/images/collection/shopping-cart.svg';
import primeTick from '../../assets/images/collection/yellow-tick_20px.svg';

import bnbIconSm from '../../assets/images/bnbIconSm.png';
import ethIconSm from '../../assets/images/ethIconSm.png';
import maticIconSm from '../../assets/images/maticIconSm.png';
import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';

// import checkTickIcon from '../../assets/images/toast/checkIcon.svg';
// import removeIcon from '../../assets/images/toast/checkIcon.svg';

// import AlertToast from '../../components/alertToast';
import { Button, Collapse, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { ThemeContext } from '../context/context';
import { axios } from '../../lib/axios'

import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../utils/useDebounce';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import { useNavigate } from 'react-router-dom';

import { useAccount, useNetwork } from 'wagmi';
import minus from '../../assets/images/minus.svg'
import { useConnectWalletModalActions } from '../../components/modals/connect-wallet/store';
import { useCheckoutModalActions } from '../../components/modals/checkout/store';
import { useSwitchNetwork } from '../../hooks/useSwitchNetwork';
import { useBuyNowModalActions } from '../../components/modals/buy-now/store';
import { chainId } from '../../config/constants/chains';
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from '../../utils/toast/customSuccess';
import ResponsivePagination from 'react-responsive-pagination';
import { dropEllipsis, dropEllipsisThenNav } from 'react-responsive-pagination/narrowBehaviour';

const chainIdMap = {
    ethereum: chainId.ETHEREUM,
    binance: chainId.BINANCE,
    polygon: chainId.POLYGON
}

const chainMap = {
    [chainId.ETHEREUM]: 'ethereum',
    [chainId.BINANCE]: 'binance',
    [chainId.POLYGON]: 'polygon'
}

const AllNFTPage = (props) => {
    const gridview = (props.filterview) ? 'g-col-5' : 'g-col-5';

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { address: account, isConnected } = useAccount()
    const { toggleConnectWalletModal, setConnectWalletModalState } =
        useConnectWalletModalActions()
    const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()
    const { switchNetworkAsync } = useSwitchNetwork()
    const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
        useBuyNowModalActions()
    const queryClient = useQueryClient()
    const { chain } = useNetwork()
    const toast = useToast()

    const landPerRow = 6

    const [next, setNext] = useState(landPerRow)

    const [getExplorenft, setGetExploreNft] = useState('')

    const [page, setPage] = useState(1)
    const [count1, setCount] = useState('')

    const [currentPage, setCurrentPage] = useState(1);

    function handlePageChange(page) {
        setCurrentPage(page);
        // ... do something with `page`
    }

    const { exploreChain, setExploreChain, exploreCollection, exploreStatus, setExploreStatus, exploreSortStatusField, setExploreSortStatusField,
        setExploreCollection, exploreTab, exploreSearch, setExploreSearch } = useContext(ThemeContext)

    const leftArrow = () => {
        return (
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>
        )
    }
    const rightArrow = () => {
        return (
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>
        )
    }

    const listtag = [
        {
            name: 'listed'
        },
        {
            name: 'On Aution'
        },
        {
            name: '123-3121'
        },
        {
            name: '123-11,623.442 ETH'
        },
        {
            name: 'ETH'
        }
    ]

    const Status = [
        {
            id: 1,
            name: "All",
            sortStatusField: "",
            // sortStatus: "",
            // sortField: "",
            // sortOrder: "",
            // type: "all",

        },
        {
            id: 2,
            name: "Listed",
            sortStatusField: "list",
            // sortStatus: "true",
            // sortField: "",
            // sortOrder: "",
            // type: "list"
        },
        {
            id: 3,
            name: "On Auction",
            sortStatusField: "auction",
            // sortStatus: "true",
            // sortField: "",
            // sortOrder: "",
            // list: "auction"
        },
        {
            id: 4,
            name: "Sale",
            // sortField: "_id",
            // sortOrder: "desc",
            sortStatusField: "sale",
            // sortStatus: "",
            // type: "new",
        },
        {
            id: 5,
            name: "Has Offers",
            sortStatusField: "make-offer",
            // sortStatus: "",
            // sortField: "",
            // sortOrder: "",
            // type: "offer"
        }

    ]

    // const litstedTagLoop = listtag.map((item) => {
    //     return (
    //         <>
    //             <div className='tagLabel'>
    //                 <span>{item.name}</span>
    //                 <i className="fas fa-times ml-2"></i>
    //             </div>
    //         </>
    //     );
    // });

    const ItemList = [
        {
            imgsrc: Itemimg1,
            imgsmsrc: ItemimgSm1,
            itemadded: false
        },
        {
            imgsrc: Itemimg2,
            imgsmsrc: ItemimgSm2,
            itemadded: true
        },
        {
            imgsrc: Itemimg3,
            imgsmsrc: ItemimgSm3,
            itemadded: true
        },
        {
            imgsrc: Itemimg4,
            imgsmsrc: ItemimgSm4,
            itemadded: false
        },
        {
            imgsrc: Itemimg5,
            imgsmsrc: ItemimgSm5
        },
        {
            imgsrc: Itemimg6,
            imgsmsrc: ItemimgSm6
        },
        {
            imgsrc: Itemimg7,
            imgsmsrc: ItemimgSm7
        },
        {
            imgsrc: Itemimg8,
            imgsmsrc: ItemimgSm8
        },
        {
            imgsrc: Itemimg9,
            imgsmsrc: ItemimgSm9
        },
        {
            imgsrc: Itemimg10,
            imgsmsrc: ItemimgSm10
        },
    ]

    const Chains = [
        {
            id: 1,
            name: "BNB",
            value: "binance",
        },
        {
            id: 2,
            name: "ETH",
            value: "ethereum",
        },
        {
            id: 3,
            name: "MATIC",
            value: "polygon",
        }
    ]

    const [loading, setLoading] = useState(false)

    const [collectionsearch, setCollectionSearch] = useState("")
    const [expCollection, setExpCollection] = useState([])
    const [totalnftcount, setTotalNftCount] = useState(0)
    const debouncedSearchText = useDebounce(exploreSearch, 500)

    const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
    const toggleCollapse1 = () => {
        setIsOpenCollapse1(!isOpenCollapse1);
    }
    const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
    const toggleCollapse2 = () => {
        setIsOpenCollapse2(!isOpenCollapse2);
    }
    const [isOpenCollapse3, setIsOpenCollapse3] = useState(false);
    const toggleCollapse3 = () => {
        setIsOpenCollapse3(!isOpenCollapse3);
    }
    const [isOpenCollapse4, setIsOpenCollapse4] = useState(false);
    const toggleCollapse4 = () => {
        setIsOpenCollapse4(!isOpenCollapse4);
    }
    const [isOpenCollapse5, setIsOpenCollapse5] = useState(false);
    const toggleCollapse5 = () => {
        setIsOpenCollapse5(!isOpenCollapse5);
    }

    const FilterCraetedStatus = (data) => {
        setExploreStatus(data.sortStatusField)
        if (data.sortStatusField == "") {
            setExploreStatus([""])
        }
        else {
            let a = exploreStatus.includes(data.sortStatusField)
            if (a == true) {
                const index = exploreStatus.indexOf(data.sortStatusField);
                if (index > -1) { // only splice array when item is found
                    exploreStatus.splice(index, 1); // 2nd parameter means remove one item only
                    const nerArr = [...exploreStatus]
                    setExploreStatus(nerArr);
                    if (nerArr.length == 0) {
                        setExploreStatus([""]);
                    }
                }

            } else if (a == false) {
                const index = exploreStatus.indexOf("");
                if (index > -1) {
                    exploreStatus.splice(index, 1);
                }
                setExploreStatus(
                    [...exploreStatus, data.sortStatusField]
                );

            }
        }

    }

    const handleCheckboxChange = (item) => {
        let a = exploreChain.includes(item.value)

        if (a == true) {

            const index = exploreChain.indexOf(item.value);
            if (index > -1) { // only splice array when item is found
                exploreChain.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...exploreChain]
                setExploreChain(nerArr);
            }

        } else if (a == false) {
            setExploreChain(
                [...exploreChain, item.value]
            );
        }
    }

    const [collDetail, setCollDetail] = useState([])

    const handleCheckboxCollection = (item) => {

        let a = collDetail?.includes(item.collectionName)
        if (a === true) {
            const index = collDetail.indexOf(item.collectionName);
            if (index > -1) { // only splice array when item is found
                collDetail.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...collDetail]
                setCollDetail(nerArr);
            }
        }
        else {
            setCollDetail([...collDetail, item?.collectionName])
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        GetExplore()
    }, [currentPage, exploreChain, exploreCollection, exploreTab, debouncedSearchText, collDetail, exploreStatus, exploreSortStatusField])

    useEffect(() => {
        GetFilterCollection()

    }, [])

    const GetExplore = async () => {
        setLoading(true)
        const fetch = await axios.get(
            `home/get_all_NFTs?chain=${exploreChain}&collectionName=${collDetail}&category=${exploreTab}&status=${exploreStatus}&page=${currentPage}&limit=100`
        )
        if (fetch.status == true) {
            let cnVerify = fetch.data;
            setCount(Math.ceil(fetch.count / 100))
            const getcollection = await axios.get("home/get_all_collections?filter=")
            if (getcollection.status == true) {
                let getcollectionverify = getcollection.data;
                for (let i = 0; i < cnVerify.length; i++) {
                    for (let j = 0; j < getcollectionverify.length; j++) {
                        if (getcollectionverify[j].collectionName === cnVerify[i].collectionName) {
                            let data = getcollectionverify[j].verification;
                            cnVerify[i]['verification'] = data;

                        }
                    }
                }
                setGetExploreNft(cnVerify)
            }

            setTotalNftCount(fetch.count)
            setLoading(false)
        }
        else {
            setGetExploreNft()
            setTotalNftCount()
            setLoading(false)

        }
    }
    const closesearch = () => {
        setExploreSearch("")

    }

    const closestatus = (data) => {
        setExploreSortStatusField("")
        let a = exploreStatus.includes(data)
        if (a == true) {
            const index = exploreStatus.indexOf(data);
            if (index > -1) { // only splice array when item is found
                exploreStatus.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...exploreStatus]
                setExploreStatus(nerArr);
                if (nerArr.length == 0) {
                    setExploreStatus([""]);
                }
            }

        }
    }

    const clearAll = () => {
        setExploreSearch("")
        setExploreChain([])
        setExploreCollection([])
        GetExplore()
        setExploreStatus([''])
        setExploreSortStatusField("")
    }

    const closechain = (item) => {
        let a = exploreChain.includes(item)

        if (a == true) {

            const index = exploreChain.indexOf(item);
            if (index > -1) { // only splice array when item is found
                exploreChain.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...exploreChain]
                setExploreChain(nerArr);
            }
        }
    }

    const closecollection = (item) => {
        let a = exploreCollection.includes(item)

        if (a == true) {

            const index = exploreCollection.indexOf(item);
            if (index > -1) { // only splice array when item is found
                exploreCollection.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...exploreCollection]
                setExploreCollection(nerArr);
            }

        }
    }

    const GetFilterCollection = async () => {
        const getcollection = await axios.get("home/get_all_collections?filter=")
        if (getcollection.status == true) {
            setExpCollection(getcollection.data)
        }
    }
    const searchItems = async (searchValue) => {
        setCollectionSearch(searchValue)
        const searchcollection = await axios.get(`home/get_all_collections?filter=${searchValue}`)
        if (searchcollection.status == true) {
            setExpCollection(searchcollection.data)
        }
        else {
            setExpCollection("")
        }
    }

    const handleNft = (data, e) => {
        e.preventDefault()
        navigate(`/assets/${data.details.chain}/${data.details.contractAddress}/${data.details.nftId}/${data.details.supplyId}`)

    }

    const litstedTagLoop3 = exploreStatus && exploreStatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel m-1'>
                            <span>{item == "fixed" ? "Listed" : item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const litstedTagLoop1 = exploreCollection && exploreCollection.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel m-1'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = exploreChain && exploreChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel m-1'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closechain(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const ItemCard = getExplorenft && getExplorenft.map((item) => {
        const isVideo = item.details.asset.endsWith('.mp4')
        const isMarketplace = item.details.isMarketplace
        const isOnFixed = item.details.saleType === 'fixed'
        const isOwner = account?.toLowerCase() === item?.owner?.address
        const isCart = item.details.isCart
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

        const handleBuyNow = async (e) => {
            e.stopPropagation()
            setBuyNowModalAssetState(item)
            setCheckoutModalAssetState([item])
            setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] })

            if (!account) {
                toggleConnectWalletModal()
                setConnectWalletModalState({
                    callback: toggleBuyNowModal
                })
                return
            }

            try {
                if (item.details.chain !== chainMap[chain.id]) {
                    await switchNetworkAsync(chainIdMap[item.details.chain])
                }

                toggleBuyNowModal()
            } catch (error) {
                console.error(error)
            }
        }

        const toggleCart = (asset) => (account) => {
            axios
                .post('asset/cart-mutation', {
                    orderId: asset.details.orderId,
                    userAddress: account,
                    status: !asset.details.isCart
                })
                .then((res) => {
                    if (res.data) {
                        toast.success(
                            `Item ${res.data.isCart ? 'added to' : 'removed from'
                            } the cart`
                        )
                        queryClient.invalidateQueries(['nfts'])
                        queryClient.invalidateQueries(['cart'])
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        const handleToggleCart = (e) => {
            e.stopPropagation()
            if (!account) {
                toggleConnectWalletModal()
                setConnectWalletModalState({
                    callback: toggleCart(item)
                })
                return
            } else {
                toggleCart(item)(account)
            }
        }


        return (
            <>
                <div className='grid-col'>
                    <div className={`gridItemExplorerCard ${item.itemadded ? 'active' : ''}`} style={{ cursor: "pointer" }}
                        onClick={(e) => handleNft(item, e)}>
                        <div className='gItemImg'>


                            {!isVideo ? (
                                <img
                                    src={item.details.asset}
                                    className='img-fluid w-100 itemImage'
                                    alt={item.details.name}
                                />
                            ) : (
                                <video
                                    src={item.details.asset}
                                    className='img-fluid w-100 itemImage'
                                    loop
                                    playsinline=''
                                    preload='metadata'
                                    controlslist='nodownload'
                                />
                            )}

                            {/* <div className='hashabsolute'>
                                <span className='hashnum'>#{item.details.supplyId}</span>
                            </div> */}
                            {isMarketplace && <div className='hoverImgCnt'>
                                <div className='d-flex justify-content-between '>
                                    <div>
                                        <img src={item.details.chain == "binance" ? bnbIconSm : item.details.chain == "ethereum" ? ethIconSm : item.details.chain == "polygon" ? maticIconSm : ""} alt="ETHIcon" className='cointypeImg mr-2' />
                                        <span className='hashnum'>#{item.details.supplyId}</span>
                                    </div>
                                    {/* <div className='alarmbadge'>
                                       <i className='fas fa-alarm-clock'></i> 6 Days left
                                    </div> */}
                                </div>
                                {/* <div className='d-flex justify-content-between align-items-center'>
                                    <button className='buybowbtn rounded-pill' type='button'>{t("Buy Now")}</button>
                                    <button className='cartBtnCircle'>
                                        <img src={shoppingcart} alt="shoppingcart" />
                                    </button>
                                </div> */}
                                {!!account &&
                                    isLoggedIn &&
                                    !isOwner &&
                                    isOnFixed && (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <button
                                                className='buybowbtn rounded-pill'
                                                type='button'
                                                style={{ flexGrow: '1' }}
                                                onClick={handleBuyNow}
                                            >
                                                {t('Buy Now')}
                                            </button>
                                            {isConnected && isOnFixed ? (
                                                !isCart ? (
                                                    <button
                                                        className='cartBtnCircle ml-1'
                                                        onClick={handleToggleCart}
                                                    >
                                                        <img
                                                            src={shoppingcart}
                                                            alt='shoppingcart'
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className='cartBtnCircle ml-1'
                                                        onClick={handleToggleCart}
                                                    >
                                                        <img
                                                            src={minus}
                                                            alt='shoppingcart'
                                                        />
                                                    </button>
                                                )
                                            ) : null}
                                        </div>
                                    )}
                            </div>}
                        </div>
                        <div className='botcntCard'>
                            <div className='bottomSmImg'>
                                <img src={item.details.asset} height={73} alt={item.details.name} />
                            </div>
                            <div className='botcntTxt'>
                                <div className='itemsmName'>{item.collection.name} <img src={primeTick} alt='primeTick' /></div>
                                <div className='itemName'>{item.details.name} #{item.details.supplyId}</div>
                                {/* <div className='d-flex justify-content-between'>
                                    <div className="itemPricetxt">{t("Highest Bid")}</div>
                                    <div className="itemPriceval">0.04 ETH</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`} style={{ inset: 0 }}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>

            <div className='row'>
                <div className='col-lg-9'>

                    <div className="mb-4">
                        {/* <div className='d-flex justify-content-between mb-4'>
                            <div className='fs-14 fw-400 dark-text-secondary'>871 items</div>
                            <div className='fs-14 fw-400 dark-text-secondary'>
                                Updated 32 seconds ago
                                <button className='refreshIcon pl-2'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4531 0.96875C14.6172 0.96875 14.7812 1.13281 14.7812 1.29688V5.67188C14.7812 5.86328 14.6172 6 14.4531 6H10.0781C9.88672 6 9.75 5.86328 9.75 5.67188V5.39844C9.75 5.23438 9.88672 5.07031 10.0781 5.07031H13.1953C12.2109 3.21094 10.2422 1.89844 8 1.89844C4.85547 1.89844 2.3125 4.38672 2.14844 7.44922C2.12109 7.64062 1.98438 7.75 1.82031 7.75H1.54688C1.35547 7.75 1.19141 7.61328 1.21875 7.42188C1.38281 3.83984 4.36328 0.96875 8 0.96875C10.4883 0.96875 12.6484 2.33594 13.8516 4.33203V1.29688C13.8516 1.13281 13.9883 0.96875 14.1797 0.96875H14.4531ZM14.4258 7.75C14.6172 7.75 14.7812 7.91406 14.7539 8.10547C14.5898 11.6875 11.6094 14.5312 8 14.5312C5.48438 14.5312 3.32422 13.1914 2.14844 11.1953V14.2031C2.14844 14.3945 1.98438 14.5312 1.82031 14.5312H1.54688C1.35547 14.5312 1.21875 14.3945 1.21875 14.2031V9.82812C1.21875 9.66406 1.35547 9.5 1.54688 9.5H5.92188C6.08594 9.5 6.25 9.66406 6.25 9.82812V10.1016C6.25 10.293 6.08594 10.4297 5.92188 10.4297H2.77734C3.76172 12.3438 5.73047 13.6016 8 13.6016C11.1172 13.6016 13.6602 11.168 13.8242 8.07812C13.8516 7.88672 13.9883 7.75 14.1523 7.75H14.4258Z" fill="#C5C5C5" />
                                    </svg>
                                </button>
                            </div>
                        </div> */}
                        {/* <div className='fiterSelectlabel mb-4'> */}
                        {/* {litstedTagLoop}
                            <div className='tagLabel'>
                                <span className='background'>Backgrounds: </span>
                                <span>Off White A</span>
                                <i className="fas fa-times ml-2"></i>
                            </div> */}

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <span class="cmntxt">
                                    {totalnftcount > 0 ? totalnftcount : ""}{" "}
                                    {totalnftcount > 0 ? t("Items") : ""}
                                </span>
                            </div>
                        </div>

                        <div className='d-flex align-items-center fiterSelectlabel mb-4'>
                            {exploreSearch ?

                                <div className='tagLabel m-1'>
                                    <span>{exploreSearch}</span>
                                    <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch}></i>
                                </div>
                                :
                                ""}

                            {exploreStatus.includes("All") ? "" : litstedTagLoop3}{litstedTagLoop1}{litstedTagLoop2}

                            {exploreSearch || exploreChain && exploreChain.length > 0 || exploreCollection && exploreCollection.length > 0 ||
                                !exploreStatus.includes("") ?
                                <button className='artcrd btn m-1' onClick={clearAll}>{t("Clear All")}</button>
                                :
                                ""
                            }

                        </div>



                        {/* </div> */}

                        {getExplorenft && getExplorenft.length > 0 ?
                            <>
                                <div className={`gridrow ${gridview}`}>
                                    {ItemCard}
                                </div>

                                <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-8'>
                                        <div class="bri_pagenation2">
                                            <nav aria-label="Page ">
                                                <ResponsivePagination
                                                    total={count1}
                                                    current={currentPage}
                                                    onPageChange={page => handlePageChange(page)}
                                                    previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                    nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                    previousClassName="page-link-1"
                                                    nextClassName="page-link-1"
                                                />
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                
                            </>
                            :
                            // <div className={`gridrow ${gridview} justify-content-center`}>
                            <div className='noItemFound'>
                                <div className='text-center'>
                                    <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                </div>
                                <div className='nofounttxt mb-4'>
                                    {t("No items were found.Try to broaden your search.")}
                                </div>
                                {/* <div>
                                        <button className='applyBtn'>{t("Apply")}</button>
                                    </div> */}
                            </div>
                            // </div>
                        }


                        {/* <div className={`gridrow g-col-5`}>
                            {ItemCard}
                        </div> */}
                    </div>                   

                </div>
                <div className='col-lg-3'>
                    <div className='filtercard'>
                        <div>
                            <Button className='filterTit' onClick={toggleCollapse1}>
                                <span>{t("Status")} </span>
                                <i className={`fas ${isOpenCollapse1 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse1}>
                                <div className='collapseCnt'>
                                    {Status && Status.map((data, index) => (
                                        <button className={exploreStatus.length > 0 ? exploreStatus.includes(data.sortStatusField) ? 'filterStatusbtn active' : 'filterStatusbtn' : 'filterStatusbtn'} onClick={() => FilterCraetedStatus(data)}>{data.name}</button>
                                    ))}
                                </div>
                            </Collapse>
                        </div>

                        <div>
                            <Button className='filterTit' onClick={toggleCollapse2}>
                                <span>{t("Chains")} </span>
                                <i className={`fas ${isOpenCollapse2 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse2}>
                                <div className='collapseCnt'>
                                    <div className='filterInnercard'>


                                        {Chains && Chains.map((data, index) => (
                                            <div class="d-flex align-items-center mb-2" key={data.id} >
                                                <label class="cuschk mr-2 mb-0" For={`checkbx1${index + 1}`}>
                                                    <input type="checkbox" id={`checkbx1${index + 1}`} name="chains" checked={exploreChain.length > 0 ? exploreChain.includes(data.value) : false} onChange={() => handleCheckboxChange(data)} />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src={data.value == "binance" ? bnbIconSm : data.value == "ethereum" ? ethIconSm : data.value == "polygon" ? maticIconSm : ""} className="mr-1" width="20px" />
                                                <h3 className='mb-0 filterLabel'>{data.value}</h3>
                                                <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.name}</h3>
                                            </div>
                                        ))}



                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button className='filterTit' onClick={toggleCollapse5}>
                                <span>{t("Collections")}</span>
                                <i className={`fas ${isOpenCollapse5 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse5}>
                                <div className='collapseCnt'>
                                    <div className="form-group formInputs">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText className='px-2'>
                                                    <svg
                                                        width="17"
                                                        height="17"
                                                        viewBox="0 0 17 17"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder={t("Search collection")}
                                                onChange={(e) => searchItems(e.target.value)}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className='filterInnercard'>
                                        <div class="scroller htcheckbox">

                                            {expCollection.length > 0 ?
                                                expCollection.map((data, index) =>
                                                (
                                                    <div class="d-flex align-items-center mb-2" key={data.id}>
                                                        <label class="cuschk mr-2 mb-0" For={`checkbox${index + 1}`}>
                                                            <input type="checkbox" id={`checkbox${index + 1}`} name="collectionchoose"
                                                                // checked={exploreCollection?.length > 0 ? exploreCollection?.includes(data.collectionName) : false}
                                                                checked={collDetail?.length > 0 ? collDetail?.includes(data.collectionName) : false}

                                                                onChange={() => handleCheckboxCollection(data)} />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <h3 className='mb-0 filterLabel'>{data.collectionName}</h3>
                                                        <img src={data.verification == "Verified" ? yellowTick : ""} className="ml-1" />
                                                        <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.totalItems}</h3>
                                                    </div>
                                                ))
                                                :
                                                "No Data Found"
                                            }


                                        </div>
                                    </div>



                                </div>
                            </Collapse>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default AllNFTPage;