import React, { useContext, useEffect, useState } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Modal,
	UncontrolledPopover,
	PopoverBody,
	UncontrolledTooltip
} from 'reactstrap'
import './profileActive.scss'
import tblUserImg1 from '../../../assets/images/collection/tblUserImg1.png'
import tblUserImg2 from '../../../assets/images/collection/tblUserImg2.png'
import tblUserImg3 from '../../../assets/images/collection/tblUserImg3.png'
import tblUserImg4 from '../../../assets/images/collection/tblUserImg4.png'
import tblUserImg5 from '../../../assets/images/collection/tblUserImg5.png'
import tblUserImg6 from '../../../assets/images/collection/tblUserImg6.png'
import tblUserImg7 from '../../../assets/images/collection/tblUserImg7.png'
import tblUserImg8 from '../../../assets/images/collection/tblUserImg8.png'
import tblUserImg9 from '../../../assets/images/collection/tblUserImg9.png'
import tblUserImg10 from '../../../assets/images/collection/tblUserImg10.png'
import tblUserImg11 from '../../../assets/images/collection/tblUserImg11.png'
import tblUserImg12 from '../../../assets/images/collection/tblUserImg12.png'

import saleIcon from '../../../assets/images/collection/saleIcon.svg'
import transferIcon from '../../../assets/images/collection/transferIcon.svg'
import listIcon from '../../../assets/images/collection/listIcon.svg'
import collectionOfferIcon from '../../../assets/images/collection/collectionOfferIcon.svg'
import offerIcon from '../../../assets/images/collection/offerIcon.svg'
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg'
import { ThemeContext } from '../../context/context'
import IconModal from '../../../assets/images/IconModal.png'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'

import PaginationComponent from 'react-reactstrap-pagination'
import { useToast } from '../../../components/alertToast'
import { axios } from '../../../lib/axios'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
	useDisconnect,
	useBalance
} from 'wagmi'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useDebounce } from '../../../utils/useDebounce'
import auctionbnb from '../../AbiArray/MarketplaceBnbabi.json'
import auctioneth from '../../AbiArray/MarketplaceEthabi.json'
import auctionmatic from '../../AbiArray/MarketplaceMaticabi.json'
import ContractDetails from '../../ContractAddress'
import Web3 from 'web3'
import { capitalize } from 'lodash'
import loader1 from '../../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';

import { chainId } from '../../../config/constants/chains'

import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}


const landPerRow = 6

const ActivityCollection = () => {
	const { t } = useTranslation()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const [loading, setLoading] = useState(false)
	let [color, setColor] = useState('#ffffff')
	const navigate = useNavigate()

	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()

	const { id } = useParams()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const { chain: network } = useNetwork()
	const chainId = network?.id

	const toast = useToast()
	const { activelistingcollectionsearch, setActivelistingCollectionSearch } =
		useContext(ThemeContext)
	const { chooselistingchain, setListingChain } = useContext(ThemeContext)
	const { minAmount, setMinAmount } = useContext(ThemeContext)
	const { maxAmount, setMaxAmount } = useContext(ThemeContext)
	const { singlecollectionList, setSingleCollectionList } =
		useContext(ThemeContext)
	const { status, setStatus } = useContext(ThemeContext)

	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)
	// const debouncedSearchText = useDebounce(activelistingcollectionsearch, 500)

	useEffect(() => {

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})

		GetActivityNFT()
	}, [
		address,
		page,
		status,
		activelistingcollectionsearch,
		status == true
			? chooselistingchain == 'ETH'
				? 'ethereum'
				: chooselistingchain == 'BNB'
					? 'binance'
					: chooselistingchain == 'MATIC'
						? 'polygon'
						: ''
			: '',
		status == true ? minAmount : '', ,
		status == true ? maxAmount : '', ,
		singlecollectionList,
		id
	])

	const [getactivitydnft, setGetActivityNft] = useState('')
	const [totalactivitynftcount, setTotalActivityNftCount] = useState('')
	const [totalnftcount, setTotalNftCount] = useState('')

	function handlePageChange(page) {
		setPage(page);
		// ... do something with `page`
	}

	console.log("activelistingcollectionsearch",activelistingcollectionsearch!=='' || status==true || singlecollectionList.length>0 ? 1 : page)

	const GetActivityNFT = async () => {
		setLoading(true)
		let data = {
			limit: 15,
			page: activelistingcollectionsearch!=='' || status==true || singlecollectionList.length>0 ? 1 : page,
			address: id ? id : address,
			filter: activelistingcollectionsearch,
			minPrice: status == true ? minAmount : '',
			maxPrice: status == true ? maxAmount : '',
			collection: singlecollectionList,
			chain:
				status == true
					? chooselistingchain == 'ETH'
						? 'ethereum'
						: chooselistingchain == 'BNB'
							? 'binance'
							: chooselistingchain == 'MATIC'
								? 'polygon'
								: ''
					: ''
		}
		const fetch = await axios.post(`user/get_activeListing`, data)
		if (fetch.status == true) {
			let cnVerify = fetch.data

			const getcollection = await axios.post('user/getListedCollection', {
				address: id ? id : address
			})
			if (getcollection.status == true) {
				let getcollectionverify = getcollection.data
				for (let i = 0; i < cnVerify.length; i++) {
					for (let j = 0; j < getcollectionverify.length; j++) {
						if (
							getcollectionverify[j].collectionName ===
							cnVerify[i].collectionName
						) {
							let data = getcollectionverify[j].verification
							cnVerify[i]['verification'] = data
						}
					}
				}
				setGetActivityNft(cnVerify)
			}
			setTotalActivityNftCount(fetch.count)
			setTotalNftCount(Math.ceil(fetch.count / 15))
			setLoading(false)
		} else {
			setGetActivityNft('')
			setTotalActivityNftCount('')
			setTotalNftCount('')
			setLoading(false)
		}
	}

	const litstedCollection =
		singlecollectionList &&
		singlecollectionList.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closeCollection(item)}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const [singlecancel, setSingleCancel] = useState('')
	const cancel = data => {
		setSingleCancel(data)
		toggle()
	}

	const closesearch = () => {
		setActivelistingCollectionSearch('')
	}

	const closesearch1 = () => {
		setMinAmount('')
		setMaxAmount('')
		setStatus(false)
	}
	const closeCollection = item => {
		let a = singlecollectionList.includes(item)
		if (a == true) {
			const index = singlecollectionList.indexOf(item)
			if (index > -1) {
				// only splice array when item is found
				singlecollectionList.splice(index, 1) // 2nd parameter means remove one item only
				const nerArr = [...singlecollectionList]
				setSingleCollectionList(nerArr)
			}
		}
	}

	const clearAll = () => {
		setActivelistingCollectionSearch('')
		// setListingChain()
		setMinAmount('')
		setMaxAmount('')
		setSingleCollectionList([])
		setStatus(false)
	}

	const [loader, setloader] = useState(false)

	const getback = () => {
		setModal(!modal)
	}

	const { pro } = useContractProvider()
	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const web3 = new Web3(pro)
	const bnbAuction = new web3.eth.Contract(
		auctionbnb,
		ContractDetails.AuctionBNB
	)
	const ethAuction = new web3.eth.Contract(
		auctioneth,
		ContractDetails.AuctionETH
	)
	const polygonAuction = new web3.eth.Contract(
		auctionmatic,
		ContractDetails.AuctionPolygon
	)

	let contract
	const cancelListing = async () => {
		setloader(true)

		if (address) {
			if (singlecancel.saleType == 'auction') {
				if (singlecancel.chain === 'ethereum') {
					contract = ethAuction
				} else if (singlecancel.chain === 'binance') {
					contract = bnbAuction
				} else if (singlecancel.chain === 'polygon') {
					contract = polygonAuction
				}

				try {
					if (walletType === 'okx') {
						if (singlecancel.chain !== chainMap[parseInt(window.okxwallet.chainId)]) {
							await switchNetwork(chainIdMap[singlecancel.chain])
						}
					} else {
						if (singlecancel.chain === 'binance' && chainId !== 97) {
							await switchNetworkAsync(97)
						} else if (singlecancel.chain === 'ethereum' && chainId !== 11155111) {
							await switchNetworkAsync(11155111)
						} else if (singlecancel.chain === 'polygon' && chainId !== 80001) {
							await switchNetworkAsync(80001)
						}
					}
				} catch (error) {
					console.error(error)
					toast.error('User rejected the switch network')
					return
				}
				try {
					let cancellisting = await contract.methods
						.cancelOrder(
							id ? id : address.toString(),
							singlecancel.contractAddress,
							singlecancel.nftId
						)
						.send({ from: address.toString() })
						.on('transactionHash', async hash => { })
						.on('receipt', async approveresult => {
							let data = {
								_id: singlecancel._id,
								address: id ? id : address,
								assetId: singlecancel.assetId,
								type: singlecancel.saleType
							}

							const fetch = await axios.post(`user/cancel_sell`, data)
							if (fetch.status == true) {
								setTimeout(() => {
									setModal(!modal)
									toast.success(fetch.message)
									GetActivityNFT()
									setloader(false)
								}, 1000)
							} else {
								setModal(!modal)
								setloader(false)
								toast.error(fetch.message)
							}
						})
				} catch (err) {
					setModal(!modal)
					setloader(false)
					toast.error('Transaction Rejected')
				}
			} else {
				let data = {
					_id: singlecancel._id,
					address: id ? id : address,
					assetId: singlecancel.assetId,
					type: singlecancel.saleType
				}

				const fetch = await axios.post(`user/cancel_sell`, data)
				if (fetch.status == true) {
					setTimeout(() => {
						setModal(!modal)
						toast.success(fetch.message)
						GetActivityNFT()
						setloader(false)
					}, 1000)
				} else {
					setModal(!modal)
					setloader(false)
					toast.error(fetch.message)
				}
			}
		}
		else {
			setloader(false)
			toast.error("Please connect wallet")
		}
	}

	const listViewrow =
		getactivitydnft &&
		getactivitydnft.map((item, index) => (
			<>
				<tr onClick={(e) =>
					e.stopPropagation(navigate(
						`/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`
					))
				}
					style={{ cursor: 'pointer' }}>
					<td style={{ textTransform: 'capitalize' }}>{item.saleType}</td>
					<td>
						<div className='d-flex align-items-center'>
							<div className='tblImg'>
								{/* <img src={item.asset} alt={`item.imgsrc`} /> */}
								{typeof item.asset == 'string' && item.asset !== undefined ? (
									item.imageFormat.startsWith('video') ? (
										<video src={item.asset} alt='Uploaded' muted autoPlay style={{ width: "60px", height: "70px", borderRadius: "12px" }} />
									) : item.asset !== 'undefined' ? (
										<img src={item.asset} alt={`item.imgsrc`} />
									) : (
										''
									)
								) : (
									''
								)}
							</div>
							<div>
								<div className='fw-600 ml-2'>
									{item.nftName} #{item.supplyId}
								</div>
								<div className='smTxt dark-text-secondary ml-2'>
									{item.collectionName}{' '}
									<img
										src={item.verification == 'Verified' ? yellowTick : ''}
										height='18'
										className='ml-1'
									/>
								</div>
							</div>
						</div>
					</td>
					<td className='text-right'>
						<div>
							{item.price.toFixed(8)} {item.currency}
						</div>
						<div className='fs-12 dark-text-secondary'>
							${(item.price * item.usdPrice).toFixed(8)}
						</div>
					</td>
					<td>{item.floordifference ? item.floordifference.toFixed(2) : "0"}% {item.up == true ? t('Above') : t('below')}</td>
					<td className='text-right'>
						{item.saleExpiry == 0 ? '-' : moment(item.saleExpiry).fromNow()}
					</td>

					{!!address && address.toLowerCase() === item.address ? (
						<td className='text-right'>
							<span className='cancelTag' onClick={(e) => e.stopPropagation(cancel(item))}>
								{t('Cancel')}
							</span>
						</td>
					) : null}
				</tr>
			</>
		))

	return (
		<>
			<div className={loading ? 'spinner' : 'd-none'}>
				<img src={loader1} alt='' className='spinAnimate' />
			</div>

			<div className='row mb-4'>
				<div className='col-lg-6'>
					<span class='cmntxt'>
						{totalactivitynftcount > 0 ? totalactivitynftcount : ''}{' '}
						{totalactivitynftcount > 0 ? 'items' : ''}
					</span>
				</div>
			</div>

			<div className='fiterSelectlabel mb-4'>
				{activelistingcollectionsearch ? (
					<div className='tagLabel'>
						<span>{activelistingcollectionsearch}</span>
						<i
							className='fas fa-times ml-2'
							style={{ cursor: 'pointer' }}
							onClick={closesearch}
						></i>
					</div>
				) : (
					''
				)}

				{litstedCollection}
				{status == true ? (
					typeof chooselistingchain !== 'undefined' &&
						minAmount &&
						maxAmount ? (
						<div className='tagLabel'>
							<span>
								{chooselistingchain} {minAmount}-{maxAmount}
							</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closesearch1}
							></i>
						</div>
					) : typeof chooselistingchain !== 'undefined' && minAmount ? (
						<div className='tagLabel'>
							<span>
								{chooselistingchain} {minAmount}
							</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closesearch1}
							></i>
						</div>
					) : typeof chooselistingchain !== 'undefined' && maxAmount ? (
						<div className='tagLabel'>
							<span>
								{chooselistingchain} {maxAmount}
							</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closesearch1}
							></i>
						</div>
					) : (
						''
					)
				) : (
					''
				)}

				{activelistingcollectionsearch ||
					(status == true
						? (typeof chooselistingchain !== 'undefined' && minAmount) ||
						maxAmount
						: '') ||
					singlecollectionList.length > 0 ? (
					<button className='clearAllBtn' onClick={clearAll}>
						{t('Clear All')}
					</button>
				) : (
					''
				)}
			</div>

			<div className='table-responsive'>
				<table className='table table-borderless activityTable'>
					{getactivitydnft && getactivitydnft.length > 0 ? (
						<thead>
							<tr>
								<th>{t('Type')}</th>
								<th>{t('Items')}</th>
								<th className='text-right'>{t('Price')}</th>
								<th>{t('Floor Difference')}</th>
								<th className='text-right'>{t('Expiration')}</th>
								<th></th>
							</tr>
						</thead>
					) : (
						''
					)}
					<tbody>
						{getactivitydnft && getactivitydnft.length > 0 ? (
							listViewrow
						) : (
							<div className='noItemFound justify-content-center'>
								<div className='text-center'>
									<img
										src={noItemfoundImage}
										alt='noItemfoundImage'
										className='img-fluid'
									/>
								</div>
								<div className='nofounttxt mb-4'>
									{t("No items were found.Try to broaden your search.")}
								</div>
								{/* <div>
									<button className='applyBtn'>{t('Apply')}</button>
								</div> */}
							</div>
						)}
					</tbody>
				</table>
			</div>

			<div className='row justify-content-center'>
				<div className='col-lg-4 col-md-6 col-sm-8'>
					<div class="bri_pagenation2">
						{getactivitydnft && getactivitydnft.length > 0 ? (
							<div className=''>
								<nav aria-label="Page ">
									<ResponsivePagination
										total={totalnftcount}
										current={page}
										onPageChange={page => handlePageChange(page)}
										previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
										nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
										previousClassName="page-link-1"
										nextClassName="page-link-1"
									/>
								</nav>
								{/* <PaginationComponent
						size='sm'
						defaultActivePage={1}
						totalItems={totalnftcount}
						maxPaginationNumbers={3}
						pageSize={15}
						onSelect={page => setPage(page)}
					/> */}
							</div>
						) : (
							''
						)}
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
			>
				{loader == true ? (
					<div className='d-flex justify-content-center align-items-center loaderCont '>
						<img src={walletLoaderCnt} />
						<div className='loaderAnimCnt'>
							<img src={walletLoader} className='loaderAnimation' />
						</div>
					</div>
				) : (
					<div className='createContent'>
						<div className='w-100'>
							<div className='d-flex justify-content-center'>
								<img src={IconModal} />
							</div>
							<h3 className='walletHeading my-2'>Are you sure?</h3>
							<h3 className='walletSubHeading mb-3 '>
								This will unpublish this sale from warrior
							</h3>
							<button
								type='button'
								className='btn btn-block gradientBtn mb-2'
								onClick={cancelListing}
							>
								Cancel listing
							</button>
							<button
								type='button'
								className='btn btn-block darkBtn'
								onClick={getback}
							>
								Get Back
							</button>
						</div>
					</div>
				)}
			</Modal>
		</>
	)
}

export default ActivityCollection
