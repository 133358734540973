const ContractDetails = {
    Eth_contract: "0x975f794b4f54a7719c996eda54cb26e34ed8023f",
    Bnb_contract: "0x9f8a1ae206e80c3325c263d359699336553fbbd4",
    Polygon_contract: "0x132b65cbb9cf21bead5d3606e895ed6a113a630b",
    Tether_contract: "0x6C1b9f8221E2f7FC7Bd41E9f38f3a88B723E2d38",
    Busd_contract: "0x821581ad0c87b5e30ccfabcc4f437fba38aa56c8",
    Wor_contract: "0xdd27def85b7eb62a149d8627dfb0eddfac3a82e8",

    //Marketplace


    // Marketplace_Eth:"0x1ae191848FCa9ba84d89dAc08F85803289775A1e",
    Marketplace_Eth:"0xf2A37fBf86B1D2ca3A00c3249C66339065782EF5",   // new contract - Leo

    //Minimal Proxy

    // Proxy_ETH:"0xb715b9fd8f656c51228e2fe3c34e4e24af5e0a36",
    Proxy_ETH:"0x095CAc9fc267d87af18cec1Fe6CB800EBba0F8E8", // new contract - Leo
    
    //Proxy_BNB:"0x4a4c4391a5e30f703fdfbaa271b01f07252122ca",
    Proxy_BNB:"0x9f0cdFa4C9c80B78F498E77C31c8284736346ae0",
    Proxy_Polygon:"0x17e57e7503fb4619dd51c6584bcc707cb32d8a4d",

    // AuctionContract

    AuctionBNB: "0xa18a560909f4f3e28179a74c7ab2f60340fd06cf",
    // AuctionETH: "0x2483c93ab2a2be334d4641ce372109f3600167fb",
    AuctionETH: "0x5BF34D09a3eFD693DcF0e1Be999125192d2ef65E",  // new contract - Leo
    AuctionPolygon: "0x8412d3c668751f9a104354274c1d2089b9e5b81a"

};
export default ContractDetails;