import React, { useContext, useEffect, useState } from 'react';

// import './listviewItem.scss';
// import style from './profile.module.scss';
// import '../../assets/scss/listviewItem.scss'

import tblUserImg1 from '../../assets/images/collection/tblUserImg1.png';
import tblUserImg2 from '../../assets/images/collection/tblUserImg2.png';
import tblUserImg3 from '../../assets/images/collection/tblUserImg3.png';
import tblUserImg4 from '../../assets/images/collection/tblUserImg4.png';
import tblUserImg5 from '../../assets/images/collection/tblUserImg5.png';
import tblUserImg6 from '../../assets/images/collection/tblUserImg6.png';
import tblUserImg7 from '../../assets/images/collection/tblUserImg7.png';
import tblUserImg8 from '../../assets/images/collection/tblUserImg8.png';
import tblUserImg9 from '../../assets/images/collection/tblUserImg9.png';
import tblUserImg10 from '../../assets/images/collection/tblUserImg10.png';
import tblUserImg11 from '../../assets/images/collection/tblUserImg11.png';
import tblUserImg12 from '../../assets/images/collection/tblUserImg12.png';
import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import ETHIcon from '../../assets/images/collection/ETHIcon.png';
import BNBIcon from '../../assets/images/collection/bnbCoin.png';
import MATICIcon from '../../assets/images/collection/maticIconSm.png'
import ScaleLoader from "react-spinners/ScaleLoader";
import { useToast } from '../../utils/toast/customSuccess'
import {
    useAccount,
} from 'wagmi'
import { axios } from '../../lib/axios'
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeContext } from '../context/context';
import { useTranslation } from 'react-i18next';
import PaginationComponent from 'react-reactstrap-pagination'
import loader from '../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';

const landPerRow = 6

const tableImg = [
    {
        imgsrc: tblUserImg1,
        ownername: 'Ahmad Dokidis'
    },
    {
        imgsrc: tblUserImg2,
        ownername: 'Allison Curtis'
    },
    {
        imgsrc: tblUserImg3,
        ownername: 'Marcus Botosh'
    },
    {
        imgsrc: tblUserImg4,
        ownername: 'Cheyenne Rosser'
    },
    {
        imgsrc: tblUserImg5,
        ownername: 'Davis Passaquindi...'
    },
    {
        imgsrc: tblUserImg6,
        ownername: 'Davis Passaquindi...'
    },
    {
        imgsrc: tblUserImg7,
        ownername: 'Anika Saris'
    },
    {
        imgsrc: tblUserImg8,
        ownername: 'Ahmad Dokidis'
    },
    {
        imgsrc: tblUserImg9,
        ownername: 'Allison Curtis'
    },
    {
        imgsrc: tblUserImg10,
        ownername: 'Marcus Botosh'
    },
    {
        imgsrc: tblUserImg11,
        ownername: 'Cheyenne Rosser'
    },
    {
        imgsrc: tblUserImg12,
        ownername: 'Davis Passaquindi...'
    },

]


const listtag = [
    {
        name: 'listed'
    },
    {
        name: 'On Auction'
    },
    {
        name: 'Binance'
    },
    {
        name: 'Loaded Lions'
    },
    {
        name: '123-11,623.442 ETH'
    },
    {
        name: 'ETH'
    }
]

const litstedTagLoop = listtag.map((item) => {
    return (
        <>
            <div className='tagLabel'>
                <span>{item.name}</span>
                <i className="fas fa-times ml-2"></i>
            </div>
        </>
    );
});



const ListViewItem = () => {


    // const rows = [];
    // for (let i = 0; i < 12; i++) {
    //     rows.push(

    //     );
    // }

    const { t } = useTranslation()



    const { address, isConnected } = useAccount()

    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const { id } = useParams()


    const { collectedsearch, setCollectedSearch } = useContext(ThemeContext)
    const { collectedfilter, setCollectedFilter } = useContext(ThemeContext)
    const { collectedstatus, setcollectedStatus } = useContext(ThemeContext)
    const { checkcollectedChain, setcollectedCheckChain } = useContext(ThemeContext)
    const { singlecollectedcollectionList, setSingleCollectedCollectionList } = useContext(ThemeContext)
    const { chooselistingcollectedchain, setListingCollectedChain } = useContext(ThemeContext)
    const { collectedminAmount, setCollectedMinAmount } = useContext(ThemeContext)
    const { collectedmaxAmount, setCollectedMaxAmount } = useContext(ThemeContext)
    const { Collectedstatus, setCollectedStatus } = useContext(ThemeContext)

    const { createdfiltersortby, setCreatedFilterSortby } = useContext(ThemeContext)
    const { createdfiltersortorder, setCreatedFilterSortorder } = useContext(ThemeContext)

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

        GetCollected()
    }, [address, Collectedstatus, collectedsearch, collectedfilter, collectedstatus, checkcollectedChain, singlecollectedcollectionList, Collectedstatus == true ? chooselistingcollectedchain : "",
        Collectedstatus == true ? collectedminAmount : "",
        Collectedstatus == true ? collectedmaxAmount : "", createdfiltersortby, createdfiltersortorder, id])

    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [getcollected, setGetcollected] = useState('')
    const [totalactivitycount, setTotalActivity] = useState("")
    const [count, setCount] = useState("")

    function handlePageChange(page) {
        setPage(page);
        // ... do something with `page`
    }

    const GetCollected = async () => {
        setLoading(true)
        const fetch = await axios.get(`user/collected-nfts?address=${id ? id : address}&page=${collectedsearch !== "" || singlecollectedcollectionList.length > 0 || checkcollectedChain.length > 0 || collectedstatus[0] != [''] || Collectedstatus == true || createdfiltersortorder !== "desc" || createdfiltersortby !== "_id" ? 1 : page}&limit=15&sortField=${createdfiltersortby}&sortOrder=${createdfiltersortorder}&filter=${collectedsearch}&collectionName=${singlecollectedcollectionList}&chain=${checkcollectedChain}&sortStatusField=${collectedstatus}&currency=${Collectedstatus == true ? chooselistingcollectedchain : ""}&minPrice=${Collectedstatus == true ? collectedminAmount : ""}&maxPrice=${Collectedstatus == true ? collectedmaxAmount : ""}`)
        if (fetch.status == true) {
            setGetcollected(fetch.data)
            setTotalActivity(Math.ceil(fetch.count / 15))
            setCount(fetch.count)
            setLoading(false)
        }
        else {
            setGetcollected('')
            setTotalActivity('')
            setCount('')
            setLoading(false)
        }
    }

    const listViewrow = getcollected && getcollected.map((item, index) =>
        <>
            <tr onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`)} style={{ cursor: "pointer" }}>
                {/* <td>
                    <label class="cuschk mr-2 mb-0" For={`checkbx11${index}`}>
                        <input type="checkbox" id={`checkbx11${index}`} />
                        <span class="checkmark"></span>
                    </label>
                </td> */}
                <td >
                    <div className='d-flex align-items-center'>
                        <div className='tblImg'>


                            {typeof item.asset == "string" && item.asset !== undefined ?

                                item.imageFormat.startsWith('video') ?
                                    <video

                                        src={item.asset}
                                        alt="Uploaded"
                                        muted
                                        autoPlay
                                        style={{ width: "50px", height: "35px", objectFit: "cover" }}
                                    />
                                    :
                                    item.asset !== 'undefined' ?
                                        <img src={item.asset} alt={`item.imgsrc`} />

                                        :
                                        ""
                                :
                                ""
                            }

                        </div>

                        <div>
                            <div className='fw-600 ml-2'>{item.nftName} #{item.supplyId}</div>
                            <div className='smTxt dark-text-secondary ml-2'>{item.collectionName}
                                <img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /></div>
                        </div>
                    </div>
                </td>
                <td><span className="dark-text-grey">{item.floorPrice ? item.floorPrice : "--"} {item.floorPrice ? item.chain == "binance" ? "BNB" : item.chain == "ethereum" ? "ETH" : item.chain == "polygon" ? "MATIC" : "" : ""}</span></td>
                <td className='text-right' style={{ opacity: "0.5" }}>{item.highestBid ? item.highestBid : "--"} {item.highestBid ? item.chain == "binance" ? "BNB" : item.chain == "ethereum" ? "ETH" : item.chain == "polygon" ? "MATIC" : "" : ""}</td>
                {/* <td className='text-right'>{item.listingprice ? item.listingprice : "-"} {item.listingprice ? item.currency : ""}</td> */}
                <td className='text-right'>{item.price ? item.price : "Not Listed"} {item.price ? item.chain == "binance" ? "BNB" : item.chain == "ethereum" ? "ETH" : item.chain == "polygon" ? "MATIC" : "" : ""}</td>
                <td className="text-right"><span className="dark-text-grey" style={{ opacity: "0.5" }}>
                    {/* {item.floordifference ? item.floordifference : "0"} % {item.up == true ? t('Above') : t('below')} */}
                    --
                </span></td>
                {/* <td className='text-right'>
                    {item.isMarketplace == true ?
                        ""
                        :
                        <button className='buybowbtn rounded-pill' style={{ backgroundColor: "yellow" }}
                            type='button'
                            onClick={() =>
                                navigate(
                                    `/listforsale/${item.contractAddress}/${item.nftId}/${item.supplyId}`
                                )
                            }>{t('List For Sale')}</button>
                    }

                </td> */}
            </tr>
        </>
    )


    const navigate = useNavigate()


    const closecollectedsearch = () => {
        setCollectedSearch("")
    }
    const closecollectedfilter = () => {
        setCollectedFilter('Recently Received')
        setCreatedFilterSortby('_id')
        setCreatedFilterSortorder('desc')
    }
    const closecollectedstatus = (data) => {
        // setcollectedStatus('')
        let a = collectedstatus.includes(data)
        if (a == true) {
            const index = collectedstatus.indexOf(data);
            if (index > -1) { // only splice array when item is found
                collectedstatus.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...collectedstatus]
                setcollectedStatus(nerArr);
                if (nerArr.length == 0) {
                    setcollectedStatus([""]);
                }
            }

        }
    }

    const litstedTagLoop3 = collectedstatus && collectedstatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollectedstatus(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = checkcollectedChain && checkcollectedChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollectedchain(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const litstedCollection = singlecollectedcollectionList && singlecollectedcollectionList.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closeCollection(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const closecollectedchain = (item) => {
        let a = checkcollectedChain.includes(item)

        if (a == true) {

            const index = checkcollectedChain.indexOf(item);
            if (index > -1) { // only splice array when item is found
                checkcollectedChain.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...checkcollectedChain]
                setcollectedCheckChain(nerArr);
            }
        }
    }

    const closeCollection = (item) => {
        let a = singlecollectedcollectionList.includes(item)
        if (a == true) {
            const index = singlecollectedcollectionList.indexOf(item);
            if (index > -1) { // only splice array when item is found
                singlecollectedcollectionList.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...singlecollectedcollectionList]
                setSingleCollectedCollectionList(nerArr);
            }
        }
    }

    const closesearch1 = () => {
        setCollectedMinAmount("")
        setCollectedMaxAmount("")
        setCollectedStatus(false)
    }


    const clearAll = () => {
        setCollectedSearch("")
        setcollectedStatus([""])
        setcollectedCheckChain([])
        setSingleCollectedCollectionList([])
        setCollectedMinAmount("")
        setCollectedMaxAmount("")
        setCollectedStatus(false)
        setCollectedFilter('Recently Received')
        setCreatedFilterSortby('_id')
        setCreatedFilterSortorder('desc')
    }

    return (
        <>
            <div className={loading ? 'spinner' : 'd-none'}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>

            <div className='py-3'>
                {/* <div className='row mb-4'>
                </div>
                <div className='fiterSelectlabel mb-4'>
                    {litstedTagLoop}

                    <button className='clearAllBtn'>Clear All</button>
                </div> */}
                <div className='row mb-4'>
                </div>

                <div className='col-lg-6'><span class="cmntxt">{count ? count : ""} {count ? t("Items") : ""}</span></div>
                <div className='fiterSelectlabel mb-4'>
                    {collectedsearch ?
                        <div className='tagLabel'>
                            <span>{collectedsearch}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closecollectedsearch}></i>
                        </div>
                        :
                        ""}
                    {collectedfilter == "Recently Received" ? "" :
                        collectedfilter ?
                            <div className='tagLabel'>
                                <span>{collectedfilter}</span>
                                <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closecollectedfilter}></i>
                            </div>
                            :
                            ""}

                    {collectedstatus.includes("") ? "" : litstedTagLoop3}
                    {litstedTagLoop2}{litstedCollection}

                    {Collectedstatus == true ?
                        typeof chooselistingcollectedchain !== "undefined" && collectedminAmount && collectedmaxAmount ?
                            <div className='tagLabel'>
                                <span>{chooselistingcollectedchain} {collectedminAmount}-{collectedmaxAmount}</span>
                                <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch1}></i>
                            </div>
                            :
                            typeof chooselistingcollectedchain !== "undefined" && collectedminAmount ?
                                <div className='tagLabel'>
                                    <span>{chooselistingcollectedchain} {collectedminAmount}</span>
                                    <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch1}></i>
                                </div>
                                :
                                typeof chooselistingcollectedchain !== "undefined" && collectedmaxAmount ?
                                    <div className='tagLabel'>
                                        <span>{chooselistingcollectedchain} {collectedmaxAmount}</span>
                                        <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch1}></i>
                                    </div>
                                    :
                                    ""
                        :
                        ""
                    }

                    {collectedsearch || (collectedfilter == "Recently Received" ? "" : collectedfilter) || !collectedstatus.includes("") || (Collectedstatus == true ? typeof chooselistingcollectedchain !== "undefined" && collectedminAmount || collectedmaxAmount : "") || checkcollectedChain?.length > 0 || singlecollectedcollectionList?.length > 0 ?
                        <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                        :
                        ""
                    }
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table table-borderless listViewTable'>
                    {
                        getcollected && getcollected.length > 0 ?
                            <thead>
                                <tr>
                                    {/* <th>
                                        <label class="cuschk mr-2 mb-0" For="checkhead">
                                            <input type="checkbox" id="checkhead" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </th> */}
                                    <th>{t("Items")}</th>
                                    <th>{t("Floor Price")}</th>
                                    <th className='text-right'>{t("Highest Bid")}</th>
                                    <th className='text-right'>{t("Listing Price")}</th>
                                    {/* <th className='text-right'>{t("Price")}</th> */}
                                    <th className='text-right'>{t("Difference")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            :
                            ""
                    }
                    <tbody>
                        {
                            getcollected && getcollected.length > 0 ?
                                listViewrow
                                :
                                <div className='noItemFound'>
                                    <div className='text-center'>
                                        <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                    </div>
                                    <div className='nofounttxt mb-4'>
                                        {t("No items were found.Try to broaden your search.")}
                                    </div>
                                    {/* <div>
                                        <button className='applyBtn'>{t("Apply")}</button>
                                    </div> */}
                                </div>

                        }
                        {/* <tr>
                            <td>
                                <label class="cuschk mr-2 mb-0" For="checkbx12">
                                    <input type="checkbox" id="checkbx12" />
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <div><img src="" alt="" /></div>
                                    <div>Azuki #1249</div>
                                </div>
                            </td>
                            <td>--</td>
                            <td>3.7364 ETH</td>
                            <td>3.73 ETH</td>
                            <td>Ahmad Dokidis</td>
                            <td>22s</td>
                            <td>
                                <button className='btnBuynow'>3.7364 ETH</button>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                        {getcollected && getcollected.length > 0 ?
                            <div class=''>
                                <nav aria-label="Page ">
                                    <ResponsivePagination
                                        total={totalactivitycount}
                                        current={page}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                        nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                        previousClassName="page-link-1"
                                        nextClassName="page-link-1"
                                    />
                                </nav>
                                {/* <PaginationComponent
                        size='sm'
                        defaultActivePage={1}
                        totalItems={totalactivitycount}
                        maxPaginationNumbers={3}
                        pageSize={15}
                        onSelect={(page) => setPage(page)}
                    /> */}
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListViewItem;