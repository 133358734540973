import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'

function makeCollectionOffer(data) {
	return axios.post('collection/make-offer', { ...data })
}

export function useCollectionMakeOffer() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: makeCollectionOffer,
		onSuccess: () => {
			// queryClient.invalidateQueries(['makeOffer'])
		}
	})
}
