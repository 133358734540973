import { useMutation } from '@tanstack/react-query'
import { axios } from '../../../lib/axios'

const config = {
	headers: {
		'Content-Type': 'multipart/form-data'
	}
}

export const getCollection = data => {
	return axios.post('/user/get_collection_names', { ...data })
}

export const useGetCollection = () => {
	return useMutation(getCollection, {
		onMutate: () => {},
		onError: error => {},
		onSuccess: getOneCollectionData => {

		}
	})
}

export const createNFT = data => {
	return axios.post('/user/create_nft', data, config)
}

export const useCreateNFT = () => {
	return useMutation(createNFT, {
		onMutate: () => {},
		onError: error => {},
		onSuccess: getOneCollectionData => {
		}
	})
}

export const getRoyalityFee = data => {
	return axios.post('/admin/nft/get_royalty_fee', { ...data })
}

export const useGetRoyalityFee = () => {
	return useMutation(getRoyalityFee, {
		onMutate: () => {},
		onError: error => {},
		onSuccess: getOneCollectionData => {
		}
	})
}


export const getName = data => {
	return axios.post('/admin/nft/check_NFT_name', { ...data })
}

export const useGetName = () => {
	return useMutation(getName, {

	})
}
