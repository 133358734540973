import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import './Bridge.scss'
// import { ToastContainer, toast, useToast } from 'react-toastify'
import ScaleLoader from 'react-spinners/ScaleLoader'
import 'react-toastify/dist/ReactToastify.css'
import Web3 from 'web3'
import { ethers } from 'ethers'
import { axios } from '../../lib/axios'
import bnbabi from '../AbiArray/bnbabi.json'
import ethabi from '../AbiArray/ethabi.json'
import polygonabi from '../AbiArray/polygonabi.json'
import usdtabi from '../AbiArray/usdtabi.json'
import busdabi from '../AbiArray/busd.json'
import worabi from '../AbiArray/worabi.json'
import { ChangeNetwork, accountChange, changed1 } from '../Services'
import { ThemeContext } from '../context/context'
import ContractDetails from '../ContractAddress'
import PaginationComponent from 'react-reactstrap-pagination'
import { formatAmount } from '../../utils/formatAmount'
import { useToast } from '../../utils/toast/customSuccess'
import loader from '../../assets/images/Loader.png'

import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
	useDisconnect,
	useBalance
} from 'wagmi'
import ResponsivePagination from 'react-responsive-pagination';


import { chainId } from '../../config/constants/chains'

import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
 }
 
 const chainMap = {
	[chainId.ETHEREUM]: "ethereum",
	[chainId.BINANCE]: "binance",
	[chainId.POLYGON]: "polygon",
 }


const landPerRow = 6
const bsc_rpc = process.env.BSC_TEST
const eth_rpc = process.env.ETH_TEST
const polygon_rpc = process.env.POLYGON_TEST
const BridgeHistroy = () => {
	const toast = useToast()
	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)

	const [loading, setLoading] = useState(false)
	let [color, setColor] = useState('#ffffff')

	const { theme, setTheme } = useContext(ThemeContext)

	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()


	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainId = network?.id

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		fetchhistory()
	}, [page, address])

	const [history, setHistory] = useState('')
	const [count1, setCount] = useState('')
	var count = 0

	function handlePageChange(page) {
		setPage(page);
		// ... do something with `page`
	}

	const fetchhistory = async () => {
		setLoading(true)
		const userHistory = await axios.get(`bridge/history?address=${address}&page=${page}&limit=15`
		)
		if (userHistory.status == true) {
			setHistory(userHistory.data)
			setCount(Math.ceil(userHistory.count / 15))
			setLoading(false)
		} else {
			setLoading(false)
		}
	}

	let providerType = ''
	if (typeof window !== undefined) {
		let web3 = new Web3(window.ethereum)
	}

	const { pro } = useContractProvider()
	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const web3 = new Web3(pro)
	const bnbcontractMarket = new web3.eth.Contract(
		bnbabi,
		ContractDetails.Bnb_contract
	)
	const ethcontractMarket = new web3.eth.Contract(
		ethabi,
		ContractDetails.Eth_contract
	)
	const polygoncontractMarket = new web3.eth.Contract(
		polygonabi,
		ContractDetails.Polygon_contract
	)

	var nonce
	var domain
	var claim
	var provider
	var value

	const ReclaimUser = async (data) => {
		setLoading(true)
		try {
			const balancecheck = await axios.post("admin/nft/check_claim_balance", {
				"network": data.network, "amount": Number(data.toAmount),
				"toCurrency": data.toCurrency
			})
			if (balancecheck.status == true) {
				const buyprice2 = web3.utils.toWei(
					data.toAmount.toString(),
					'ether'
				)
				const buyprice3 = data.toAmount.toString() * 1000000

				const getvrs = await axios.post(
					'/admin/nft/get_vrs',
					{
						userAddress: address,
						currencySymbol: data.toCurrency,
						amount: data.toAmount.toString()
					}
				)
				if (getvrs.status == true) {
					{

						try {
							if (walletType === 'okx') {
								if (data.network !== chainMap[parseInt(window.okxwallet.chainId)]) {
									await switchNetwork(chainIdMap[data.network])
								}
							} else {
								if (
									data.toCurrency == "BUSD" &&
									chainId !== 97
								) {
									await switchNetworkAsync(97)
								} else if (
									data.toCurrency == "BNB" &&
									chainId !== 97
								) {
									await switchNetworkAsync(97)
								} else if (
									data.toCurrency == "WOR" &&
									chainId !== 97
								) {
									await switchNetworkAsync(97)
								}
								else if (
									data.toCurrency == "ETH" &&
									chainId !== 11155111
								) {
									await switchNetworkAsync(11155111)
								} else if (
									data.toCurrency == "USDT" &&
									chainId !== 11155111
								) {
									await switchNetworkAsync(11155111)
								}
								else if (
									data.toCurrency == "MATIC" &&
									chainId !== 80001
								) {
									await switchNetworkAsync(80001)
								}
							}
						}
						catch (error) {
							toast.error('User rejected the switch network')
							setLoading(false)
							return
						}


						try {
							data.toCurrency == 'ETH' ? (
								(claim = await ethcontractMarket.methods
									.claim(
										buyprice2,
										String(getvrs.data.blockNumber),
										getvrs.data.v,
										getvrs.data.r,
										getvrs.data.s,
										'0x0000000000000000000000000000000000000000'
									)
									.send({
										from: address
									})
									.on('transactionHash', async (hash) => {
										const getTransactionReceipt = async (hash) => {
											const result =
												await web3.eth.getTransactionReceipt(
													hash
												)
											if (result === null) {
												getTransactionReceipt(hash)
											} else {

												let data1 = {
													id: data._id,
													adminFee: (formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													txnHash: result.transactionHash,
													toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													status: 'completed'
												}
												const fetch = await axios.post(

													'bridge/re-claim',
													data1
												)
												if (fetch.status == true) {
													toast.success(
														'Transaction completed successfully'
													)
													fetchhistory()
													setLoading(false)
												}
											}
										}
										getTransactionReceipt(hash)
									})
									.on('receipt', async (approveresult) => {
									}))
							) : data.toCurrency == 'MATIC' ? (
								(claim = await polygoncontractMarket.methods
									.claim(
										buyprice2,
										String(getvrs.data.blockNumber),
										getvrs.data.v,
										getvrs.data.r,
										getvrs.data.s,
										'0x0000000000000000000000000000000000000000'
									)
									.send({
										from: address
									})
									.on('transactionHash', async (hash) => {
										const getTransactionReceipt = async (hash) => {
											const result =
												await web3.eth.getTransactionReceipt(
													hash
												)
											if (result === null) {
												getTransactionReceipt(hash)
											} else {

												let data1 = {
													id: data._id,
													adminFee:
														(formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													txnHash:
														result.transactionHash,
													toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													status: 'completed'
												}
												const fetch = await axios.post('bridge/re-claim', data1
												)
												if (fetch.status == true) {
													toast.success(
														'Transaction completed successfully'
													)
													fetchhistory()
													setLoading(false)
												}
											}
										}
										getTransactionReceipt(hash)
									})
									.on('receipt', async (approveresult) => {
									}))


							) : data.toCurrency == 'USDT' ? (
								(claim = await ethcontractMarket.methods
									.claim(
										buyprice3,
										String(getvrs.data.blockNumber),
										getvrs.data.v,
										getvrs.data.r,
										getvrs.data.s,
										ContractDetails.Tether_contract
									)
									.send({
										from: address
									})
									.on('transactionHash', async (hash) => {
										const getTransactionReceipt = async (hash) => {
											const result =
												await web3.eth.getTransactionReceipt(
													hash
												)
											if (result === null) {
												getTransactionReceipt(hash)
											} else {

												let data1 = {
													id: data._id,
													adminFee: (formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													txnHash:
														result.transactionHash,
													toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													status: 'completed'
												}
												const fetch = await axios.post('bridge/re-claim', data1)
												if (fetch.status == true) {
													toast.success(
														'Transaction completed successfully'
													)
													fetchhistory()
													setLoading(false)
												}
											}
										}
										getTransactionReceipt(hash)
									})
									.on('receipt', async (approveresult) => {
									}))
							) : data.toCurrency == 'BUSD' ? (
								(claim = await bnbcontractMarket.methods
									.claim(
										buyprice2,
										String(getvrs.data.blockNumber),
										getvrs.data.v,
										getvrs.data.r,
										getvrs.data.s,
										ContractDetails.Busd_contract
									)
									.send({
										from: address
									})
									.on('transactionHash', async (hash) => {
										const getTransactionReceipt = async (hash) => {
											const result =
												await web3.eth.getTransactionReceipt(
													hash
												)
											if (result === null) {
												getTransactionReceipt(hash)
											} else {

												let data1 = {
													id: data._id,
													adminFee:
														(formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													txnHash:
														result.transactionHash,
													toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													status: 'completed'
												}
												const fetch = await axios.post('bridge/re-claim', data1)
												if (fetch.status == true) {
													toast.success(
														'Transaction completed successfully'
													)
													fetchhistory()
													setLoading(false)
												}
											}
										}
										getTransactionReceipt(hash)
									})
									.on('receipt', async (approveresult) => {
									}))
							) : data.toCurrency == 'WOR' ? (
								(claim = await bnbcontractMarket.methods
									.claim(
										buyprice2,
										String(getvrs.data.blockNumber),
										getvrs.data.v,
										getvrs.data.r,
										getvrs.data.s,
										ContractDetails.Wor_contract
									)
									.send({
										from: address
									})
									.on('transactionHash', async (hash) => {
										const getTransactionReceipt = async (hash) => {
											const result =
												await web3.eth.getTransactionReceipt(
													hash
												)
											if (result === null) {
												getTransactionReceipt(hash)
											} else {

												let data1 = {
													id: data._id,
													adminFee:
														(formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													txnHash:
														result.transactionHash,
													toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													status: 'completed'
												}
												const fetch = await axios.post('bridge/re-claim', data1)
												if (fetch.status == true) {
													toast.success(
														'Transaction completed successfully'
													)
													fetchhistory()
													setLoading(false)
												}
											}
										}
										getTransactionReceipt(hash)
									})
									.on('receipt', async (approveresult) => {
									}))
							) : data.toCurrency == 'BNB' ? (
								(claim = await bnbcontractMarket.methods
									.claim(
										buyprice2,
										String(getvrs.data.blockNumber),
										getvrs.data.v,
										getvrs.data.r,
										getvrs.data.s,
										'0x0000000000000000000000000000000000000000'
									)
									.send({
										from: address
									})
									.on('transactionHash', async (hash) => {
										const getTransactionReceipt = async (hash) => {
											const result =
												await web3.eth.getTransactionReceipt(
													hash
												)
											if (result === null) {
												getTransactionReceipt(hash)
											} else {

												let data1 = {
													id: data._id,
													adminFee:
														(formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													txnHash:
														result.transactionHash,
													toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
													status: 'completed'
												}
												const fetch = await axios.post('bridge/re-claim', data1)
												if (fetch.status == true) {
													toast.success(
														'Transaction completed successfully'
													)
													fetchhistory()
													setLoading(false)
												}
											}
										}
										getTransactionReceipt(hash)
									})
									.on('receipt', async (approveresult) => {
									}))
							) : (
								<></>
							)
						}
						catch (err) {
							toast.error("Transaction Rejected")
							setLoading(false)
						}
					}
				} else {
					toast.error(getvrs.data)
					setLoading(false)
				}
			}
			else {
				toast.error(balancecheck.message)
				setLoading(false)
			}
		} catch (error) {
		}
	}

	return (
		<>
			{/* <ToastContainer /> */}
			<div className={loading ? 'spinner' : 'd-none'}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>

			<div className='withoutBannerHeaderPage'>
				{/* <Header /> */}

				<section class='BrigeHises  inrPgMt'>
					<div class='container-fluid'>
						<div class='brhsd text-center'>
							<h1>Bridge History</h1>
						</div>
						{history && history.length > 0 ? (
							<div class='britab'>
								<div class='table-responsive'>
									<table class='table table-borderless mb-0'>
										<thead class='bri_head'>
											<tr>
												<th>S.no </th>
												<th>Date & Time </th>
												<th>From Amount</th>
												<th>To Amount</th>
												<th>Admin Fee</th>
												<th>From Address</th>
												<th>To Address</th>
												<th>Transaction Hash </th>
												<th>Status</th>
												<th>Action</th>
											</tr>
										</thead>

										<tbody class='buy_table_body'>
											{/* {history && history.length > 0 ? ( */}
											{history &&
												history.map((val, key) => {
													return (
														<tr key={key}>
															<td>
																{page == 1
																	? key + 1
																	: (page - 1) * 15 +
																	(key + 1)}
															</td>
															<td>
																{new Date(
																	val.createdAt
																).toLocaleString()}
															</td>
															<td>
																{val.fromAmount}{' '}
																{val.fromCurrency}
															</td>
															<td>
																{val.type == 'claim' ? (
																	<>
																		<span>
																			{(Number(val.toAmount).toFixed(
																				8
																			))}
																		</span>
																		<span>
																			{' '}
																			{
																				val.toCurrency
																			}
																		</span>
																	</>
																) : (
																	'-'
																)}
															</td>
															<td>{Number(val.adminFee).toFixed(8)}</td>
															<td>
																{val.fromAddress
																	? val.fromAddress.slice(
																		0,
																		5
																	) +
																	'..' +
																	val.fromAddress.slice(
																		38,
																		42
																	)
																	: ''}
															</td>
															<td>
																{val.toAddress
																	? val.toAddress.slice(
																		0,
																		5
																	) +
																	'..' +
																	val.toAddress.slice(
																		38,
																		42
																	)
																	: ''}
															</td>
															<td>
																{val.txnHash ? (
																	// val.type == "claim" ?

																	<a
																		rel='noreferrer'
																		href={
																			val.toCurrency ==
																				'BNB' ? (
																				`https://testnet.bscscan.com/tx/${val.txnHash}`
																			) : val.toCurrency ==
																				'ETH' ? (
																				`https://sepolia.etherscan.io/tx/${val.txnHash}`
																			) : val.toCurrency ==
																				'MATIC' ? (
																				`https://mumbai.polygonscan.com/tx/${val.txnHash}`
																			) : val.toCurrency ==
																				'USDT' ? (
																				`https://sepolia.etherscan.io/tx/${val.txnHash}`
																			) : val.toCurrency ==
																				'BUSD' ? (
																				`https://testnet.bscscan.com/tx/${val.txnHash}`
																			) : val.toCurrency ==
																				'WOR' ? (
																				`https://testnet.bscscan.com/tx/${val.txnHash}`
																			) : (
																				<></>
																			)
																		}
																		target='_blank'
																	>
																		{' '}
																		{val.txnHash !==
																			''
																			? val.txnHash.slice(
																				0,
																				4
																			) +
																			'...' +
																			val.txnHash.slice(
																				15,
																				18
																			)
																			: '-'}{' '}
																	</a>
																) : (
																	// :
																	// <a rel="noreferrer" href={val.fromCurrency == 'BNB' ? `https://testnet.bscscan.com/tx/${val.txnHash}` : val.fromCurrency == 'ETH' ? `https://sepolia.etherscan.io/tx/${val.txnHash}` : val.fromCurrency == 'MATIC' ? `https://mumbai.polygonscan.com/tx/${val.txnHash}` : val.fromCurrency == 'USDT' ? `https://sepolia.etherscan.io/tx/${val.txnHash}` : val.fromCurrency == 'BUSD' ? `https://testnet.bscscan.com/tx/${val.txnHash}` : val.fromCurrency == 'WOR' ? `https://testnet.bscscan.com/tx/${val.txnHash}` : <></>} target="_blank"> {val.txnHash !== '' ? (val.txnHash).slice(0, 4) + '...' + (val.txnHash).slice(15, 18) : "-"} </a>
																	'-'
																)}
															</td>
															<td class='yelcl'>
																{val.status}{' '}
															</td>
															<td>
																{val.status ==
																	'cancelled' ? (
																	<button
																		class='btn'
																		onClick={() =>
																			ReclaimUser(
																				val
																			)
																		}
																	>
																		Claim
																	</button>
																) : (
																	'claimed'
																)}
															</td>
														</tr>
													)
												})}

										</tbody>
									</table>
								</div>
							</div>
						) : (
							<div>
								<div className='not-found text-center'>
									<span>No Data Found</span>
								</div>
							</div>
						)}
						<div className='row justify-content-center'>
							<div className='col-lg-4 col-md-6 col-sm-8'>
								<div class="bri_pagenation2">
									<div class=''>
										<nav aria-label="Page ">
											<ResponsivePagination
												total={count1}
												current={page}
												onPageChange={page => handlePageChange(page)}
												previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
												nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
												previousClassName="page-link-1"
												nextClassName="page-link-1"
											/>
										</nav>
										{/* <PaginationComponent
								size='sm'
								defaultActivePage={1}
								totalItems={count1}
								maxPaginationNumbers={3}
								pageSize={15}
								onSelect={(page) => setPage(page)}
							/> */}
									</div>
								</div>
							</div>
						</div>
						{/* <div class="bri_pagenation">
                  <nav aria-label="Page ">
                     <ul class="pagination justify-content-end mb-0">
                        <li class="page-item"><a href="#" class="page-link page-link-1 "> Next  </a></li>
                        <li class="page-item"><a href="#" class="page-link active">01</a></li>
                        <li class="page-item"><a href="#" class="page-link">02</a></li>
                        <li class="page-item"><a href="#" class="page-link">03</a></li>
                        <li class="page-item"><a href="#" class="page-link page-link-1 "> Previous </a></li>
                     </ul>
                  </nav>
               </div> */}
					</div>
				</section>
			</div>
		</>
	)
}
export default BridgeHistroy
