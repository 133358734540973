import { configureChains, createConfig, createStorage } from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { sepolia, bscTestnet, polygonMumbai } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { OkxConnector } from '../connectors/okx'
import { PUBLIC_NODES } from '../config/nodes'

export const CHAINS = [sepolia, bscTestnet, polygonMumbai]

const mostNodesConfig = Object.values(PUBLIC_NODES).reduce((prev, cur) => {
	return cur.length > prev ? cur.length : prev
}, 0)

export const { chains, publicClient } = configureChains(
	CHAINS,
	Array.from({ length: mostNodesConfig })
		.map((_, i) => i)
		.map((i) => {
			return jsonRpcProvider({
				rpc: (chain) => {
					return PUBLIC_NODES[chain.id]?.[i]
						? {
								http: PUBLIC_NODES[chain.id][i]
						  }
						: null
				}
			})
		})
)

export const metaMaskConnector = new MetaMaskConnector({
	chains,
	options: {
		shimDisconnect: false
	}
})

export const coinbaseConnector = new CoinbaseWalletConnector({
	chains,
	options: {
		appName: 'Wor NFT'
	}
})

const okxConnector = new OkxConnector({ chains })

export const wagmiConfig = createConfig({
	storage: createStorage({
		storage: window.localStorage,
		key: 'wagmi_v1.1'
	}),
	autoConnect: true,
	publicClient,
	connectors: [metaMaskConnector, coinbaseConnector, okxConnector]
})
