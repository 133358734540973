import { useQuery} from '@tanstack/react-query'
import { axios } from '../../../lib/axios'
import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

export const getSearchName = ({ name }) => {
    return axios.get(`home/search?search=${name}`)
}

export const useGetSearchName = ({search}) => {
    return useQuery({
		queryKey: ['nft_list', search],
		queryFn: () => getSearchName({ name: search })
	})
}

const getBlock = data => {
	return axios.post('/admin/nft/update_report', { ...data })
}

export const useBlock = () => {

	return useMutation(getBlock, {
		onMutate: () => { },
		onError: error => { },
		onSuccess: getReport => {
			if (getReport.data.status === false) {
				
			}

			if (getReport.data.status === true) {
				useQueryClient.invalidateQueries(['block'])
				
			}
		}
	})
}