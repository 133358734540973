import React, { Component, Suspense, useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '../src/style.scss'
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useNavigate
} from 'react-router-dom'
import Home from './views/Home/Home'
import Bridge from './views/Bridge/Bridge'
import BridgeHistory from './views/Bridge/BridgeHistroy'
import HomeCollection from './views/HomeCollection/HomeCollection'
import HomeActive from './views/HomeActive/HomeActive'
import HomeActive1 from './views/HomeActive1/HomeActive'

import CollectionPage from './views/collection/CollectionPage'
import CollectionLayout from './components/collectionLayout'
import MyCollectionLayout from './components/mycollectionLayout'
import MyCollection from './views/MyCollection/MyCollection'
import ChooseCollection from './views/MyCollection/ChooseCollection'
import CreateCollection from './views/MyCollection/CreateCollection'
import EditCollection from './views/MyCollection/EditCollection'
import MintLayout from './components/mintLayout'
import CreateNFT from './views/Mint/CreateNft'
import ProfilePage from './views/Profile/ProfilePage'
import ProfileLayout from './components/profileLayout'
import NftDetails from './views/NftDetails/NftDetails'
import SettingsLayout from './components/Settings'
import Profile from './views/Settings/Profile'
import Notification from './views/Settings/Notification'
import Offers from './views/Settings/Offers'
import Earnings from './views/Settings/Earnings'
import AccountSupport from './views/Settings/AccountSupport'
import Emailverify from './views/Settings/EmailVerifypage'
import { Subscribeverify } from './components/Footer/SubscribeVerifypage'
import CMSPagesLayout from './components/CMSLayout';
import LegalNotice from './views/cmsPages/legal';
import PrivacyPage from './views/cmsPages/privacy';
import TermsPage from './views/cmsPages/terms';
import HelpCenterLayout from './components/helpCenterLayout';
import HelpCenterMainPage from './views/helpcenter/helpcenterMain';
import HelpCenterCategoryActive from './views/helpcenter/categoryActive';
import HelpCenterCategorySearch from './views/helpcenter/searchResult';
import { ThemeProvider } from '../src/views/context/context'
import { Toaster } from 'react-hot-toast'
import ChooseNftChain from './views/Mint/ChooseNftChain'
import Listforsale from './views/listforsale/Listforsale'
import ListforsaleLayout from './components/listforsaleLayout'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './lib/react-query'
import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from './lib/wagmi'
import { SWRConfig } from 'swr'
import { SkeletonTheme } from 'react-loading-skeleton'
import Nft from './features/nft/Nft'
import Collection from './features/collection/Collection'
import { ModalProvider } from './providers/modal-provider'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import HomeExplorerLayout from './components/homeExplorerLayout'
import HomeExplorerMain from './views/homeExplorer'
import Error from './views/Error/Error'
import PrivateRoutes from './components/Protectedroute'
const App = props => {
	useEffect(() => {
		window.addEventListener('scroll', toggleVisible)
	}, [])

	const [visible, setVisible] = useState(false)
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 1000) {
			setVisible(true)
		} else if (scrolled <= 1000) {
			setVisible(false)
		}
	}
	const [profilePic, setProfilePic] = useState()


	return (
		<React.Fragment>
			<BrowserRouter>
				<ThemeProvider>
					<Toaster gutter={20} />
					<QueryClientProvider client={queryClient}>
						<SWRConfig>
							<WagmiConfig config={wagmiConfig}>
								<SkeletonTheme baseColor='#202020' highlightColor='#444'>
									<ModalProvider />

									<div className='withoutBannerHeaderPage'>

										<Header profilePic={profilePic} setProfilePic={setProfilePic}/>

										<Routes history={props.history}>
											<Route path='/' element={<Home />} />


											<Route element={<PrivateRoutes />}>
												<Route path='/bridge' element={<Bridge />} />
												<Route
													path='/bridgehistory'
													element={
														<BridgeHistory />
													}
												/>
												<Route
													path='/homecollection'
													element={<HomeCollection />}
												/>
												<Route path='/activity' element={<HomeActive1 />} />
												<Route path='/nftList/:id' element={<HomeActive />} />
												<Route path='/collection' element={<CollectionLayout />}>
													<Route index element={<CollectionPage />} />
												</Route>
												<Route path='/profile' element={<ProfileLayout profilePic={profilePic} setProfilePic={setProfilePic}/>} >
													<Route index element={<ProfilePage profilePic={profilePic} setProfilePic={setProfilePic}/>} />
												</Route>
												<Route path='/profile/:id' element={<ProfileLayout profilePic={profilePic} setProfilePic={setProfilePic}/>}>
													<Route index element={<ProfilePage profilePic={profilePic} setProfilePic={setProfilePic}/>} />
												</Route>
												<Route path='/' element={<MyCollectionLayout />}>
													<Route path='/mycollection' element={<MyCollection />} />
													<Route
														path='/choosecollection'
														element={<ChooseCollection />}
													/>
													<Route
														path='/createcollection'
														element={<CreateCollection />}
													/>
													<Route
														path='/editcollection/:id'
														element={<EditCollection />}
													/>
												</Route>
												<Route path='/' element={<MintLayout />}>
													<Route path='/createmint' element={<CreateNFT />} />
													<Route
														path='/chooseNftChain'
														element={<ChooseNftChain />}
													/>
												</Route>
												<Route path='/nftdetails/:id' element={<NftDetails />} />

												<Route path='/' element={<ListforsaleLayout />}>
													<Route
														path='/listforsale/:collectionAddress/:nftId/:supply'
														element={<Listforsale />}
													/>
												</Route>

												<Route path='/' element={<SettingsLayout />}>
													<Route path='/settingsprofile' element={<Profile profilePic={profilePic} setProfilePic={setProfilePic} />} />
													<Route
														path='/settingsnotification'
														element={<Notification />}
													/>
													<Route path='/settingsoffers' element={<Offers />} />
													<Route path='/settingsearnings' element={<Earnings />} />
													<Route
														path='/settingsaccountsupport'
														element={<AccountSupport />}
													/>
												</Route>
												<Route path='/emailverify/:id' element={<Emailverify />} />
												<Route path='/subscribeverify/:id' element={<Subscribeverify />} />

												<Route
													path='/collection/:collectionAddress'
													element={<Collection />}
												/>

												<Route
													path='/assets/:chain/:collectionAddress/:nftId/:supplyId'
													element={<Nft />}
												/>
												<Route path='/' element={<HelpCenterLayout />}>
													<Route path='/helpcenter' element={<HelpCenterMainPage />} />
													<Route path='/categoryactive/:id' element={<HelpCenterCategoryActive />} />
													<Route path='/searchResult/:id' element={<HelpCenterCategorySearch />} />
												</Route>

												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt="Privacy" headFirstTxt="Policy" />}>
													<Route path='/privacy' element={<PrivacyPage />} />
												</Route>
												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt="Terms" headFirstTxt="of Service" />}>
													<Route path='/terms' element={<TermsPage />} />
												</Route>
												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt="Legal" headFirstTxt="Notice" />}>
													<Route path='/Legalnotice' element={<LegalNotice />} />
												</Route>

												<Route path='/' element={<HomeExplorerLayout />}>
													<Route path='/explorer' element={<HomeExplorerMain />} />
												</Route>

											
												{/* <Route 
										path='/' 
										element={<CMSPagesLayout HeadPrimeTxt="Privacy" headFirstTxt="Policy" />}>
											<Route path='/privacy' element={<PrivacyPage />} />
										</Route>

										<Route
										 path='/'
										  element={<CMSPagesLayout HeadPrimeTxt="Terms" headFirstTxt="of Service" />}>
											<Route path='/terms' element={<TermsPage />} />
										</Route>

										<Route 
										path='/'
										 element={<CMSPagesLayout HeadPrimeTxt="Legal" headFirstTxt="Notice" />}>
											<Route path='/Legalnotice' element={<LegalNotice />} />
										</Route> */}

												<Route path='/404' element={<Error />} />
											</Route>

										</Routes>
										<Footer />
									</div>


								</SkeletonTheme>
							</WagmiConfig>
						</SWRConfig>
					</QueryClientProvider>

					<div className='d-flex justify-content-end'>
						<button
							className='scroll_btn'
							onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
							style={{ display: visible ? 'inline' : 'none' }}
						>
							<i className='fa fa-arrow-up'></i>
						</button>
					</div>
				</ThemeProvider>
			</BrowserRouter>
		</React.Fragment>
	)
}

export default App
