import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

function fetchCollectionDetails(collectionAddress) {
	const url = new URL(BACKEND_URL + 'collection/details')
	url.searchParams.set('address', collectionAddress)

	return axios.get(url)
}

export function useFetchCollectionDetails(collectionAddress) {
	return useQuery({
		queryKey: ['collection', collectionAddress],
		queryFn: () => fetchCollectionDetails(collectionAddress),
		enabled: !!collectionAddress
	})
}
