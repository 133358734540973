import React, { useEffect, useState } from 'react';
// import '../MyCollection/myCollection.scss';
import './Settings.scss';
import { axios } from '../../lib/axios'
import { useToast } from '../../utils/toast/customSuccess'
import {
  useAccount,
} from 'wagmi'
//import '../../assets/scss/mycollecion_mint.scss'
import { NumericFormat } from 'react-number-format'
import {
  Input,
} from 'reactstrap'

export const SettingsNotification = () => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { address, isConnected } = useAccount()

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const CheckTick = () => {
    return (
      <>
        <svg className="ml-auto" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
        </svg>
      </>
    )
  }

  const toast = useToast()

  useEffect(() => {
    getNotification()
  }, [])

  const [getallnotify, setGetAllNotify] = useState("")

  const getNotification = async () => {
    const fetch = await axios.post("/user/get_notifications", { "address": address })
    if (fetch.status == true) {
      setGetAllNotify(fetch.data[0])

    }
  }

  const itemSoldChange = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "itemSold": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  };
  const bidActivityChange = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "bidActivity": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  };

  const priceChange = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "priceChange": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  }

  const auctionExpiration = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "auctionExpiration": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  }
  const outbid = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "outbid": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  }
  const ownedItemUpdates = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "ownedItemUpdates": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  }
  const successfulPurchase = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "successfulPurchase": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  }
  const successfulMint = async (e) => {
    const fetch = await axios.post("/user/update_Notifications", { "successfulMint": e.target.checked, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
      getNotification()
    }
    else {
      toast.success(fetch.message)
    }
  }

  const [mintheresold, setmintheresold] = useState("")
  const minimumBidThreshold = async (e) => {
    e.preventDefault()
    const fetch = await axios.post("/user/update_Notifications", { "minimumBidThreshold": mintheresold, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
    }
    else {
      toast.success(fetch.message)
    }
  }

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">Notifications</h3>
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <p className="greyTxt mb-3">Select which notifications you would like to receive.</p>
          <div className="form-row">
            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Item Sold</span>
                  <p>When someone purchased one of your items</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={itemSoldChange} checked={getallnotify && getallnotify.itemSold == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>


            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Bid Activity</span>
                  <p>When someone bids on one of your items</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={bidActivityChange} checked={getallnotify && getallnotify.bidActivity == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Price Change</span>
                  <p>When an item you made an offer on changes in price</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={priceChange} checked={getallnotify && getallnotify.priceChange == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Auction Expiration</span>
                  <p>When a timed auction you created ends</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={auctionExpiration} checked={getallnotify && getallnotify.auctionExpiration == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Outbid</span>
                  <p>When an offer you placed is exceeded by another user</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={outbid} checked={getallnotify && getallnotify.outbid == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Owned Item Updates</span>
                  <p>When a significant update occurs for one of the items you have purchased on WARRIOR</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={ownedItemUpdates} checked={getallnotify && getallnotify.ownedItemUpdates == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Successful Purchase</span>
                  <p>When you successfully buy an item</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={successfulPurchase} checked={getallnotify && getallnotify.successfulPurchase == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">Successful Mint</span>
                  <p>When you successfully mint an item</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={successfulMint} checked={getallnotify && getallnotify.successfulMint == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <hr className="w-100" />

            <div className="col-12 mb-3">
              <span className="formLabel">Minimum Bid Threshold</span>
              <p className="greyTxt">Receive notifications only when you receive offers with a value greater than or equal to this amount of ETH.</p>
              <div className="input-group verified">
                <NumericFormat className="form-control" onChange={(e) => setmintheresold(e.target.value)} value={getallnotify && getallnotify.minimumBidThreshold} allowNegative={false} customInput={Input} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="greyTxt">ETH</span>
                  </div>
                </div>
                {mintheresold ?
                  <button className="btn gradientBtn ml-2" onClick={minimumBidThreshold}>Save</button>
                  :
                  <button className="btn gradientBtn ml-2" disabled>Save</button>

                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SettingsNotification;