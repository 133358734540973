import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import structuredClone from '@ungap/structured-clone'

import { flattenFilters } from '../../../utils/flattenFilters'
import { INITIAL_FILTER_STATE } from '../../../utils/parseQueryStringToObject'
import { NoItemsFound } from '../Items'
import { NftsGridPlaceholder } from './loader/NftsGridPlaceholder'
import { fetchCollectionNfts } from '../apis/fetchCollectionNfts'
import { NftCard } from './NftCard'
import { useCollectionActions, useCollectionState } from '../store'

export const filterValueMap = {
	IS_LISTED: 'Listed',
	ON_AUCTION: 'On Auction',
	IS_NEW: 'New',
	HAS_OFFERS: 'Has Offers',
	USDT: 'USDT',
	ETH: 'ETH',
	MATIC: 'MATIC',
	BNB: 'BNB',
	WOR: 'WOR',
	BUSD: 'BUSD'
}

export function Chip({ filters, filter, onClearChip }) {
	if (!filter.value || filter.key.includes('owner')) return

	const handleClearChip = () => {
		const flattenedFilters = flattenFilters(filters)
		const filterKey = filter.key.split(/\]\[|\[|\]/).filter(Boolean)[1]
		const arr = Object.entries(flattenedFilters)
			.filter((f) => f[0].includes(filterKey))
			.filter((f) => f[1] !== filter.value)
		const updatedFilters = {
			...filters,
			search: {
				...filters.search,
				[filterKey]:
					arr.length === 0
						? null
						: arr.reduce((acc, cur, curIndex) => {
								acc = { ...acc, [curIndex]: cur[1] }
								return acc
						  }, {})
			}
		}
		onClearChip(updatedFilters)
	}

	return (
		<div className='tagLabel'>
			<span>
				{filter.key.includes('query')
					? filter.value
					: filterValueMap[filter.value]}
			</span>
			<i
				className='fas fa-times ml-2 hover'
				onClick={handleClearChip}
			></i>
		</div>
	)
}

export function TraitChip({ filters, filter, onClearChip }) {
	if (!filter.value) return

	const handleClearChip = () => {
		const flattenedFilters = flattenFilters(filters)
		let i = 0
		const o = Object.entries(flattenedFilters)
			.filter(([, v]) => v !== filter.value)
			.reduce((acc, [key, value], index, arr) => {
				if (key.includes('name') && !acc.hasOwnProperty(value)) {
					acc[value] = arr.filter(([k]) =>
						k.includes(`[stringTraits][${i}][values]`)
					)
					i++
				}
				return acc
			}, {})

		const updatedFilters = {
			...filters,
			search: {
				...filters.search,
				stringTraits:
					Object.entries(o).find((k) => k[0] === filter.key)[1]
						.length === 0
						? Object.entries(o)
								.filter((k) => k[0] !== filter.key)
								.reduce((acc, [k, v], i) => {
									acc = {
										...acc,
										[i]: {
											name: k,
											values: v.reduce((a, [, v], i) => {
												a = {
													...a,
													[i]: v
												}
												return a
											}, {})
										}
									}
									return acc
								}, {})
						: Object.entries(o).reduce((acc, [k, v], i) => {
								acc = {
									...acc,
									[i]: {
										name: k,
										values: v.reduce((a, [, v], i) => {
											a = {
												...a,
												[i]: v
											}
											return a
										}, {})
									}
								}
								return acc
						  }, {})
			}
		}

		onClearChip(updatedFilters)
	}

	return (
		<div className='tagLabel'>
			<span className='background'>{filter.key}: </span>
			<span>{filter.value}</span>
			<i
				className='fas fa-times ml-2 hover'
				onClick={handleClearChip}
			></i>
		</div>
	)
}

export function PriceChip({ filter, filters, onClearChip }) {
	const handleClearChip = () => {
		const updatedFilters = {
			...filters,
			search: {
				...filters.search,
				priceFilter: null
			}
		}
		onClearChip(updatedFilters)
	}

	return (
		<div className='tagLabel'>
			<span className='background'>
				{`${filter.value.min} - ${filter.value.max}`}{' '}
			</span>
			<span>{filter.key}</span>
			<i
				className='fas fa-times ml-2 hover'
				onClick={handleClearChip}
			></i>
		</div>
	)
}

export default function NftsInGridView({
	view,
	showFilters,
	filters,
	handleFiltersChange
}) {
	const { t } = useTranslation()
	const params = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()

	const { noOfItems } = useCollectionState()
	const { setNoOfItems } = useCollectionActions()

	const gridview = showFilters ? 'g-col-5' : 'g-col-6'
	const filterEntries = filters && Object.entries(flattenFilters(filters))

	const chips = filterEntries
		?.filter(
			([key, value]) =>
				!(
					key.includes('stringTraits') ||
					key.includes('priceFilter') ||
					key.includes('sort')
				)
		)
		.map(([key, value], i) => {
			return (
				<Chip
					key={key}
					filters={filters}
					filter={{ key, value }}
					onClearChip={handleFiltersChange}
				/>
			)
		})

	const a = filters?.search.stringTraits ?? {}
	const b = Object.values(a).reduce((acc, cur) => {
		if (!acc.hasOwnProperty(cur.name)) {
			acc[cur.name] = Object.values(cur.values)
		}
		return acc
	}, {})

	const traitChips = Object.entries(b ?? {}).map(([key, value]) => {
		return value.map((v) => {
			return (
				<TraitChip
					key={v}
					filters={filters}
					filter={{ key, value: v }}
					onClearChip={handleFiltersChange}
				/>
			)
		})
	})

	const priceFilters = filters?.search.priceFilter ?? {}
	const priceFilter = Object.entries(priceFilters).reduce(
		(acc, [key, value], i, arr) => {
			if (key === 'symbol' && !acc.hasOwnProperty(value)) {
				acc[value] = { min: arr[1][1], max: arr[2][1] }
			}
			return acc
		},
		{}
	)

	const priceChip = Object.entries(priceFilter ?? {}).map(([key, value]) => {
		return (
			<PriceChip
				key={key}
				filters={filters}
				filter={{ key, value }}
				onClearChip={handleFiltersChange}
			/>
		)
	})

	const handleClearAll = () => {
		handleFiltersChange(structuredClone(INITIAL_FILTER_STATE))
	}

	const showClearAllButton =
		filterEntries?.filter(
			([key, value]) =>
				!!value && !key.includes('owner') && !key.includes('sort')
		).length !== 0

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				'nfts',
				params.collectionAddress,
				searchParams.toString(),
				filters,
				view
			],
			queryFn: ({ pageParam = 1 }) =>
				fetchCollectionNfts(
					params.collectionAddress,
					filters,
					pageParam,
					(noOfItems) => {
						setNoOfItems(noOfItems)
					}
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.length ? allPages.length + 1 : undefined
			},
			enabled: !!params.collectionAddress
		})

	const intersectionObserverRef = useRef()
	const lastNftRef = useCallback(
		(nft) => {
			if (isFetchingNextPage) {
				return
			}

			if (intersectionObserverRef.current) {
				intersectionObserverRef.current.disconnect()
			}

			intersectionObserverRef.current = new IntersectionObserver(
				(nfts) => {
					if (
						nfts[0].isIntersecting &&
						hasNextPage &&
						!isFetchingNextPage
					) {
						fetchNextPage()
					}
				}
			)

			if (nft) {
				intersectionObserverRef.current.observe(nft)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const nftCards = data?.pages.map((page) => {
		return page.map((nft, i) => {
			if (page.length === i + 1) {
				return (
					<NftCard
						ref={lastNftRef}
						key={nft.details._id}
						item={nft}
						onClick={() =>
							navigate(
								`/assets/${nft.details.chain}/${nft.details.contractAddress}/${nft.details.nftId}/${nft.details.supplyId}`
							)
						}
					/>
				)
			}
			return (
				<NftCard
					key={nft.details._id}
					item={nft}
					onClick={() =>
						navigate(
							`/assets/${nft.details.chain}/${nft.details.contractAddress}/${nft.details.nftId}/${nft.details.supplyId}`
						)
					}
				/>
			)
		})
	})

	const hasNoData = nftCards && searchParams.toString().length > 0 && data?.pages?.[0].length === 0
	const hasNoItems = nftCards && searchParams.toString().length === 0 && noOfItems === 0

	return (
		<div className='py-3'>
			<div className='row mb-4'>
				<div className='col-lg-6'>
					<span class='cmntxt'>
						{noOfItems} {t('Items')}
					</span>
				</div>
				<div className='col-lg-6 text-right'></div>
			</div>
			<div className='fiterSelectlabel mb-4'>
				{chips &&
					traitChips &&
					priceChip && [...chips, ...traitChips, ...priceChip]}
				{showClearAllButton && (
					<button className='clearAllBtn' onClick={handleClearAll}>
						{t('Clear All')}
					</button>
				)}
			</div>

			{!nftCards ? (
				<NftsGridPlaceholder numItems={15} />
			) : (
				<div className={`gridrow ${gridview}`}>{nftCards}</div>
			)}

			{hasNoData && <NoItemsFound onClick={() => setSearchParams('')} />}
			{hasNoItems && <NoItemsFound displayText='No items' />}
		</div>
	)
}
