import { Connector, ConnectorNotFoundError } from 'wagmi'
import okxWeb3 from '@okwallet/extension'
import {
	UserRejectedRequestError,
	createWalletClient,
	getAddress,
	http
} from 'viem'

import { normalizeChainId } from '../utils/normalizeChainId'

export class OkxConnector extends Connector {
	id = 'okx'

	name = 'Okx'

	ready = typeof window !== 'undefined' && window?.okxwallet

	provider

	constructor({ chains }) {
		super({ chains })
	}

	async getProvider() {
		if (!this.provider) {
			this.provider = okxWeb3
		}

		if (!this.provider) {
			throw new ConnectorNotFoundError()
		}

		return this.provider
	}

	async connect() {
		try {
			const provider = await this.getProvider()

			if (!provider) throw new ConnectorNotFoundError()

			if (provider.addListener) {
				provider.addListener('accountChanged', this.onAccountChanged)
				provider.addListener('chainChanged', this.onChainChanged)
				provider.addListener('disconnect', this.onDisconnect)
			}

			this.emit('message', { type: 'connecting' })
			const account = await this.getAccount()
			const id = await this.getChainId()
			return { account, chain: { id }, provider }
		} catch (error) {
			this.disconnect()
			if (this.isUserRejectedRequestError(error))
				throw new UserRejectedRequestError(error)
			throw error
		}
	}

	async isAuthorized() {
		const walletName = this.storage?.getItem('wallet')
		const connected = Boolean(this.storage?.getItem('connected'))
		const isConnect = walletName === 'okx' && connected
		return Promise.resolve(isConnect)
	}

	async getWalletClient({ chainId } = {}) {
		const [provider, account] = await Promise.all([
			this.getProvider({ chainId }),
			this.getAccount()
		])
		const chain =
			this.chains.find((x) => x.id === chainId) || this.chains[0]
		if (!provider) throw new Error('provider is required.')
		return createWalletClient({
			account,
			chain,
			transport: http()
		})
	}

	async getAccount() {
		const provider = await this.getProvider()
		if (!provider) throw new ConnectorNotFoundError()
		return provider.init().then((wallet) => {
			const account = wallet.addresses.SEPOLIA_ETH.address
			if (typeof account === 'string' && !account.startsWith('0x')) {
				account = `0x${account}`
			}
			return getAddress(account)
		})
	}

	async getChainId() {
		const provider = await this.getProvider()
		if (!provider) throw new ConnectorNotFoundError()
		return window.ethereum
			.request({ method: 'eth_chainId' })
			.then(normalizeChainId)
	}

	onAccountChanged(accounts) {
		if (accounts.length === 0) {
			this.emit('disconnect')
		} else {
			let account = accounts[0]
			if (typeof account === 'string' && !account.startsWith('0x')) {
				account = `0x${account}`
			}
			this.emit('change', {
				account: getAddress(account)
			})
		}
	}

	onChainChanged(chainId) {
		const id = normalizeChainId(chainId)
		this.emit('change', { chain: { id } })
	}

	onDisconnect() {
		this.emit('disconnect')
	}

	async disconnect() {
		const provider = await this.getProvider()
		if (provider) {
			await provider.disconnect()
		}
		if (!provider?.removeListener) return

		provider.removeListener('accountChanged', this.onAccountChanged)
		provider.removeListener('chainChanged', this.onChainChanged)
		provider.removeListener('disconnect', this.onDisconnect)
	}

	isUserRejectedRequestError(error) {
		return error.code === 4001
	}
}
