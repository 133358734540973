import { useAccount, usePublicClient } from 'wagmi'
import { formatGwei, parseUnits } from 'viem'
import useSWR from 'swr'

import { useMarketplaceContract } from './useContract'
import { axios } from '../lib/axios'
import { getOrder } from '../utils/getOrder'
import { estimateGas } from '../utils/estimateGas'
import { useBuyNowModalState } from '../components/modals/buy-now/store'
import { useBuy } from '../features/nft/apis/buy'

export const useBuyNow = (marketplaceContractAddress) => {
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress
	)
	const publicClient = usePublicClient()
	const { address: account } = useAccount()
	const { mutateAsync: buy } = useBuy()
	const { asset } = useBuyNowModalState()
	// const { data: args } = useSWR(['args'], async () => {
	// 	const sellArgs = await getSellArgs(asset)
	// 	return sellArgs
	// })
	// const { data: estimatedGasPrice } = useSWR(
	// 	['estimatedGasPrice', asset, args, marketplaceContract],
	// 	async () => {
	// 		const value = parseFloat(
	// 			parseUnits(
	// 				asset.details.price.toString(),
	// 				asset.details.decimals
	// 			)
	// 		)
	// 		const estimatedGas = await estimateGas(
	// 			marketplaceContract,
	// 			'sell',
	// 			args,
	// 			{ value }
	// 		)
	// 		return formatGwei(estimatedGas)
	// 	},
	// 	{ refreshInterval: 30000 }
	// )

	const getSellArgs = async (asset) => {
		const royaltyFee = asset.details.royalty
		const royaltyReceipt = asset.details.creator
		const isStore = !asset.details.isMinted
		const uri = asset.details.uri

		const order = getOrder({
			asset,
			assetType: 'erc1155',
			orderType: 'sell'
		})

		try {
			const {
				data: { v, r, s }
			} = await axios.post('asset/buyer-fee-signature', {
				orderTuple: order,
				royalty: royaltyFee,
				royaltyAddress: royaltyReceipt,
				network: asset.details.chain
			})

			const buyerFeeSig = [v, r, s]
			const args = [
				[order],
				[buyerFeeSig],
				[royaltyFee],
				[royaltyReceipt],
				[isStore],
				[uri]
			]

			return args
		} catch (error) {
			console.error(error)
			throw new Error(error)
		}
	}

	const buyNow = async (asset) => {
		const value = parseFloat(
			parseUnits(asset.details.price.toString(), asset.details.decimals)
		).toString()

		try {
			const sellArgs = await getSellArgs(asset)

			const hash = await marketplaceContract.write.sell(sellArgs, {
				account,
				...(asset.details.isCoin ? { value } : {}),
			})

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status === 'success') {
				const res = await buy(asset)
				if (res.status) {
					return transactionReceipt
				}
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			console.error(error)
			throw new Error(error)
		}
	}

	return { getSellArgs, buyNow }
}
