import React, { useState, useEffect } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown,
	Modal
} from 'reactstrap'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
	useDisconnect
} from 'wagmi'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NumericFormat } from 'react-number-format'
import { format } from 'date-fns'

import IconModal from '../../../assets/images/IconModal.png'
import angleDown from '../../../assets/images/angleDown.svg'
import { useNavigate } from 'react-router-dom'
import { useFixedPriceSell } from '../apis/fetchListAssetDetails'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import { useToast } from '../../../utils/toast/customSuccess'
import ApprovalABI from '../../AbiArray/Approvalcollection/Bnbapproval.json'
import Web3 from 'web3'
import { chainId } from '../../../config/constants/chains'
import contracts from '../../../config/constants/contracts'
import { useTranslation } from 'react-i18next'
import { formatValue } from '../../../utils/formatValue'
import { formatAmount } from '../../../utils/formatAmount'
import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'

const intervals = [
	{ label: '1 Hour', duration: 60, format: '1H' },
	{ label: '6 Hours', duration: 360, format: '6H' },
	{ label: '1 Day', duration: 1440, format: '1D' },
	{ label: '3 Days', duration: 4320, format: '3D' },
	{ label: '7 Days', duration: 10080, format: '7D' },
	{ label: '1 Month', duration: 43200, format: '1M' }
]

const decrementDuration = [
	{ label: '1 Minute', duration: 1 },
	{ label: '15 Minutes', duration: 15 },
	{ label: '1 Hour', duration: 60 }
]

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export const DutchAuction = ({ data, onData, activeTab }) => {
	const { t } = useTranslation()
	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()
	const { pro } = useContractProvider()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainId = network?.id

	const navigate = useNavigate()
	const fixedPriceSellMutation = useFixedPriceSell()
	const toast = useToast()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const schema = yup.object().shape({
		price: yup
			.number()
			.typeError('Please enter a starting price')
			.required('Required')
			.max(100000000000000, 'The amount cannot exceed 100,000,000,000,000')
			.test(
				'not-zero',
				'The value should be atleast one',
				value => value !== 0
			),
		// .test(
		// 	'decimal',
		// 	'The amount cannot have precision greater than 14 decimal places',
		// 	value => (value.toString().split('.')[1] || '').length <= 14
		// ),
		endPrice: yup
			.number()
			.typeError('Please enter a ending price')
			.required('Required')
			.max(100000000000000, 'The amount cannot exceed 100,000,000,000,000')
			.test('not-zero', 'The value should be atleast one', value => value !== 0)
			// .test(
			// 	'decimal',
			// 	'The amount cannot have precision greater than 14 decimal places',
			// 	value => (value.toString().split('.')[1] || '').length <= 14
			// )
			.lessThan(
				yup.ref('price'),
				'Ending price must be less than the starting price'
			)
			.test(
				'greaterThanStartingPrice',
				'Ending price must be less than starting price',
				function (value) {
					const startingPrice = this.parent.price
					return value < startingPrice && value !== startingPrice
				}
			)
	})

	const {
		control,
		register,
		handleSubmit,
		setValue,
		getValues,
		setError,
		watch,
		reset,
		formState: { errors, isValid, isDirty }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const marketplaceContractAddress = data
		? contracts.marketplace[chainIdMap[data?.details?.chain]]
		: null

	const web3 = new Web3(pro)
	const tetherContract = new web3.eth.Contract(
		ApprovalABI,
		data?.details?.contractAddress
	)

	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const startPrice = watch('price')
	const endPrice = watch('endPrice')

	useEffect(() => {
		if (endPrice >= startPrice) {
			setError('endPrice', 'Ending price must be less than starting price')
		}
	}, [startPrice, endPrice])

	useEffect(() => {
		if (activeTab) {
			setValue('price', '')
		}
	}, [activeTab])

	const CheckTick = () => {
		return (
			<>
				<svg
					class='ml-auto'
					width='12'
					height='9'
					viewBox='0 0 12 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
						fill='#FFA900'
					/>
				</svg>
			</>
		)
	}

	const [selectedInterval, setSelectedInterval] = useState(intervals[4])
	const [selectedDuration, setSelectedDuration] = useState(decrementDuration[0])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [dropdownOpen1, setDropdownOpen1] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isError, setIsError] = useState(false)

	const [date, setDate] = useState('')
	const [time, setTime] = useState('')

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen)
	const toggleDropdown1 = () => setDropdownOpen1(!dropdownOpen1)

	const handleIntervalChange = interval => {
		setSelectedInterval(interval)
	}

	const handleDuartionChange = decrementDuration => {
		setSelectedDuration(decrementDuration)
	}

	const filteredIntervalOptions = intervals.filter(
		option => option.label !== selectedDuration?.label
	)

	const filteredDurationOptions = decrementDuration.filter(
		option => option.label !== selectedInterval?.label
	)

	const intervalItems = filteredIntervalOptions.map(interval => (
		<DropdownItem
			tag='a'
			key={interval.label}
			onClick={() => handleIntervalChange(interval)}
		>
			<div className='d-flex align-items-center'>
				{interval.label}{' '}
				{selectedInterval.label === interval.label && <CheckTick />}
			</div>
		</DropdownItem>
	))

	useEffect(() => {
		const currentDate = new Date()
		const nextDate = new Date(
			currentDate.getTime() + selectedInterval.duration * 60000
		)

		const formatDate = format(nextDate, 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval])

	useEffect(() => {
		setValue('currency', data && data?.allowedCurrencies?.[0])
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [data?.allowedCurrencies])

	const getCurrency = watch('currency')
	const getPrice = watch('price')

	const plat = data?.details?.platformFee / 100
	const royal = data?.details?.royalty / 100

	const finalAmount = getPrice * (1 - (plat + royal))
	const usdtPrice = finalAmount * getCurrency?.usdPrice
	const singleNftUsdtPrice = getPrice * getCurrency?.usdPrice

	useEffect(() => {
		const obj = {
			token: getCurrency,
			price: getPrice,
			totalSellAmount: finalAmount,
			singleNftUsdtPrice: singleNftUsdtPrice
		}

		onData(obj)
	}, [getCurrency, getPrice])

	const onSubmit = async fetch => {
		const sellParams = {
			id: data?.id,
			saleType: 'dutch-auction',
			currency: getCurrency?.currencySymbol,
			price: fetch.price,
			endPrice: fetch.endPrice,
			duration: selectedInterval?.format,
			decrementDuration: selectedDuration?.duration,
			seller: address
		}
		setLoading(true)
		setIsError(false)
		toggle()
		try {
			if (walletType === 'okx') {
				if (
					data?.details?.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[data?.details?.chain])
				}
			} else {
				if (data?.details?.chain === 'binance' && chainId !== 97) {
					await switchNetworkAsync(97)
				} else if (
					data?.details?.chain === 'ethereum' &&
					chainId !== 11155111
				) {
					await switchNetworkAsync(11155111)
				} else if (data?.details?.chain === 'polygon' && chainId !== 80001) {
					await switchNetworkAsync(80001)
				}
			}
		} catch (error) {
			setLoading(false)
			setIsError(true)
			return
		}

		const approvalResponse = await tetherContract.methods
			.isApprovedForAll(address, marketplaceContractAddress)
			.call()

		if (approvalResponse) {
			setLoading(true)
			toggle()
			const res = await fixedPriceSellMutation.mutateAsync(sellParams)

			if (res?.status) {
				toast.success(res?.message)
			} else {
				setIsError(true)
				toast.error(res?.message)
			}
		} else {
			try {
				await tetherContract.methods
					.setApprovalForAll(marketplaceContractAddress, true)
					.send({ from: address.toString() })
					.on('transactionHash', async hash => {})
					.on('receipt', async approveresult => {})

				setLoading(true)
				toggle()
				const res = await fixedPriceSellMutation.mutateAsync(sellParams)

				if (res?.status) {
					toast.success(res?.message)
					setIsError(false)
				} else {
					setIsError(true)
					toast.error(res?.message)
				}
			} catch (err) {
				setModal(false)
				setIsError(true)
			}
		}
	}

	useEffect(() => {
		if (fixedPriceSellMutation.isSuccess) {
			setLoading(false)
		}
	}, [fixedPriceSellMutation.isSuccess])

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-row'>
					<div className='col-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t('Starting Price')}</span>
							{errors.price && (
								<label className='text-danger errLabel ml-auto'>
									{errors.price.message}
								</label>
							)}
						</div>
						<div className='d-flex urlFieldCnt traitsCnt mb-2'>
							<div class='input-group mb-2'>
								<Controller
									name='price'
									control={control}
									defaultValue=''
									render={({ field }) => (
										<NumericFormat
											{...field}
											placeholder='Amount'
											className='form-control'
											decimalScale={10}
											allowNegative={false}
											onValueChange={values => {
												const { value } = values
												field.onChange(value)
											}}
										/>
									)}
								/>
								<div class='input-group-append'>
									<div class='input-group-text'>
										<Controller
											name='currency'
											control={control}
											// defaultValue={data?.allowedCurrencies[0]}
											render={({ field: { onChange, value } }) => (
												<select
													value={JSON.stringify(value)}
													onChange={event =>
														onChange(JSON.parse(event.target.value))
													}
												>
													{data &&
														data?.allowedCurrencies?.map((currency, index) => (
															<option
																key={index}
																value={JSON.stringify(currency)}
															>
																{currency.currencySymbol}
															</option>
														))}
												</select>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t('Ending Price')}</span>
							{errors.endPrice && (
								<label className='text-danger errLabel ml-auto'>
									{errors.endPrice.message}
								</label>
							)}
						</div>
						<div className='d-flex urlFieldCnt traitsCnt mb-2'>
							<div class='input-group mb-2'>
								<Controller
									name='endPrice'
									control={control}
									defaultValue=''
									render={({ field }) => (
										<NumericFormat
											{...field}
											placeholder='Amount'
											className='form-control'
											decimalScale={10}
											allowNegative={false}
											onValueChange={values => {
												const { value } = values
												field.onChange(value)
											}}
										/>
									)}
								/>
								<div class='input-group-append'>
									<div class='input-group-text'>
										<Controller
											name='currency'
											control={control}
											// defaultValue={data?.allowedCurrencies[0]}
											render={({ field: { onChange, value } }) => (
												<select
													value={JSON.stringify(value)}
													onChange={event =>
														onChange(JSON.parse(event.target.value))
													}
												>
													{data &&
														data?.allowedCurrencies?.map((currency, index) => (
															<option
																key={index}
																value={JSON.stringify(currency)}
															>
																{currency.currencySymbol}
															</option>
														))}
												</select>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<hr className='w-100' />

					<div className='col-lg-6 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>Duration</span>
						</div>
						<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
							<DropdownToggle caret className='d-flex align-items-center w-100'>
								{selectedInterval.label}
								<img src={angleDown} className='ml-auto' />
							</DropdownToggle>
							<DropdownMenu>{intervalItems}</DropdownMenu>
						</Dropdown>
					</div>

					<div className='col-lg-6 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>Ends At</span>
						</div>
						<div className='d-flex urlFieldCnt traitsCnt'>
							<div class='input-group'>
								<input type='text' class='form-control' value={date} />
								<div class='input-group-append'>
									<div class='input-group-text'>{time}</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-lg-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>Decreament Duration</span>
						</div>
						<Dropdown isOpen={dropdownOpen1} toggle={toggleDropdown1}>
							<DropdownToggle caret className='d-flex align-items-center w-100'>
								{selectedDuration.label}
								<img src={angleDown} className='ml-auto' />
							</DropdownToggle>
							<DropdownMenu>
								{filteredDurationOptions.map(decrementDuration => (
									<DropdownItem
										tag='a'
										key={decrementDuration.label}
										onClick={() => handleDuartionChange(decrementDuration)}
									>
										<div className='d-flex align-items-center'>
											{decrementDuration.label}{' '}
											{selectedDuration.label === decrementDuration.label && (
												<CheckTick />
											)}
										</div>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
					<hr className='w-100' />

					<div className='col-12 mt-3'>
						<div className='borderedCnt mb-3'>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t('Listing Price')}</h3>
								<span className='valueLabel ml-auto'>
									{getPrice === '' ? '--' : formatAmount(getPrice, 10)}{' '}
									{getCurrency?.currencySymbol}
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>WARRIOR Fee</h3>
								<span className='valueLabel ml-auto'>
									{data?.details?.platformFee}%
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t('Creator Earnings')}</h3>
								<span className='valueLabel ml-auto'>
									{data?.details?.royalty === '' || undefined
										? '-'
										: `${data?.details?.royalty}%`}
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>Potential Earnings</h3>
								<span className='valueLabel ml-auto'>
									<span>
										{finalAmount ? `($${formatAmount(usdtPrice, 10)})` : null}
									</span>
									{finalAmount ? formatAmount(finalAmount, 10) : '--'}{' '}
									{getCurrency?.currencySymbol}
								</span>
							</div>
						</div>
					</div>
					<hr className='w-100' />

					<div className='col-12 d-flex align-items-center mb-3'>
						<button
							type='button'
							className='btn btn-block blackBtn mx-2 my-2'
							onClick={() => navigate(-1)}
						>
							{t('Cancel')}
						</button>
						<button
							type='submit'
							className='btn btn-block gradientBtn mx-2 my-2'
							disabled={
								!isDirty || !isValid || fixedPriceSellMutation.isLoading
							}
						>
							{fixedPriceSellMutation.isLoading
								? 'Loading ...'
								: 'List for Sale'}
						</button>
					</div>
				</div>
			</form>
			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
			>
				{isError ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							<img src={IconModal} />
						</div>
						<h3 className='walletHeading my-3'>Something went wrong</h3>
						<button
							type='button'
							className='btn btn-block darkBtn'
							onClick={toggle}
						>
							{t('Okay')}
						</button>
					</div>
				) : loading ? (
					<div>
						<h3 className='walletHeading my-3'>Loading</h3>
						<h3 className='walletSubHeading'>
							To continue send transaction with your wallet.
						</h3>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							<img src={walletLoaderCnt} />
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</div>
				) : (
					<div className='createContent'>
						{/* <button className='btn closeBtn' onClick={toggle}>
						<svg
							width='12'
							height='12'
							viewBox='0 0 12 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
								fill='#595F6A'
							/>
						</svg>
					</button> */}
						<div className='d-flex justify-content-center'>
							<img
								src={data?.details?.asset}
								style={{
									height: '60px',
									aspectRatio: '1',
									borderRadius: '12px'
								}}
							/>
						</div>
						<h3 className='walletHeading my-3'>Item has been listed!</h3>
						<p className='text-center'>
							<span>{data?.details?.name}</span> from{' '}
							<span>{data?.collection?.name}</span> collection has been listed
							for sale.
						</p>
						<button
							type='button'
							className='btn btn-block gradientBtn'
							onClick={() => navigate('/profile', { state: '2' })}
						>
							View Listing
						</button>
					</div>
				)}
			</Modal>
		</>
	)
}

export default DutchAuction
