import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { ThemeContext } from '../../../views/context/context'
import { useContext } from 'react'
function collectionSaleChart(collectionAddress,Analyticsfilter) {
	const url = new URL(BACKEND_URL + 'collection/sales-chart')
	url.searchParams.set('contractAddress', collectionAddress)
	url.searchParams.set('filter', Analyticsfilter)
	return axios.get(url)
}

export function useCollectionSaleChart(collectionAddress) {
	const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)

	return useQuery({
        queryKey: ['sale_chart', collectionAddress,Analyticsfilter],
		queryFn: () => collectionSaleChart(collectionAddress,Analyticsfilter),
		enabled: !!(collectionAddress)
	})
}
