import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'

function toggleFavorites(asset) {
	return axios.post('home/watchlist-mutation_collection', {
		...asset
	})
}

export function useToggleWatchlist() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: toggleFavorites,
		onSuccess: () => {
			queryClient.invalidateQueries(['collection'])
		}
	})
}
