import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { usePriceHistory } from '../apis/priceHistory';
import cndds from '../../../assets/images/cndds.png'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const gradientOffset = () => {
    const data = [
        { stop: 0, color: '#dcac38' },
        { stop: 1, color: '#dcac38', opacity: 0.1 },
    ];

    return data.map((entry, index) => (
        <stop key={`stop-${index}`} offset={`${entry.stop * 100}%`} stopColor={entry.color} stopOpacity={entry.opacity || 1} />
    ));
};

const linearGradient = (
    <defs>
        <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
            {gradientOffset()}
        </linearGradient>
    </defs>
);

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="tooltip-content">
                    <h3 className="label-bold">{`${payload[0].value} ${payload[0].payload.currency}`}</h3>
                    <p className="label">{`${payload[0].payload.dateTime}`}</p>
                </div>
            </div>
        );
    }
    return null;
};


const PriceHistory = () => {

    const{t}=useTranslation()
    const params = useParams()
    const [chartData, setChartData] = useState([]);

    const { data, isLoading, isError, error, isSuccess, refetch } = usePriceHistory(
        params.collectionAddress,
        params.nftId,
        params.supplyId
    )
    
    useEffect(() => {
        const processedData = data?.data?.map(item => ({
            time: moment(item.createdAt).format("MMM D"),
            dateTime: moment(item.createdAt).format("MMM D, LT"),
            price: item.price,
            currency: item.currency
        }));
        setChartData(processedData);
    }, [isSuccess, data])

    if (!data?.data?.length) {
        return (
            <div className="col-12 my-auto">
                <div class="noResf text-center" >
                    <img class="img-fluid" src={cndds} alt="" />
                    <h1>{t("No Data")}</h1>
                </div>
            </div>
        )
    }


    return (
        <ResponsiveContainer width='100%'
            height={400}>
            <AreaChart data={chartData} margin={{ top: 20, right: 50, bottom: 20, left: 50 }}>
                {linearGradient}
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="price" stroke="#dcac38" fill="url(#areaGradient)" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default PriceHistory;