import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

async function fetchBids(orderId) {
	const url = new URL(BACKEND_URL + 'asset/bids')
	url.searchParams.set('id', orderId)

	return axios.get(url)
}

export function useFetchBids(orderId) {
	return useQuery({
		queryKey: ['bids', orderId],
		queryFn: () => fetchBids(orderId),
		enabled: !!orderId
	})
}
