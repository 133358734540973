import { forwardRef } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useCheckoutModalActions } from '../../../components/modals/checkout/store'
import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import { useBuyNowModalActions } from '../../../components/modals/buy-now/store'
import { chainId } from '../../../config/constants/chains'
import { timeUntil } from '../../../utils/formatRoundTime'

import ETHIcon from '../../../assets/images/collection/ETHIcon.png'
import BNBIcon from '../../../assets/images/bnbIconSm.png'
import MaticIcon from '../../../assets/images/maticIconSm.png'
import shoppingcart from '../../../assets/images/collection/shopping-cart.svg'
import minus from '../../../assets/images/minus.svg'
import { axios } from '../../../lib/axios'
import { useToast } from '../../../utils/toast/customSuccess'

const chainIconMap = {
	ethereum: ETHIcon,
	binance: BNBIcon,
	polygon: MaticIcon
}

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export const NftCard = forwardRef(function NftCard(
	{ item, onClick, classNames },
	ref
) {
	const { t } = useTranslation()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()
	const { address: account, isConnected } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { chain } = useNetwork()
	const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
		useBuyNowModalActions()
	const isVideo = item.details.asset.endsWith('.mp4')
	const timeUntilsExpiry =
		item.details.isMarketplace && timeUntil(item.details.saleExpiry)
	const toast = useToast()
	const queryClient = useQueryClient()

	const isMarketplace = item.details.isMarketplace
	const isOnFixed = item.details.saleType === 'fixed'
	const isOwner = account?.toLowerCase() === item?.owner?.address
	const isCart = item.details.isCart
	const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'
	const now = Math.floor(new Date().getTime() / 1000)
	const isAuctionExpired = now > item.details.saleExpiry

	const handleBuyNow = async (e) => {
		e.stopPropagation()
		setBuyNowModalAssetState(item)
		setCheckoutModalAssetState([item])
		setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] })

		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleBuyNowModal
			})
			return
		}

		try {
			if (item.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[item.details.chain])
			}

			toggleBuyNowModal()
		} catch (error) {
			console.error(error)
		}
	}

	const toggleCart = (asset) => (account) => {
		axios
			.post('asset/cart-mutation', {
				orderId: asset.details.orderId,
				userAddress: account,
				status: !asset.details.isCart
			})
			.then((res) => {
				if (res.data) {
					toast.success(
						`Item ${
							res.data.isCart ? 'added to' : 'removed from'
						} the cart`
					)
					queryClient.invalidateQueries(['nfts'])
					queryClient.invalidateQueries(['cart'])
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const handleToggleCart = (e) => {
		e.stopPropagation()
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleCart(item)
			})
			return
		} else {
			toggleCart(item)(account)
		}
	}

	return (
		<div
			className={classNames ? classNames : 'grid-col hover'}
			ref={ref ? ref : null}
			onClick={onClick}
			style={{
				objectFit: 'cover',
				//width: classNames ? '194.333px' : '171.2px'
				width: classNames ? '100%' : '100%'
			}}
		>
			<Link
				to={`/assets/${item.details.chain}/${item.details.contractAddress}/${item.details.nftId}/${item.details.supplyId}`}
			>
				<div className='gridItemCard'>
					<div className='gItemImg'>
						{!isVideo ? (
							<img
								src={item.details.asset}
								alt={item.details.name}
								className='img-fluid w-100 itemImage'
							/>
						) : (
							<video
								id='video-asset'
								controls
								loop
								playsinline=''
								preload='metadata'
								controlslist='nodownload'
								src={item.details.asset}
								style={{
									objectFit: 'contain',
									height: classNames ? '168px' : '160.55px',
									width: classNames ? '194.333px' : '173.6px'
								}}
							/>
						)}
						<div className='idTag'>#{item.details.supplyId}</div>
						{isMarketplace && (
							<div className='hoverImgCnt'>
								<div className='d-flex justify-content-between '>
									<div>
										<img
											src={
												chainIconMap[item.details.chain]
											}
											alt='chain'
											className='cointypeImg'
										/>
									</div>
									{!isAuctionExpired && (
										<div className='alarmbadge'>
											<i className='fas fa-alarm-clock'></i>{' '}
											{`${timeUntilsExpiry} left`}
										</div>
									)}
								</div>
								{!!account &&
									isLoggedIn &&
									!isOwner &&
									isOnFixed && (
										<div className='d-flex justify-content-between align-items-center'>
											<button
												className='buybowbtn rounded-pill'
												type='button'
												style={{ flexGrow: '1' }}
												onClick={handleBuyNow}
											>
												{t('Buy Now')}
											</button>
											{isConnected && isOnFixed ? (
												!isCart ? (
													<button
														className='cartBtnCircle ml-1'
														onClick={
															handleToggleCart
														}
													>
														<img
															src={shoppingcart}
															alt='shoppingcart'
														/>
													</button>
												) : (
													<button
														className='cartBtnCircle ml-1'
														onClick={
															handleToggleCart
														}
													>
														<img
															src={minus}
															alt='shoppingcart'
														/>
													</button>
												)
											) : null}
										</div>
									)}
							</div>
						)}
					</div>
					<div className='botcntCard'>
						<div className='bottomSmImg'>
							<img
								src={item.details.asset}
								height={73}
								alt={item.details.name}
							/>
						</div>
						<div className='botcntTxt'>
							<div className='itemName'>{item.details.name}</div>
							{isMarketplace && (
								<div className='d-flex justify-content-between'>
									<div className='itemPricetxt'>
										{t('Price')}
									</div>
									<div className='itemPriceval'>{`${parseFloat(
										item.details.price.toFixed(8)
									)} ${item.details.currency}`}</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Link>
		</div>
	)
})
