import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import yellowTick24px from '../../../assets/images/collection/yellow-tick-24px.svg'
import yellowTick20px from '../../../assets/images/collection/yellow-tick_20px.svg'

export default function CollectionDetails({ collection }) {
	const [showFullDescription, setFullDescription] = useState(false)
	const [isImageLoaded, setIsImageLoaded] = useState(false)

	const showIcon = collection.description.length > 60

	const showFullDescriptionHandler = () => {
		setFullDescription(!showFullDescription)
	}

	const description = showFullDescription
		? collection.description
		: collection.description.length < 60
		? collection.description
		: `${collection.description.slice(0, 60)}...`

	return (
		<div className='col-lg-6 order-lg-2 text-center'>
			<div className='userDetails'>
				<div
					className='imgtag'
					style={{ position: 'relative', zIndex: '69' }}
				>
					{isImageLoaded ? null : <Skeleton />}
					<img
						src={collection.logo}
						alt='userImg'
						style={isImageLoaded ? {} : { display: 'none' }}
						onLoad={() => setIsImageLoaded(true)}
					/>
				</div>
				<div className='text-center'>
					<div className='userName'>
						{collection.collectionName}
						{collection.isVerified && (
							<img
								src={yellowTick24px}
								alt='verified'
								className='ml-2'
							/>
						)}
					</div>
					<div className='usernameby'>
						<span className='fw-400'>By</span>{' '}
						{collection.creator.name !== ''
							? collection.creator.name
							: 'Unnamed'}{' '}
						{collection.creator.isVerified && (
							<img
								src={yellowTick20px}
								alt='verified'
								className='ml-2'
							/>
						)}
					</div>
					<div className='userDescpara'>{description}</div>
					{showIcon && (
						<div onClick={showFullDescriptionHandler}>
							<button className='readmorebtn'>
								Read {showFullDescription ? 'Less' : 'More'}{' '}
								<i
									className={`fas ${
										showFullDescription
											? 'fa-angle-up'
											: 'fa-angle-down'
									} ml-2`}
								></i>
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
