import { useCallback, useState } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'
import { useAccount } from 'wagmi'
import {
	EmailShareButton,
	TelegramShareButton,
	TwitterShareButton,
	FacebookShareButton
} from 'react-share'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { debounce } from 'lodash'

import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useToast } from '../../../utils/toast/customSuccess'
import { useReportModalActions } from '../../../components/modals/report-modal/store'
import { axios } from '../../../lib/axios'

import ellipsisV from '../../../assets/images/collection/ellipsis-v.svg'
import pennant from '../../../assets/images/collection/pennant.svg'
import shareIcon from '../../../assets/images/collection/shareIcon.svg'
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg'
import heart from '../../../assets/images/heart.svg'
import likeIcon from '../../../assets/images/likeIcon.svg'
import profileImg from '../../../assets/images/default-profile.png'

export function AssetHeader({ asset }) {
	const { t } = useTranslation()
	const { address: account } = useAccount()
	const [isLoading, setIsLoading] = useState(false)
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { toggleReportModal, setAsset: setReportAssetState } =
		useReportModalActions()
	const toast = useToast()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const toggleFavorites = (asset) => (account) => {
		setIsLoading(true)
		axios
			.post('asset/favorite-mutation', {
				id: asset.id,
				status: !asset.details.isFavorited,
				address: account
			})
			.then(() => {
				queryClient.invalidateQueries(['asset'])
			})
			.catch((error) => {
				console.error(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const handleFavorites = () => {
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleFavorites(asset)
			})
		} else {
			toggleFavorites(asset)(account)
		}
	}

	const throttledHandleFavorites = useCallback(
		debounce(handleFavorites, 500),
		[asset]
	)

	const url = `https://localhost/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}/${asset?.details?.supplyId}`

	const copyToLink = () => {
		navigator?.clipboard?.writeText(url)
		toast.success('Link Copied!')
	}

	const handleReport = () => {
		setReportAssetState(asset)
		toggleReportModal()
	}

	return (
		<div className='nftCard mb-3'>
			<div className='d-flex align-items-center w-100 mb-4 flex-wrap'>
				<h3 className='mb-0 mr-2'>{asset.details.name}</h3>
				<div className='d-flex align-items-center ml-auto'>
					<div
						className='iconBtn iconBtn-h40 d-flex justify-content-center align-items-center mr-2 hover'
						onClick={throttledHandleFavorites}
						style={{
							pointerEvents: `${isLoading ? 'none' : 'auto'}`,
							cursor: `${isLoading ? 'cursor-default' : 'pointer'
								}`
						}}
					>
						{asset.details.isFavorited ? (
							<img src={heart} />
						) : (
							<img src={likeIcon} />
						)}
					</div>
					<UncontrolledDropdown className='mr-2'>
						<DropdownToggle className='iconBtn iconBtn-h40'>
							<img src={shareIcon} alt='shareIcon' />
						</DropdownToggle>
						<DropdownMenu right className='dropdownMenu'>
							<DropdownItem className='mb-2'>
								<i class='fab fa-twitter-square mr-2'></i>{' '}
								<TwitterShareButton
									title={'Check out this account on wornft'}
									url={`https://localhost/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}/${asset?.details?.supplyId}`}
									via={['WorNft']}
								>
									{' '}
									Twitter
								</TwitterShareButton>
							</DropdownItem>
							<DropdownItem className='mb-2'>
								<i class='fab fa-telegram mr-2'></i>
								<TelegramShareButton
									url={`https://localhost/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}/${asset?.details?.supplyId}`}
									title={'Check out this account on wornft'}
									className='share-button'
									via={['WorNft']}
								>
									Telegram
								</TelegramShareButton>
							</DropdownItem>
							<DropdownItem className='mb-2'>
								<i class='fab fa-facebook mr-2'></i>
								<FacebookShareButton
									title={'Check out this account on wornft'}
									url={`https://localhost/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}/${asset?.details?.supplyId}`}
									via={['WorNft']}
									className='share-button'
								>
									Facebook
								</FacebookShareButton>
							</DropdownItem>
							<DropdownItem className='mb-2'>
								<i class='fas fa-envelope mr-2'></i>
								<EmailShareButton
									title={'Check out this account on wornft'}
									url={`https://localhost/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}/${asset?.details?.supplyId}`}
									via={['WorNft']}
									className='share-button'
								>
									Email
								</EmailShareButton>
							</DropdownItem>
							<DropdownItem className='mb-2' onClick={copyToLink}>
								<i class='fas fa-link mr-2'></i>{' '}
								{t('Copy Link')}
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>

					{account ?
						<UncontrolledDropdown>
							<DropdownToggle className='iconBtn iconBtn-h40'>
								<img src={ellipsisV} alt='ellipsisV' />
							</DropdownToggle>
							<DropdownMenu right className='dropdownMenu'>
								<DropdownItem onClick={handleReport}>
									<img
										src={pennant}
										alt='pennant'
										className='mr-2'
									/>{' '}
									{t('Report')}
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
						:
						""
					}
				</div>
			</div>
			<div className='row w-100'>
				<div className='col-lg-6'>
					<div className='d-flex align-items-center mb-lg-0 mb-3'>
						<img
							src={asset.collection.profile}
							height='44'
							width='44'
							className='mr-3 object-cover'
							style={{ borderRadius: '12px' }}
						/>
						<div className='d-flex flex-column'>
							<span className='cardLabel'>Collection</span>
							<Link
								className='cardValue d-flex align-items-center hover text-decoration-none color-inherit'
								to={`/collection/${asset.collection.address}`}
							>
								{asset.collection.name}{' '}
								{asset.collection.isVerified ? (
									<img src={yellowTick} className='ml-2' />
								) : null}
							</Link>
						</div>
					</div>
				</div>

				<div className='col-lg-6'>
					<div className='d-flex align-items-center'>
						<img
							src={
								asset.owner.profile !== 'undefined'
									? asset.owner.profile
									: profileImg
							}
							height='44'
							width='44'
							className='mr-3 object-cover'
							style={{ borderRadius: '12px' }}
						/>
						<div className='d-flex flex-column'>
							<span className='cardLabel'>{t("Owner")}</span>
							<Link
								className='cardValue d-flex align-items-center hover text-decoration-none color-inherit'
								to={`/profile/${asset.owner.address}`}
							>
								{asset.owner.name
									? asset.owner.name
									: 'Unnamed'}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
