import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useAccount, useNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useCountdown from '../../../hooks/useCountdown'
import { getAuctionCountdown, timeUntil } from '../../../utils/formatRoundTime'
import { formatDateToTimeMonth } from '../../../utils/formatTime'
import { Spinner } from '../../loader/spinner/Spinner'
import { CloseIcon } from '../buy-now/CloseIcon'
import {
	usePlaceBidModalActions,
	usePlaceBidModalState
} from '../place-bid/store'
import { useFetchBids } from './fetchBids'
import { useBidsModalActions, useBidsModalState } from './store'
import contracts from '../../../config/constants/contracts'
import { chainId } from '../../../config/constants/chains'
import { useConnectWalletModalActions } from '../connect-wallet/store'
import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import { formatAddress } from '../../../utils/formatAddress'

import cndds from '../../../assets/images/cndds.png'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export function BidsModal() {
	const { t } = useTranslation()

	const { toggleBidsModal } = useBidsModalActions()
	const { isOpen } = useBidsModalState()
	const { togglePlaceBidModal } = usePlaceBidModalActions()
	const { asset } = usePlaceBidModalState()
	const { data, isLoading } = useFetchBids(asset?.details?.orderId)
	const bids = data?.data.bidderDetails
	const { secondsRemaining } = useCountdown(data?.data?.saleExpiry)
	const { days, hours, minutes, seconds } =
		getAuctionCountdown(secondsRemaining)
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { address: account } = useAccount()
	const { chain } = useNetwork()

	if (!asset) {
		return null
	}

	const now = Math.floor(new Date().getTime() / 1000)
	const isAuctionExpired = now > asset.details.saleExpiry

	const handlePlaceBid = async () => {
		setConnectWalletModalState({ chainId: chainIdMap[asset.details.chain] })
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: togglePlaceBidModal
			})
			return
		}

		try {
			if (asset.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[asset.details.chain])
			}

			togglePlaceBidModal()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal modalLG'
			backdropClassName='selCurBp'
		>
			<ModalHeader
				toggle={toggleBidsModal}
				close={<CloseIcon onClose={toggleBidsModal} />}
			>
				{t('Bids')}
			</ModalHeader>
			<ModalBody>
				{isLoading ? (
					<div
						className='d-flex justify-content-center align-items-center'
						style={{ height: '500px' }}
					>
						<Spinner />
					</div>
				) : (
					<>
						<div className='borderBox mb-4'>
							<div className='d-flex flex-wrap justify-content-between'>
								<div>
									<div className='fs-14 fw-400 dark-text-secondary'>
										{t('Bids')}
									</div>
									<div className='fs-16 fw-400 dark-text'>
										{data?.data?.bids}
									</div>
								</div>
								<div>
									<div className='fs-14 fw-400 dark-text-secondary'>
										Bidders
									</div>
									<div className='fs-16 fw-400 dark-text'>
										{data?.data?.bidders}
									</div>
								</div>
								<div style={{ width: '20%' }}>
									<div className='fs-14 fw-400 dark-text-secondary'>
										Time Left
									</div>
									{!isAuctionExpired ? (
										<div className='fs-16 fw-400 dark-text'>
											{`${days}d ${hours}h ${minutes}m ${seconds}s`}
										</div>
									) : (
										'Expired'
									)}
								</div>
								<div>
									<div className='fs-14 fw-400 dark-text-secondary'>
										{t('Duration')}
									</div>
									<div className='fs-16 fw-400 dark-text'>
										{data?.data?.duration}
									</div>
								</div>
							</div>
						</div>
						<div
							className='table-responsive scroller'
							style={{ overflowY: 'auto', maxHeight: '320px' }}
						>
							<table className='table table-borderless table-hover mb-0 hmecollection nftTables_offers'>
								<thead className='trd_head'>
									<tr>
										<th className='text-center'>
											Bid Amount
										</th>
										<th className='text-center'>
											USD Price
										</th>
										<th className='text-center'>
											Bid Time
										</th>
										<th className='text-center'>Bidder</th>
									</tr>
								</thead>
								<tbody
									className='trdtable_body'
									style={{ overflowY: 'auto' }}
								>
									{bids?.length === 0 ? (
										<tr>
											<td colspan='4'>
												<div className='col-12'>
													<div class='noResf d-flex flex-column justify-content-center align-items-center'>
														<img
															class='img-fluid'
															src={cndds}
															alt=''
														/>
														<h1>No Data</h1>
													</div>
												</div>
											</td>
										</tr>
									) : (
										bids.map((bidder) => (
											<tr key={bidder.bidAmount}>
												<td className='text-center'>{`${bidder.bidAmount} ${data?.data?.currency}`}</td>
												<td className='text-center'>
													${bidder.usdPrice}
												</td>
												<td className='text-center'>
													{formatDateToTimeMonth(
														bidder.bidTime
													)}
												</td>
												<td className='yellowTxt text-center'>
													<Link
														className='yellowTxt'
														to={`/profile/${bidder.bidderAddress}`}
													>
														{bidder.bidder ===
														'Unnamed'
															? formatAddress(
																	bidder.bidderAddress,
																	5
															  )
															: bidder.bidder}
													</Link>
												</td>
											</tr>
										))
									)}
								</tbody>
							</table>
						</div>
						<div className='d-flex flex-wrap justify-content-between mt-4'>
							<button
								className='btn darkBtn my-1 '
								onClick={toggleBidsModal}
							>
								Get Back
							</button>
							{!!account &&
								account?.toLowerCase() !==
									asset?.owner?.address && (
									<button
										className='btn gradientBtn my-1'
										onClick={handlePlaceBid}
									>
										Place a Bid
									</button>
								)}
						</div>
					</>
				)}
			</ModalBody>
		</Modal>
	)
}
