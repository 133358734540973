import { BidPlacedSuccessfullyModal } from '../components/modals/bid-placed-successful/BidPlacedSuccessfullyModal'
import { BidsModal } from '../components/modals/bids/BidsModal'
import { BuyNowModal } from '../components/modals/buy-now/BuyNowModal'
import { CheckoutModal } from '../components/modals/checkout/CheckoutModal'
import ConnectWalletModal from '../components/modals/connect-wallet/ConnectWalletModal'
import { SomethingWentWrongModal } from '../components/modals/error/SomethingWentWrongModal'
import { PlaceBidModal } from '../components/modals/place-bid/PlaceBidModal'
import { PurchaseLoadingModal } from '../components/modals/purchase-loading/PurchaseLoadingModal'
import { MakeOfferModal } from '../components/modals/makeOffer/makeOffer'
import OfferPlaceSuccess from '../components/modals/offer-placed-successful/offerplaceSuccess'
import EditListingModal from '../components/modals/edit-listing-modal/editListingModal'
import ReportModal from '../components/modals/report-modal/reportModal'
import { CartModal } from '../components/modals/cart/CartModal'
import { MakeCollectionOfferModal } from '../components/modals/collection-offer/MakeCollectionOfferModal'
import { CollectionOfferSuccessModal } from '../components/modals/collection-offer-success/CollectionOfferSuccessModal'

export const ModalProvider = () => {
	return (
		<>
			<ConnectWalletModal />
			<BuyNowModal />
			<PurchaseLoadingModal />
			<SomethingWentWrongModal />
			<CheckoutModal />
			<BidsModal />
			<PlaceBidModal />
			<BidPlacedSuccessfullyModal />
			<MakeOfferModal />
			<OfferPlaceSuccess />
			<EditListingModal />
			<ReportModal />
			<CartModal />
			<MakeCollectionOfferModal />
			<CollectionOfferSuccessModal />
		</>
	)
}
