import { useEffect } from 'react'
import { Modal } from 'reactstrap'
import { useAccount } from 'wagmi'

import {
	useConnectWalletModalActions,
	useConnectWalletModalState
} from './store'
import useAuth, {
	WalletConnectorNotFoundError,
	WalletSwitchChainError
} from '../../../hooks/useAuth'
import { useToast } from '../../../utils/toast/customSuccess'
import { timeout } from '../../../utils/timeout'

import connectWalletIcon from '../../../assets/images/connectWalletIcon.png'
import metamaskIcon from '../../../assets/images/metamaskIcon.png'
import okxIcon from '../../../assets/images/okxIcon.png'
import coinbaseIcon from '../../../assets/images/coinbaseIcon.png'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import walletTick from '../../../assets/images/walletTick.png'
import { useTranslation } from 'react-i18next'

export default function ConnectWalletModal() {

	const{t}=useTranslation()
	const { login, logout } = useAuth()
	const { isOpen, isIdle, isLoading, isSuccess, callback } =
		useConnectWalletModalState()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const toast = useToast()
	const { isConnected } = useAccount()
	const { chainId } = useConnectWalletModalState()

	useEffect(() => {
		if (isConnected) return

		setConnectWalletModalState({ isIdle: true })
	}, [isConnected, setConnectWalletModalState])

	const handleConnect = (connectorId) => async () => {
		setConnectWalletModalState({ isIdle: false, isLoading: true })
		login({ connectorId, chainId })
			.then(async () => {
				localStorage.setItem('isLoggedIn', 'true')
				dispatchEvent(new Event('storage'))
				setConnectWalletModalState({ isLoading: false })
				await timeout(() => {
					setConnectWalletModalState({
						isSuccess: true
					})
					toggleConnectWalletModal()
				})
				await timeout(callback)
			})
			.catch((error) => {
				console.log(error)
				logout()
				setConnectWalletModalState({ isIdle: true, isLoading: false })
				if (error instanceof WalletConnectorNotFoundError) {
					console.log('no provider found')
				} else if (error instanceof WalletSwitchChainError) {
					toast.error(error.message)
				} else if (
					error.message.includes('User rejected the request')
				) {
					toast.error('User rejected the request')
				} else if (error.message.includes('Try connecting')) {
					toast.error(error.message)
				} else {
					toast.error(
						'Error connecting, please authorize wallet to access.'
					)
				}
			})
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggleConnectWalletModal}
			centered='true'
			className='curMdl lgMdl'
			backdropClassName='selCurBp'
		>
			<div className='selCrHd'>
				<button className='btn'>
					<svg
						width='12'
						height='12'
						viewBox='0 0 12 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
							fill='#595F6A'
						/>
					</svg>
				</button>
				<img src={connectWalletIcon} />
				<button className='btn' onClick={toggleConnectWalletModal}>
					<svg
						width='12'
						height='12'
						viewBox='0 0 12 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
							fill='#595F6A'
						/>
					</svg>
				</button>
			</div>
			<h3 className='walletHeading'>{t("Connect Wallet")}</h3>
			{isIdle ? (
				<>
					<p className='walletSubHeading'>
						{t("Choose how you want to connect. There are several wallet providers.")}
					</p>
					<div className='d-flex flex-wrap mt-3 justify-content-center'>
						<div
							className='walletIconCnt'
							onClick={handleConnect('metaMask')}
						>
							<img src={metamaskIcon} />
							<h3 className='heading'>Meta Mask</h3>
						</div>
						<div
							className='walletIconCnt'
							onClick={handleConnect('okx')}
						>
							<img src={okxIcon} />
							<h3 className='heading'>OKX</h3>
						</div>
						<div
							className='walletIconCnt'
							onClick={handleConnect('coinbaseWallet')}
						>
							<img src={coinbaseIcon} />
							<h3 className='heading'>Coinbase</h3>
						</div>
					</div>
				</>
			) : isLoading ? (
				<div className='d-flex justify-content-center align-items-center loaderCont'>
					<img src={walletLoaderCnt} />
					<div className='loaderAnimCnt'>
						<img src={walletLoader} className='loaderAnimation' />
					</div>
				</div>
			) : isSuccess ? (
				<div className='d-flex justify-content-center align-items-center'>
					<img src={walletTick} />
				</div>
			) : null}
		</Modal>
	)
}
