import React, { useContext, useEffect, useState } from 'react'
//import './gridviewlist.scss'
// import style from './profile.module.scss'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'
import Itemimg1 from '../../assets/images/collection/Itemimg1.png'
import Itemimg2 from '../../assets/images/collection/Itemimg2.png'
import Itemimg3 from '../../assets/images/collection/Itemimg3.png'
import Itemimg4 from '../../assets/images/collection/Itemimg4.png'
import Itemimg5 from '../../assets/images/collection/Itemimg5.png'
import Itemimg6 from '../../assets/images/collection/Itemimg6.png'
import Itemimg7 from '../../assets/images/collection/Itemimg7.png'
import Itemimg8 from '../../assets/images/collection/Itemimg8.png'
import Itemimg9 from '../../assets/images/collection/Itemimg9.png'
import Itemimg10 from '../../assets/images/collection/Itemimg10.png'

import ItemimgSm1 from '../../assets/images/collection/Itemimg-sm1.png'
import ItemimgSm2 from '../../assets/images/collection/Itemimg-sm2.png'
import ItemimgSm3 from '../../assets/images/collection/Itemimg-sm3.png'
import ItemimgSm4 from '../../assets/images/collection/Itemimg-sm4.png'
import ItemimgSm5 from '../../assets/images/collection/Itemimg-sm5.png'
import ItemimgSm6 from '../../assets/images/collection/Itemimg-sm6.png'
import ItemimgSm7 from '../../assets/images/collection/Itemimg-sm7.png'
import ItemimgSm8 from '../../assets/images/collection/Itemimg-sm8.png'
import ItemimgSm9 from '../../assets/images/collection/Itemimg-sm9.png'
import ItemimgSm10 from '../../assets/images/collection/Itemimg-sm10.png'

import transferIcon from '../../assets/images/collection/transferIcon.svg'
import listIcon from '../../assets/images/collection/listIcon.svg'
import offerIcon from './assets/bestOfferIcon.svg'
import selectIcon from './assets/selectIcon.svg'
import hideIcon from './assets/hideIcon.svg'
import copyIcon from './assets/copyIcon.svg'
import settingsIcon from './assets/settingsIcon.svg'

import ETHIcon from '../../assets/images/collection/ETHIcon.png'
import BNBIcon from '../../assets/images/collection/bnbCoin.png'
import MATICIcon from '../../assets/images/collection/maticIconSm.png'

import shoppingcart from '../../assets/images/collection/shopping-cart.svg'

import checkTickIcon from '../../assets/images/toast/checkIcon.svg'
import removeIcon from '../../assets/images/toast/checkIcon.svg'
import yellowTick20px from '../../assets/images/collection/yellow-tick_20px.svg'
import moreIcon from './assets/moreIcon.svg'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'

import ScaleLoader from 'react-spinners/ScaleLoader'

import { useTranslation } from 'react-i18next'

import { ThemeContext } from '../context/context'
import { useToast } from '../../utils/toast/customSuccess'
import { useAccount } from 'wagmi'
import { axios } from '../../lib/axios'
import { useNavigate, useParams } from 'react-router-dom'
import PaginationComponent from 'react-reactstrap-pagination'
import { useDebounce } from '../../utils/useDebounce'
import loader from '../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';

const landPerRow = 6

const GridViewItem = (props) => {
	const { t } = useTranslation()

	const { address, isConnected } = useAccount()

	const gridview = props.filterview ? 'g-col-5' : 'g-col-6'
	const listtag = [
		{
			name: t('listed')
		},
		{
			name: t('On Aution')
		},
		{
			name: '123-3121'
		},
		{
			name: '123-11,623.442 ETH'
		},
		{
			name: t('ETH')
		}
	]
	const [loading, setLoading] = useState(false)
	let [color, setColor] = useState('#ffffff')

	const litstedTagLoop = listtag.map((item) => {
		return (
			<>
				<div className='tagLabel'>
					<span>{item.name}</span>
					<i className='fas fa-times ml-2'></i>
				</div>
			</>
		)
	})

	const ItemList = [
		{
			imgsrc: Itemimg1,
			imgsmsrc: ItemimgSm1,
			itemadded: false
		},
		{
			imgsrc: Itemimg2,
			imgsmsrc: ItemimgSm2,
			itemadded: true
		},
		{
			imgsrc: Itemimg3,
			imgsmsrc: ItemimgSm3,
			itemadded: true
		},
		{
			imgsrc: Itemimg4,
			imgsmsrc: ItemimgSm4,
			itemadded: false
		},
		{
			imgsrc: Itemimg5,
			imgsmsrc: ItemimgSm5
		},
		{
			imgsrc: Itemimg6,
			imgsmsrc: ItemimgSm6
		},
		{
			imgsrc: Itemimg7,
			imgsmsrc: ItemimgSm7
		},
		{
			imgsrc: Itemimg8,
			imgsmsrc: ItemimgSm8
		},
		{
			imgsrc: Itemimg9,
			imgsmsrc: ItemimgSm9
		},
		{
			imgsrc: Itemimg10,
			imgsmsrc: ItemimgSm10
		}
	]

	const { collectedsearch, setCollectedSearch } = useContext(ThemeContext)
	const { collectedfilter, setCollectedFilter } = useContext(ThemeContext)
	const { collectedstatus, setcollectedStatus } = useContext(ThemeContext)
	const { checkcollectedChain, setcollectedCheckChain } =
		useContext(ThemeContext)
	const { singlecollectedcollectionList, setSingleCollectedCollectionList } =
		useContext(ThemeContext)
	const { chooselistingcollectedchain, setListingCollectedChain } =
		useContext(ThemeContext)
	const { collectedminAmount, setCollectedMinAmount } =
		useContext(ThemeContext)
	const { collectedmaxAmount, setCollectedMaxAmount } =
		useContext(ThemeContext)
	const { Collectedstatus, setCollectedStatus } = useContext(ThemeContext)
	const { createdfiltersortby, setCreatedFilterSortby } =
		useContext(ThemeContext)
	const { createdfiltersortorder, setCreatedFilterSortorder } =
		useContext(ThemeContext)

	const { id } = useParams()
	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)
	// const debouncedSearchText = useDebounce(collectedsearch, 500)

	useEffect(() => {

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})

		GetCollected()
	}, [
		page,
		address,
		Collectedstatus,
		collectedsearch,
		collectedfilter,
		collectedstatus,
		checkcollectedChain,
		singlecollectedcollectionList,
		Collectedstatus == true ? chooselistingcollectedchain : "",
		Collectedstatus == true ? collectedminAmount : "",
		Collectedstatus == true ? collectedmaxAmount : "",
		createdfiltersortby,
		createdfiltersortorder,
		id,
	])

	const [getcollected, setGetcollected] = useState('')
	const [totalactivitycount, setTotalActivity] = useState('')

	const [count, setCount] = useState('')

	function handlePageChange(page) {
		setPage(page);
		// ... do something with `page`
	}

	console.log("collectedsearch", collectedsearch !== "", singlecollectedcollectionList.length > 0, checkcollectedChain.length > 0, collectedstatus[0] != [''], Collectedstatus == true, createdfiltersortorder == "desc", createdfiltersortby == "_id", collectedsearch !== "" || singlecollectedcollectionList.length > 0 || checkcollectedChain.length > 0 || collectedstatus[0] != [''] || Collectedstatus == true || createdfiltersortorder == "desc" || createdfiltersortby == "_id" ? 1 : page,collectedstatus[0] != [''])

	const GetCollected = async () => {
		setLoading(true)
		const fetch =
			await axios.get(`user/collected-nfts?address=${id ? id : address}&page=${collectedsearch !== "" || singlecollectedcollectionList.length > 0 || checkcollectedChain.length > 0 || collectedstatus[0] != [''] || Collectedstatus == true || createdfiltersortorder !== "desc" || createdfiltersortby !== "_id" ? 1 : page}&limit=15&sortField=${createdfiltersortby}&sortOrder=${createdfiltersortorder}&filter=${collectedsearch}&collectionName=${singlecollectedcollectionList}&chain=${checkcollectedChain}&sortStatusField=${collectedstatus}&currency=${Collectedstatus == true ? chooselistingcollectedchain : ""}&minPrice=${Collectedstatus == true ? collectedminAmount : ""}&maxPrice=${Collectedstatus == true ? collectedmaxAmount : ""}`)
		if (fetch.status == true) {
			setGetcollected(fetch.data)
			setTotalActivity(Math.ceil(fetch.count / 15))
			setCount(fetch.count)
			setLoading(false)
		} else {
			setGetcollected('')
			setTotalActivity('')
			setCount('')
			setLoading(false)
		}
	}

	const navigate = useNavigate()

	const ItemCard =
		getcollected &&
		getcollected.map((item) => {
			return (
				<>
					<div
						className='grid-col'
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover'
						}}
						onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`)}
					>
						<div
							className={`gridItemCard ${item.itemadded ? 'active' : ''
								}`}
						>
							<div className='gItemImg'>
								{typeof item.asset == 'string' &&
									item.asset !== undefined ? (
									item.imageFormat.startsWith('video') ? (
										<video
											className='img-fluid w-100 itemImage'
											src={item.asset}
											alt='Uploaded'
											muted
											autoPlay
											style={{
												width: '100px',
												height: '150px',
												objectFit: 'cover'
											}}
										/>
									) : item.asset !== 'undefined' ? (
										<img
											src={item.asset}
											alt='Itemimg1'
											className='img-fluid w-100 itemImage'
										/>
									) : (
										''
									)
								) : (
									''
								)}
								<div className='idTag'>#{item.supplyId}</div>
								<div className='hoverImgCnt'>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='d-flex align-items-center'>
											<img
												src={
													item.chain == 'binance'
														? BNBIcon
														: item.chain ==
															'ethereum'
															? ETHIcon
															: item.chain ==
																'polygon'
																? MATICIcon
																: ''
												}
												alt='ETHIcon'
												className='cointypeImg'
											/>
											{/* <div className='idTag'>#{item.nftId}</div> */}
										</div>
										{/* <div className='plusbadge'>
											<i className='fas fa-plus'></i>
										</div> */}
									</div>
									<div className='d-flex justify-content-between align-items-center'>
										{item.isMarketplace == true ? (
											''
										) : (

											!!address && address.toLowerCase() === item.owner ? (
												<button
													className='buybowbtn rounded-pill'
													type='button'
													onClick={(e) => {
														e.stopPropagation()
														navigate(
															`/listforsale/${item.contractAddress}/${item.nftId}/${item.supplyId}`
														)
													}}
												>
													{t('List For Sale')}
												</button>
											) : null
										)
										}
										{/* <button className='cartBtnCircle'>
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="plainToggle">
                                                <img src={moreIcon} alt="shoppingcart" />
                                            </DropdownToggle>
                                            <DropdownMenu right className={style.dropdownMenu}>
                                                <DropdownItem>
                                                    <img src={selectIcon} alt="selectIcon" className='mr-2' /> Select
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <img src={listIcon} alt="listIcon" className='mr-2' /> {t("List For Sale")}
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <img src={offerIcon} alt="offerIcon" className='mr-2' /> {t("Accept Best Offer")}
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <img src={hideIcon} alt="hideIcon" className='mr-2' /> {t("Hide")}
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <img src={transferIcon} alt="transferIcon" className='mr-2' /> {t("Transfer")}
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <img src={copyIcon} alt="copyIcon" className='mr-2' /> {t("Copy Link")}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </button> */}
									</div>
								</div>
							</div>
							<div className='botcntCard'>
								<div className='bottomSmImg'>
									<img
										src={item.imgsmsrc}
										height={73}
										alt='ItemimgSm1'
									/>
								</div>
								<div className='botcntTxt'>
									<div className='itemLabel'>
										{item.collectionName}{' '}
										<img
											src={item.verification == "Verified" ? yellowTick20px : ""}
											height='12'
										/>
									</div>
									<div className='itemName'>
										{item.nftName}
									</div>
									{/* <div className='d-flex justify-content-between'>
                                    <div className="itemPricetxt">{t("Highest Bid")}</div>
                                    <div className="itemPriceval">1.4 ETH</div>
                                </div> */}
								</div>
							</div>
						</div>
					</div>
				</>
			)
		})

	const closecollectedsearch = () => {
		setCollectedSearch('')
	}
	const closecollectedfilter = () => {
		setCollectedFilter('Recently Received')
		setCreatedFilterSortby('_id')
		setCreatedFilterSortorder('desc')
	}
	const closecollectedstatus = (data) => {
		// setcollectedStatus('')
		let a = collectedstatus.includes(data)
		if (a == true) {
			const index = collectedstatus.indexOf(data);
			if (index > -1) { // only splice array when item is found
				collectedstatus.splice(index, 1); // 2nd parameter means remove one item only
				const nerArr = [...collectedstatus]
				setcollectedStatus(nerArr);
				if (nerArr.length == 0) {
					setcollectedStatus([""]);
				}
			}

		}
	}

	const litstedTagLoop3 = collectedstatus && collectedstatus.map((item) => {
		return (
			<>
				{item == "" ?
					""
					:
					<>
						<div className='tagLabel'>
							<span>{item}</span>
							<i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollectedstatus(item)}></i>
						</div>
					</>
				}
			</>
		);
	});



	const litstedTagLoop2 =
		checkcollectedChain &&
		checkcollectedChain.map((item) => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closecollectedchain(item)}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const litstedCollection =
		singlecollectedcollectionList &&
		singlecollectedcollectionList.map((item) => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closeCollection(item)}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const closecollectedchain = (item) => {
		let a = checkcollectedChain.includes(item)

		if (a == true) {
			const index = checkcollectedChain.indexOf(item)
			if (index > -1) {
				// only splice array when item is found
				checkcollectedChain.splice(index, 1) // 2nd parameter means remove one item only
				const nerArr = [...checkcollectedChain]
				setcollectedCheckChain(nerArr)
			}
		}
	}

	const closeCollection = (item) => {
		let a = singlecollectedcollectionList.includes(item)
		if (a == true) {
			const index = singlecollectedcollectionList.indexOf(item)
			if (index > -1) {
				// only splice array when item is found
				singlecollectedcollectionList.splice(index, 1) // 2nd parameter means remove one item only
				const nerArr = [...singlecollectedcollectionList]
				setSingleCollectedCollectionList(nerArr)
			}
		}
	}

	const closesearch1 = () => {
		setCollectedMinAmount('')
		setCollectedMaxAmount('')
		setCollectedStatus(false)
	}

	const clearAll = () => {
		setCollectedSearch('')
		setcollectedStatus([""])
		setcollectedCheckChain([])
		setSingleCollectedCollectionList([])
		setCollectedMinAmount('')
		setCollectedMaxAmount('')
		setCollectedStatus(false)
		setCollectedFilter('Recently Received')
		setCreatedFilterSortby('_id')
		setCreatedFilterSortorder('desc')
	}

	return (
		<>
			<div className={loading ? 'spinner' : 'd-none'}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>
			<div className='py-3'>
				<div className='row mb-4'>
					<div className='col-lg-6'>
						<span class='cmntxt'>
							{count ? count : ''}{' '}
							{count ? t('Items') : ''}
						</span>
					</div>

				</div>
				<div className='fiterSelectlabel mb-4'>

					{collectedsearch ? (
						<div className='tagLabel'>
							<span>{collectedsearch}</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closecollectedsearch}
							></i>
						</div>
					) : (
						''
					)}
					{collectedfilter == "Recently Received" ? "" :
						collectedfilter ? (
							<div className='tagLabel'>
								<span>{collectedfilter}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closecollectedfilter}
								></i>
							</div>
						) : (
							''
						)}

					{collectedstatus.includes("") ? "" : litstedTagLoop3}
					{litstedTagLoop2}
					{litstedCollection}

					{Collectedstatus == true ? (
						typeof chooselistingcollectedchain !== 'undefined' &&
							collectedminAmount &&
							collectedmaxAmount ? (
							<div className='tagLabel'>
								<span>
									{chooselistingcollectedchain}{' '}
									{collectedminAmount}-{collectedmaxAmount}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closesearch1}
								></i>
							</div>
						) : typeof chooselistingcollectedchain !==
							'undefined' && collectedminAmount ? (
							<div className='tagLabel'>
								<span>
									{chooselistingcollectedchain}{' '}
									{collectedminAmount}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closesearch1}
								></i>
							</div>
						) : typeof chooselistingcollectedchain !==
							'undefined' && collectedmaxAmount ? (
							<div className='tagLabel'>
								<span>
									{chooselistingcollectedchain}{' '}
									{collectedmaxAmount}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closesearch1}
								></i>
							</div>
						) : (
							''
						)
					) : (
						''
					)}

					{collectedsearch ||
						(collectedfilter == "Recently Received" ? "" : collectedfilter) ||
						!collectedstatus.includes("") ||
						(Collectedstatus == true
							? (typeof chooselistingcollectedchain !== 'undefined' &&
								collectedminAmount) ||
							collectedmaxAmount
							: '') ||
						checkcollectedChain?.length > 0 ||
						singlecollectedcollectionList?.length > 0 ? (
						<button className='clearAllBtn' onClick={clearAll}>
							{t('Clear All')}
						</button>
					) : (
						''
					)}
				</div>


				{getcollected && getcollected.length > 0 ? (
					<div className={`gridrow ${gridview}`}>
						{ItemCard}
					</div>
				) : (
					// <div
					// 	className={`gridrow ${gridview} justify-content-center`}
					// >
					<div className='noItemFound'>
						<div className='text-center'>
							<img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
						</div>
						<div className='nofounttxt mb-4'>
							{t("No items were found.Try to broaden your search.")}
						</div>
						{/* <div>
                                        <button className='applyBtn'>{t("Apply")}</button>
                                    </div> */}
					</div>
					// </div>
				)}

				<div className='row justify-content-center'>
					<div className='col-lg-4 col-md-6 col-sm-8'>
						<div class="bri_pagenation2">
							{getcollected && getcollected.length > 0 ?
								<div class=''>
									<nav aria-label="Page ">
										<ResponsivePagination
											total={totalactivitycount}
											current={page}
											onPageChange={page => handlePageChange(page)}
											previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
											nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
											previousClassName="page-link-1"
											nextClassName="page-link-1"
										/>
									</nav>
									{/* <PaginationComponent
							size='sm'
							defaultActivePage={1}
							totalItems={totalactivitycount}
							maxPaginationNumbers={3}
							pageSize={15}
							onSelect={(page) => setPage(page)}
						/> */}
								</div>
								:
								""
							}
						</div>
					</div>
				</div>

				<useToast
					toastImgIcon={checkTickIcon}
					toastmassage={t('Added to Cart')}
				/>
				<useToast
					toastImgIcon={removeIcon}
					toastmassage={t('Removed from Cart')}
				/>
			</div>
		</>
	)
}

export default GridViewItem
