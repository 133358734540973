

import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { axios } from '../lib/axios'
import { useAccount } from 'wagmi'
import { useParams } from 'react-router-dom';
import loader from '../assets/images/Loader.png'
const PrivateRoutes = () => {

    const { address } = useAccount()
    const { id } = useParams()
    const [status, setStatus] = useState(true)

    useEffect(() => {
        isAuthenticated()
    }, [address])

    const [loading, setLoading] = useState(false);

    const isAuthenticated = async () => {
        setLoading(true)
        const checkReport = await axios.post(
            '/home/check_user_reported',
            { address: id ? id : address }
        )
        if (checkReport.status == true) {
            setStatus(true)
            setLoading(false)
        } else {
            setStatus(false)
            setLoading(false)
        }
    }

    return (
        <>
            {/* <div className={loading ? "spinner" : "d-none"}>
                <img src={loader} alt='' className='spinAnimate' />
            </div> */}

            {/* {loading && loading == false && */}
                {status && status == true ? <Outlet /> : <Navigate to="/" />}
        </>
    )
}

export default PrivateRoutes
