import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

export function fetchCollectionTraits(collectionAddress) {
	const url = new URL(BACKEND_URL + 'collection/traits')
	url.searchParams.set('address', collectionAddress)

	return axios.get(url)
}

export function useFetchCollectionTraits(collectionAddress) {
	return useQuery({
		queryKey: ['collection-traits', collectionAddress],
		queryFn: () => fetchCollectionTraits(collectionAddress),
		enabled: !!collectionAddress
	})
}
