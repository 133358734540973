import React, { useState, useEffect } from 'react'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal
} from 'reactstrap'
import {
	useAccount,
	useNetwork,
	useSwitchNetwork,
	usePublicClient
} from 'wagmi'
import { parseUnits } from 'viem'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NumericFormat } from 'react-number-format'
import { format } from 'date-fns'
import IconModal from '../../../assets/images/IconModal.png'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import angleDown from '../../../assets/images/angleDown.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useFixedPriceSell } from '../apis/fetchListAssetDetails'
import { useMarketplaceContract } from '../../../hooks/useContract'
import { useSellAuction } from '../../../hooks/useSellAuction'
import { chainId } from '../../../config/constants/chains'
import contracts from '../../../config/constants/contracts'
// import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import marketPlaceAbi from '../../../config/abi/marketplace.json'
import Web3 from 'web3'
import ApprovalABI from '../../AbiArray/Approvalcollection/Bnbapproval.json'
import { useToast } from '../../../utils/toast/customSuccess'
import { useTranslation } from 'react-i18next'
import { formatValue } from '../../../utils/formatValue'
import { formatAmount } from '../../../utils/formatAmount'
import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

const intervals = [
	{ label: '10 minutes', duration: 10, format: '10M' },
	{ label: '1 hour', duration: 60, format: '1H' },
	{ label: '6 hours', duration: 360, format: '6H' },
	{ label: '1 day', duration: 1440, format: '1D' },
	{ label: '3 days', duration: 4320, format: '3D' },
	{ label: '7 days', duration: 10080, format: '7D' },
	{ label: '1 month', duration: 43200, format: '1M' }
]

export const Auction = ({ data, onData, activeTab }) => {
	const { t } = useTranslation()
	const { address: account, isConnected } = useAccount()
	const { chain } = useNetwork()
	const { pro } = useContractProvider()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const navigate = useNavigate()
	const params = useParams()
	const toast = useToast()

	const fixedPriceSellMutation = useFixedPriceSell()
	const [loading, setLoading] = useState(false)
	const [modal, setModal] = useState(false)
	const [canList, setCanList] = useState()
	const toggle = () => setModal(!modal)

	const schema = yup.object().shape({
		price: yup
			.number()
			.typeError('Please enter a valid amount')
			.required('Required')
			.max(100000000000000, 'The amount cannot exceed 100,000,000,000,000')
			.test('not-zero', 'The value should be atleast one', value => value !== 0)
		// .test(
		// 	'decimal',
		// 	'The amount cannot have precision greater than 14 decimal places',
		// 	value => (value.toString().split('.')[1] || '').length <= 14
		// )
	})

	const {
		control,
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		formState: { errors, isValid, isDirty }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			supply: data?.supply
		}
	})

	useEffect(() => {
		if (activeTab) {
			setValue('price', '')
		}
	}, [activeTab])

	const chainId = chainIdMap[data?.details?.chain]

	const marketplaceContractAddress = data
		? contracts.marketplace[chainIdMap[data?.details?.chain]]
		: null

	const web3 = new Web3(pro)
	const tetherContract = new web3.eth.Contract(
		marketPlaceAbi,
		marketplaceContractAddress
	)
	const approvalTetherContract = new web3.eth.Contract(
		ApprovalABI,
		data?.details?.contractAddress
	)

	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const CheckTick = () => {
		return (
			<>
				<svg
					class='ml-auto'
					width='12'
					height='9'
					viewBox='0 0 12 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
						fill='#FFA900'
					/>
				</svg>
			</>
		)
	}

	const [selectedInterval, setSelectedInterval] = useState(intervals[4])
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const [date, setDate] = useState('')
	const [time, setTime] = useState('')

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

	const handleIntervalChange = interval => {
		setSelectedInterval(interval)
	}

	const intervalItems = intervals.map(interval => (
		<DropdownItem
			tag='a'
			key={interval.label}
			onClick={() => handleIntervalChange(interval)}
		>
			<div className='d-flex align-items-center'>
				{interval.label}{' '}
				{selectedInterval.label === interval.label && <CheckTick />}
			</div>
		</DropdownItem>
	))

	useEffect(() => {
		const currentDate = new Date()
		const nextDate = new Date(
			currentDate.getTime() + selectedInterval.duration * 60000
		)

		const formatDate = format(nextDate, 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval])

	useEffect(() => {
		setValue('currency', data && data?.allowedCurrencies?.[0])
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [data?.allowedCurrencies])

	const getCurrency = watch('currency')
	const getPrice = watch('price')

	const plat = data?.details?.platformFee / 100
	const royal = data?.details?.royalty / 100

	const finalAmount = getPrice * (1 - (plat + royal))
	const usdtPrice = finalAmount * getCurrency?.usdPrice
	const singleNftUsdtPrice = getPrice * getCurrency?.usdPrice

	useEffect(() => {
		const obj = {
			token: getCurrency,
			price: getPrice,
			totalSellAmount: finalAmount,
			singleNftUsdtPrice: singleNftUsdtPrice
		}

		onData(obj)
	}, [getCurrency, getPrice])

	useEffect(() => {
		async function getSellStatus() {
			const url = new URL(BACKEND_URL + 'asset/can-sell')
			url.searchParams.set('collection', params.collectionAddress)
			url.searchParams.set('nftId', params.nftId)

			const response = await axios.get(url)
			if (response.status) {
				setCanList(true)
			} else {
				setCanList(false)
			}
		}

		getSellStatus()
	}, [])

	const [isError, setIsError] = useState(false)

	const onSubmit = async () => {
		const amount = parseUnits(getPrice, getCurrency?.currencyDecimal)

		const asset = [
			account,
			'0x0000000000000000000000000000000000000000',
			params.collectionAddress,
			getCurrency?.contractAddress,
			data?.details?.nftId,
			1,
			amount,
			Math.round(Date.now() / 1000),
			Math.round((Date.now() + selectedInterval?.duration * 60 * 1000) / 1000),
			2,
			0
		]
		setLoading(true)
		setIsError(false)
		toggle()

		try {
			if (walletType === 'okx') {
				if (
					data?.details?.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[data?.details?.chain])
				}
			} else {
				if (data?.details?.chain === 'binance' && chain.id !== 97) {
					await switchNetworkAsync(97)
				} else if (
					data?.details?.chain === 'ethereum' &&
					chain.id !== 11155111
				) {
					await switchNetworkAsync(11155111)
				} else if (data?.details?.chain === 'polygon' && chain.id !== 80001) {
					await switchNetworkAsync(80001)
				}
			}
		} catch (error) {
			setLoading(false)
			setIsError(true)
			return
		}

		const approvalResponse = await approvalTetherContract.methods
			.isApprovedForAll(account, marketplaceContractAddress)
			.call()

		if (approvalResponse) {
			try {
				await tetherContract.methods
					.createAuction(asset)
					.send({
						from: account.toString()
					})
					.on('transactionHash', async hash => {})
					.on('receipt', async approveresult => {
						if (approveresult.status === true) {
							const res = await fixedPriceSellMutation.mutateAsync({
								id: data?.id,
								saleType: 'auction',
								currency: getCurrency?.currencySymbol,
								price: getPrice,
								duration: selectedInterval?.format,
								seller: account
							})

							if (res?.status) {
								toast.success(res?.message)
							} else {
								setIsError(true)
								toast.error(res?.message)
							}
						}
					})
			} catch (error) {
				setLoading(false)
				setIsError(true)
			}
		} else {
			try {
				await approvalTetherContract.methods
					.setApprovalForAll(marketplaceContractAddress, true)
					.send({ from: account.toString() })
					.on('transactionHash', async hash => {})
					.on('receipt', async approveresult => {})

				await tetherContract.methods
					.createAuction(asset)
					.send({
						from: account.toString()
					})
					.on('transactionHash', async hash => {})
					.on('receipt', async approveresult => {
						if (approveresult.status === true) {
							const res = await fixedPriceSellMutation.mutateAsync({
								id: data?.id,
								saleType: 'auction',
								currency: getCurrency?.currencySymbol,
								price: getPrice,
								duration: selectedInterval?.format,
								seller: account
							})

							if (res?.status) {
								toast.success(res?.message)
							} else {
								setIsError(true)
								toast.error(res?.message)
							}
						}
					})
			} catch (error) {
				setLoading(false)
				setIsError(true)
			}
		}
	}

	useEffect(() => {
		if (fixedPriceSellMutation.isSuccess) {
			setLoading(false)
		}
	}, [fixedPriceSellMutation.isSuccess])

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-row'>
					<div className='col-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>Starting Price</span>
							{errors.price && (
								<label className='text-danger errLabel ml-auto'>
									{errors.price.message}
								</label>
							)}
						</div>
						<div className='d-flex urlFieldCnt traitsCnt mb-2'>
							<div class='input-group mb-2'>
								<Controller
									name='price'
									control={control}
									defaultValue=''
									render={({ field }) => (
										<NumericFormat
											{...field}
											placeholder='Amount'
											className='form-control'
											decimalScale={10}
											allowNegative={false}
											onValueChange={values => {
												const { value } = values
												field.onChange(value)
											}}
										/>
									)}
								/>
								<div class='input-group-append'>
									<div class='input-group-text'>
										<Controller
											name='currency'
											control={control}
											render={({ field: { onChange, value } }) => (
												<select
													value={JSON.stringify(value)}
													onChange={event =>
														onChange(JSON.parse(event.target.value))
													}
												>
													{data &&
														data?.allowedCurrencies?.map((currency, index) => (
															<option
																key={index}
																value={JSON.stringify(currency)}
															>
																{currency.currencySymbol}
															</option>
														))}
												</select>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-lg-6 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t('Duration')}</span>
						</div>
						<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
							<DropdownToggle caret className='d-flex align-items-center w-100'>
								{selectedInterval.label}
								<img src={angleDown} className='ml-auto' />
							</DropdownToggle>
							<DropdownMenu>{intervalItems}</DropdownMenu>
						</Dropdown>
					</div>

					<div className='col-lg-6 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>Ends At</span>
						</div>
						<div className='d-flex urlFieldCnt traitsCnt'>
							<div class='input-group'>
								<input type='text' class='form-control' value={date} />
								<div class='input-group-append'>
									<div class='input-group-text'>{time}</div>
								</div>
							</div>
						</div>
					</div>
					<hr className='w-100' />

					<div className='col-12 mt-3'>
						<div className='borderedCnt mb-3'>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t('Listing Price')}</h3>
								<span className='valueLabel ml-auto'>
									{getPrice === '' ? '--' : formatAmount(getPrice, 10)}{' '}
									{getCurrency?.currencySymbol}
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>WARRIOR Fee</h3>
								<span className='valueLabel ml-auto'>
									{data?.details?.platformFee}%
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t('Creator Earnings')}</h3>
								<span className='valueLabel ml-auto'>
									{data?.details?.royalty === '' || undefined
										? '-'
										: `${data?.details?.royalty}%`}
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>Potential Earnings</h3>
								<span className='valueLabel ml-auto'>
									<span>
										{finalAmount ? `($${formatAmount(usdtPrice, 10)})` : null}
									</span>
									{finalAmount ? formatAmount(finalAmount, 10) : '--'}{' '}
									{getCurrency?.currencySymbol}
								</span>
							</div>
						</div>
					</div>
					<hr className='w-100' />

					{!canList && (
						<div className='noteTxt mb-3'>
							<span className='pr-2'>
								<svg
									width='21'
									height='20'
									viewBox='0 0 21 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M10.5 2.25C14.75 2.25 18.25 5.75 18.25 10C18.25 14.2812 14.75 17.75 10.5 17.75C6.21875 17.75 2.75 14.2812 2.75 10C2.75 5.75 6.21875 2.25 10.5 2.25ZM10.5 16.25C13.9375 16.25 16.75 13.4688 16.75 10C16.75 6.5625 13.9375 3.75 10.5 3.75C7.03125 3.75 4.25 6.5625 4.25 10C4.25 13.4688 7.03125 16.25 10.5 16.25ZM10.5 5.6875C11.2188 5.6875 11.8125 6.28125 11.8125 7C11.8125 7.75 11.2188 8.3125 10.5 8.3125C9.75 8.3125 9.1875 7.75 9.1875 7C9.1875 6.28125 9.75 5.6875 10.5 5.6875ZM12.25 13.625C12.25 13.8438 12.0625 14 11.875 14H9.125C8.90625 14 8.75 13.8438 8.75 13.625V12.875C8.75 12.6875 8.90625 12.5 9.125 12.5H9.5V10.5H9.125C8.90625 10.5 8.75 10.3438 8.75 10.125V9.375C8.75 9.1875 8.90625 9 9.125 9H11.125C11.3125 9 11.5 9.1875 11.5 9.375V12.5H11.875C12.0625 12.5 12.25 12.6875 12.25 12.875V13.625Z'
										fill='#6E737E'
									/>
								</svg>
							</span>
							<span>
								{t(
									'One of the copies of this NFT has already been listed for auction. After the auction expires, you can list this NFT for auction.'
								)}
							</span>
						</div>
					)}

					<div className='col-12 d-flex align-items-center mb-3'>
						<button
							type='button'
							className='btn btn-block blackBtn mx-2 my-2'
							onClick={() => navigate(-1)}
						>
							{t('Cancel')}
						</button>
						<button
							type='submit'
							className='btn btn-block gradientBtn mx-2 my-2'
							disabled={!isDirty || !isValid || !canList}
						>
							{t('List for Sale')}
						</button>
					</div>
				</div>
			</form>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
			>
				{isError ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							<img src={IconModal} />
						</div>
						<h3 className='walletHeading my-3'>Something went wrong</h3>
						<button
							type='button'
							className='btn btn-block darkBtn'
							onClick={toggle}
						>
							{t('Okay')}
						</button>
					</div>
				) : loading ? (
					<div>
						<h3 className='walletHeading my-3'>Loading</h3>
						<h3 className='walletSubHeading'>
							To continue send transaction with your wallet.
						</h3>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							<img src={walletLoaderCnt} />
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</div>
				) : (
					<div className='createContent'>
						{/* <button className='btn closeBtn' onClick={toggle}>
						<svg
							width='12'
							height='12'
							viewBox='0 0 12 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
								fill='#595F6A'
							/>
						</svg>
					</button> */}
						<div className='d-flex justify-content-center'>
							<img
								src={data?.details?.asset}
								style={{
									height: '60px',
									aspectRatio: '1',
									borderRadius: '12px'
								}}
							/>
						</div>
						<h3 className='walletHeading my-3'>Item has been listed!</h3>
						<p className='text-center'>
							<span>{data?.details?.name}</span> from{' '}
							<span>{data?.collection?.name}</span> collection has been listed
							for sale.
						</p>
						<button
							type='button'
							className='btn btn-block gradientBtn'
							onClick={() => navigate('/profile', { state: '2' })}
						>
							View Listing
						</button>
					</div>
				)}
			</Modal>
		</>
	)
}

export default Auction
