import React, { useEffect, useState } from 'react'
import {
	Button,
	Collapse,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { NumericFormat } from 'react-number-format'
import { useAccount } from 'wagmi'
import FilterOffer from './FilterOffer'

function StatusFilter({ filters, onStatusChange }) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [selectedStatuses, setSelectedStatuses] = useState(
		!filters.search.status ? ['ALL'] : Object.values(filters.search.status)
	)

	useEffect(() => {
		setSelectedStatuses(
			!filters.search.status
				? ['ALL']
				: Object.values(filters.search.status)
		)
	}, [filters])

	const handleOnClick = (status) => () => {
		if (status === 'ALL') {
			setSelectedStatuses(['ALL'])
			const updatedfilters = {
				...filters,
				search: {
					...filters.search,
					status: null
				}
			}
			onStatusChange(updatedfilters)
		} else {
			setSelectedStatuses((currentStatuses) => {
				const statusArrayWithoutAllStatus = currentStatuses.includes(
					'ALL'
				)
					? currentStatuses.filter((s) => s !== 'ALL')
					: currentStatuses
				const statusArrayWithToggledStatus =
					statusArrayWithoutAllStatus.includes(status)
						? currentStatuses.filter((s) => s !== status)
						: [...statusArrayWithoutAllStatus, status]
				const updatedStatuses =
					statusArrayWithToggledStatus.length === 0
						? ['ALL']
						: statusArrayWithToggledStatus

				const updatedfilters = {
					...filters,
					search: {
						...filters.search,
						status:
							statusArrayWithToggledStatus.length === 0
								? null
								: updatedStatuses.reduce(
									(acc, cur, curIndex) => {
										if (cur === 'ALL') {
											return acc
										}
										acc = { ...acc, [curIndex]: cur }
										return acc
									},
									{}
								)
					}
				}
				onStatusChange(updatedfilters)
				return updatedStatuses
			})
		}
	}

	return (
		<div>
			<Button className='filterTit' onClick={() => setIsOpen(!isOpen)}>
				<span>{t('Status')} </span>
				<i
					className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'
						}`}
				></i>
			</Button>
			<Collapse isOpen={isOpen}>
				<div className='collapseCnt'>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('ALL')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('ALL')}
					>
						{t('All')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('IS_LISTED')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('IS_LISTED')}
					>
						{t('List')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('ON_AUCTION')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('ON_AUCTION')}
					>
						{t('On Auction')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('IS_NEW')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('IS_NEW')}
					>
						{t('New')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('HAS_OFFERS')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('HAS_OFFERS')}
					>
						{t('Has Offers')}
					</button>
				</div>
			</Collapse>
		</div>
	)
}

function StatusActivityFilter({ filters, onStatusChange }) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [selectedStatuses, setSelectedStatuses] = useState(
		!filters.search.status ? ['ALL'] : Object.values(filters.search.status)
	)

	useEffect(() => {
		setSelectedStatuses(
			!filters.search.status
				? ['ALL']
				: Object.values(filters.search.status)
		)
	}, [filters])

	const handleOnClick = (status) => () => {
		if (status === 'ALL') {
			setSelectedStatuses(['ALL'])
			const updatedfilters = {
				...filters,
				search: {
					...filters.search,
					status: null
				}
			}
			onStatusChange(updatedfilters)
		} else {
			setSelectedStatuses((currentStatuses) => {
				const statusArrayWithoutAllStatus = currentStatuses.includes(
					'ALL'
				)
					? currentStatuses.filter((s) => s !== 'ALL')
					: currentStatuses
				const statusArrayWithToggledStatus =
					statusArrayWithoutAllStatus.includes(status)
						? currentStatuses.filter((s) => s !== status)
						: [...statusArrayWithoutAllStatus, status]
				const updatedStatuses =
					statusArrayWithToggledStatus.length === 0
						? ['ALL']
						: statusArrayWithToggledStatus

				const updatedfilters = {
					...filters,
					search: {
						...filters.search,
						status:
							statusArrayWithToggledStatus.length === 0
								? null
								: updatedStatuses.reduce(
									(acc, cur, curIndex) => {
										if (cur === 'ALL') {
											return acc
										}
										acc = { ...acc, [curIndex]: cur }
										return acc
									},
									{}
								)
					}
				}
				onStatusChange(updatedfilters)
				return updatedStatuses
			})
		}
	}

	return (
		<div>
			<Button className='filterTit' onClick={() => setIsOpen(!isOpen)}>
				<span>{t('Event Type')} </span>
				<i
					className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'
						}`}
				></i>
			</Button>
			<Collapse isOpen={isOpen}>
				<div className='collapseCnt'>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('ALL')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('ALL')}
					>
						{t('All')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('SALES')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('SALES')}
					>
						{t('Sales')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('LISTINGS')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('LISTINGS')}
					>
						{t('Listing')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes('OFFERS')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('OFFERS')}
					>
						{t('Offers')}
					</button>
					<button
						className={classnames(
							{
								active: selectedStatuses.includes(
									'COLLECTION_OFFERS'
								)
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('COLLECTION_OFFERS')}
					>
						{t('Collection Offers')}
					</button>
					{/* <button
						className={classnames(
							{
								active: selectedStatuses.includes('transfer')
							},
							'filterStatusbtn'
						)}
						onClick={handleOnClick('transfer')}
					>
						{t('Transfers')}
					</button> */}
				</div>
			</Collapse>
		</div>
	)
}

function OwnerFilter({ filters, onOwnerChange }) {
	const { t } = useTranslation()
	const { address: account } = useAccount()
	const [isOpen, setIsOpen] = useState(false)
	const [checkedFilter, setCheckedFilter] = useState(
		!filters.search.owner
			? 'all'
			: filters.search.owner.address === account
				? 'me'
				: 'all'
	)

	useEffect(() => {
		setCheckedFilter(
			!filters.search.owner
				? 'all'
				: filters.search.owner.address === account
					? 'me'
					: 'all'
		)
	}, [filters])

	const handleOnChange = (owner) => () => {
		if (owner === 'all') {
			setCheckedFilter('all')
			const updatedfilters = {
				...filters,
				search: {
					...filters.search,
					owner: null
				}
			}
			onOwnerChange(updatedfilters)
		} else {
			setCheckedFilter((currentOwner) => {
				const updatedfilters = {
					...filters,
					search: {
						...filters.search,
						owner: {
							address: account
						}
					}
				}
				onOwnerChange(updatedfilters)
				return currentOwner
			})
		}
	}

	return (
		<div>
			<Button
				className='filterTit'
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				<span>{t('Owner')} </span>
				<i
					className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'
						}`}
				></i>
			</Button>
			<Collapse isOpen={isOpen}>
				<div className='collapseCnt'>
					<div className='filterInnercard'>
						<div class='d-flex align-items-center mb-3'>
							<label class='cuschk mr-2 mb-0' For='checkbx1'>
								<input
									type='checkbox'
									id='checkbx1'
									checked={checkedFilter === 'all'}
									onChange={handleOnChange('all')}
								/>
								<span class='checkmark'></span>
							</label>
							<h3 className='mb-0'>{t('All')}</h3>
						</div>
						<div class='d-flex align-items-center mb-0'>
							<label class='cuschk mr-2 mb-0' For='checkbx2'>
								<input
									type='checkbox'
									id='checkbx2'
									checked={checkedFilter === 'me'}
									onChange={handleOnChange('me')}
								/>
								<span class='checkmark'></span>
							</label>
							<h3 className='mb-0'>{t('Me')}</h3>
						</div>
					</div>
				</div>
			</Collapse>
		</div>
	)
}

function PriceFilter({ collectionDetails, filters, onPriceFilterChange }) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [minimumPrice, setMinimumPrice] = useState('')
	const [maximumPrice, setMaximumPrice] = useState('')
	const [currencies, setCurrencies] = useState(
		collectionDetails.data.currencies
	)
	const [selectedCurrency, setSelectedCurrency] = useState(
		filters.search.priceFilter
			? filters.search.priceFilter.symbol
			: currencies[0]
	)

	useEffect(() => {
		setSelectedCurrency(
			filters.search.priceFilter
				? filters.search.priceFilter.symbol
				: currencies[0]
		)
		setMinimumPrice(
			filters.search.priceFilter ? filters.search.priceFilter.min : ''
		)
		setMaximumPrice(
			filters.search.priceFilter ? filters.search.priceFilter.max : ''
		)
	}, [filters])

	const handleMinPriceChange = (values) => {
		const { floatValue } = values
		setMinimumPrice(floatValue)
	}

	const handleMaxPriceChange = (values) => {
		const { floatValue } = values
		setMaximumPrice(floatValue)
	}

	const handleClick = () => {
		const updatedFilters = {
			...filters,
			search: {
				...filters.search,
				priceFilter: {
					symbol: selectedCurrency,
					min: minimumPrice,
					max: maximumPrice
				}
			}
		}
		onPriceFilterChange(updatedFilters)
	}

	return (
		<div>
			<Button
				className='filterTit'
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				<span>{t('Price')} </span>
				<i
					className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'
						}`}
				></i>
			</Button>
			<Collapse isOpen={isOpen}>
				<div className='collapseCnt'>
					<div className='row'>
						<div className='col'>
							<div className='form-group formInputs'>
								<Input
									type='select'
									onChange={(e) =>
										setSelectedCurrency(e.target.value)
									}
									value={selectedCurrency}
								>
									{currencies.map((currency) => (
										<option key={currency} value={currency}>
											{currency}
										</option>
									))}
								</Input>
							</div>
						</div>
					</div>
					<div className='row align-items-center'>
						<div className='col'>
							<div className='form-group formInputs'>
								<NumericFormat
									className='form-control'
									placeholder='Min'
									value={minimumPrice}
									allowNegative={false}
									customInput={Input}
									onValueChange={handleMinPriceChange}
								/>
							</div>
						</div>
						<div className='col-auto text-center px-0 mb-3'>to</div>
						<div className='col'>
							<div className='form-group formInputs'>
								<NumericFormat
									className='form-control'
									placeholder='Max'
									value={maximumPrice}
									allowNegative={false}
									customInput={Input}
									onValueChange={handleMaxPriceChange}
								/>
							</div>
						</div>
					</div>
					<div>
						<button
							className='applybtn'
							disabled={
								minimumPrice === '' || maximumPrice === ''
							}
							onClick={handleClick}
						>
							{t('Apply')}
						</button>
					</div>
				</div>
			</Collapse>
		</div>
	)
}

function CurrencyFilter({ collectionDetails, filters, onCurrenciesChange }) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [currencies, setCurrencies] = useState(
		collectionDetails.data.currencies
	)
	const [selectedCurrencies, setSelectedCurrencies] = useState(
		!filters.search.paymentAssets
			? []
			: Object.values(filters.search.paymentAssets)
	)

	useEffect(() => {
		setSelectedCurrencies(
			!filters.search.paymentAssets
				? []
				: Object.values(filters.search.paymentAssets)
		)
	}, [filters])

	const handleOnChange = (currency) => (e) => {
		setSelectedCurrencies((currentCurrencies) => {
			const currenciesArray = currentCurrencies.includes(currency)
				? currentCurrencies.filter((c) => c !== currency)
				: [...currentCurrencies, currency]

			const updatedfilters = {
				...filters,
				search: {
					...filters.search,
					paymentAssets:
						currenciesArray.length === 0
							? null
							: currenciesArray.reduce((acc, cur, curIndex) => {
								acc = { ...acc, [curIndex]: cur }
								return acc
							}, {})
				}
			}
			onCurrenciesChange(updatedfilters)
			return currenciesArray
		})
	}

	return (
		<div>
			<Button
				className='filterTit'
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				<span>{t('Currency')} </span>
				<i
					className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'
						}`}
				></i>
			</Button>
			<Collapse isOpen={isOpen}>
				<div className='collapseCnt'>
					<div className='filterInnercard'>
						{currencies?.map((c, i) => {
							return (
								<div
									class='d-flex align-items-center mb-3'
									key={i}
								>
									<label class='cuschk mr-2 mb-0' for={c}>
										<input
											type='checkbox'
											id={c}
											checked={selectedCurrencies.includes(
												c
											)}
											onChange={handleOnChange(c)}
										/>
										<span class='checkmark'></span>
									</label>
									<h3 className='mb-0'>{c}</h3>
								</div>
							)
						})}
					</div>
				</div>
			</Collapse>
		</div>
	)
}

function TraitValue({ traitKey, value, filters, onTraitsChange }) {
	const trait = Object.values(filters.search.stringTraits ?? {})
		.filter((t) => t.name === traitKey)
		.findIndex((t) => Object.values(t.values).includes(value.traitName))

	const [isChecked, setIsChecked] = useState(trait !== -1)

	useEffect(() => {
		setIsChecked(trait !== -1)
	}, [filters, trait])

	const handleOnChange = () => {
		setIsChecked(!isChecked)

		const stringTraitsArray = Object.entries(
			filters.search.stringTraits ?? {}
		)
		const [traitIndex, traitObject] =
			stringTraitsArray.find((s) => s[1].name === traitKey) ?? []

		const values = Object.values(traitObject ? traitObject.values : {})
		const valuesArray = values.includes(value.traitName)
			? values.filter((v) => v !== value.traitName)
			: [...values, value.traitName]
		const o = valuesArray.reduce((acc, cur, curIndex) => {
			acc = { ...acc, [curIndex]: cur }
			return acc
		}, {})

		const stringTraitsObj = stringTraitsArray
			.filter((t) => t[1].name !== traitKey)
			.reduce((acc, cur, curIndex) => {
				acc = { ...acc, [curIndex]: cur[1] }
				return acc
			}, {})

		const obj = traitIndex
			? {
				[traitIndex]: {
					name: encodeURIComponent(traitKey),
					values: o
				}
			}
			: {
				[stringTraitsArray.length]: {
					name: encodeURIComponent(traitKey),
					values: {
						0: encodeURIComponent(value.traitName)
					}
				}
			}

		const updatedfilters = {
			...filters,
			search: {
				...filters.search,
				stringTraits:
					valuesArray.length !== 0
						? {
							...filters.search.stringTraits,
							...obj
						}
						: stringTraitsObj
			}
		}
		onTraitsChange(updatedfilters)
	}

	return (
		<div class='d-flex align-items-center mb-3 justify-content-between'>
			<div className='d-flex align-items-center'>
				<label class='cuschk mr-2 mb-0'>
					<input
						type='checkbox'
						checked={isChecked}
						onChange={handleOnChange}
					/>
					<span class='checkmark'></span>
				</label>
				<h3 className='mb-0'>{value.traitName}</h3>
			</div>
			<span className='rightTxt'>{value.traitCount}</span>
		</div>
	)
}

function Trait({ trait, filters, onTraitsChange }) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [values, setValues] = useState(trait.values)

	const content = values.map((value, i) => {
		return (
			<TraitValue
				key={i}
				traitKey={trait.key}
				value={value}
				filters={filters}
				onTraitsChange={onTraitsChange}
			/>
		)
	})

	const handleOnChange = (e) => {
		if (e.target.value === '') {
			return setValues(trait.values)
		}
		setValues(
			trait.values.filter((v) => {
				return v.traitName
					.toLowerCase()
					.includes(e.target.value.toLowerCase())
			})
		)
	}

	const hasNoValues = values.length === 0

	return (
		<div>
			<Button className='filterTit' onClick={() => [setIsOpen(!isOpen)]}>
				<span>{t(trait.key)} </span>
				<span>
					<span className='rightcounttxt'>{trait.count}</span>
					<i
						className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'
							}`}
					></i>
				</span>
			</Button>
			<Collapse isOpen={isOpen}>
				<div className='collapseCnt'>
					<div className='form-group formInputs'>
						<InputGroup>
							<InputGroupAddon addonType='prepend'>
								<InputGroupText className='px-2'>
									<svg
										width='17'
										height='17'
										viewBox='0 0 17 17'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z'
											fill='white'
										/>
									</svg>
								</InputGroupText>
							</InputGroupAddon>
							<Input
								placeholder={t('Search traits')}
								onChange={handleOnChange}
							/>
						</InputGroup>
					</div>
					{content}
					{hasNoValues && (
						<p style={{ textAlign: 'center' }}>No data</p>
					)}
				</div>
			</Collapse>
		</div>
	)
}

function TraitsFilter({ traits, filters, onTraitsChange }) {
	const { t } = useTranslation()

	const content = traits?.data?.map((trait, i) => {
		return (
			<Trait
				key={i}
				trait={trait}
				filters={filters}
				onTraitsChange={onTraitsChange}
			/>
		)
	})

	return (
		<>
			{content?.length > 0 && (
				<>
					<div className='filtersubTit'>{t('Traits')}</div>
					{content}
				</>
			)}
		</>
	)
}

export function FiltersSidebar({
	traits,
	filters,
	handleFiltersChange,
	tab,
	collectionDetails
}) {
	const { isConnected } = useAccount()

	return (
		<>
			<div className='filtercard'>
				{tab !== '2' ?
					<>
						{tab !== '3' ? (
							<StatusFilter
								filters={filters}
								onStatusChange={handleFiltersChange}
							/>
						) : (
							<StatusActivityFilter
								filters={filters}
								onStatusChange={handleFiltersChange}
							/>
						)}
						{isConnected && (
							<OwnerFilter
								filters={filters}
								onOwnerChange={handleFiltersChange}
							/>
						)}
						<PriceFilter
							collectionDetails={collectionDetails}
							filters={filters}
							onPriceFilterChange={handleFiltersChange}
						/>
						<CurrencyFilter
							collectionDetails={collectionDetails}
							filters={filters}
							onCurrenciesChange={handleFiltersChange}
						/>
						<TraitsFilter
							traits={traits}
							filters={filters}
							onTraitsChange={handleFiltersChange}
						/>
					</>
					:
					<FilterOffer
						traits={traits}
						filters={filters}
						onTraitsChange={handleFiltersChange}
					/>
				}
			</div>
		</>
	)
}
