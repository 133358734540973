import { useAccount, useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'

function cancelAuctionOrder(asset, address) {
	const order = {
		orderId: asset.details.orderId
	}
	return axios.post('asset/cancel-listing', { ...order })
}

export function useCancelAuctionOrder() {
	const queryClient = useQueryClient()
	const { address: account } = useAccount()

	return useMutation({
		mutationFn: (asset) => cancelAuctionOrder(asset, account),
		onSuccess: () => {
			queryClient.invalidateQueries(['asset'])
		}
	})
}
