import React, { useContext, useState } from 'react';
//import style from './myCollection.scss';
import Binance from '../../assets/images/Binance.svg';
import Eth from '../../assets/images/Eth.svg';
import Polygon from '../../assets/images/Polygon.svg';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import MyCollectionData from './MyCollectionData';
// import './myCollection.scss';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../context/context';
//import '../../assets/scss/mycollecion_mint.scss'

export const ChooseCollection = () => {

  const navigate = useNavigate()

  const tableImg = [
    {
      imgsrc: Binance,
      blockchain: 'BNB',
      symbol: 'binance'
    },
    {
      imgsrc: Eth,
      blockchain: 'ETH',
      symbol: "ethereum"
    },
    {
      imgsrc: Polygon,
      blockchain: 'MATIC',
      symbol: "polygon"
    }

  ]
  const { blockchain, setBlockChain } = useContext(ThemeContext);

  const chooseblockchain = (data) => {
    setBlockChain(data.symbol)
    sessionStorage.setItem("blockchain", data.symbol)
    localStorage.setItem('currency', data.blockchain)
    navigate("/createcollection")
  }

  const back = () => {
    navigate("/mycollection")
  }

  return (
    <div style={{marginBottom:"60px"}}>
      <div className="row" >
        <div className="col-12 d-flex flex-column align-items-center justify-content-center chooseBlckCnt">
          <button className="backIcon" onClick={back}><i className="fas fa-angle-left"></i></button>
          <h3 className="collectionSecHeading text-center">Choose Blockchain</h3>
          <p className="collectionSecSubHeading text-center">Choose the most suitable blockchain for your needs.</p>
        </div>
      </div>
      <div className="row mt-5 " >
        {tableImg && tableImg.map((data) =>
        (

          <div className="col-sm-4" onClick={() => chooseblockchain(data)}>
            <div className="chsBlckChnCnt">
              <div className="d-flex flex-column justify-content-center align-items-center content">
                <img src={data.imgsrc} className="mb-2" />
                <h3>{data.blockchain}</h3>
              </div>
            </div>
          </div>

        ))}
      </div>

    </div>
  );
};

export default ChooseCollection;