import { useCallback } from 'react'
import { Signature } from 'ethers'
import { useAccount, useSignMessage as useSignMessageWagmi } from 'wagmi'

export function useSignMessage() {
	const { address: account } = useAccount()
	const { signMessageAsync: sign } = useSignMessageWagmi()

	return {
		signMessage: useCallback(async (args) => sign(args), [account]),
		getVrs: useCallback((hash) => {
			const { v, r, s } = Signature.from(hash)
			return [v, r, s]
		}, [])
	}
}
