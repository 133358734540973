import { useQuery } from '@tanstack/react-query'
import queryString from 'query-string'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { flattenFilters } from '../../../utils/flattenFilters'
import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'

export async function fetchCollectionActivity(
	collectionAddress,
	filters,
	pageParam,
	callback
) {
	const url = new URL(BACKEND_URL + 'collection/activity')
	url.searchParams.set('address', collectionAddress)
	url.searchParams.set('page', pageParam)
	const flattenedFilters = flattenFilters(filters)
	const queryString = convertObjectToQueryString(flattenedFilters)
	url.search += queryString

	const response = await axios.get(url)
	callback(response.count ?? response.data.length)
	return response.data
}

export function useFetchCollectionActivity(collectionAddress) {
	return useQuery({
		queryKey: ['collection_activity', collectionAddress],
		queryFn: () => fetchCollectionActivity(collectionAddress),
		enabled: !!collectionAddress
	})
}
