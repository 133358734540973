import React, { useEffect, useState } from 'react';
import logoImg from '../../assets/images/logoImg.png';
import coverImg from '../../assets/images/coverImg.png';
import ethIconSm from '../../assets/images/ethIconSm.png';
import bnbIconSm from '../../assets/images/bnbIconSm.png';
import maticIconSm from '../../assets/images/maticIconSm.png';
import angleDown from '../../assets/images/angleDown.svg';
import discord from '../../assets/images/discord.svg';
import telegramPlane from '../../assets/images/telegram-plane.svg';
import twitter from '../../assets/images/twitterNew.svg';
import instagram from '../../assets/images/instagram.svg';
import youtube from '../../assets/images/youtube.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import Pencil from '../../assets/images/Pencil.svg';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown, UncontrolledPopover, PopoverBody, Input } from 'reactstrap';
import classnames from 'classnames';
import MyCollectionData from './MyCollectionData';
// import './myCollection.scss';
//import '../../assets/scss/mycollecion_mint.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { axios } from '../../lib/axios'
import validator from 'validator'
import { useToast } from '../../utils/toast/customSuccess';
import ScaleLoader from "react-spinners/ScaleLoader";
import reject from './decline.png'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next';

export const ChooseCollection = () => {

  const { t } = useTranslation()
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const toast = useToast()

  const CheckTick = () => {
    return (
      <>
        <svg class="ml-auto" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
        </svg>
      </>
    )
  }

  const navigate = useNavigate()
  const params = useParams()
  const mycollection = () => {
    navigate("/mycollection")
  }

  useEffect(() => {
    singlecollection()
    Category()
  }, [])

  const [singledata, setSingleData] = useState("")
  const [getcategory, setCategory] = useState("")

  const [Collectionverify, setCollectionVerify] = useState("")
  const [Logoverify, setLogoVerify] = useState("")



  const singlecollection = async () => {
    setLoading(true)
    const collection = await axios.post("user/getSingleCollection", { "id": params.id })
    if (collection.status == true) {
      setSingleData(collection.data)
      setDescription(collection.data.description)
      setBannerImage(collection.data.bannerImage)
      setDiscord(collection.data.discord)
      setTelegram(collection.data.telegram)
      setTwitter(collection.data.twitter)
      setInsta(collection.data.instagram)
      setYoutube(collection.data.youtube)
      setSingleCategory(collection.data.categoryName)
      setImgtype(collection.data.imageFormat)
      setRoyalityfee(collection.data.royaltyFee)
      setVerify(collection.data.twitterVerify)
      SetWholeVerify(collection.data.verification)
      setCollectionVerify(collection.data.collectionNameVerify)
      setLogoVerify(collection.data.logoVerify)
      setLoading(false)
    }
    else {
      // toast.error(collection.data.message)
    }
  }

  const Category = async () => {
    const category = await axios.post("user/getCategoryList", "")
    if (category.status == true) {
      setCategory(category.data)
    }
    else {
      // toast.error(collection.data.message)
    }
  }

  const [singlecategory, setSingleCategory] = useState("")
  const categories = (data) => {
    if (data.name == "" || data.name == undefined) {
      document.getElementById('category').innerHTML = 'Please choose categories'
    }
    else {
      setSingleCategory(data.name)
      document.getElementById('category').innerHTML = ''

    }
  }

  const [description, setDescription] = useState("")
  const [bannerimage, setBannerImage] = useState()
  const [discord1, setDiscord] = useState("")
  const [telegram, setTelegram] = useState("")
  const [twitter1, setTwitter] = useState("")
  const [insta, setInsta] = useState("")
  const [youtube1, setYoutube] = useState("")
  const [imgtype, setImgtype] = useState("")
  const [royalityfee, setRoyalityfee] = useState("")
  let imageFile;
  const imageupload = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(webp|png|gif|mp4)$/)) {
      document.getElementById('uploadimage').innerHTML = 'Please select valid image for WEBP,GIF,PNG,MP4'
    }
    else if (imageFile == "" || imageFile == undefined) {
      setBannerImage('')
      document.getElementById('uploadimage').innerHTML = 'Please choose image'
    }
    else {
      setBannerImage(imageFile);
      setImgtype(imageFile.type)
      document.getElementById('uploadimage').innerHTML = ''
    }
  }

  let disreg = /(https?: \/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/
  let telreg = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/
  let twireg = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/
  let instareg = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/
  let youreg = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/

  const Discord = (e) => {

    setDiscord(e.target.value)

  }
  const Telegram = (e) => {

    setTelegram(e.target.value)

  }

  const Twitter = (e) => {
    if (e.target.value == "" || e.target.value == undefined) {
      setTwitter(e.target.value)
      document.getElementById('twit').innerHTML = 'Please enter twitter url'
    }
    else if (!twireg.test(e.target.value)) {
      setTwitter(e.target.value)
      document.getElementById('twit').innerHTML = 'Invalid url'
    }
    else {
      setTwitter(e.target.value)
      document.getElementById('twit').innerHTML = ''
    }
  }
  const Instagram = (e) => {

    setInsta(e.target.value)

  }
  const Youtube = (e) => {

    setYoutube(e.target.value)

  }


  var numbers = /^[0-9]+$/;

  const Royalityfee1 = (e) => {
    if (e.target.value == "" || e.target.value == undefined) {
      setRoyalityfee(e.target.value)
      document.getElementById('royality').innerHTML = 'Please enter royality fee'
    }
    else if (e.target.value >= 31) {
      setRoyalityfee(e.target.value)
      document.getElementById('royality').innerHTML = 'Maximum royality fee is 30 %'
    }
    else {
      setRoyalityfee(e.target.value)
      document.getElementById('royality').innerHTML = ''
    }
  }

  const [verify, setVerify] = useState("")
  const [wholeverify, SetWholeVerify] = useState("")

  const Verify = () => {

    if (singledata && singledata.volume >= 5) {
      setVerify(singledata.twitterVerify == "" ? "Pending" : singledata.twitterVerify == "Rejected" ? "Pending" : singledata.twitterVerify == "Verified" ? "Verified" : "")

      SetWholeVerify(singledata.verification == "" ? "Pending" : singledata.verification == "Pending" ? "Pending" : singledata.verification == "Verified" ? "Verified" : "")

      setCollectionVerify(singledata.collectionNameVerify == "" ? "" : singledata.collectionNameVerify == "Verified" ? "Verified" : "")
      setLogoVerify(singledata.logoVerify == "" ? "" : singledata.logoVerify == "Verified" ? "Verified" : "")
    }
    else {
      toast.error(`Minimum 5 ${singledata.chain == "binance" ? "BNB" : singledata.chain == "ethereum" ? "ETH" : singledata.chain == "polygon" ? "MATIC" : ""} of Volume to verify`)

    }
  }


  const SavesChanges = async () => {
    setLoading(true)
    if (bannerimage == "" && bannerimage == "undefined" && singlecategory == "" && royalityfee == "" && twitter1 == "") {
      document.getElementById('uploadimage').innerHTML = 'Please choose image'
      document.getElementById('category').innerHTML = 'Please choose categories'
      document.getElementById('twit').innerHTML = 'Please enter twitter url'
      document.getElementById('royality').innerHTML = 'Please enter royality fee'

      setLoading(false)
    }
    else if (bannerimage == "" || bannerimage == "undefined") {
      document.getElementById('uploadimage').innerHTML = 'Please choose image'
      setLoading(false)
    }
    else if (singlecategory == "" || singlecategory == undefined) {
      document.getElementById('category').innerHTML = 'Please choose categories'
      setLoading(false)
    }
    else if (royalityfee == "") {
      document.getElementById('royality').innerHTML = 'Please enter royality fee'
      setLoading(false)
    }
    else if (royalityfee >= 31) {
      document.getElementById('royality').innerHTML = 'Maximum royality fee is 30 %'
      setLoading(false)
    }
    else if (twitter1 == "" || twitter1 == undefined) {
      document.getElementById('twit').innerHTML = 'Please enter twitter url'
      setLoading(false)
    }
    else if (!twireg.test(twitter1)) {
      document.getElementById('twit').innerHTML = 'Invalid url'
      setLoading(false)
    }
    else {

      const formData = new FormData()
      formData.append('collectionName', singledata.collectionName)
      formData.append('categoryName', singlecategory)
      formData.append('logoImage', singledata.logoImage)
      formData.append('bannerImage', bannerimage)
      formData.append('description', description)
      formData.append('royaltyFee', royalityfee)
      formData.append('discord', discord1)
      formData.append('telegram', telegram)
      formData.append('twitter', twitter1)
      formData.append('instagram', insta)
      formData.append('youtube', youtube1)
      formData.append('url', singledata.url)
      formData.append('chain', singledata.chain)
      formData.append('_id', singledata._id)
      formData.append('imageFormat', imgtype)
      formData.append('verification', wholeverify)
      formData.append('twitterVerify', verify)

      const editcollection = await axios.post("user/update-single-collection", formData)
      if (editcollection.status == true) {
        toast.success(editcollection.message)
        setLoading(false)
        setTimeout(() => {
          navigate("/mycollection")
        }, 1000)
      }
      else {
        toast.error(editcollection.message)
        setLoading(false)
      }
    }

  }

  const cancel = () => {
    navigate("/mycollection")
  }


  return (
    <>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <div className="row">
        <div className="col-lg-2 mb-3">
          <button className="backIcon" onClick={mycollection}><i className="fas fa-angle-left"></i></button>
        </div>
        <div className="col-lg-3 mb-3">
          <h3 className="collectionSecHeading text-left">Edit <br />Collection</h3>
        </div>
        <div className="col-lg-6">
          <div className="createCollectionCard">
            <form>
              <div className="walletCnt mb-3">
                {singledata ?
                  <h3> {singledata.creator
                    ? singledata.creator.slice(0, 5) +
                    '...' +
                    singledata.creator.slice(38, 42)
                    : ''}</h3>

                  :
                  ""
                }
                <span className="walletLabel successLabel ml-auto">{singledata.creator ? "Wallet Connected" : "Connect Wallet"}</span>
              </div>
              <div className="form-row">
                <div className="col-12 mb-4 d-flex align-items-center flex-wrap">
                  <div className="imgContainer mr-4">
                    <span className="formLabel">Logo Image </span>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="imgCnt">
                        <img className="logoImg" src={singledata && singledata.logoImage} />
                        {/* <div className="justify-content-center align-items-center editIcon">
                          <a href="#"><img src={Pencil} /></a>
                        </div> */}
                      </div>

                    </div>
                  </div>
                  <div className="coverContainer">
                    <span className="formLabel">Cover Image <span style={{ color: "red" }}>*</span></span>
                    <div className="coverCnt d-flex flex-column">

                      {typeof bannerimage == "string" && bannerimage !== "undefined" ?

                        imgtype.startsWith('video') ?
                          <video
                            className='vitag'
                            src={bannerimage}
                            alt="Uploaded"

                            muted
                            autoPlay
                          />
                          :

                          <img className="coverImg" src={bannerimage} />

                        :
                        typeof bannerimage == "object" ?
                          imgtype.startsWith('video') ?
                            <video
                              className='vitag'
                              src={URL.createObjectURL(bannerimage)}
                              alt="Uploaded"

                              muted
                              autoPlay
                            />
                            :
                            < img className="coverImg" src={URL.createObjectURL(bannerimage)} />
                          :
                          <a href="#"><img src={Pencil} /></a>
                      }
                      {/* <div className="justify-content-center align-items-center editIcon">
                        <a href="#"><img src={Pencil} /></a>
                      </div> */}
                      {singledata && singledata.verification == "Verified" ?
                        ""
                        :
                        <div className=" ndblock editIcon ">
                          <div class="text-center">
                            <p>PNG, GIF, WEBP, MP4.</p>
                            <p className="fs-12">Max 100mb. At least 1400*360 pixels.</p>
                            <div className="chooseFileBtn mt-2 mx-auto">
                              <input type="file" id="chooseBtn" onChange={(e) => imageupload(e)} />
                              <label for="chooseBtn">Choose File</label>
                            </div>

                          </div>
                        </div>
                      }
                    </div>
                    <span id='uploadimage' style={{ color: "red" }}></span>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">Collection Name</span>
                    {/* <label className="text-danger errLabel ml-auto">This collection name already exist</label> */}
                  </div>
                  <input type="text" placeholder="Name your collection" className="form-control" value={singledata && singledata.collectionName} />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">Collection Symbol</span>
                    {/* <label className="text-danger errLabel ml-auto">This collection name already exist</label> */}
                  </div>
                  <input type="text" placeholder="Collection symbol" className="form-control" value={singledata && singledata.collectionSymbol} />
                </div>

                <div className="col-12 mb-3">
                  <span className="formLabel">Description (Optional)</span>
                  {singledata && singledata.verification == "Verified" ?
                    < textarea className="form-control" placeholder="Enter Description" name="description" defaultValue={description} disabled></textarea>
                    :
                    <textarea className="form-control" placeholder="Enter Description" name="description" onChange={(e) => setDescription(e.target.value)} defaultValue={description}></textarea>
                  }
                </div>

                <div className="col-12 mb-3 urlFieldCnt">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">Collection URL</span>
                    {/* <label className="text-danger errLabel ml-auto">This URL already exists</label> */}
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      {/* <div class="input-group-text">wornft.com/</div> */}
                    </div>
                    <input type="text" class="form-control" placeholder="Enter URL" value={singledata && singledata.url} />
                  </div>
                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <span className="formLabel">{t("Royalty Fee")}(In Percentage) <span style={{ color: "red" }}>*</span></span>
                  {singledata && singledata.royaltyFee == 0 ?
                    < NumericFormat className="form-control" placeholder="Enter Royality Fee" name="royalityfee" onChange={Royalityfee1} value={royalityfee} allowNegative={false} customInput={Input} />
                    :
                    <NumericFormat className="form-control" placeholder="Enter Royality Fee" name="royalityfee" value={royalityfee} allowNegative={false} customInput={Input} disabled />
                  }
                </div>
                <span id='royality' style={{ color: "red" }}></span>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel mr-2">Blockchain</span>
                    <UncontrolledDropdown className="ml-auto">
                      <DropdownToggle caret className="d-flex align-items-center">
                        <img src={ethIconSm} className="mr-2" />
                        {singledata.chain == "binance" ? "BNB" : singledata.chain == "ethereum" ? "ETH" : singledata.chain == "polygon" ? "MATIC" : ""}
                        {/* <img src={angleDown} className="ml-auto" /> */}
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </div>
                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel mr-2">Category <span style={{ color: "red" }}>*</span></span>
                    <UncontrolledDropdown className="ml-auto">
                      <DropdownToggle caret className="d-flex align-items-center">
                        {singlecategory ? singlecategory : "Select Category"}
                        {singledata && singledata.verification == "Verified" ?
                          ""
                          :
                          <img src={angleDown} className="ml-auto" />
                        }
                      </DropdownToggle>
                      {singledata && singledata.verification == "Verified" ?
                        ""
                        :
                        <DropdownMenu>
                          {getcategory && getcategory.map((data) => (
                            <div onClick={() => categories(data)}>
                              <DropdownItem tag="a" value={data.name}>
                                {data.name}
                              </DropdownItem>
                            </div>
                          ))}
                        </DropdownMenu>

                      }

                    </UncontrolledDropdown>
                  </div>
                  <div style={{ justifyContent: "end", display: "flex" }}>
                    <span id='category' style={{ color: "red" }}></span>
                  </div>
                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">Social Media <span style={{ color: "red" }}>*</span></span>
                  </div>
                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={discord} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        {/* <div class="input-group-text">discord.com/</div> */}
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder="Enter Discord URL" name="discord1" defaultValue={discord1} disabled />
                        :
                        <input type="text" class="form-control" placeholder="Enter Discord URL" name="discord1" onKeyUp={Discord} defaultValue={discord1} />
                      }
                    </div>
                  </div>
                  <span id='dis' className='ml-5' style={{ color: "red" }}></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={telegramPlane} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        {/* <div class="input-group-text">telegram.com/</div> */}
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder="Enter Telegram URL" name="telegram" defaultValue={telegram} disabled />
                        :
                        <input type="text" class="form-control" placeholder="Enter Telegram URL" name="telegram" onKeyUp={Telegram} defaultValue={telegram} />
                      }
                    </div>
                  </div>
                  <span id='tel' className='ml-5' style={{ color: "red" }}></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={twitter} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        {/* <div class="input-group-text">twitter.com/</div> */}
                      </div>
                      {singledata && singledata.twitterVerify == "Verified" ?
                        <input type="text" class="form-control" placeholder="Enter Twitter URL" name="twitter1" defaultValue={twitter1} disabled />
                        :
                        <input type="text" class="form-control" placeholder="Enter Twitter URL" name="twitter1" onKeyUp={Twitter} defaultValue={twitter1} />
                      }
                    </div>
                  </div>
                  <span id='twit' className='ml-5' style={{ color: "red" }}></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={instagram} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        {/* <div class="input-group-text">instagram.com/</div> */}
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder="Enter Instagram URL" name="insta" defaultValue={insta} disabled />
                        :
                        <input type="text" class="form-control" placeholder="Enter Instagram URL" name="insta" onKeyUp={Instagram} defaultValue={insta} />
                      }
                    </div>
                  </div>
                  <span id='insta' className='ml-5' style={{ color: "red" }}></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={youtube} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        {/* <div class="input-group-text">youtube.com/</div> */}
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder="Enter Youtube URL" name="youtube1" defaultValue={youtube1} disabled />
                        :
                        <input type="text" class="form-control" placeholder="Enter Youtube URL" name="youtube1" onKeyUp={Youtube} defaultValue={youtube1} />
                      }
                    </div>
                  </div>
                  <span id='tube' className='ml-5' style={{ color: "red" }}></span>

                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">Verification</span>
                    <div className="dark-primary ml-auto">
                      Requirements <img src={infoIcon} id="popoverLegacy" />
                    </div>
                    <UncontrolledPopover trigger="legacy" placement="bottom" target="popoverLegacy" className="verifyPopOver">
                      <PopoverBody>
                        <div>
                          <label class="custCheck">Logo Image.
                            {singledata.logoVerify == "Verified" ?
                              <input type="checkbox" checked />
                              :
                              // <input type="checkbox" />
                              ""
                            }
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div>
                          <label class="custCheck">Title.
                            {singledata.collectionNameVerify == "Verified" ?
                              <input type="checkbox" checked />
                              :
                              // <input type="checkbox" />
                              ""
                            }
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div>
                          {singledata && singledata.emailVerify == "Verified" ?
                            <>
                              <label class="custCheck">Verified email.
                                <input type="checkbox" checked />
                                <span class="checkmark"></span>
                              </label>
                            </>
                            :
                            <>
                              <label class="custCheck">Verified email.
                                <input type="checkbox" disabled />
                                <span class="checkmark1"></span>
                              </label>
                            </>
                          }
                        </div>
                        <div>
                          {singledata && singledata.twitterVerify == "Verified" ?
                            <>
                              <label class="custCheck">Connected Twitter account..
                                <input type="checkbox" checked />
                                <span class="checkmark"></span>
                              </label>
                            </>
                            :
                            singledata && singledata.twitterVerify == "Rejected" ?
                              <>
                                <img src={reject} width="25px" height="25px" />
                                <label>Connected Twitter account.</label>
                              </>
                              :

                              <div>
                                <label class="custCheck">Connected Twitter account..
                                  <input type="checkbox" disabled />
                                  <span class="checkmark1"></span>
                                </label>
                              </div>
                          }


                        </div>
                        <div>
                          {singledata && singledata.volume >= 5 ?
                            <label class="custCheck">Minimum 5 {singledata.chain == "binance" ? "BNB" : singledata.chain == "ethereum" ? "ETH" : singledata.chain == "polygon" ? "MATIC" : ""} of Volume.
                              <input type="checkbox" checked />
                              <span class="checkmark"></span>
                            </label>
                            :
                            <label class="custCheck">Minimum 5 {singledata.chain == "binance" ? "BNB" : singledata.chain == "ethereum" ? "ETH" : singledata.chain == "polygon" ? "MATIC" : ""} of Volume.
                              <input type="checkbox" disabled />
                              <span class="checkmark1"></span>
                            </label>
                          }
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                  <div style={{ justifyContent: "end", display: "flex" }} className='mt-2'>
                    {
                      singledata.collectionNameVerify == "" && singledata.logoVerify == "" && singledata.twitterVerify == "" && twitter1 && twireg.test(twitter1) ?
                        <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify}><span>{verify == "" ? "Get Verified" : verify == "Rejected" ? "Get Verified" : verify}</span></div>
                        :

                        singledata.collectionNameVerify == "Verified" && singledata.logoVerify == "Verified" && singledata.twitterVerify == "Verified" ?
                          <div className="getVerified" style={{ cursor: "pointer" }} ><p>{singledata && singledata.verification}</p></div>
                          :
                          singledata && singledata.twitterVerify == "Rejected" && twitter1 && twireg.test(twitter1) ?
                            <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify}><span>{verify == "" ? "Get Verified" : verify == "Rejected" ? "Get Verified" : verify}</span></div>
                            :

                            singledata && singledata.twitterVerify == "Pending" ?
                              <div className="getVerified" style={{ cursor: "pointer" }}><span>{verify == "" ? "Get Verified" : verify == "Rejected" ? "Get Verified" : verify}</span></div>
                              :

                              twitter1 && twireg.test(twitter1) && Collectionverify !== "" && Logoverify !== "" ?
                                <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify}><span>{verify == "" ? "Get Verified" : verify == "Rejected" ? "Get Verified" : verify}</span></div>
                                :
                                singledata.collectionNameVerify == "" && twitter1 && twireg.test(twitter1) ?
                                  <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify}><span>{Collectionverify == "" ? "Pending" : Collectionverify == "Verified" ? "Verified" : ""}</span></div>
                                  :
                                  singledata.logoVerify == "" && twitter1 && twireg.test(twitter1) ?
                                    <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify}><span>{Logoverify == "" ? "Pending" : Logoverify == "Verified" ? "Verified" : ""}</span></div>
                                    :

                                    ""
                    }
                  </div>
                </div>

                <div className="col-12 d-flex align-items-center mb-3">
                  <button type="button" className="btn btn-block blackBtn mx-2 my-2" onClick={cancel}>{t("Cancel")}</button>
                  {singledata && singledata.verification == "Verified" ?
                    <button type="button" className="btn btn-block gradientBtn mx-2 my-2" disabled>Save Changes</button>
                    :
                    <button type="button" className="btn btn-block gradientBtn mx-2 my-2" onClick={SavesChanges}>Save Changes</button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div >
      </div >

    </>
  );
};

export default ChooseCollection;