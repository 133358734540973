export const formatAmount = (x, decimal = 8) => {
	if (!x) return

	if (Math.abs(x) < 1.0) {
		var e = parseInt(x.toString().split('e-')[1])
		if (e) {
			x *= Math.pow(10, e - 1)
			x = '0.' + new Array(e).join('0') + x.toString().substring(2)
		}
		var rightDecimal = parseInt(x.toString().split('.')[1])

		if (rightDecimal > +decimal) {
			x = Number.parseFloat(x).toFixed(decimal)
		}
	} else {
		var e = parseInt(x.toString().split('+')[1])
		if (e > 20) {
			e -= 20
			x /= Math.pow(10, e)
			x += new Array(e + 1).join('0')
		}

		var rightDecimal = parseInt(x.toString().split('.')[1])

		if (rightDecimal > +decimal) {
			x = Number.parseFloat(x).toFixed(decimal)
		}
	}

	return x
}
