import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

export async function fetchCartItems(address) {
	const url = new URL(BACKEND_URL + 'cart')
	url.searchParams.set('address', address)

	return axios.get(url)
}

export function useFetchCartItems(address, isOpen) {
	return useQuery({
		queryKey: ['cart', address, isOpen],
		queryFn: () => fetchCartItems(address),
		enabled: !!address
	})
}
