import { chainId } from './chains'

export default {
	bridge: {
		[chainId.BINANCE]: '0x9F8A1Ae206e80c3325C263d359699336553FbbD4',
		[chainId.ETHEREUM]: '0x975f794B4f54A7719C996EDa54Cb26e34eD8023F',
		[chainId.POLYGON]: '0x132B65CBB9Cf21BeaD5d3606E895Ed6a113A630b'
	},
	nft: {
		//[chainId.BINANCE]: '0x9d33B4473f8415011FBD6ec4567c364BF5E66Ff6',
         [chainId.BINANCE]: '0x9f0cdFa4C9c80B78F498E77C31c8284736346ae0', // 新的BSC测试链地址
		//[chainId.ETHEREUM]: '0xc87862F63dF8e9F402D37959521990e946f65216',
		[chainId.ETHEREUM]: '0xf2A37fBf86B1D2ca3A00c3249C66339065782EF5', // 新的Sepolia测试链地址
		[chainId.POLYGON]: '0x90d1bDADe9110fa218e8c24e0913e024879a6A14'
	},
	marketplace: {
		[chainId.BINANCE]: '0xa18a560909F4f3E28179a74C7AB2f60340Fd06cF',
		[chainId.ETHEREUM]: '0x2483C93Ab2a2BE334d4641cE372109f3600167Fb',
		[chainId.POLYGON]: '0x8412D3c668751F9A104354274c1D2089B9E5b81a'
	},
	zero: '0x0000000000000000000000000000000000000000',
	usdt: '0x6C1b9f8221E2f7FC7Bd41E9f38f3a88B723E2d38',
	busd: '0x821581AD0C87B5E30cCfAbCc4f437fba38aA56C8',
	wor: '0xDD27DEf85B7Eb62A149d8627DFb0EDdFAC3A82E8'
}
