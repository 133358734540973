import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useAccount } from 'wagmi'
import useSWR from 'swr'
import { formatUnits } from 'viem'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from './CloseIcon'
import { useBuyNowModalActions, useBuyNowModalState } from './store'
import { formatAddress } from '../../../utils/formatAddress'
import { copyText } from '../../../utils/copyText'
import { CheckIcon } from '../../../components/CheckIcon'
import { useTokenContract } from '../../../hooks/useContract'
import {
	useGetBnbBalance,
	useGetEthBalance,
	useGetMaticBalance
} from '../../../hooks/useBalance'
import contracts from '../../../config/constants/contracts'
import { useApproveToken } from '../../../hooks/useApproveToken'
import { chainId } from '../../../config/constants/chains'
import { useBuyNow } from '../../../hooks/useBuyNow'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { useSomethingWentWrongModalActions } from '../error/store'
import { useCheckoutModalActions } from '../checkout/store'

import yellowtick20 from '../../../assets/images/yellow-tick_20px.svg'
import copyIcon from '../../../assets/images/copyIcon.svg'

import '../modal.scss'
import { formatAmount } from '../../../utils/formatAmount'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

export function BuyNowModal() {
	const { t } = useTranslation()
	const [copied, setCopied] = useState(false)
	const { isOpen, asset } = useBuyNowModalState()
	const { toggleBuyNowModal } = useBuyNowModalActions()
	const { address: account } = useAccount()
	const { balance: bnbBalance } = useGetBnbBalance()
	const { balance: ethBalance } = useGetEthBalance()
	const { balance: maticBalance } = useGetMaticBalance()
	const tokenAddress =
		asset && !asset.details.isCoin
			? contracts[asset.details.currency?.toLowerCase()]
			: null
	const tokenContract = useTokenContract(tokenAddress)
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null
	const { data: tokenBalance } = useSWR(
		['tokenBalance', tokenContract],
		async () => {
			const decimals = await tokenContract.read.decimals()
			const rawBalance = await tokenContract.read.balanceOf([account])
			return parseFloat(formatUnits(rawBalance, decimals))
		}
	)
	const { approve } = useApproveToken(tokenAddress)
	const { buyNow } = useBuyNow(marketplaceContractAddress)
	const { togglePurchaseLoadingModal, setHeading } = usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()
	const { toggleCheckoutModal } = useCheckoutModalActions()

	if (!asset) {
		return null
	}

	const nativeCurrencyBalanceMap = {
		ethereum: ethBalance,
		binance: bnbBalance,
		polygon: maticBalance
	}

	const total = asset.details.price
	const isFundsSufficient =
		total && asset.details.isCoin
			? total < nativeCurrencyBalanceMap[asset.details.chain]
			: total < tokenBalance
	const isVideo = asset?.details?.asset?.endsWith('.mp4')

	const handleCheckout = async () => {
		setHeading('Purchase')
		toggleBuyNowModal()
		togglePurchaseLoadingModal()
		try {
			if (!asset.details.isCoin) {
				await approve(
					asset.details.price.toString(),
					marketplaceContractAddress
				)
			}
			await buyNow(asset)
			toggleCheckoutModal()
		} catch (error) {
			console.error(error)
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
		>
			<ModalHeader
				toggle={toggleBuyNowModal}
				close={<CloseIcon onClose={toggleBuyNowModal} />}
			>
				{t('Buy NFT')}
			</ModalHeader>
			<ModalBody>
				<div>
					<div className='cartItem'>
						<div className='itemImg'>
							{!isVideo ? (
								<img
									className='object-cover'
									alt='asset'
									src={asset.details.asset}
								/>
							) : (
								<video
									id='video-asset'
									loop
									playsinline=''
									autoplay
									preload='metadata'
									controlslist='nodownload'
									src={asset.details.asset}
									style={{
										objectFit: 'contain',
										height: '84px',
										width: '84px'
									}}
								/>
							)}
						</div>
						<div className='flex-grow-1 p-3'>
							<div className='d-flex justify-content-between mb-2'>
								<div className='itemName'>
									{asset.collection.name}{' '}
									{asset.collection.isVerified && (
										<img
											src={yellowtick20}
											alt='yellowtick20'
											className='ml-1'
										/>
									)}
								</div>
								<span className='fs-14 fw-400 dark-text-secondary'>
									{/* $-- */}
								</span>
							</div>
							<div className='d-flex justify-content-between mb-n1'>
								<div className='itemId'>
									{asset.details.name}
								</div>
								<span className='itemBal'>{`${parseFloat(
									asset.details.price.toFixed(8)
								)} ${asset.details.currency}`}</span>
							</div>
						</div>
					</div>
				</div>
				<hr className='hrCls' />
				<div className='position-relative zindex1'>
					<div className='d-flex align-items-center mb-3'>
						<span className='fs-14 fw-600 dark-text'>
							{t('Wallet')}
						</span>
						<span className='walletLabel successLabel ml-2'>
							{t('Connected')}
						</span>
					</div>

					<div className='walletCnt d-flex align-items-center flex-wrap mb-3'>
						<h3>{formatAddress(account, 8)}</h3>
						{copied ? (
							<CheckIcon className='ml-auto' />
						) : (
							<img
								src={copyIcon}
								alt='copy-icon'
								className='ml-auto hover'
								onClick={() => {
									setCopied(true)
									copyText(account, () => {
										setTimeout(() => {
											setCopied(false)
										}, 600)
									})
								}}
							/>
						)}
					</div>
				</div>

				<hr className='hrCls mt-3' />

				<div className='borderRoundedCard'>
					{/* <div class='d-flex justify-content-between mb-2'>
						<div className='fs-14 fw-400 dark-text-secondary'>
							{t("SubTotal")}
						</div>
						<div className='fs-16 fw-400 dark-text'>
							<span className='dark-text-grey fs-16 fw-400'>
								($12,311.21)
							</span>{' '}
							{`${asset.details.price} ${asset.details.currency}`}
						</div>
					</div>
					<div class='d-flex justify-content-between mb-2'>
						<div className='fs-14 fw-400 dark-text-secondary'>
							{t("Fee")}
						</div>
						{!total ? (
							<div className='w-100 position-relative'>
								<Skeleton
									className='position-absolute w-50'
									width='100%'
									style={{ right: '0' }}
								/>
							</div>
						) : (
							<div className='fs-16 fw-400 dark-text'>{`${estimatedGasPrice} ${asset.details.currency}`}</div>
						)}
					</div> */}
					<div class='d-flex justify-content-between'>
						<div className='fs-14 fw-400 dark-text-secondary'>
							{t('Total')}
						</div>
						{/* {!total ? (
							<div className='w-100 position-relative'>
								<Skeleton
									className='position-absolute w-50'
									width='100%'
									style={{ right: '0' }}
								/>
							</div>
						) : (
							<div className='fs-16 fw-700 dark-text'>
								{`${parseFloat(total.toFixed(8))} ${
									asset.details.currency
								}`}
							</div>
						)} */}
						<div className='fs-16 fw-700 dark-text'>
							<span className='dark-text-grey fs-16 fw-400'>
								($ {formatAmount(asset.details.priceInUsd)} )
							</span>{' '}
							{`${parseFloat(total.toFixed(8))} ${
								asset.details.currency
							}`}
						</div>
					</div>
				</div>

				<div className='mt-4'>
					{isFundsSufficient ? (
						<button
							className='btn btn-block checkoutBtn'
							onClick={handleCheckout}
						>
							{t('Checkout')}{' '}
						</button>
					) : (
						<p className='text-danger fs-14 text-center'>
							{t('Insufficient funds!')}
						</p>
					)}
				</div>
			</ModalBody>
		</Modal>
	)
}
