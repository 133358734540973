import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import '../Activity/profileActivity.scss';
import './profileOfferReceived.scss';

import item1 from '../../../assets/images/collection/modalItem1.png';
import copyIcon from '../../../assets/images/copyIcon.svg';


import tblUserImg1 from '../../../assets/images/collection/tblUserImg1.png';
import tblUserImg2 from '../../../assets/images/collection/tblUserImg2.png';
import tblUserImg3 from '../../../assets/images/collection/tblUserImg3.png';
import tblUserImg4 from '../../../assets/images/collection/tblUserImg4.png';
import tblUserImg5 from '../../../assets/images/collection/tblUserImg5.png';
import tblUserImg6 from '../../../assets/images/collection/tblUserImg6.png';
import tblUserImg7 from '../../../assets/images/collection/tblUserImg7.png';
import tblUserImg8 from '../../../assets/images/collection/tblUserImg8.png';
import tblUserImg9 from '../../../assets/images/collection/tblUserImg9.png';
import tblUserImg10 from '../../../assets/images/collection/tblUserImg10.png';
import tblUserImg11 from '../../../assets/images/collection/tblUserImg11.png';
import tblUserImg12 from '../../../assets/images/collection/tblUserImg12.png';

import saleIcon from '../../../assets/images/collection/saleIcon.svg';
import transferIcon from '../../../assets/images/collection/transferIcon.svg';
import listIcon from '../../../assets/images/collection/listIcon.svg';
import collectionOfferIcon from '../../../assets/images/collection/collectionOfferIcon.svg';
import offerIcon from '../../../assets/images/collection/offerIcon.svg';
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import greenCheck from '../../../assets/images/greenCheck.svg';
import PaginationComponent from 'react-reactstrap-pagination'

import { useToast } from '../../../utils/toast/customSuccess'
import { ThemeContext } from '../../context/context';
import { axios } from '../../../lib/axios'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../utils/useDebounce'
import moment from 'moment';
import loader from '../../../assets/images/Loader.png'
import ContractDetails from '../../ContractAddress';
import MarketplaceBnbabi from '../../AbiArray/MarketplaceBnbabi.json'
import MarketplaceEthabi from '../../AbiArray/MarketplaceEthabi.json'
import MarketplaceMaticabi from '../../AbiArray/MarketplaceMaticabi.json'
import Approveabi from '../../AbiArray/Approvalcollection/Bnbapproval.json'
import {
    useAccount,
    useConnect,
    useNetwork,
    useSwitchNetwork,
    useDisconnect,
    useBalance
} from 'wagmi'
import Web3 from 'web3';
import { getOrder1 } from '../../../utils/getOrder';
import redClose from '../../../assets/images/redClose.svg';
import walletLoaderCnt from "../../../assets/images/walletLoader.png"
import walletLoader from "../../../assets/images/loaderCircle.png"
import ResponsivePagination from 'react-responsive-pagination';

import { chainId } from '../../../config/constants/chains'

import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'

const chainIdMap = {
    ethereum: chainId.ETHEREUM,
    binance: chainId.BINANCE,
    polygon: chainId.POLYGON
}

const chainMap = {
    [chainId.ETHEREUM]: 'ethereum',
    [chainId.BINANCE]: 'binance',
    [chainId.POLYGON]: 'polygon'
}


const landPerRow = 6

const ActivityCollection = (props) => {
    const {
        buttonLabel,
        className
    } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);

    const closeBtn = <button className="close">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z" fill="#6E737E" />
        </svg>
    </button>;

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { address, isConnected } = useAccount()
    const { connectAsync, connectors } = useConnect()

    const { switchNetworkAsync } = useSwitchNetwork()
    const { switchNetwork } = useOkxSwitchNetwork()

    const { chain: network } = useNetwork()
    const chainId = network?.id

    const toast = useToast()

    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState('#ffffff')

    const { offerCollection, setOfferCollection, offersearchCollection, setOfferSearchCollection } = useContext(ThemeContext)

    const clearAll = () => {
        setOfferCollection([])
        setOfferSearchCollection("")
    }
    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    // const debouncedSearchText = useDebounce(offersearchCollection, 500)

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

        Getofferreceived()
    }, [address, page, offerCollection, offersearchCollection, id])

    const [offerreceived, setOfferReceived] = useState("")
    const [count, setCount] = useState("")

    function handlePageChange(page) {
        setPage(page);
        // ... do something with `page`
    }

    const [totalcount, setTotalCount] = useState('')

    console.log("offerCollection", offerCollection.length > 0 || offersearchCollection !== "" ? 1 : page)

    const Getofferreceived = async () => {
        setLoading(true)
        const fetch = await axios.get(`user/offers_received?address=${id ? id : address}&page=${offerCollection.length > 0 || offersearchCollection !== "" ? 1 : page}&limit=15&collectionName=${offerCollection}&filter=${offersearchCollection}`)
        if (fetch.status == true) {
            setOfferReceived(fetch.data)
            setLoading(false)
            setCount(Math.ceil(fetch.count / 15))
            setTotalCount(fetch.count)
        }
        else {
            setLoading(false)
            setOfferReceived("")
            setTotalCount('')
            setCount("")
        }
    }


    const listViewrow = offerreceived && offerreceived.map((item, index) =>
        <>
            <tr onClick={(e) => e.stopPropagation(navigate(
                `/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`
            ))}
                style={{ cursor: 'pointer' }}>
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='tblImg'>
                            {/* <img src={item.asset} alt={`item.imgsrc`} /> */}
                            {typeof item.asset == "string" && item.asset !== undefined ?

                                item.imageFormat.startsWith('video') ?
                                    <video
                                        src={item.asset}
                                        alt="Uploaded"
                                        muted
                                        autoPlay
                                        style={{ width: "60px", height: "70px", borderRadius: "12px" }}
                                    />

                                    :
                                    item.asset !== 'undefined' ?
                                        <img src={item.asset} alt={`item.imgsrc`} />
                                        :
                                        ""
                                :
                                ""
                            }
                        </div>
                        <div>
                            <div className='fw-600 ml-2'>{item.nftName} <img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /></div>
                        </div>
                    </div>
                </td>
                <td className='text-right'>
                    <div>{item.price.toFixed(8)} {item.chain == "binance" ? "BNB" : item.chain == "ethereum" ? "ETH" : item.chain == "polygon" ? "MATIC" : ""}</div>
                    <div className='fs-12 dark-text-secondary'>${(item.price * item.usdPrice).toFixed(8)}</div>
                </td>
                <td className='text-right'>{item.floordifference ? item.floordifference.toFixed(2) : "0"}% {item.up == true ? t('Above') : t('below')}</td>
                <td className='text-right'>{item.quantity}</td>
                <td className='text-right'>{item.saleExpiry == 0 ? '-' : moment(item.saleExpiry).fromNow()}</td>
                <td className='text-right'>{item.createdAt == 0 ? '-' : moment(item.createdAt).fromNow()}</td>

                {!!address && address.toLowerCase() === item.owner ?
                    <td className='text-right'><span className="outlineBtn" onClick={(e) => e.stopPropagation(Accept(item))}>Accept</span></td>

                    : null}


            </tr>
        </>
    )

    const closecollection = (item) => {
        let a = offerCollection.includes(item)

        if (a == true) {

            const index = offerCollection.indexOf(item);
            if (index > -1) { // only splice array when item is found
                offerCollection.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...offerCollection]
                setOfferCollection(nerArr);
            }

        }
    }
    const closesearch = () => {
        setOfferSearchCollection("")

    }

    const litstedTagLoop1 = offerCollection && offerCollection.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const [offeraccept, setOfferAccept] = useState("")

    const { pro } = useContractProvider()
    const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

    const web3 = new Web3(pro)
    const bnbmarketplace = new web3.eth.Contract(MarketplaceBnbabi, ContractDetails.AuctionBNB)
    const ethmarketplace = new web3.eth.Contract(MarketplaceEthabi, ContractDetails.AuctionETH)
    const polygonmarketplace = new web3.eth.Contract(MarketplaceMaticabi, ContractDetails.AuctionPolygon)

    let contract;
    let approvalTetherContract;
    let approvalResponse

    const [loaders, setloaders] = useState(false)
    const Accept = async (item) => {

        setModal(true)
        if (address) {

            const order = getOrder1({
                asset: {
                    ...item,
                    owner: { ...item.owner, address: item.fromAddress },
                    details: { ...item, price: item.price }
                },
                assetType: 'erc1155',
                orderType: 'buy',
                expiry: item.expiry
            })

            try {
                if (walletType === 'okx') {
                    if (item.chain !== chainMap[parseInt(window.okxwallet.chainId)]) {
                        await switchNetwork(chainIdMap[item.chain])
                    }
                } else {
                    if (
                        item.chain === 'binance' &&
                        chainId !== 97
                    ) {
                        await switchNetworkAsync(97)
                    } else if (
                        item.chain === 'ethereum' &&
                        chainId !== 11155111
                    ) {
                        await switchNetworkAsync(11155111)
                    } else if (
                        item.chain === 'polygon' &&
                        chainId !== 80001
                    ) {
                        await switchNetworkAsync(80001)
                    }
                }
            }
            catch (error) {
                toast.error('User rejected the switch network')

                setModal(false)

                return
            }
            approvalTetherContract = new web3.eth.Contract(
                Approveabi,
                item.contractAddress
            )
            if (item.chain === 'ethereum') {
                contract = bnbmarketplace;
                approvalResponse = await approvalTetherContract.methods
                    .isApprovedForAll(address, ContractDetails.AuctionETH)
                    .call()
            } else if (item.chain === 'binance') {
                contract = ethmarketplace;
                approvalResponse = await approvalTetherContract.methods
                    .isApprovedForAll(address, ContractDetails.AuctionBNB)
                    .call()
            } else if (item.chain === 'polygon') {
                contract = polygonmarketplace;
                approvalResponse = await approvalTetherContract.methods
                    .isApprovedForAll(address, ContractDetails.AuctionPolygon)
                    .call()
            }

            if (approvalResponse) {
                const data = await axios.post('asset/buyer-fee-signature', {
                    orderTuple: order,
                    royalty: item.royaltyFee,
                    royaltyAddress: item.owner,
                    network: item.chain
                })

                const buyerFeeSig = [data.data.v, data.data.r, data.data.s]

                try {
                    await contract.methods.buy([order], item.signTuple, [buyerFeeSig], [item.royaltyFee], [item.owner], [!(item.isMinted)], [item.uri], item.totalQuantity)
                        .send({
                            from: address.toString()
                        })
                        .on('transactionHash', async hash => {
                        })
                        .on('receipt', async approveresult => {

                            const params = {
                                "orderId": item._id,
                                "address": address
                            }
                            const approveRes = await axios.post('asset/approve-offer', params)
                            if (approveRes.status == true) {
                                toast.success(approveRes.message)
                                setModal(false)
                                Getofferreceived()
                            }
                            else {
                                toast.error(approveRes.message)
                                setModal(false)
                            }
                        })
                } catch (error) {
                    console.error(error)
                    setModal(false)
                }
            }

            else {
                try {
                    if (item.chain === 'ethereum') {
                        contract = bnbmarketplace;

                        await approvalTetherContract.methods
                            .setApprovalForAll(ContractDetails.AuctionETH, true)
                            .send({ from: address.toString() })
                            .on('transactionHash', async hash => { })
                            .on('receipt', async approveresult => { })

                    } else if (item.chain === 'binance') {
                        contract = ethmarketplace;

                        await approvalTetherContract.methods
                            .setApprovalForAll(ContractDetails.AuctionBNB, true)
                            .send({ from: address.toString() })
                            .on('transactionHash', async hash => { })
                            .on('receipt', async approveresult => { })

                    } else if (item.chain === 'polygon') {
                        contract = polygonmarketplace;

                        await approvalTetherContract.methods
                            .setApprovalForAll(ContractDetails.AuctionPolygon, true)
                            .send({ from: address.toString() })
                            .on('transactionHash', async hash => { })
                            .on('receipt', async approveresult => { })
                    }

                    const data = await axios.post('asset/buyer-fee-signature', {
                        orderTuple: order,
                        royalty: item.royaltyFee,
                        royaltyAddress: item.owner,
                        network: item.chain
                    })


                    const buyerFeeSig = [data.data.v, data.data.r, data.data.s]

                    try {
                        await contract.methods.buy([order], item.signTuple, [buyerFeeSig], [item.royaltyFee], [item.owner], [!(item.isMinted)], [item.uri], item.totalQuantity)
                            .send({
                                from: address.toString()
                            })
                            .on('transactionHash', async hash => {
                            })
                            .on('receipt', async approveresult => {

                                const params = {
                                    "orderId": item._id,
                                    "address": address
                                }
                                const approveRes = await axios.post('asset/approve-offer', params)
                                if (approveRes.status == true) {
                                    toast.success(approveRes.message)
                                    Getofferreceived()
                                    setModal(false)
                                }
                                else {
                                    toast.error(approveRes.message)
                                    setModal(false)
                                }
                            })
                    } catch (error) {
                        console.error(error)

                        setModal(false)
                    }
                }
                catch (error) {
                    console.error(error)
                    setModal(false)
                }
            }
        }
        else {
            toast.error("Please connect wallet")
            setModal(false)
        }

    }


    const close = async () => {
        // setModal1(!modal1)
        // setModal(!modal)

    }


    return (
        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className='col-lg-6'><span class="cmntxt">{totalcount ? totalcount : ""} {totalcount ? "items" : ""}</span></div>

            <div className='fiterSelectlabel mb-4'>
                {offersearchCollection ?
                    <div className='tagLabel'>
                        <span>{offersearchCollection}</span>
                        <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch}></i>
                    </div>
                    :
                    ""}


                {litstedTagLoop1}
                {offersearchCollection || offerCollection.length > 0 ?
                    <button className='clearAllBtn' onClick={clearAll}>Clear All</button>
                    :
                    ""
                }


            </div>

            <div className='table-responsive'>
                <table className='table table-borderless activityTable offerTable'>
                    {offerreceived && offerreceived.length > 0 ?
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th className='text-right'>Price</th>
                                <th className='text-right'>Floor Difference</th>
                                <th className='text-right'>Quantity</th>
                                <th className='text-right'>Expiration</th>
                                <th className='text-right'>Received</th>
                                <th></th>
                            </tr>
                        </thead>
                        :
                        ""
                    }
                    <tbody>
                        {offerreceived && offerreceived.length > 0 ?
                            listViewrow
                            :
                            <div className='noItemFound'>
                                <div className='text-center'>
                                    <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                </div>
                                <div className='nofounttxt mb-4'>
                                    No items were found.Try to broaden your search.
                                </div>

                            </div>

                        }

                    </tbody>
                </table>
            </div>

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                        {offerreceived && offerreceived.length > 0 ?
                            <div class=''>
                                <nav aria-label="Page ">
                                    <ResponsivePagination
                                        total={count}
                                        current={page}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                        nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                        previousClassName="page-link-1"
                                        nextClassName="page-link-1"
                                    />
                                </nav>

                                {/* <PaginationComponent
                        size='sm'
                        defaultActivePage={1}
                        totalItems={count}
                        maxPaginationNumbers={3}
                        pageSize={15}
                        onSelect={(page) => setPage(page)}
                    /> */}
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} centered="true" className="mymodal" backdropClassName="selCurBp" backdrop='static'>
                {/* {loaders == true ? */}
                <>
                    <ModalHeader >Accept Offer</ModalHeader>
                    <ModalBody>
                        <p className="dark-text-secondary text-center">To continue send transaction with your wallet.</p>
                        <div className="d-flex justify-content-center align-items-center loaderCont">
                            <img src={walletLoaderCnt} />
                            <div className="loaderAnimCnt">
                                <img src={walletLoader} className="loaderAnimation" />
                            </div>
                        </div>
                    </ModalBody>
                </>
                {/* :
                    ""
                } */}
            </Modal>

        </>
    )
}

export default ActivityCollection;