import { forwardRef, useCallback, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { fetchListings } from '../apis/fetchListings'
import { useTranslation } from 'react-i18next'
import cndds from '../../../assets/images/cndds.png'

const Listing = forwardRef(function Listing({ listing }, ref) {
	return (
		<tr ref={ref ? ref : null}>
			<td>{`${listing.price} ${listing.currency}`}</td>
			<td>${listing.usdPrice}</td>
			<td>1</td>
			<td className='yellowTxt'>{listing.from ? listing.from : '-'}</td>
		</tr>
	)
})

export function AssetListings({ asset }) {

	const{t}=useTranslation()
	const params = useParams()
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				'listings',
				params.collectionAddress,
				params.nftId,
				params.supplyId
			],
			queryFn: ({ pageParam = 1 }) =>
				fetchListings(
					params.collectionAddress,
					params.nftId,
					params.supplyId,
					pageParam
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.length ? allPages.length + 1 : undefined
			},
			enabled: !!(
				params.collectionAddress &&
				params.nftId &&
				params.supplyId
			)
		})

	const intersectionObserver = useRef()
	const lastListingRef = useCallback(
		(listing) => {
			if (isFetchingNextPage) {
				return
			}

			if (intersectionObserver.current) {
				intersectionObserver.current.disconnect()
			}

			intersectionObserver.current = new IntersectionObserver(
				(listings) => {
					if (listings[0].isIntersecting && hasNextPage) {
						fetchNextPage()
					}
				}
			)

			if (listing) {
				intersectionObserver.current.observe(listing)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const listings = data?.pages.map((page) => {
		return page.map((listing, i) => {
			if (page.length === i + 1) {
				return (
					<Listing
						key={listing._id}
						ref={lastListingRef}
						listing={listing}
						asset={asset}
					/>
				)
			}
			return <Listing key={listing._id} listing={listing} asset={asset} />
		})
	})

	const hasNoListings = listings?.[0].length === 0

	if (hasNoListings) {
		return (
			<div className='col-12'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={cndds} alt='' />
					<h1>{t("No Data")}</h1>
				</div>
			</div>
		)
	}

	return (
		<div className='table-responsive'>
			<table className='table table-borderless table-hover mb-0 hmecollection'>
				<thead className='trd_head'>
					<tr>
						<th>{t("Price")}</th>
						<th>{t("USD Price")}</th>
						<th>{t("Quantity")}</th>
						<th>{t("From")}</th>
					</tr>
				</thead>
				<tbody className='trdtable_body'>{listings}</tbody>
			</table>
		</div>
	)
}
