import React, { useContext, useEffect, useState } from 'react';
import './helpcenter.scss';
import { useNavigate } from 'react-router-dom';
import loader from '../../assets/images/Loader.png'
import { axios } from '../../lib/axios'
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { ThemeContext } from '../context/context';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import { useDebounce } from '../../utils/useDebounce';
const HelpCenterCategorySearch = () => {

  const navigate = useNavigate()
  const { id } = useParams()

  const { helpsearch, setHelpsearch } = useContext(ThemeContext)
  const debouncedSearchText = useDebounce(helpsearch, 500)


  useEffect(() => {
    Getanswer()
  }, [debouncedSearchText])

  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false);
  const Getanswer = async () => {
    setLoading(true)
    const update = await axios.get(`/home/get_single_answer?filter=${helpsearch}&id=${id}`)
    if (update.status == true) {
      setLoading(false)
      setData(update.data)
    }
    else {
      setLoading(false)
      setData("")
    }
  }

  const back = (e) => {
    e.preventDefault()
    navigate(`/categoryactive/ ${data && data[0].categoryName}`)
  }

  return (
    <>
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
            {data && data.length > 0 ?
              <div style={{ position: 'absolute', top: '-10px' }}>
                <button className='backbtn' onClick={(e) => back(e)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.78125 9.75L11.4375 6.125C11.5938 5.96875 11.8438 5.96875 11.9688 6.125L12.2188 6.34375C12.3438 6.5 12.3438 6.71875 12.2188 6.875L9 10L12.1875 13.1562C12.3438 13.3125 12.3438 13.5312 12.1875 13.6875L11.9688 13.9062C11.8438 14.0625 11.5938 14.0625 11.4375 13.9062L7.78125 10.2812C7.625 10.125 7.625 9.90625 7.78125 9.75Z" fill="white" />
                  </svg>
                </button>
              </div>
              :
              ""
            }
            <div className="text-center hpCateActive mb-5">
              <span>Help Center</span>
              <span className="verticalline"></span>
              <span className="">{data && data[0].categoryName}</span>
              <span className="verticalline"></span>
              <span className="active">Article Name</span>
            </div>
          </div>

        </div>
        <div className='row'>
          {data && data.length > 0 ?
            <div className='col-12 paraTxt'>
              <div className='fs-28 fw-600 mb-3'>{data && data[0].categoryName}</div>
              <p>{ReactHtmlParser(data ? data && data[0].answer : "")}
              </p>
            </div>
            :
            <div className="col-md-12">
              <div className='noItemFound'>
                <div className='text-center'>
                  <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                </div>
                <div className='nofounttxt mb-4'>
                  No items were found. Try to broaden your search.
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </>
  )
}

export default HelpCenterCategorySearch;