import { forwardRef } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import { Link } from 'react-router-dom'

import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useCheckoutModalActions } from '../../../components/modals/checkout/store'
import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import { useBuyNowModalActions } from '../../../components/modals/buy-now/store'
import { chainId } from '../../../config/constants/chains'
import { calculateTimeSinceGivenDate } from '../../../utils/formatRoundTime'
import { formatAddress } from '../../../utils/formatAddress'

import ETHIcon from '../../../assets/images/collection/ETHIcon.png'
import BNBIcon from '../../../assets/images/bnbIconSm.png'
import MaticIcon from '../../../assets/images/maticIconSm.png'

const chainIconMap = {
	ethereum: ETHIcon,
	binance: BNBIcon,
	polygon: MaticIcon
}

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export const NftList = forwardRef(function NftList({ item, onClick }, ref) {
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()
	const { address: account } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { chain } = useNetwork()
	const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
		useBuyNowModalActions()
	const isVideo = item.details.asset.endsWith('.mp4')
	const timeSinceCreated = calculateTimeSinceGivenDate(item.details.createdAt)

	const handleBuyNow = async (e) => {
		e.stopPropagation()
		setBuyNowModalAssetState(item)
		setCheckoutModalAssetState(item)
		setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] })

		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleBuyNowModal
			})
			return
		}

		try {
			if (item.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[item.details.chain])
			}

			toggleBuyNowModal()
		} catch (error) {
			console.error(error)
		}
	}

	const isOwner =
		account && account.toLowerCase() === item.owner.address.toLowerCase()
	const isOnFixed =
		item.details.isMarketplace && item.details.saleType === 'fixed'
	const isOnAuction =
		item.details.isMarketplace && item.details.saleType === 'auction'
	const isOnDutchAuction =
		item.details.isMarketplace && item.details.saleType === 'dutch-auction'

	return (
		<tr ref={ref ? ref : null} onClick={onClick} className='hover'>
			<td>
				<div className='d-flex align-items-center'>
					<div className='tblImg'>
						{!isVideo ? (
							<img
								src={item.details.asset}
								alt='Itemimg1'
								className='img-fluid itemImage'
							/>
						) : (
							<video
								id='video-asset'
								loop
								playsinline=''
								preload='metadata'
								controlslist='nodownload'
								src={item.details.asset}
								style={{
									objectFit: 'contain',
									height: '60px',
									width: '60px'
								}}
							/>
						)}
					</div>
					<div className='fw-600'>{item.details.name}</div>
				</div>
			</td>
			<td
				className='text-right'
				style={{ opacity: isOnAuction ? '1' : '0.5' }}
			>
				{isOnAuction ? item.details.highestBidAmount : '--'}
			</td>
			<td className='text-right'>
				{item.details.isMarketplace ? '--' : '-'}
			</td>
			<td className='text-right'>
				<Link
					to={`/profile/${item.owner.address}`}
					style={{ textDecoration: 'none' }}
				>
					<span className='dark-primary'>
						{item.owner.name === ''
							? formatAddress(item.owner.address, 4)
							: item.owner.name}
					</span>
				</Link>
			</td>
			<td>
				<span className='dark-text-secondary'>{timeSinceCreated}</span>
			</td>
			{item.details.isMarketplace && isOnFixed ? (
				<td className='text-right'>
					<button className='btnBuynow' onClick={handleBuyNow}>
						Buy Now
					</button>
				</td>
			) : (
				<td></td>
			)}
		</tr>
	)
})
