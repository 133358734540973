import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

//Importing translation files

import translationEN from './english.json';
import translationzhCN from './simplified.json'

//Creating object with the variables of imported translation files

const resources = {
    en: {
        translation: translationEN
    },
    zh :{
        translation: translationzhCN
    },
};

// i18N Initialization

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources,
    lng: localStorage.getItem("lang"),
    keySeparator: false,
    interpolation:{
        escapeValue: false,
    },
});