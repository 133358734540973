import React, { useEffect, useState } from 'react';

import style from './profilelayout.module.scss'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Outlet, useParams } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import loader from '../../assets/images/Loader.png'
import profileBanner from '../../assets/images/profile/profileCover.png';
import Pencil from '../../assets/images/Pencil.svg';
import {
    useAccount,

} from 'wagmi'
import { axios } from '../../lib/axios'
import { useToast } from '../../utils/toast/customSuccess';

const ProfileLayout = (props) => {
    const bannerimgsize = '100%';


    useEffect(() => {
        document.body.classList.add('collectionpages');
    }, []);

    const { address, isConnected } = useAccount()
    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const toast = useToast()

    const [profilecoverimage, setprofilecoverimage] = useState()
    const [coverimgtype, setcoverimgtype] = useState()
    const [data, setData] = useState("")

    const { id } = useParams()
    useEffect(() => {
        GetProfile()
    }, [address, id])

    const GetProfile = async () => {
        setLoading(true)
        const update = await axios.post("user/getSingleProfile", { "address": id ? id : address })
        if (update.status == true) {
            setLoading(false)
            setData(update.data)
            setprofilecoverimage(update.data.cover)
            setcoverimgtype(update.data.coverImageType)
        }
        else {
            setLoading(false)
            setprofilecoverimage()
            setcoverimgtype()
        }
    }

    const imageupload1 = async (e) => {
        let imageFile1 = e.target.files[0];
        if (!imageFile1.name.match(/\.(webp|png|gif|mp4)$/)) {
            toast.error('Please select valid image for WEBP,GIF,PNG,MP4')
        }
        else if (imageFile1 == "" || imageFile1 == undefined) {
            toast.error('Please choose cover image')
        }
        else {
            setLoading(true)
            const formData = new FormData()
            formData.append('address', address.toLowerCase())
            formData.append('name', data.name)
            formData.append('logo', data.logo)
            formData.append('cover', imageFile1)
            formData.append('bio', data.bio)
            formData.append('email', data.email)
            formData.append('twitter', data.twitter)
            formData.append('instagram', data.instagram)
            formData.append('website', data.website)
            formData.append('verification', data.verification)
            formData.append('twitterVerify', data.twitterVerify)
            formData.append('nameVerify', data.nameVerify)
            formData.append('logoVerify', data.logoVerify)
            formData.append('emailVerify', data.emailVerify)
            formData.append('coverImageType', imageFile1.type)
            formData.append('logoImageType', data.logoImageType)

            const update = await axios.post("user/update-profile", formData)
            if (update.status == true) {
                toast.success(update.message)
                // props.setProfilePic(update.data.logo)
                setLoading(false)
                GetProfile()
            }
            else {
                toast.error(update.message)
                setLoading(false)
            }

        }
    }


    return (
        <>
            <div className={loading ? "spinner" : "d-none"}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            {/* <div className={style.cl_banner}>
                
                {typeof profilecoverimage == "string" && profilecoverimage !== "undefined" ?
                    coverimgtype.startsWith('video') ?
                        <video
                            className='vitag1'
                            src={profilecoverimage && profilecoverimage}
                            alt="Uploaded"
                            muted
                            autoPlay
                            width={bannerimgsize} height="400" style={{ objectFit: "cover" }}
                        />
                        :
                        profilecoverimage ?
                            <img src={profilecoverimage && profilecoverimage} width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                            :
                            <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                    :
                    <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                }
            </div> */}


            <div className={style.cl_banner}>
                <div className={style.coverBannerCnt}>
                    {typeof profilecoverimage == "string" && profilecoverimage !== "undefined" ?
                        coverimgtype.startsWith('video') ?
                            <video
                                className='vitag1'
                                src={profilecoverimage && profilecoverimage}
                                alt="Uploaded"
                                muted
                                autoPlay
                                width={bannerimgsize} height="400" style={{ objectFit: "cover" }}
                            />
                            :
                            profilecoverimage ?
                                <img src={profilecoverimage && profilecoverimage} width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                                :
                                <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                        :
                        <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                    }

                </div>
                {data && data.verification == 'Verified' ?
                    ""
                    :
                    id === address || id == undefined ?
                        <label htmlFor='userBannerImg' className={style.editIconCnt}>
                            <input id="userBannerImg" type='file' className={style.fileInputType} onChange={(e) => imageupload1(e)} />
                            <img src={Pencil} />
                        </label>
                        :
                        ""
                }
            </div>

            <Outlet context={[data]} />
        </>
    )
}

export default ProfileLayout;