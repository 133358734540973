import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { NftCard } from '../../collection/components/NftCard'
import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

export function MoreFromTheCollection() {
	const params = useParams()
	const navigate = useNavigate()
	const { data, isLoading } = useQuery({
		queryKey: ['nfts'],
		queryFn: async () => {
			const url = new URL(BACKEND_URL + 'collection/assets')
			url.searchParams.set('address', params.collectionAddress)
			return axios.get(url)
		},
		select: (res) => {
			if (res.status) {
				return res.data
			}
		}
	})

	if (isLoading) {
		return null
	}


	const nfts = data
		.filter((n) =>
			n.details.nftId == params.nftId
				? n.details.supplyId != params.supplyId
				: true
		)
		.slice(0, 6)

	return (
		<>
			<div className='col-12 mt-3'>
				<div className='d-flex align-items-center mb-3 flex-wrap'>
					<h3 className='gradientHeading'>
						More from this collection.
					</h3>
					<Link
						className='btn darkBtn ml-auto mb-4'
						to={`/collection/${params.collectionAddress}`}
					>
						View Collection
					</Link>
				</div>
			</div>
			<div className='col-12'>
				<div className='row'>
					{nfts &&
						nfts.map((nft) => {
							return (
								<NftCard
									item={nft}
									onClick={() =>
										navigate(
											`/assets/${params.chain}/${params.collectionAddress}/${nft.details.nftId}/${nft.details.supplyId}`
										)
									}
									classNames='col-sm-6 col-lg-3 col-xl-2 hover mb-4'
								/>
							)
						})}
				</div>
			</div>
		</>
	)
}
