import { Input, InputGroup, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledCollapse, UncontrolledTooltip } from "reactstrap"
import React, { useEffect, useState } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./HomeActive.scss"
import classnames from 'classnames';

import tc1 from "./assets/tc1.png"
import tc2 from "./assets/tc2.png"
import tc3 from "./assets/tc3.png"
import tc4 from "./assets/tc4.png"
import tc5 from "./assets/tc5.png"
import tc6 from "./assets/tc6.png"
import tc7 from "./assets/tc7.png"
import tc8 from "./assets/tc8.png"
import sm4 from "./assets/sm4.png"
import sm3 from "./assets/sm3.png"
import sm2 from "./assets/sm2.png"
import sm1 from "./assets/sm1.png"
import angleDown from "./assets/angle-down.png"
import ser from "./assets/ser.png"
import cndds from "./assets/cndds.png"
import tag from "./assets/tag.png"
import tag2 from "./assets/tag2.png"
import { axios } from '../../lib/axios'
import ScaleLoader from "react-spinners/ScaleLoader";
import PaginationComponent from 'react-reactstrap-pagination'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';

import saleIcon from '../../assets/images/collection/saleIcon.svg';
import transferIcon from '../../assets/images/collection/transferIcon.svg';
import listIcon from '../../assets/images/collection/listIcon.svg';
import collectionOfferIcon from '../../assets/images/collection/collectionOfferIcon.svg';
import offerIcon from '../../assets/images/collection/offerIcon.svg';
import mintIcon from '../../assets/images/mintIcon.svg';
import dealsIcon from '../../assets/images/dealsIcon.svg';
import loader from '../../assets/images/Loader.png'

import {
   useAccount,
} from 'wagmi'
import { useToast } from "../../utils/toast/customSuccess";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { hoursToGo } from "../../utils/formatRoundTime";
import ResponsivePagination from 'react-responsive-pagination';

const landPerRow = 6

const Bridge = () => {

   const { t } = useTranslation()

   const [activeTab, setActiveTab] = useState('1');
   const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);

   }

   const [activeTab1, setActiveTab1] = useState('1');
   const toggle1 = tab => {
      if (activeTab1 !== tab) setActiveTab1(tab);
   }


   const { address, isConnected } = useAccount()

   const [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#ffffff");
   const [next, setNext] = useState(landPerRow)
   const [page, setPage] = useState(1)

   const [checkcollectedChain, setcollectedCheckChain] = useState([])
   const [checkcreatecollection, setCheckcreateCollection] = useState([])
   const [createdcollectionsearch, setCollectionSearch] = useState("")

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })

      fetchdata()
      GetFilterCollection()

   }, [page, activeTab, checkcollectedChain, checkcreatecollection, createdcollectionsearch])

   const [activity, setActivity] = useState("")
   const [count, setCount] = useState("")

   function handlePageChange(page) {
      setPage(page);
      // ... do something with `page`
   }

   console.log("checkcollectedChain", checkcollectedChain.length > 0 || checkcreatecollection.length > 0 ? 1 : page)

   const fetchdata = async () => {
      setLoading(true)
      const fetch = await axios.get(`home/get_all_activity?page=${checkcollectedChain.length > 0 || checkcreatecollection.length > 0 ? 1 : page}&limit=100&type=${activeTab == "1" ? "all" : activeTab == "2" ? "sale" : "list"}&chain=${checkcollectedChain}&collectionName=${checkcreatecollection}`)
      if (fetch.status == true) {
         setActivity(fetch.data)
         setCount(Math.ceil(fetch.count / 100))
         setLoading(false)
      }
      else {
         setActivity("")
         setCount("")
         setLoading(false)
      }
   }

   const navigate = useNavigate()

   const Chains = [
      {
         id: 1,
         name: t("BNB"),
         value: "binance",
      },
      {
         id: 2,
         name: t("ETH"),
         value: "ethereum",
      },
      {
         id: 3,
         name: t("MATIC"),
         value: "polygon",
      }
   ]


   const handleCheckboxChange = (item) => {
      let a = checkcollectedChain.includes(item.value)

      if (a == true) {

         const index = checkcollectedChain.indexOf(item.value);
         if (index > -1) { // only splice array when item is found
            checkcollectedChain.splice(index, 1); // 2nd parameter means remove one item only
            const nerArr = [...checkcollectedChain]
            setcollectedCheckChain(nerArr);
         }

      } else if (a == false) {
         setcollectedCheckChain(
            [...checkcollectedChain, item.value]
         );
      }
   }

   const litstedChain = checkcollectedChain && checkcollectedChain.map((item) => {
      return (
         <>
            {item == "" ?
               ""
               :
               <>
                  <div className='tagLabel'>
                     <span>{item}</span>
                     <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollectedchain(item)}></i>
                  </div>
               </>
            }
         </>
      );
   });

   const closecollectedchain = (item) => {
      let a = checkcollectedChain.includes(item)

      if (a == true) {

         const index = checkcollectedChain.indexOf(item);
         if (index > -1) { // only splice array when item is found
            checkcollectedChain.splice(index, 1); // 2nd parameter means remove one item only
            const nerArr = [...checkcollectedChain]
            setcollectedCheckChain(nerArr);
         }
      }
   }

   const clearAll = () => {

      setcollectedCheckChain([])
      setCheckcreateCollection([])
   }


   const [collection, setCollection] = useState()

   const GetFilterCollection = async () => {
      const getcollection = await axios.get(`home/get_all_collections?filter=${createdcollectionsearch}`)
      if (getcollection.status == true) {
         setCollection(getcollection.data)
      }
      else {
         setCollection("")
      }
   }

   const handleCheckboxCollection = (item) => {
      let a = checkcreatecollection.includes(item.collectionName)

      if (a == true) {

         const index = checkcreatecollection.indexOf(item.collectionName);
         if (index > -1) { // only splice array when item is found
            checkcreatecollection.splice(index, 1); // 2nd parameter means remove one item only
            const nerArr = [...checkcreatecollection]
            setCheckcreateCollection(nerArr);
         }

      } else if (a == false) {
         setCheckcreateCollection(
            [...checkcreatecollection, item.collectionName]
         );
      }
   }

   const closecollection = (item) => {
      let a = checkcreatecollection.includes(item)

      if (a == true) {

         const index = checkcreatecollection.indexOf(item);
         if (index > -1) { // only splice array when item is found
            checkcreatecollection.splice(index, 1); // 2nd parameter means remove one item only
            const nerArr = [...checkcreatecollection]
            setCheckcreateCollection(nerArr);
         }

      }
   }


   const litstedCollection = checkcreatecollection && checkcreatecollection.map((item) => {
      return (
         <>
            {item == "" ?
               ""
               :
               <>
                  <div className='tagLabel'>
                     <span>{item}</span>
                     <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)}></i>
                  </div>
               </>
            }
         </>
      );
   });




   const searchItems = async (searchValue) => {
      setCollectionSearch(searchValue)
   }

   return (
      <div className='withoutBannerHeaderPage'>
         <div className={loading ? "spinner" : "d-none"}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>
         {/* <Header /> */}

         <section class="TraSec">
            <div class="container-fluid">
               <div class="mb-36 d-flex">
                  <Nav tabs className="trad_tab_hd">
                     <NavItem>
                        <NavLink
                           className={classnames({ active: activeTab === '1' })}
                           onClick={() => { toggle('1'); }}
                        >
                           {t("All")}
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: activeTab === '2' })}
                           onClick={() => { toggle('2'); }}
                        >
                           {t("Sales")}
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: activeTab === '3' })}
                           onClick={() => { toggle('3'); }}
                        >
                           {t("Listings")}
                        </NavLink>
                     </NavItem>
                  </Nav>
               </div>
               <div>
                  <div class="row">
                     <div class="col-xl-9 order-xl-1 order-2 mb-3">
                        <TabContent activeTab={activeTab}>
                           <TabPane tabId="1">
                              <div>
                                 <div class="mb-2 d-flex flexwrap" >
                                    <div className='fiterSelectlabel mb-4'>
                                       {/* <label class="artcrd mr-1"><span>Art</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label>
                                    <label class="artcrd mr-1"><span>Music</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}

                                       {/* <label class="artcrd mr-1"><span>Binance</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}
                                       {litstedChain}{litstedCollection}
                                       {checkcollectedChain?.length > 0 || checkcreatecollection.length > 0 ?
                                          <label class="artcrd mr-1" onClick={clearAll}><span >{t("Clear All")}</span></label>
                                          :
                                          ""
                                       }
                                    </div>
                                 </div>
                                 <div class="table-responsive">
                                    <table class="table table-borderless mb-0 hmecollection">
                                       {activity && activity.length > 0 ?
                                          <thead class="trd_head">
                                             <tr>
                                                <th></th>
                                                <th>{t("Items")} </th>
                                                <th>{t("Price")}
                                                </th>
                                                {/* <th>{t("Rarity")}</th> */}
                                                <th>{t("Quantity")}</th>
                                                <th>{t("From")}</th>
                                                <th>{t("To")}</th>
                                                <th><svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M6 0.6875C9.21094 0.6875 11.8125 3.28906 11.8125 6.5C11.8125 9.71094 9.21094 12.3125 6 12.3125C2.78906 12.3125 0.1875 9.71094 0.1875 6.5C0.1875 3.28906 2.78906 0.6875 6 0.6875ZM6 11.1875C8.57812 11.1875 10.6875 9.10156 10.6875 6.5C10.6875 3.92188 8.57812 1.8125 6 1.8125C3.39844 1.8125 1.3125 3.92188 1.3125 6.5C1.3125 9.10156 3.39844 11.1875 6 11.1875ZM7.42969 8.75L5.4375 7.29688C5.36719 7.25 5.34375 7.15625 5.34375 7.08594V3.21875C5.34375 3.07812 5.46094 2.9375 5.625 2.9375H6.375C6.51562 2.9375 6.65625 3.07812 6.65625 3.21875V6.54688L8.20312 7.69531C8.34375 7.78906 8.36719 7.95312 8.27344 8.09375L7.82812 8.67969C7.73438 8.82031 7.57031 8.84375 7.42969 8.75Z"
                                                      fill="#595F6A" />
                                                </svg>
                                                </th>
                                             </tr>
                                          </thead>
                                          :
                                          ""
                                       }
                                       <tbody class="trdtable_body">
                                          {activity && activity.length > 0 ?
                                             activity && activity.map((data, index) =>
                                             (
                                                <tr onClick={() => navigate(`/assets/${data.chain}/${data.contractAddress}/${data.nftId}/${data.supplyId}`)} style={{ cursor: "pointer" }}>
                                                   <td>
                                                      <label class="liscdbs">
                                                         <span class="mr-1">
                                                            <img src={data.type == "sale" ?
                                                               saleIcon : data.type == "list" ?
                                                                  listIcon : data.type == "offer" ?
                                                                     offerIcon : data.type == "collectionoffer" ?
                                                                        collectionOfferIcon : data.type == "transfer" ?
                                                                           transferIcon : data.type == "mint" ?
                                                                              mintIcon : data.type == "midealsentnt" ?
                                                                                 dealsIcon : data.type == "dealaccepted" ?
                                                                                    dealsIcon : data.type == "sale" ? saleIcon : offerIcon} alt="" />
                                                         </span>{data.type}
                                                      </label>
                                                   </td>
                                                   <td>
                                                      <div class="d-flex align-items-center">
                                                         <span class="mr-2">

                                                            {typeof data.asset == "string" && data.asset !== undefined ?

                                                               data.imageFormat.startsWith('video') ?
                                                                  <video
                                                                     className='img-fluid w-100 itemImage'
                                                                     src={data.asset}
                                                                     alt="Uploaded"
                                                                     muted
                                                                     autoPlay
                                                                     // width={1440} height={552}
                                                                     style={{ objectFit: "cover", borderRadius: "50%", width: "30px", height: "30px" }}
                                                                  />
                                                                  :
                                                                  data.asset !== 'undefined' ?
                                                                     <img src={data.asset} alt="" width="30px" height="30px" style={{ borderRadius: "50%" }} />
                                                                     :
                                                                     ""
                                                               :
                                                               ""
                                                            }

                                                         </span>
                                                         <div>
                                                            <h2>{data.nftName}</h2>
                                                            <p>{data.collectionName} <span class="ml-1"><img
                                                               src={data.verification == "Verified" ? sm4 : ""} alt="" />
                                                            </span>
                                                            </p>
                                                         </div>

                                                      </div>
                                                   </td>
                                                   <td>
                                                      <h3>{data.price == "0" ? 0 : data.price.toFixed(6)} {data.currency}</h3>
                                                      <p>{data.type == "mint" ? "" : "$"}{data.type == "mint" ? "" : parseFloat((data.price * data.usdPrice).toFixed(2))}</p>
                                                   </td>
                                                   {/* <td>
                                                      --
                                                   </td> */}
                                                   <td>
                                                      {data.quantity}
                                                   </td>
                                                   <td class="orncl">
                                                      {data.fromAddress == "" ? "--" : data.fromAddress.slice(0, 3) + "..." + data.fromAddress.slice(39, 42)}
                                                   </td>
                                                   <td class="orncl">
                                                      {data.toAddress == "" ? "--" : data.toAddress.slice(0, 3) + "..." + data.toAddress.slice(39, 42)}
                                                   </td>
                                                   <td>
                                                      {moment(data.createdAt).fromNow()}
                                                      {/* {hoursToGo(new Date(data.createdAt).valueOf()/1000)} */}
                                                   </td>
                                                </tr>
                                             ))
                                             :

                                             <div className='noItemFound justify-content-center'>
                                                <div className='text-center'>
                                                   <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                                </div>
                                                <div className='nofounttxt mb-4'>
                                                   {t("No items were found.Try to broaden your search.")}
                                                </div>
                                                {/* <div>
                                                   <button className='applyBtn'>{t("Apply")}</button>
                                                </div> */}
                                             </div>
                                          }
                                       </tbody>

                                    </table>
                                 </div>
                                 <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-8'>
                                       <div class="bri_pagenation2">
                                          {activity && activity.length > 0 ?
                                             <div class=''>
                                                <nav aria-label="Page ">
                                                   <ResponsivePagination
                                                      total={count}
                                                      current={page}
                                                      onPageChange={page => handlePageChange(page)}
                                                      previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                      nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                      previousClassName="page-link-1"
                                                      nextClassName="page-link-1"
                                                   />
                                                </nav>
                                                {/* <PaginationComponent
                                             size='sm'
                                             defaultActivePage={1}
                                             totalItems={count}
                                             maxPaginationNumbers={3}
                                             pageSize={100}
                                             onSelect={(page) => setPage(page)}
                                          /> */}
                                             </div>
                                             :
                                             ""
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </TabPane>
                           <TabPane tabId="2">
                              <div>
                                 <div class="mb-2 d-flex flexwrap" >
                                    <div className='fiterSelectlabel mb-4'>
                                       {/* <label class="artcrd mr-1"><span>Art</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label>
                                    <label class="artcrd mr-1"><span>Music</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}

                                       {/* <label class="artcrd mr-1"><span>Binance</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}
                                       {litstedChain}{litstedCollection}
                                       {checkcollectedChain?.length > 0 || checkcreatecollection.length > 0 ?
                                          <label class="artcrd mr-1" onClick={clearAll}><span >{t("Clear All")}</span></label>
                                          :
                                          ""
                                       }
                                    </div>
                                 </div>
                                 <div class="table-responsive">
                                    <table class="table table-borderless mb-0 hmecollection">
                                       {activity && activity.length > 0 ?
                                          <thead class="trd_head">
                                             <tr>
                                                <th></th>
                                                <th>{t("Items")} </th>
                                                <th>{t("Price")}
                                                </th>
                                                {/* <th>{t("Rarity")}</th> */}
                                                <th>{t("Quantity")}</th>
                                                <th>{t("From")}</th>
                                                <th>{t("To")}</th>
                                                <th><svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M6 0.6875C9.21094 0.6875 11.8125 3.28906 11.8125 6.5C11.8125 9.71094 9.21094 12.3125 6 12.3125C2.78906 12.3125 0.1875 9.71094 0.1875 6.5C0.1875 3.28906 2.78906 0.6875 6 0.6875ZM6 11.1875C8.57812 11.1875 10.6875 9.10156 10.6875 6.5C10.6875 3.92188 8.57812 1.8125 6 1.8125C3.39844 1.8125 1.3125 3.92188 1.3125 6.5C1.3125 9.10156 3.39844 11.1875 6 11.1875ZM7.42969 8.75L5.4375 7.29688C5.36719 7.25 5.34375 7.15625 5.34375 7.08594V3.21875C5.34375 3.07812 5.46094 2.9375 5.625 2.9375H6.375C6.51562 2.9375 6.65625 3.07812 6.65625 3.21875V6.54688L8.20312 7.69531C8.34375 7.78906 8.36719 7.95312 8.27344 8.09375L7.82812 8.67969C7.73438 8.82031 7.57031 8.84375 7.42969 8.75Z"
                                                      fill="#595F6A" />
                                                </svg>
                                                </th>
                                             </tr>
                                          </thead>
                                          :
                                          ""
                                       }
                                       <tbody class="trdtable_body">
                                          {activity && activity.length > 0 ?
                                             activity && activity.map((data, index) =>
                                             (
                                                <tr onClick={() => navigate(`/assets/${data.chain}/${data.contractAddress}/${data.nftId}/${data.supplyId}`)} style={{ cursor: "pointer" }}>
                                                   <td>
                                                      <label class="liscdbs">
                                                         <span class="mr-1">
                                                            <img src={saleIcon} alt="" />
                                                         </span>{data.type == "buy" ? "sale" : data.type}
                                                      </label>
                                                   </td>
                                                   <td>
                                                      <div class="d-flex align-items-center">
                                                         <span class="mr-2">
                                                            {typeof data.asset == "string" && data.asset !== undefined ?

                                                               data.imageFormat.startsWith('video') ?
                                                                  <video
                                                                     className='img-fluid w-100 itemImage'
                                                                     src={data.asset}
                                                                     alt="Uploaded"
                                                                     muted
                                                                     autoPlay
                                                                     // width={1440} height={552}
                                                                     style={{ objectFit: "cover", borderRadius: "50%", width: "30px", height: "30px" }}
                                                                  />
                                                                  :
                                                                  data.asset !== 'undefined' ?
                                                                     <img src={data.asset} alt="" width="30px" height="30px" style={{ borderRadius: "50%" }} />
                                                                     :
                                                                     ""
                                                               :
                                                               ""
                                                            }
                                                         </span>
                                                         <div>
                                                            <h2>{data.nftName}</h2>
                                                            <p>{data.collectionName} <span class="ml-1"><img
                                                               src={data.verification == "Verified" ? sm4 : ""} alt="" />
                                                            </span>
                                                            </p>
                                                         </div>

                                                      </div>
                                                   </td>
                                                   <td>
                                                      <h3>{data.price == "0" ? 0 : data.price.toFixed(6)} {data.currency}</h3>
                                                      <p>{data.type == "mint" ? "" : "$"}{data.type == "mint" ? "" : parseFloat((data.price * data.usdPrice).toFixed(2))}</p>
                                                   </td>
                                                   {/* <td>
                                                      --
                                                   </td> */}
                                                   <td>
                                                      {data.quantity}
                                                   </td>
                                                   <td class="orncl">
                                                      {data.fromAddress == "" ? "--" : data.fromAddress.slice(0, 3) + "..." + data.fromAddress.slice(39, 42)}
                                                   </td>
                                                   <td class="orncl">
                                                      {data.toAddress == "" ? "--" : data.toAddress.slice(0, 3) + "..." + data.toAddress.slice(39, 42)}
                                                   </td>
                                                   <td>
                                                      {moment(data.createdAt).fromNow()}
                                                      {/* {hoursToGo(new Date(data.createdAt).valueOf()/1000)} */}

                                                   </td>
                                                </tr>
                                             ))
                                             :

                                             <div className='noItemFound justify-content-center'>
                                                <div className='text-center'>
                                                   <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                                </div>
                                                <div className='nofounttxt mb-4'>
                                                   {t("No items were found.Try to broaden your search.")}
                                                </div>
                                                {/* <div>
                                                   <button className='applyBtn'>{t("Apply")}</button>
                                                </div> */}
                                             </div>
                                          }
                                       </tbody>

                                    </table>
                                 </div>
                                 <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-8'>
                                       <div class="bri_pagenation2">
                                          {activity && activity.length > 0 ?
                                             <div class=''>
                                                <nav aria-label="Page ">
                                                   <ResponsivePagination
                                                      total={count}
                                                      current={page}
                                                      onPageChange={page => handlePageChange(page)}
                                                      previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                      nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                      previousClassName="page-link-1"
                                                      nextClassName="page-link-1"
                                                   />
                                                </nav>
                                                {/* <PaginationComponent
                                             size='sm'
                                             defaultActivePage={1}
                                             totalItems={count}
                                             maxPaginationNumbers={3}
                                             pageSize={100}
                                             onSelect={(page) => setPage(page)}
                                          /> */}
                                             </div>
                                             :
                                             ""
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </TabPane>
                           <TabPane tabId="3">
                              <div>
                                 <div class="mb-2 d-flex flexwrap" >
                                    <div className='fiterSelectlabel mb-4'>
                                       {/* <label class="artcrd mr-1"><span>Art</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label>
                                    <label class="artcrd mr-1"><span>Music</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}

                                       {/* <label class="artcrd mr-1"><span>Binance</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}
                                       {litstedChain}{litstedCollection}
                                       {checkcollectedChain?.length > 0 || checkcreatecollection.length > 0 ?
                                          <label class="artcrd mr-1" onClick={clearAll}><span >{t("Clear All")}</span></label>
                                          :
                                          ""
                                       }
                                    </div>
                                 </div>
                                 <div class="table-responsive">
                                    <table class="table table-borderless mb-0 hmecollection">
                                       {activity && activity.length > 0 ?
                                          <thead class="trd_head">
                                             <tr>
                                                <th></th>
                                                <th>{t("Items")} </th>
                                                <th>{t("Price")}
                                                </th>
                                                {/* <th>{t("Rarity")}</th> */}
                                                <th>{t("Quantity")}</th>
                                                <th>{t("From")}</th>
                                                <th>{t("To")}</th>
                                                <th><svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M6 0.6875C9.21094 0.6875 11.8125 3.28906 11.8125 6.5C11.8125 9.71094 9.21094 12.3125 6 12.3125C2.78906 12.3125 0.1875 9.71094 0.1875 6.5C0.1875 3.28906 2.78906 0.6875 6 0.6875ZM6 11.1875C8.57812 11.1875 10.6875 9.10156 10.6875 6.5C10.6875 3.92188 8.57812 1.8125 6 1.8125C3.39844 1.8125 1.3125 3.92188 1.3125 6.5C1.3125 9.10156 3.39844 11.1875 6 11.1875ZM7.42969 8.75L5.4375 7.29688C5.36719 7.25 5.34375 7.15625 5.34375 7.08594V3.21875C5.34375 3.07812 5.46094 2.9375 5.625 2.9375H6.375C6.51562 2.9375 6.65625 3.07812 6.65625 3.21875V6.54688L8.20312 7.69531C8.34375 7.78906 8.36719 7.95312 8.27344 8.09375L7.82812 8.67969C7.73438 8.82031 7.57031 8.84375 7.42969 8.75Z"
                                                      fill="#595F6A" />
                                                </svg>
                                                </th>
                                             </tr>
                                          </thead>
                                          :
                                          ""
                                       }
                                       <tbody class="trdtable_body">
                                          {activity && activity.length > 0 ?
                                             activity && activity.map((data, index) =>
                                             (
                                                <tr onClick={() => navigate(`/assets/${data.chain}/${data.contractAddress}/${data.nftId}/${data.supplyId}`)} style={{ cursor: "pointer" }}>
                                                   <td>
                                                      <label class="liscdbs">
                                                         <span class="mr-1">
                                                            <img src={listIcon} alt="" />
                                                         </span>{data.type}
                                                      </label>
                                                   </td>
                                                   <td>
                                                      <div class="d-flex align-items-center">
                                                         <span class="mr-2">
                                                            {typeof data.asset == "string" && data.asset !== undefined ?

                                                               data.imageFormat.startsWith('video') ?
                                                                  <video
                                                                     className='img-fluid w-100 itemImage'
                                                                     src={data.asset}
                                                                     alt="Uploaded"
                                                                     muted
                                                                     autoPlay
                                                                     // width={1440} height={552}
                                                                     style={{ objectFit: "cover", borderRadius: "50%", width: "30px", height: "30px" }}
                                                                  />
                                                                  :
                                                                  data.asset !== 'undefined' ?
                                                                     <img src={data.asset} alt="" width="30px" height="30px" style={{ borderRadius: "50%" }} />
                                                                     :
                                                                     ""
                                                               :
                                                               ""
                                                            }
                                                         </span>
                                                         <div>
                                                            <h2>{data.nftName}</h2>
                                                            <p>{data.collectionName} <span class="ml-1"><img
                                                               src={data.verification == "Verified" ? sm4 : ""} alt="" />
                                                            </span>
                                                            </p>
                                                         </div>

                                                      </div>
                                                   </td>
                                                   <td>
                                                      <h3>{data.price == "0" ? 0 : data.price.toFixed(6)} {data.currency}</h3>
                                                      <p>{data.type == "mint" ? "" : "$"}{data.type == "mint" ? "" : parseFloat((data.price * data.usdPrice).toFixed(2))}</p>
                                                   </td>
                                                   {/* <td>
                                                      --
                                                   </td> */}
                                                   <td>
                                                      {data.quantity}
                                                   </td>
                                                   <td class="orncl">
                                                      {data.fromAddress == "" ? "--" : data.fromAddress.slice(0, 3) + "..." + data.fromAddress.slice(39, 42)}
                                                   </td>
                                                   <td class="orncl">
                                                      {data.toAddress == "" ? "--" : data.toAddress.slice(0, 3) + "..." + data.toAddress.slice(39, 42)}
                                                   </td>
                                                   <td>
                                                      {moment(data.createdAt).fromNow()}
                                                      {/* {hoursToGo(new Date(data.createdAt).valueOf()/1000)} */}

                                                   </td>
                                                </tr>
                                             ))
                                             :

                                             <div className='noItemFound justify-content-center'>
                                                <div className='text-center'>
                                                   <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                                </div>
                                                <div className='nofounttxt mb-4'>
                                                   {t("No items were found.Try to broaden your search.")}
                                                </div>
                                                {/* <div>
                                                   <button className='applyBtn'>{t("Apply")}</button>
                                                </div> */}
                                             </div>
                                          }
                                       </tbody>

                                    </table>
                                 </div>
                                 <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-8'>
                                       <div class="bri_pagenation2">
                                          {activity && activity.length > 0 ?
                                             <div class=''>
                                                <nav aria-label="Page ">
                                                   <ResponsivePagination
                                                      total={count}
                                                      current={page}
                                                      onPageChange={page => handlePageChange(page)}
                                                      previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                      nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                      previousClassName="page-link-1"
                                                      nextClassName="page-link-1"
                                                   />
                                                </nav>
                                                {/* <PaginationComponent
                                             size='sm'
                                             defaultActivePage={1}
                                             totalItems={count}
                                             maxPaginationNumbers={3}
                                             pageSize={100}
                                             onSelect={(page) => setPage(page)}
                                          /> */}
                                             </div>
                                             :
                                             ""
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </TabPane>
                        </TabContent>
                     </div>
                     <div class="col-xl-3 col-md-6 order-xl-2 order-1 mb-3">
                        <div class="filtl">
                           <h1>{t("Filter")}</h1>
                           <div class="d-flex align-items-center justify-content-between hdfil mb-20" id="collapse1" style={{ cursor: 'pointer' }}>
                              <h2>{t("Collections")}</h2>
                              <img class="rntar" src={angleDown} alt="" />
                           </div>
                           <UncontrolledCollapse toggler="#collapse1" className="mb-20">
                              <div class="input-group sergps mb-3">
                                 <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                       <img src={ser} alt="" />
                                    </span>
                                 </div>
                                 <input type="search" class="form-control" placeholder=""
                                    aria-label="" aria-describedby="basic-addon1" name="searchInput" onChange={(e) => searchItems(e.target.value)} />
                              </div>
                              <div class="bxnflt">
                                 {collection && collection.length > 0 ?
                                    collection && collection.map((data, index) =>
                                    (
                                       <div class="d-flex align-items-center mb-1" key={data._id}>
                                          <label class="cuschk mr-2" For={`checkbox${index + 1}`}>
                                             <input type="checkbox" id={`checkbox${index + 1}`} name="collectionchoose" checked={checkcreatecollection.length > 0 ? checkcreatecollection.includes(data.collectionName) : false} onChange={() => handleCheckboxCollection(data)} />
                                             <span class="checkmark"></span>
                                          </label>
                                          <h3>{data.collectionName} <span> <img src={data.verification == "Verified" ? sm4 : ""} alt="" /> </span></h3>
                                       </div>
                                    ))
                                    :

                                    <div class="noResf text-center" >
                                       <img class="img-fluid" src={cndds} alt="" />
                                       <h1>{t("No Results")}</h1>
                                       <p>{t("Try broadening your search")}</p>
                                    </div>
                                 }
                              </div>
                           </UncontrolledCollapse>
                           <div class="d-flex align-items-center justify-content-between hdfil " id="collapse2" style={{ cursor: 'pointer' }}>
                              <h2>{t("Chains")}</h2>
                              <img class="rntar" src={angleDown} alt="" />
                           </div>
                           <UncontrolledCollapse toggler="#collapse2" className="mb-20">
                              <div class="bxnflt">
                                 {Chains && Chains.map((data, index) => (

                                    <div class="d-flex align-items-center mb-1 justify-content-between" >
                                       <div class="d-flex align-items-center ">

                                          <label class="cuschk mr-2" For={`checkbx1${index + 1}`}>
                                             <input type="checkbox" id={`checkbx1${index + 1}`} name="chains" checked={checkcollectedChain?.length > 0 ? checkcollectedChain.includes(data.value) : false} onChange={() => handleCheckboxChange(data)} />
                                             <span class="checkmark"></span>
                                          </label>
                                          <h3 style={{ textTransform: "capitalize" }}> <span class="mr-1"><img src={data.value == "binance" ? sm1 : data.value == "ethereum" ? sm2 : data.value == "polygon" ? sm3 : ""} className="mr-1" width="20px" /></span>{data.value}</h3>
                                       </div>
                                       <h3>{data.name}</h3>
                                    </div >

                                 ))}

                              </div>
                           </UncontrolledCollapse>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <Footer /> */}
      </div>
   )
}
export default Bridge;