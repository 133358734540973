import { useState } from 'react'
import { CollectionBannerPlaceholder } from './loader/CollectionHeaderPlaceholder'

export default function CollectionBanner({ collection }) {
	const [isImageLoaded, setIsImageLoaded] = useState(false)

	return (
		<>
			{isImageLoaded ? null : <CollectionBannerPlaceholder />}
			<div className='cl_banner'>
				<img
					src={collection.bannerImage}
					alt='collectionBanner'
					width='100%'
					height="500"
					style={isImageLoaded ? {} : { display: 'none', objectFit: "cover" }}
					onLoad={() => setIsImageLoaded(true)}
				/>
			</div>
		</>
	)
}
