import { useAccount, usePublicClient } from 'wagmi'
import { parseUnits } from 'viem'

import { useMarketplaceContract } from './useContract'
import { usePlaceBidModalState } from '../components/modals/place-bid/store'

export function usePlaceBid(marketplaceContractAddress) {
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress
	)
	const publicClient = usePublicClient()
	const { address: account } = useAccount()
	const { asset } = usePlaceBidModalState()

	const placeBid = async ({ owner, nftAddress, tokenId, bidAmount }) => {
		const value = parseUnits(bidAmount.toString(), asset.details.decimals)
		try {
			const hash = await marketplaceContract.write.bid(
				[owner, nftAddress, tokenId, value],
				{
					account,
					...(asset.details.isCoin ? { value } : {})
				}
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status === 'success') {
				return transactionReceipt
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			console.error(error)
			throw new Error(error)
		}
	}

	return { placeBid }
}
