import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { ThemeContext } from '../../../views/context/context'
import { useContext } from 'react'
function collectionVolumeChart(collectionAddress,Analyticsfilter) {
	const url = new URL(BACKEND_URL + 'collection/volume-chart')
	url.searchParams.set('contractAddress', collectionAddress)
	url.searchParams.set('filter', Analyticsfilter)

	return axios.get(url)
}

export function useCollectionVolumeChart(collectionAddress) {
	const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)
	return useQuery({
		queryKey: ['volume_chart', collectionAddress,Analyticsfilter],
		queryFn: () => collectionVolumeChart(collectionAddress,Analyticsfilter),
		enabled: !!collectionAddress
	})
}
