import React, { useContext, useEffect, useState } from 'react';
import './helpcenter.scss';

import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PaginationComponent from 'react-reactstrap-pagination'
import loader from '../../assets/images/Loader.png'
import { axios } from '../../lib/axios'
import { ThemeContext } from '../context/context';
import { useDebounce } from '../../utils/useDebounce';
const landPerRow = 6

const HelpCenterCategoryActive = () => {
  const questionList = ["Question 1", "Question 2", "Question 3", "Question 4", "Question 5", "Question 6", "Question 7", "Question 8", "Question 9", "Question 10"];


  const { id } = useParams()

  const { helpsearch, setHelpsearch } = useContext(ThemeContext)
  const debouncedSearchText = useDebounce(helpsearch, 500)

  useEffect(() => {
    Getquestion()
  }, [debouncedSearchText])

  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)
  const [next, setNext] = useState(landPerRow)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState('')

  const Getquestion = async () => {
    setLoading(true)
    const update = await axios.get(`/home/get_single_question?filter=${helpsearch}&categoryName=${id}&page=${page}&limit=15`)
    if (update.status == true) {
      setLoading(false)
      setData(update.data)
      setCount(update.count)
    }
    else {
      setLoading(false)
      setCount("")
      setData("")
    }
  }
  const navigate = useNavigate()
  const back = (e) => {
    e.preventDefault()
    navigate("/helpcenter")
  }


  const searchResult = (data) => {
    navigate("/searchResult/" + data._id)
    setHelpsearch("")
  }

  return (
    <>
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
          {data && data.length > 0 ?
                  <div style={{ position: 'absolute', top: '-10px' }}>
                    <button className='backbtn' onClick={(e) => back(e)}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.78125 9.75L11.4375 6.125C11.5938 5.96875 11.8438 5.96875 11.9688 6.125L12.2188 6.34375C12.3438 6.5 12.3438 6.71875 12.2188 6.875L9 10L12.1875 13.1562C12.3438 13.3125 12.3438 13.5312 12.1875 13.6875L11.9688 13.9062C11.8438 14.0625 11.5938 14.0625 11.4375 13.9062L7.78125 10.2812C7.625 10.125 7.625 9.90625 7.78125 9.75Z" fill="white" />
                      </svg>
                    </button>
                  </div>
                  :
                  ""
                }
            <div className="text-center hpCateActive mb-5 mt-5 mt-sm-0">
              <span>Help Center</span>
              <span className="verticalline"></span>
              <span className="">{data && data[0].categoryName}</span>
              <span className="verticalline"></span>
              <span className="active">Search Results</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {/* <div className="fiterSelectlabel mb-4 flex-fill">
                <div className="tagLabel">
                  <span>Que</span>
                  <i className="fas fa-times ml-2"></i>
                </div>
              </div> */}
              <div className="fs-14 fw-400 dark-text-secondary">
               
                {count} {count ? "results" : ""}
              </div>
            </div>
            <div>
              <div className='mt-5'>
                {data && data.length > 0 ?
                  data && data.map((question) => (
                    <>
                      <div className="quesListItem" onClick={() => searchResult(question)} style={{ cursor: "pointer" }}>
                        <div>{question.question}</div>
                        <div>
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </>
                  ))
                  :
                  <div className="col-md-12">
                    <div className='noItemFound'>
                      <div className='text-center'>
                        <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                      </div>
                      <div className='nofounttxt mb-4'>
                        No items were found. Try to broaden your search.
                      </div>
                    </div>
                  </div>

                }
              </div>

              {data && data.length > 0 ?
                <div class="bri_pagenation2 mt-4">
                  <div class='pagination justify-content-center align-items-center mb-0'>
                    <PaginationComponent
                      size='sm'
                      defaultActivePage={1}
                      totalItems={count}
                      maxPaginationNumbers={3}
                      pageSize={15}
                      onSelect={(page) => setPage(page)}
                    />
                  </div>
                </div>
                :
                ""
              }


              {/* <div class="bri_pagenation2 mt-4">
                <nav aria-label="Page ">
                  <ul class="pagination justify-content-center align-items-center mb-0">
                    <li class="page-item">
                      <a href="#" class="page-link page-link-1 ">
                        {" "}
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z"
                            fill="#F7F7F7"
                          />
                        </svg>
                      </a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link active">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <div class="clog">0f</div>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">
                        10
                      </a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link page-link-1 ">
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z"
                            fill="#F7F7F7"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
            {/* <div className="row">
                <div className="col-lg-12">
                  <div className="noItemFound">
                    <div className="text-center">
                      <img
                        src={noItemfoundImage}
                        alt="noItemfoundImage"
                        className="img-fluid"
                      />
                    </div>
                    <div className="nofounttxt mb-4">
                      No items were found. Try to broaden your search.
                    </div>
                    <div>
                      <button className="applyBtn">Apply</button>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpCenterCategoryActive;