import cndds from '../../../assets/images/cndds.png'

export function AssetTraits({ asset }) {
	const traits = asset.traits.map((trait) => (
		<div className='col-sm-6 col-lg-4' key={trait.traitName}>
			<div className='traitCnt'>
				<span className='cardLabel'>{trait.traitName}</span>
				<div className='cardValue'>
					<span className='yellowTxt mr-2'>{trait.traitValue}</span>
					{`${trait.traitPercent} %`}
				</div>
			</div>
		</div>
	))

	if (traits.length === 0) {
		return (
			<div className='col-12'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={cndds} alt='' />
					<h1>No Traits</h1>
				</div>
			</div>
		)
	}

	return <div className='row'>{traits}</div>
}
