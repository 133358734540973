import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from '../buy-now/CloseIcon'
import { useCheckoutModalActions, useCheckoutModalState } from './store'

import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'

import '../modal.scss'

function Nft({ nft }) {
	const isVideo = nft.details.asset.endsWith('.mp4')

	return (
		<div className='cartItem'>
			<div className='itemImg'>
				{!isVideo ? (
					<img
						className='object-cover'
						alt='asset'
						src={nft.details.asset}
					/>
				) : (
					<video
						id='video-asset'
						loop
						playsinline=''
						autoplay
						preload='metadata'
						controlslist='nodownload'
						src={nft.details.asset}
						style={{
							objectFit: 'contain',
							height: '84px',
							width: '84px'
						}}
					/>
				)}
			</div>
			<div className='flex-grow-1 p-3'>
				<div className='d-flex justify-content-between mb-2'>
					<div className='itemName'>
						{nft.collection.name}{' '}
						{nft.collection.isVerified && (
							<img
								src={yellowtick20}
								alt='yellowtick20'
								className='ml-1'
							/>
						)}
					</div>
				</div>
				<div className='d-flex justify-content-between mb-n1'>
					<div className='itemId'>{nft.details.name}</div>
					<span className='itemBal'>{`${parseFloat(
						nft.details.price.toFixed(8)
					)} ${nft.details.currency}`}</span>
				</div>
			</div>
		</div>
	)
}

export function CheckoutModal() {
	const { t } = useTranslation()
	const { toggleCheckoutModal, closeCheckoutModal } =
		useCheckoutModalActions()
	const { isOpen, asset } = useCheckoutModalState()

	if (!asset) {
		return null
	}

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
		>
			<ModalHeader
				close={<CloseIcon onClose={toggleCheckoutModal} />}
			></ModalHeader>
			<ModalBody>
				<div className='text-center mt-n5'>
					<svg
						className='mb12px'
						width='40'
						height='40'
						viewBox='0 0 40 40'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='40' height='40' rx='12' fill='#00B976' />
						<path
							d='M25.5938 14.625C25.75 14.4688 26 14.4688 26.125 14.625L27.0312 15.5C27.1562 15.6562 27.1562 15.9062 27.0312 16.0312L17.6562 25.4062C17.5 25.5625 17.2812 25.5625 17.125 25.4062L12.9375 21.25C12.8125 21.0938 12.8125 20.8438 12.9375 20.7188L13.8438 19.8125C13.9688 19.6875 14.2188 19.6875 14.375 19.8125L17.375 22.8438L25.5938 14.625Z'
							fill='white'
						/>
					</svg>

					<div className='checkTit mb-2'>
						{t('Checkout success!')}
					</div>
					<div className='subcheckTxt mb-3'>
						{t('You successfully purchased')}{' '}
						<span className='fw-600 dark-text'>the NFT!</span>
					</div>
				</div>

				<div>
					{asset.map((nft) => (
						<Nft key={nft.id} nft={nft} />
					))}
				</div>

				<div className='mt-4' onClick={closeCheckoutModal}>
					<button className='btn btn-block checkoutBtn'>
						{t('Okay')}
					</button>
				</div>
			</ModalBody>
		</Modal>
	)
}
