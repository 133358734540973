import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

export async function fetchListings(
	collectionAddress,
	nftId,
	supplyId,
	pageParam
) {
	const url = new URL(BACKEND_URL + 'asset/listings')
	url.searchParams.set('collection', collectionAddress)
	url.searchParams.set('id', nftId)
	url.searchParams.set('supplyId', supplyId)
	url.searchParams.set('page', pageParam)

	const response = await axios.get(url)
	return response.data
}

export function useFetchListings(
	collectionAddress,
	nftId,
	supplyId,
	pageParam
) {
	return useQuery({
		queryKey: ['listings', collectionAddress, nftId, pageParam],
		queryFn: () => fetchListings(collectionAddress, nftId, pageParam),
		enabled: !!(collectionAddress && nftId && supplyId)
	})
}
