import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi';

import OffersList from './components/OffersList';
import { parseQueryStringToObject } from '../../utils/parseQueryStringToObject';
import { flattenFilters } from '../../utils/flattenFilters';
import { convertObjectToQueryString } from '../../utils/convertObjectToQueryString';
import { collectionOffer, useCollectionFilterOffer } from './apis/collectionOffer';
import { ItemsViewTabs } from './components/ItemsViewTabs';
import { Filters } from './components/Filters';


export const Offers = ({ collectionAddress, collectionDetails, tab }) => {
    const { address: account } = useAccount()

    const [searchParams, setSearchParams] = useSearchParams()
    const [filters, setFilters] = useState(() =>
        parseQueryStringToObject(searchParams)
    )

    useEffect(() => {
        const filters = parseQueryStringToObject(searchParams)
        setFilters(filters)
    }, [setFilters, searchParams])

    const handleFiltersChange = (filters) => {
        setFilters(filters)
        const flattenedFilters = flattenFilters(filters)
        const queryString = convertObjectToQueryString(flattenedFilters)
        setSearchParams(queryString)
    }

    const [activeTab, setActiveTab] = useState('grid')
    const [showFilters, setShowFilters] = useState(true)
    const [noOfItems, setNoOfItems] = useState(0)

    const navigate = useNavigate()

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery({
            queryKey: [
                'collection_offer',
                collectionAddress,
                searchParams.toString(),
                filters,
                activeTab
            ],
            queryFn: ({ pageParam = 1 }) =>
                collectionOffer(
                    collectionAddress,
                    account,
                    filters,
                    pageParam
                ),
            getNextPageParam: (lastPage, allPages) => {
                return lastPage?.length ? allPages?.length + 1 : undefined
            },
            enabled: !!collectionAddress
        })
    const { data: traitsData } = useCollectionFilterOffer(collectionAddress)

    const intersectionObserverRef = useRef()
    const lastNftRef = useCallback(
        (nft) => {
            if (isFetchingNextPage) {
                return
            }

            if (intersectionObserverRef.current) {
                intersectionObserverRef.current.disconnect()
            }

            intersectionObserverRef.current = new IntersectionObserver(
                (nfts) => {
                    if (
                        nfts[0].isIntersecting &&
                        hasNextPage &&
                        !isFetchingNextPage
                    ) {
                        fetchNextPage()
                    }
                }
            )

            if (nft) {
                intersectionObserverRef.current.observe(nft)
            }
        },
        [isFetchingNextPage, hasNextPage, fetchNextPage]
    )

    // const hasNoData = nftCards?.[0]?.length === 0



    return (
        <Filters
            itemsViewTabsContent={
                <OffersList
                    activeTab={activeTab}
                    showFilters={showFilters}
                    collectionDetails={collectionDetails}
                    noOfItems={noOfItems}
                    // nfts={nftCards}
                    // hasNoData={hasNoData}
                    filters={filters}
                    handleFiltersChange={handleFiltersChange}
                    data={data}
                />
            }
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            traits={traitsData}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
            collectionDetails={collectionDetails}
            tab={tab}
        >
            <ItemsViewTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            ></ItemsViewTabs>
        </Filters>
    );
}