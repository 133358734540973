import { create } from 'zustand'

const useConnectWalletModalStore = create((set) => ({
	isOpen: false,
	isIdle: true,
	isLoading: false,
	isSuccess: false,
	chainId: 11155111,
	callback: () => {},
	actions: {
		toggleConnectWalletModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		closeConnectWalletModal: () =>
			set((state) => ({
				...state,
				isOpen: false
			})),
		setConnectWalletModalState: (newState) =>
			set((state) => ({
				...state,
				...newState
			}))
	}
}))

export const useConnectWalletModalState = () =>
	useConnectWalletModalStore(
		({ isOpen, isIdle, isLoading, isSuccess, chainId, callback }) => ({
			isOpen,
			isIdle,
			isLoading,
			isSuccess,
			chainId,
			callback
		})
	)

export const useConnectWalletModalActions = () =>
	useConnectWalletModalStore((state) => state.actions)
