import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

function priceHistory(collectionAddress, nftId, supplyId) {
	const url = new URL(BACKEND_URL + 'asset/price-history')
	url.searchParams.set('collection', collectionAddress)
	url.searchParams.set('id', nftId)
	url.searchParams.set('supplyId', supplyId)

	return axios.get(url)
}

export function usePriceHistory(collectionAddress, nftId, supplyId) {
	return useQuery({
		queryFn: () => priceHistory(collectionAddress, nftId, supplyId),
		enabled: !!(collectionAddress && nftId && supplyId)
	})
}
