import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { Spinner } from 'reactstrap';
import classnames from 'classnames';

// import './collectionOffer.scss';
import { useCollectionOffer } from '../apis/collectionOffer';
import { useParams } from 'react-router';
import { useAccount } from 'wagmi';
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg'
import { useSearchParams } from 'react-router-dom';
import { NoItemsFound } from '../Items';

function TraitChip({ filter, filters, onClearChip }) {
    const handleClearChip = () => {
        const updatedFilters = {
            ...filters,
            search: {
                ...filters.search,
                stringTraits: null
            }
        }
        onClearChip(updatedFilters)
    }

    return (
        <>
            {filter?.value?.name !== 'No Trait' ?
                <div className='fiterSelectlabel mb-4'>
                    <div className='tagLabel'>
                        <span className='background'>{filter?.value?.name}: </span>
                        <span>{filter?.value?.values?.[0]}</span>
                        <i className="fas fa-times ml-2" onClick={handleClearChip}></i>
                    </div>
                </div>
                : null}
        </>
    )
}

const OffersList = ({ filters, setSearchParams, data, handleFiltersChange }) => {
    const traitChips = Object.entries(filters?.search.stringTraits ?? {}).map(([key, value]) => {
        return (
            <TraitChip
                key={key}
                filters={filters}
                filter={{ key, value }}
                onClearChip={handleFiltersChange}
            />
        )
    })

    const offerList = data?.pages?.map((page) => {
        return page?.map((offer, i) => {
            if (page?.length === i + 1) {
                return (
                    <tr>
                        <td>
                            <span className="tdValue">{offer.price} {offer.currency}</span>
                            <span className="progressBarCnt" style={{ width: offer.percentage + '%' }}></span>
                        </td>
                        <td><span className="tdValue">{parseFloat(offer.volume?.toFixed(8))} {offer.currency}</span></td>
                        <td><span className="tdValue">{offer.quantity}</span></td>
                        <td><span className="tdValue">{offer.bidders}</span></td>
                        <td><span className="tdValue">{offer.yourOffers}</span></td>
                    </tr>
                )
            }
            return (
                <tr>
                    <td>
                        <span className="tdValue">{offer.price} {offer.currency}</span>
                        <span className="progressBarCnt" style={{ width: offer.percentage + '%' }}></span>
                    </td>
                    <td><span className="tdValue">{parseFloat(offer.volume?.toFixed(8))} {offer.currency}</span></td>
                    <td><span className="tdValue">{offer.quantity}</span></td>
                    <td><span className="tdValue">{offer.bidders}</span></td>
                    <td><span className="tdValue">{offer.yourOffers}</span></td>
                </tr>
            )
        })
    })


    const hasNoData = offerList?.[0]?.length === 0

    return (
        <>
            {traitChips}
            <div class="table-responsive">
                <table class="table table-borderless progressTable mb-0">
                    <thead class="trd_head">
                        <tr>
                            <th>Offer Price</th>
                            <th>Total Volume </th>
                            <th>Total Offers</th>
                            <th>Bidders</th>
                            <th>Your Offers</th>
                        </tr>
                    </thead>
                    <tbody class="trdtable_body">
                        {offerList}
                    </tbody>
                </table>
                {hasNoData && <NoItemsFound />}
            </div>
        </>
    );
}

export default OffersList;