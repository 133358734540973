import { useAccount, usePublicClient, erc20ABI } from 'wagmi'
import { createWalletClient, custom, formatUnits, parseUnits } from 'viem'

import { useTokenContract } from './useContract'
import contracts from '../config/constants/contracts'
import { getContract } from '../utils/getContract'

export const useApproveToken = (tokenAddress) => {
	const { address: account } = useAccount()
	const tokenContract = useTokenContract(tokenAddress)
	const publicClient = usePublicClient()

	const approve = async (amount, spender) => {
		try {
			const decimals = await tokenContract.read.decimals()
			const allowance = await tokenContract.read.allowance([
				account,
				spender
			])

			const formattedAllowance = formatUnits(
				allowance.toString(),
				decimals
			)

			if (formattedAllowance >= amount) return

			const amountToApprove = parseUnits(amount, decimals)

			const hash = await tokenContract.write.approve(
				[spender, amountToApprove],
				{ account }
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status === 'success') {
				return transactionReceipt
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			console.error(error)
			throw new Error(error)
		}
	}

	const approveTokens = async (tokens, chain) => {
		const address = contracts.marketplace[chain.id]

		for (const token of tokens) {
			try {
				const client = createWalletClient({
					chain: chain,
					transport: custom(window.ethereum)
				})

				const contract = getContract({
					abi: erc20ABI,
					address: token.address,
					signer: client,
					publicClient
				})

				const decimals = await contract.read.decimals()
				const allowance = await contract.read.allowance([
					account,
					address
				])

				const formattedAllowance = formatUnits(
					allowance.toString(),
					decimals
				)

				if (formattedAllowance < token.amount) {
					const amountToApprove = parseUnits(
						token.amount.toString(),
						decimals
					)

					const hash = await contract.write.approve(
						[address, amountToApprove],
						{ account }
					)

					const transactionReceipt =
						await publicClient.waitForTransactionReceipt({
							hash
						})

					if (transactionReceipt.status !== 'success') {
						throw new Error(transactionReceipt)
					}
				}
			} catch (error) {
				console.error(error)
				throw new Error(error)
			}
		}
	}

	return { approve, approveTokens }
}
