import React, { useCallback, useState, useEffect } from 'react'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalHeader
} from 'reactstrap'
import useSWR from 'swr'
import { formatUnits } from 'viem'
import { NumericFormat } from 'react-number-format'
import { useAccount } from 'wagmi'
import { debounce } from 'lodash'
import { format } from 'date-fns'
import { Signature } from 'ethers'
import { useTranslation } from 'react-i18next'
import Web3 from 'web3'
import { useQueryClient } from '@tanstack/react-query'

import { useMakeOfferModalActions, useMakeOfferModalState } from './store'
import { CloseIcon } from './CloseIcon'
import { chainId } from '../../../config/constants/chains'
import contracts from '../../../config/constants/contracts'
import marketPlaceAbi from '../../../config/abi/marketplace.json'
import { useGetNativeCoinBalance } from '../../../hooks/useBalance'
import {
	useMarketplaceContract,
	useTokenContract
} from '../../../hooks/useContract'
import { useMakeOffer } from './offer'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { useOfferPlacedSuccessfullyModalActions } from '../offer-placed-successful/store'
import { useSomethingWentWrongModalActions } from '../error/store'
import { getOrder } from '../../../utils/getOrder'
import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'
import { useContractProvider } from '../../../utils/useContractProvider'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const intervals = [
	{ label: '12 hours', duration: 720, format: '12H' },
	{ label: '1 day', duration: 1440, format: '1D' },
	{ label: '3 days', duration: 4320, format: '3D' },
	{ label: '7 days', duration: 10080, format: '7D' },
	{ label: '1 month', duration: 43200, format: '1M' }
]

const nativeCoinMap = {
	ethereum: 'ETH',
	binance: 'BNB',
	polygon: 'MATIC'
}

export const MakeOfferModal = (props) => {
	const { t } = useTranslation()

	const { pro } = useContractProvider()

	const [offerPlaceModal, setOfferPlaceModal] = useState(false)
	const [isBidHighest, setIsBidHighest] = useState(false)

	const { isOpen, asset } = useMakeOfferModalState()
	const { toggleMakeOfferModal } = useMakeOfferModalActions()
	const { togglePurchaseLoadingModal } = usePurchaseLoadingModalActions()

	const { toggleOfferPlacedSuccessfullyModal, setAsset } =
		useOfferPlacedSuccessfullyModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()

	const [bidAmount, setBidAmount] = useState()
	const [hasFunds, setHasFunds] = useState(false)

	const [selectedInterval, setSelectedInterval] = useState(intervals[4])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [date, setDate] = useState('')
	const [time, setTime] = useState('')
	const [selectedDate, setSelectedDate] = useState('')

	const { balance: nativeCoinBalance } = useGetNativeCoinBalance(
		chainIdMap[asset?.details?.chain]
	)
	const queryClient = useQueryClient()

	const { address: account } = useAccount()
	const { mutateAsync: makeOffer } = useMakeOffer()

	const tokenAddress = asset?.details?.isCoin
		? null
		: asset?.details?.currencyAddress
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null
	const tokenContract = useTokenContract(tokenAddress)

	const web3 = new Web3(pro)

	const tetherContract = new web3.eth.Contract(
		marketPlaceAbi,
		marketplaceContractAddress
	)

	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress
	)
	const { data: tokenBalance } = useSWR(
		['tokenBalance', tokenContract],
		async () => {
			const decimals = await tokenContract.read.decimals()
			const rawBalance = await tokenContract.read.balanceOf([account])
			return parseFloat(formatUnits(rawBalance, decimals))
		}
	)
	const debounceValidateBidAmount = useCallback(
		debounce((floatValue) => validateBidAmount(floatValue), 500),
		[asset]
	)

	const balance = nativeCoinBalance

	function validateBidAmount(floatValue) {
		setHasFunds(false)
		if (floatValue >= balance) {
			setHasFunds(false)
		} else {
			setHasFunds(true)
		}
		if (floatValue > 0 && floatValue >= asset.details.minOfferPrice) {
			setIsBidHighest(true)
		} else {
			setIsBidHighest(false)
		}
	}

	const handleBidAmountChange = (values) => {
		const { floatValue } = values
		setBidAmount(floatValue)
		debounceValidateBidAmount(floatValue)
	}

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

	const handleIntervalChange = (interval) => {
		setSelectedInterval(interval)
	}

	useEffect(() => {
		const currentDate = new Date()
		const nextDate = new Date(
			currentDate.getTime() + selectedInterval.duration * 60000
		)

		const formatDate = format(nextDate, 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setSelectedDate(nextDate)
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval])

	const handleMakeOffer = async () => {
		togglePurchaseLoadingModal()

		const order = getOrder({
			asset: {
				...asset,
				owner: { ...asset.owner, address: account },
				details: { ...asset.details, price: bidAmount }
			},
			assetType: 'erc1155',
			orderType: 'buy'
		})
		const currency =
			asset.details.chain === 'binance'
				? 'BNB'
				: asset.details.chain === 'ethereum'
				? 'ETH'
				: 'MATIC'
		try {
			console.log('wroking')
			const expiry = Math.floor(new Date(selectedDate).getTime() / 1000)
			console.log(
				'🚀 ~ file: makeOffer.js:190 ~ handleMakeOffer ~ expiry:',
				expiry
			)
			const nftAddress = asset.collection.address
			const numberOfNfts = 1
			try {
				console.log(
					'🚀 ~ file: makeOffer.js:199 ~ handleMakeOffer ~ tetherContract:',
					tetherContract
				)
				console.log({ numberOfNfts, expiry, nftAddress, account })
				const offerSign = await tetherContract.methods
					.getHash(numberOfNfts, expiry, nftAddress, account)
					.call()

				console.log(
					'🚀 ~ file: makeOffer.js:196 ~ handleMakeOffer ~ offerSign:',
					offerSign
				)
				const sign = await web3.eth.personal.sign(offerSign, account)
				console.log(
					'🚀 ~ file: makeOffer.js:212 ~ handleMakeOffer ~ sign:',
					sign
				)

				const { v, r, s } = Signature.from(sign)
				const buyerSig = [v, r, s]
				setAsset(asset)
				const data = {
					currency,
					expiry,
					id: asset?.id,
					price: bidAmount,
					duration: selectedInterval?.format,
					address: account,
					orderTuple: order,
					signTuple: buyerSig
				}

				const res = await makeOffer(data)

				if (res.status) {
					queryClient.invalidateQueries(['makeOffer'])
					queryClient.invalidateQueries(['asset'])
					toggleMakeOfferModal()
					toggleOfferPlacedSuccessfullyModal()
					return
				} else {
					togglePurchaseLoadingModal()
					toggleSomethingWentWrongModal()
				}
			} catch (error) {
				console.log(
					'🚀 ~ file: makeOffer.js:201 ~ handleMakeOffer ~ error:',
					error
				)
			}
		} catch (error) {
			console.error(error)
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const CheckTick = () => {
		return (
			<>
				<svg
					width='12'
					height='9'
					viewBox='0 0 12 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
						fill='#FFA900'
					/>
				</svg>
			</>
		)
	}

	const intervalItems = intervals.map((interval) => (
		<DropdownItem
			tag='a'
			key={interval.label}
			onClick={() => handleIntervalChange(interval)}
		>
			<div>
				{interval.label}{' '}
				{selectedInterval.label === interval.label && <CheckTick />}
			</div>
		</DropdownItem>
	))

	if (!asset) {
		return null
	}

	const allowedCurrencies = asset.allowedCurrencies.reduce((acc, cur) => {
		if (!acc.hasOwnProperty(cur.currencySymbol)) {
			acc[cur.currencySymbol] = cur
		}
		return acc
	}, {})

	const usdPrice =
		bidAmount &&
		bidAmount *
			allowedCurrencies[nativeCoinMap[asset.details.chain]].usdPrice

	return (
		<>
			<Modal
				isOpen={isOpen}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
			>
				<ModalHeader
					toggle={toggleMakeOfferModal}
					close={<CloseIcon onClose={toggleMakeOfferModal} />}
				>
					{t('Make an Offer')}
				</ModalHeader>
				<ModalBody>
					<div>
						<div className='cartItem'>
							<div className='itemImg'>
								<img alt='asset' src={asset?.details.asset} />
							</div>
							<div className='flex-grow-1 p-3'>
								<div className='d-flex justify-content-between mb-2'>
									<div className='itemName'>
										{asset?.collection.name}{' '}
										{asset?.collection.isVerified && (
											<img
												src={yellowtick20}
												alt='yellowtick20'
												className='ml-1'
											/>
										)}
									</div>
									<span className='fs-14 fw-400 dark-text-secondary'>
										{usdPrice
											? `$ ${parseFloat(
													usdPrice.toFixed(8)
											  )}`
											: '$--'}
									</span>
								</div>
								<div className='d-flex justify-content-between mb-n1'>
									<div className='itemId'>
										{asset?.details.name}
									</div>
									<span className='itemBal'>
										{!bidAmount
											? '--'
											: `${bidAmount} ${
													nativeCoinMap[
														asset.details.chain
													]
											  }`}
									</span>
								</div>
							</div>
						</div>
					</div>
					<hr className='hrCls' />

					<div className='borderRoundedCard'>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t('Your Balance')}
							</div>
							<div className='fs-16 fw-600 dark-text'>{`${nativeCoinBalance} ${
								asset.details.chain === 'binance'
									? 'BNB'
									: asset.details.chain === 'ethereum'
									? 'ETH'
									: asset.details.chain === 'polygon'
									? 'MATIC'
									: ''
							}`}</div>
						</div>
						{/* <div class='d-flex justify-content-between'>
							<div className='fs-14 fw-400 dark-text-secondary'>Best Offer</div>
							<div className='fs-16 fw-600 dark-text'>117.864 ETH</div>
						</div> */}
					</div>

					<hr className='hrCls mt-3' />
					<div className='position-relative zindex1'>
						<div className='d-flex justify-content-between mb-1'>
							<span className='fs-14 fw-600 dark-text'>
								{t('Price')}
							</span>
							{!!bidAmount && !hasFunds ? (
								<span className='fs-14 fw-400 dark-red'>
									{t('Not enough funds')}
								</span>
							) : null}
							{!!bidAmount && !isBidHighest ? (
								<span className='fs-14 fw-400 dark-red'>
									{t(
										`Minimum offer price is ${
											asset.details.minOfferPrice
										} ${nativeCoinMap[asset.details.chain]}`
									)}
								</span>
							) : null}
						</div>
						<div className='form-group formInputs'>
							<InputGroup
								className={`${
									!!bidAmount && !hasFunds ? 'err' : ''
								}`}
							>
								<NumericFormat
									className='form-control mx-auto'
									placeholder='Price'
									value={bidAmount}
									allowNegative={false}
									customInput={Input}
									onValueChange={handleBidAmountChange}
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText
										className='pr-0'
										style={{ color: 'white' }}
									>
										{asset.details.chain === 'binance'
											? 'BNB'
											: asset.details.chain === 'ethereum'
											? 'ETH'
											: asset.details.chain === 'polygon'
											? 'MATIC'
											: ''}
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
						</div>
						<div className='d-flex justify-content-between mb-1'>
							<span className='fs-14 fw-600 dark-text'>
								Duration
							</span>
						</div>
						<div className='row form-row position-relative'>
							<div className='col-lg-8'>
								<div className='form-group formInputs'>
									<InputGroup>
										<Input
											value={date}
											placeholder={date}
											disabled
										/>
										<InputGroupAddon addonType='append'>
											<InputGroupText className='pr-0'>
												<span className='fs-16 fw-400'>
													{time}
												</span>
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
								</div>
							</div>
							<div className='col-lg-4'>
								<Dropdown
									className='customdropdown w-100'
									isOpen={dropdownOpen}
									toggle={toggleDropdown}
								>
									<DropdownToggle className='d-flex align-items-center justify-content-between w-100'>
										{selectedInterval.label}
										<i
											className={`fas ${
												dropdownOpen
													? 'fa-angle-up'
													: 'fa-angle-down'
											}`}
										></i>
									</DropdownToggle>
									<DropdownMenu
										className='hover'
										style={{ width: 'inherit' }}
									>
										{intervalItems}
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</div>

					<div className='mt-4'>
						<button
							className='btn btn-block btnSecondary'
							// onClick={() => setOfferPlaceModal(true)}
							disabled={!bidAmount || !hasFunds || !isBidHighest}
							onClick={() => handleMakeOffer()}
						>
							{t('Make an Offer')}{' '}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}
