import { Input, InputGroup, Modal, ModalHeader, ToastHeader } from "reactstrap"
import React, { useState, useEffect, useContext } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Bridge.scss"

import c1 from "./assets/cn1.png"
import c2 from "./assets/cn2.png"
import elprd from "./assets/elprd.png"
import swap from "./assets/swap.png"
import searchIcon from "./assets/search.svg"

import eth from "./assets/eth.png"
import bnb from "./assets/bnb.png"
import poly from "./assets/poly.png"
import tether from "./assets/tether.png"

import Web3 from "web3";
import { ethers } from "ethers";
import { axios } from '../../lib/axios'
import bnbabi from '../AbiArray/bnbabi.json'
import ethabi from '../AbiArray/ethabi.json'
import polygonabi from '../AbiArray/polygonabi.json'
import usdtabi from '../AbiArray/usdtabi.json'
import busdabi from '../AbiArray/busd.json'
import worabi from '../AbiArray/WorTokenAbi.json'
import { ChangeNetwork, accountChange, changed1 } from "../Services"
import { ThemeContext } from "../context/context";
// import { ToastContainer, toast } from 'react-toastify';
import ScaleLoader from "react-spinners/ScaleLoader";
import 'react-toastify/dist/ReactToastify.css';
import ContractDetails from '../ContractAddress'
import okxWeb3 from '@okwallet/extension';
import { formatAmount } from "../../utils/formatAmount";
import { useToast } from "../../utils/toast/customSuccess";
import {
   useAccount,
   useConnect,
   useNetwork,
   useSwitchNetwork,
   useDisconnect,
   useBalance
} from 'wagmi'
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format'
import loader from '../../assets/images/Loader.png'

import { chainId } from '../../config/constants/chains'

import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'

const chainIdMap = {
   ethereum: chainId.ETHEREUM,
   binance: chainId.BINANCE,
   polygon: chainId.POLYGON
}

const chainMap = {
   [chainId.ETHEREUM]: "ethereum",
   [chainId.BINANCE]: "binance",
   [chainId.POLYGON]: "polygon",
}

const bsc_rpc = process.env.BSC_TEST;
const eth_rpc = process.env.ETH_TEST;
const polygon_rpc = process.env.POLYGON_TEST;

const Bridge = () => {
   const toast = useToast()

   const [modal, setModal] = useState(false);
   const toggle = () => {
      setModal(!modal);
      fetchdata()
   }

   const { address, isConnected } = useAccount()
   const { connectAsync, connectors } = useConnect()


   useEffect(() => {

   }, [address])

   const { switchNetworkAsync } = useSwitchNetwork()
   const { switchNetwork } = useOkxSwitchNetwork()

   const { chain: network } = useNetwork()
   const chainId = network?.id

   console.log(chainId)

   const [modal1, setModal1] = useState(false);
   const toggle1 = () => {
      setModal1(!modal1);
      fetchdata()
   }
   const [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#ffffff");

   const { theme, setTheme } = useContext(ThemeContext);

   const [currencycoin, setCurrencyCoin] = useState("")

   const [currencycointype, setCurrencyCoinType] = useState("")
   const [currencyimage, setCurrencyimage] = useState("")
   const [currencycoin1, setCurrencyCoin1] = useState("")
   const [currencycointype1, setCurrencyCoinType1] = useState("")
   const [currencyimage1, setCurrencyimage1] = useState("")
   const [currencysymbol, setCurrencySymbol] = useState("")
   const [currencysymbol1, setCurrencySymbol1] = useState("")
   const [fromamount, setFromamount] = useState("")
   const [toamount, setToamount] = useState("")
   const [minamount, setMinAmount] = useState("")
   const [maxamount, setMaxamount] = useState("")
   const [Network, setNetwork] = useState("")
   const [Network1, setNetwork1] = useState("")

   const navigate = useNavigate()

   let providerType = ""
   if (typeof window !== undefined) {
      let web3 = new Web3(window.ethereum);

   }


   const { pro } = useContractProvider()
   const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))


   const web3 = new Web3(pro);
   const bnbcontractMarket = new web3.eth.Contract(bnbabi, ContractDetails.Bnb_contract)
   const ethcontractMarket = new web3.eth.Contract(ethabi, ContractDetails.Eth_contract)
   const polygoncontractMarket = new web3.eth.Contract(polygonabi, ContractDetails.Polygon_contract)
   const usdtcontractMarket = new web3.eth.Contract(usdtabi, ContractDetails.Tether_contract)
   const busdcontractMarket = new web3.eth.Contract(busdabi, ContractDetails.Busd_contract)
   const worcontractMarket = new web3.eth.Contract(worabi, ContractDetails.Wor_contract)


   const [fromcurrencybal, setfromCurrencybal] = useState("")
   const [tocurrencybal, settoCurrencybal] = useState("")

   var BnbRpcurl = "https://bsc-testnet.publicnode.com";
   var polygonRpcurl = "https://rpc-mumbai.maticvigil.com";
   var EthRpcurl = "https://chain-proxy.wallet.coinbase.com?targetName=ethereum-sepolia"

   var polygonBalance = new Web3(new Web3.providers.HttpProvider(polygonRpcurl));
   var bianceBalance = new Web3(new Web3.providers.HttpProvider(BnbRpcurl));
   var EthBalance = new Web3(new Web3.providers.HttpProvider(EthRpcurl));

   let binancecontract = new bianceBalance.eth.Contract(bnbabi, ContractDetails.Bnb_contract);
   let polygoncontract = new polygonBalance.eth.Contract(polygonabi, ContractDetails.Polygon_contract);
   let ethcontract = new EthBalance.eth.Contract(ethabi, ContractDetails.Eth_contract);
   let busdcontract = new bianceBalance.eth.Contract(busdabi, ContractDetails.Busd_contract);
   let worcontract = new bianceBalance.eth.Contract(worabi, ContractDetails.Wor_contract);
   let usdtcontract = new EthBalance.eth.Contract(usdtabi, ContractDetails.Tether_contract);

   const [usetgetBal, setUsergetBal] = useState("")
   const [adminfeeamount, setAdminFeeAmount] = useState("")

   const getCurrency = async (data) => {
      setLoading(true)
      setCurrencyCoin(data.currencyName)
      setCurrencyCoinType(data.currencyType)
      setCurrencyimage(data.currencyImage)
      setCurrencySymbol(data.currencySymbol)
      setNetwork(data.currencyNetwork)
      setMinAmount(data.minAmount)
      setMaxamount(data.maxAmount)
      localStorage.setItem("currency", data.currencySymbol)
      setModal(!modal)
      const fetch = await axios.post("admin/nft/get_single_pair", { "fromCurrency": data.currencySymbol, "toCurrency": currencysymbol1, "fromAmount": fromamount })
      if (fetch.status == true) {
         setLoading(false)
         setToamount(fetch.usdPrice)
         setUsergetBal((Number(fetch.usdPrice) - ((data.adminFee * +fetch.usdPrice) / 100)))
         setAdminFeeAmount((formatAmount((data.adminFee * +fetch.usdPrice) / 100)))
      }
      if (data.minAmount && fromamount && data.currencySymbol) {
         if (data.minAmount > fromamount) {
            document.getElementById('fromamount1').innerHTML = 'Your Min Amount is ' + data.minAmount
            setLoading(false)
         }
         else if (data.maxAmount < fromamount) {
            document.getElementById('fromamount1').innerHTML = 'Your Max Amount is ' + data.maxAmount
            setLoading(false)
         }
         else {
            document.getElementById('fromamount1').innerHTML = ''
            fetchdata()
            setLoading(false)
         }
      }
      console.log(data.currencySymbol)
      if (data.currencySymbol == "BUSD") {
         let balance = await busdcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1000000000000000000)
         setLoading(false)
      }
      if (data.currencySymbol == "WOR") {
         let balance = await worcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1000000000000000000)
         setLoading(false)
      }
      if (data.currencySymbol == "USDT") {
         let balance = await usdtcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1000000)
         setLoading(false)
      }
      if (data.currencySymbol == "ETH") {
         var balance = EthBalance.eth.getBalance(address).then(async (bal) => {
            setfromCurrencybal(bal / 1000000000000000000)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "BNB") {
         console.log(address)
         var balance = bianceBalance.eth.getBalance(address).then(async (bal) => {
            console.log(bal)
            setfromCurrencybal(bal / 1000000000000000000)
            setLoading(false)
         })
         console.log(balance)

      }
      if (data.currencySymbol == "MATIC") {
         var balance = polygonBalance.eth.getBalance(address).then(async (bal) => {
            setfromCurrencybal(bal / 1000000000000000000)
            setLoading(false)
         })
      }
      document.getElementById('fromcurrency1').innerHTML = ''
      fetchdata()

   }

   const [feecurrency, setFeeCurrency] = useState("")

   const getCurrency1 = async (data) => {
      setLoading(true)
      setCurrencyCoin1(data.currencyName)
      setCurrencyCoinType1(data.currencyType)
      setCurrencyimage1(data.currencyImage)
      setCurrencySymbol1(data.currencySymbol)
      setNetwork1(data.currencyNetwork)
      setFeeCurrency(data.adminFee)
      setModal1(!modal1)
      const fetch = await axios.post("admin/nft/get_single_pair", { "fromCurrency": currencysymbol, "toCurrency": data.currencySymbol, "fromAmount": fromamount })
      if (fetch.status == true) {
         setToamount(fetch.usdPrice)
         setUsergetBal((Number(fetch.usdPrice) - ((data.adminFee * +fetch.usdPrice) / 100)))
         setAdminFeeAmount((formatAmount((data.adminFee * +fetch.usdPrice) / 100)))
         setLoading(false)
      }
      if (data.currencySymbol == "BUSD") {
         let balance = await busdcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1000000000000000000)
         setLoading(false)
      }
      if (data.currencySymbol == "WOR") {
         console.log("balance")
         let balance = await worcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1000000000000000000)
         setLoading(false)
      }
      if (data.currencySymbol == "USDT") {
         let balance = await usdtcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1000000)
         setLoading(false)
      }
      if (data.currencySymbol == "ETH") {
         var balance = EthBalance.eth.getBalance(address).then(async (bal) => {
            settoCurrencybal(bal / 1000000000000000000)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "BNB") {
         var balance = bianceBalance.eth.getBalance(address).then(async (bal) => {
            settoCurrencybal(bal / 1000000000000000000)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "MATIC") {
         var balance = polygonBalance.eth.getBalance(address).then(async (bal) => {
            settoCurrencybal(bal / 1000000000000000000)
            setLoading(false)
         })
      }

      document.getElementById('tocurrency1').innerHTML = ''
      fetchdata()
   }

   const getAmount = async (e) => {
      setToamount("")
      if (e.target.value === '' || !e.target.value) {
         setFromamount('')
         document.getElementById('fromamount1').innerHTML = 'Please enter amount'
      }

      else if (e.target.value.length > 8) {
         document.getElementById('fromamount1').innerHTML = "Only allow 8 digits"
      }

      else {
         setFromamount(e.target.value)
         document.getElementById('fromamount1').innerHTML = ''
         const fetch = await axios.post("admin/nft/get_single_pair", { "fromCurrency": currencysymbol, "toCurrency": currencysymbol1, "fromAmount": e.target.value })
         if (fetch.status == true) {
            setToamount(fetch.usdPrice)
            setUsergetBal((Number(fetch.usdPrice) - ((feecurrency * +fetch.usdPrice) / 100)))
            setAdminFeeAmount((formatAmount((feecurrency * +fetch.usdPrice) / 100)))
         }
         if (minamount && e.target.value && currencysymbol) {

            if (minamount > Number(e.target.value)) {
               document.getElementById('fromamount1').innerHTML = 'Your Min Amount is ' + minamount
            }
            else if (maxamount < Number(e.target.value)) {
               document.getElementById('fromamount1').innerHTML = 'Your Max Amount is ' + maxamount
            }
            else {
               document.getElementById('fromamount1').innerHTML = ''
            }
         }
      }

   }


   useEffect(() => {
      fetchdata()
   }, [])

   const [currencies, setCurrencies] = useState("")
   const fetchdata = async () => {
      const fetch = await axios.get("currencies")
      if (fetch.status == true) {
         setCurrencies(fetch.data)
      }
   }

   var nonce;
   var domain;
   var claim;
   var provider;
   var value;
   var contract;

   const Buy = async () => {
      setLoading(true)
      if (address) {

         if (currencysymbol1 == "ETH") {
            contract = ethcontractMarket
         } else if (currencysymbol1 == "MATIC") {
            contract = polygoncontractMarket
         } else if (currencysymbol1 == "BNB") {
            contract = bnbcontractMarket
         }
         else if (currencysymbol1 == "USDT") {
            contract = ethcontractMarket
         }
         else if (currencysymbol1 == "BUSD") {
            contract = bnbcontractMarket
         }
         else if (currencysymbol1 == "WOR") {
            contract = bnbcontractMarket
         }

         if (fromamount == "" && currencysymbol == "" && currencysymbol1 == "") {
            document.getElementById('fromamount1').innerHTML = 'Please enter amount'
            document.getElementById('fromcurrency1').innerHTML = 'Please choose from currency'
            document.getElementById('tocurrency1').innerHTML = 'Please choose to currency'
            setLoading(false)
         }
         else if (currencysymbol == "") {
            document.getElementById('fromcurrency1').innerHTML = 'Please choose from currency'
            setLoading(false)
         }
         else if (currencysymbol1 == "") {
            document.getElementById('tocurrency1').innerHTML = 'Please choose to currency'
            setLoading(false)
         }
         else if (fromamount == "") {
            document.getElementById('fromamount1').innerHTML = 'Please enter amount'
            setLoading(false)
         }
         else if (minamount > fromamount) {
            document.getElementById('fromamount1').innerHTML = 'Your Min Amount is ' + minamount
            setLoading(false)
         }
         else if (maxamount < fromamount) {
            document.getElementById('fromamount1').innerHTML = 'Your Max Amount is ' + maxamount
            setLoading(false)
         }
         else {
            const balancecheck = await axios.post("admin/nft/check_claim_balance", { "network": Network1, "amount": formatAmount(Number(toamount)), "toCurrency": currencysymbol1 })
            if (balancecheck.status == true) {

               if (currencysymbol == "BNB" && currencycointype == "coin") {
                  try {

                     if (walletType === 'okx') {
                        console.log(chainIdMap[Network], parseInt(window.okxwallet.chainId))
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "BNB" &&
                           chainId !== 97
                        ) {
                           console.log('chain 1')
                           await switchNetworkAsync(97)
                        }
                     }
                     var balance = web3.eth.getBalance(address).then(async (bal) => {
                        const bnbbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(bnbbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           try {
                              const deposit = await bnbcontractMarket.methods.deposit("0x0000000000000000000000000000000000000000", buyprice1).send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {
                                 // if (approveresult.status == "true") {
                                 const fetch = await axios.post("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1000000000000000000).toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount.toString()), "status": "completed" })
                                 if (fetch.status == true) {
                                    try {

                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                       const buyprice3 = toamount * 1000000;

                                       const getvrs = await axios.post("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount.toString()) })
                                       if (getvrs.status == true) {
                                          {
                                             // { currencysymbol1 == "ETH" ? await ChangeNetwork.Ethnetwork(theme) : currencysymbol1 == "MATIC" ? await ChangeNetwork.Polygonnetwork(theme) : currencysymbol1 == "USDT" ? await ChangeNetwork.Ethnetwork(theme) : currencysymbol1 == "BUSD" ? await ChangeNetwork.Bnbnetwork(theme) : currencysymbol1 == "WOR" ? await ChangeNetwork.Bnbnetwork(theme) : <></> }

                                             console.log(currencysymbol1 == "ETH", currencysymbol1, chainId, currencysymbol1 == "ETH" &&
                                                chainId !== 11155111)
                                             try {

                                                if (walletType == 'okx') {
                                                   if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                      await switchNetwork(chainIdMap[Network1])
                                                   }
                                                } else {
                                                   if (currencysymbol1 == "ETH" && chainId !== 11155111
                                                   ) {
                                                      await switchNetworkAsync(11155111)
                                                   } else if (currencysymbol1 == "USDT" && chainId !== 11155111
                                                   ) {
                                                      await switchNetworkAsync(11155111)
                                                   }
                                                   else if (currencysymbol1 == "BUSD" && chainId !== 97
                                                   ) {
                                                      console.log('chain 1')
                                                   }
                                                   else if (currencysymbol1 == "WOR" && chainId !== 97
                                                   ) {
                                                      console.log('chain 1')
                                                   }
                                                   else if (currencysymbol1 == "MATIC" && chainId !== 80001
                                                   ) {
                                                      console.log('chain 3')
                                                      await switchNetworkAsync(80001)
                                                   }
                                                   else if (currencysymbol1 == "ETH" && chainId == 11155111
                                                   ) {
                                                      console.log('chain 2')
                                                      await switchNetworkAsync(11155111)
                                                   } else if (currencysymbol1 == "USDT" && chainId == 11155111
                                                   ) {
                                                      console.log('chain 2')
                                                      await switchNetworkAsync(11155111)
                                                   }
                                                   else if (currencysymbol1 == "BUSD" && chainId == 97
                                                   ) {
                                                      console.log('chain 1')
                                                      // await switchNetworkAsync(97)
                                                   }
                                                   else if (currencysymbol1 == "WOR" && chainId == 97
                                                   ) {
                                                      console.log('chain 1')
                                                      // await switchNetworkAsync(97)
                                                   }
                                                   else if (currencysymbol1 == "MATIC" && chainId !== 80001
                                                   ) {
                                                      console.log('chain 3')
                                                      await switchNetworkAsync(80001)
                                                   }
                                                }
                                             }
                                             catch (error) {
                                                console.log(error)
                                                toast.error('User rejected the switch network')
                                                setLoading(false)

                                                return
                                             }

                                             console.log(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Busd_contract, typeof (currencysymbol1), chainId)
                                             try {
                                                currencysymbol1 == "ETH" ?

                                                   claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await axios.post("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)
                                                   }).on('receipt', async (approveresult) => {
                                                   })
                                                   :
                                                   currencysymbol1 == "MATIC" ?
                                                      claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                         const getTransactionReceipt = async (hash) => {
                                                            const result = await web3.eth.getTransactionReceipt(hash)
                                                            if (result === null) {
                                                               getTransactionReceipt(hash)
                                                            } else {
                                                               let data = {
                                                                  "fromCurrency": currencysymbol,
                                                                  "toCurrency": currencysymbol1,
                                                                  "fromAddress": result.to,
                                                                  "toAddress": result.from,
                                                                  "fromAmount": fromamount.toString(),
                                                                  "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "txnHash": result.transactionHash,
                                                                  "network": Network1,
                                                                  "type": "claim",
                                                                  "status": "completed"
                                                               }
                                                               const fetch = await axios.post("bridge/claim", data)
                                                               if (fetch.status == true) {
                                                                  toast.success("Transaction completed successfully")
                                                                  setCurrencyCoin("Select Currency")
                                                                  setCurrencyCoin1("Select Currency")
                                                                  setCurrencyimage("")
                                                                  setCurrencyimage1("")
                                                                  setFromamount("")
                                                                  setToamount("")
                                                                  setCurrencySymbol("")
                                                                  setCurrencySymbol1("")
                                                                  setfromCurrencybal("")
                                                                  settoCurrencybal("")
                                                                  setLoading(false)
                                                                  navigate("/bridgehistory")
                                                                  return
                                                               }
                                                            }
                                                         }
                                                         getTransactionReceipt(hash)

                                                      }).on('receipt', async (approveresult) => {
                                                      })
                                                      :
                                                      currencysymbol1 == "USDT" ?
                                                         claim = await contract.methods.claim(buyprice3, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Tether_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                            const getTransactionReceipt = async (hash) => {
                                                               const result = await web3.eth.getTransactionReceipt(hash)
                                                               if (result === null) {
                                                                  getTransactionReceipt(hash)
                                                               } else {
                                                                  let data = {
                                                                     "fromCurrency": currencysymbol,
                                                                     "toCurrency": currencysymbol1,
                                                                     "fromAddress": result.to,
                                                                     "toAddress": result.from,
                                                                     "fromAmount": fromamount.toString(),
                                                                     "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "txnHash": result.transactionHash,
                                                                     "network": Network1,
                                                                     "type": "claim",
                                                                     "status": "completed"
                                                                  }
                                                                  const fetch = await axios.post("bridge/claim", data)
                                                                  if (fetch.status == true) {
                                                                     toast.success("Transaction completed successfully")
                                                                     setCurrencyCoin("Select Currency")
                                                                     setCurrencyCoin1("Select Currency")
                                                                     setCurrencyimage("")
                                                                     setCurrencyimage1("")
                                                                     setFromamount("")
                                                                     setToamount("")
                                                                     setCurrencySymbol("")
                                                                     setCurrencySymbol1("")
                                                                     setfromCurrencybal("")
                                                                     settoCurrencybal("")
                                                                     setLoading(false)
                                                                     navigate("/bridgehistory")
                                                                     return
                                                                  }
                                                               }
                                                            }
                                                            getTransactionReceipt(hash)
                                                         }).on('receipt', async (approveresult) => {

                                                         })
                                                         :
                                                         currencysymbol1 == "BUSD" ?

                                                            claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Busd_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                               const getTransactionReceipt = async (hash) => {
                                                                  const result = await web3.eth.getTransactionReceipt(hash)
                                                                  if (result === null) {
                                                                     getTransactionReceipt(hash)
                                                                  } else {
                                                                     let data = {
                                                                        "fromCurrency": currencysymbol,
                                                                        "toCurrency": currencysymbol1,
                                                                        "fromAddress": result.to,
                                                                        "toAddress": result.from,
                                                                        "fromAmount": fromamount.toString(),
                                                                        "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "txnHash": result.transactionHash,
                                                                        "network": Network1,
                                                                        "type": "claim",
                                                                        "status": "completed"
                                                                     }
                                                                     const fetch = await axios.post("bridge/claim", data)
                                                                     if (fetch.status == true) {
                                                                        toast.success("Transaction completed successfully")
                                                                        setCurrencyCoin("Select Currency")
                                                                        setCurrencyCoin1("Select Currency")
                                                                        setCurrencyimage("")
                                                                        setCurrencyimage1("")
                                                                        setFromamount("")
                                                                        setToamount("")
                                                                        setCurrencySymbol("")
                                                                        setCurrencySymbol1("")
                                                                        setfromCurrencybal("")
                                                                        settoCurrencybal("")
                                                                        setLoading(false)
                                                                        navigate("/bridgehistory")
                                                                        return
                                                                     }
                                                                  }
                                                               }
                                                               getTransactionReceipt(hash)
                                                            }).on('receipt', async (approveresult) => {


                                                            })
                                                            :

                                                            currencysymbol1 == "WOR" ?
                                                               claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Wor_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                                  const getTransactionReceipt = async (hash) => {
                                                                     const result = await web3.eth.getTransactionReceipt(hash)
                                                                     if (result === null) {
                                                                        getTransactionReceipt(hash)
                                                                     } else {
                                                                        let data = {
                                                                           "fromCurrency": currencysymbol,
                                                                           "toCurrency": currencysymbol1,
                                                                           "fromAddress": result.to,
                                                                           "toAddress": result.from,
                                                                           "fromAmount": fromamount.toString(),
                                                                           "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                           "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                           "txnHash": result.transactionHash,
                                                                           "network": Network1,
                                                                           "type": "claim",
                                                                           "status": "completed"
                                                                        }
                                                                        const fetch = await axios.post("bridge/claim", data)
                                                                        if (fetch.status == true) {
                                                                           toast.success("Transaction completed successfully")
                                                                           setCurrencyCoin("Select Currency")
                                                                           setCurrencyCoin1("Select Currency")
                                                                           setCurrencyimage("")
                                                                           setCurrencyimage1("")
                                                                           setFromamount("")
                                                                           setToamount("")
                                                                           setCurrencySymbol("")
                                                                           setCurrencySymbol1("")
                                                                           setfromCurrencybal("")
                                                                           settoCurrencybal("")
                                                                           setLoading(false)
                                                                           navigate("/bridgehistory")
                                                                           return
                                                                        }
                                                                     }
                                                                  }
                                                                  getTransactionReceipt(hash)
                                                               }).on('receipt', async (approveresult) => {

                                                               })
                                                               :
                                                               <></>
                                             }
                                             catch (err) {
                                                let data =
                                                {
                                                   "fromCurrency": currencysymbol,
                                                   "toCurrency": currencysymbol1,
                                                   "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract : currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                                   "toAddress": address,
                                                   "fromAmount": fromamount.toString(),
                                                   "toAmount": toamount.toString(),
                                                   "network": Network1,
                                                   "adminFee": "0",
                                                   "type": "claim",
                                                   "status": "cancelled"
                                                }
                                                let fetch = await axios.post("bridge/claim", data)
                                                if (fetch.status == true) {
                                                   toast.error("Transaction Rejected")
                                                   setLoading(false)
                                                }
                                             }
                                          }
                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount.toString(),
                                             "toAmount": toamount.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await axios.post("bridge/claim", data)
                                          if (fetch.status == true) {
                                             // toast.error("Transaction Rejected")
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }

                                    }
                                    catch (error) {

                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                             currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount.toString(),
                                          "toAmount": toamount.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await axios.post("bridge/claim", data)
                                       if (fetch.status == true) {
                                          toast.error("Transaction Rejected")
                                          setLoading(false)
                                       }

                                    }
                                 }
                              })
                           }
                           catch (error) {
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient bnb balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }

               }
               else if (currencysymbol == "ETH" && currencycointype == "coin") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "ETH" &&
                           chainId !== 11155111
                        ) {
                           console.log('chain 2')
                           await switchNetworkAsync(11155111)
                        }
                     }

                     var balance = web3.eth.getBalance(address).then(async (bal) => {
                        const ethbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(ethbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           try {
                              const deposit = await ethcontractMarket.methods.deposit("0x0000000000000000000000000000000000000000", buyprice1).send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {
                                 // if (approveresult.status == "true") {
                                 const fetch = await axios.post("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1000000000000000000).toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount.toString()), "status": "completed" })
                                 if (fetch.status == true) {
                                    try {
                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                       const buyprice3 = toamount * 1000000;
                                       const getvrs = await axios.post("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount.toString()) })
                                       if (getvrs.status == true) {
                                          try {

                                             if (walletType === 'okx') {
                                                if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                   await switchNetwork(chainIdMap[Network1])
                                                }
                                             } else {
                                                if (currencysymbol1 == "BNB" && chainId !== 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "BUSD" && chainId !== 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "WOR" && chainId !== 97) {
                                                   await switchNetworkAsync(97)
                                                }
                                                else if (currencysymbol1 == "USDT" && chainId !== 11155111) {
                                                   console.log('chain 2')
                                                }
                                                else if (currencysymbol1 == "MATIC" && chainId !== 80001) {
                                                   await switchNetworkAsync(80001)
                                                }
                                                else if (currencysymbol1 == "BNB" && chainId == 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "BUSD" && chainId == 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "WOR" && chainId == 97) {
                                                   await switchNetworkAsync(97)
                                                }
                                                else if (currencysymbol1 == "USDT" && chainId == 11155111) {
                                                   console.log('chain 2')
                                                }
                                                else if (currencysymbol1 == "MATIC" && chainId == 80001) {
                                                   await switchNetworkAsync(80001)
                                                }
                                             }
                                          }
                                          catch (error) {
                                             console.log(error)
                                             toast.error('User rejected the switch network')
                                             setLoading(false)

                                             return
                                          }

                                          try {
                                             currencysymbol1 == "BNB" ?
                                                claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await axios.post("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            return
                                                         }
                                                      }
                                                   }
                                                   getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {

                                                })
                                                :
                                                currencysymbol1 == "MATIC" ?
                                                   claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await axios.post("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)

                                                   }).on('receipt', async (approveresult) => {

                                                   })
                                                   :
                                                   currencysymbol1 == "USDT" ?
                                                      claim = await contract.methods.claim(buyprice3, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Tether_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                         const getTransactionReceipt = async (hash) => {
                                                            const result = await web3.eth.getTransactionReceipt(hash)
                                                            if (result === null) {
                                                               getTransactionReceipt(hash)
                                                            } else {
                                                               let data = {
                                                                  "fromCurrency": currencysymbol,
                                                                  "toCurrency": currencysymbol1,
                                                                  "fromAddress": result.to,
                                                                  "toAddress": result.from,
                                                                  "fromAmount": fromamount.toString(),
                                                                  "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "txnHash": result.transactionHash,
                                                                  "network": Network1,
                                                                  "type": "claim",
                                                                  "status": "completed"
                                                               }
                                                               const fetch = await axios.post("bridge/claim", data)
                                                               if (fetch.status == true) {
                                                                  toast.success("Transaction completed successfully")
                                                                  setCurrencyCoin("Select Currency")
                                                                  setCurrencyCoin1("Select Currency")
                                                                  setCurrencyimage("")
                                                                  setCurrencyimage1("")
                                                                  setFromamount("")
                                                                  setToamount("")
                                                                  setCurrencySymbol("")
                                                                  setCurrencySymbol1("")
                                                                  setfromCurrencybal("")
                                                                  settoCurrencybal("")
                                                                  setLoading(false)
                                                                  navigate("/bridgehistory")
                                                                  return
                                                               }
                                                            }
                                                         }
                                                         getTransactionReceipt(hash)
                                                      }).on('receipt', async (approveresult) => {

                                                      })
                                                      :
                                                      currencysymbol1 == "BUSD" ?
                                                         claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Busd_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                            const getTransactionReceipt = async (hash) => {
                                                               const result = await web3.eth.getTransactionReceipt(hash)
                                                               if (result === null) {
                                                                  getTransactionReceipt(hash)
                                                               } else {
                                                                  let data = {
                                                                     "fromCurrency": currencysymbol,
                                                                     "toCurrency": currencysymbol1,
                                                                     "fromAddress": result.to,
                                                                     "toAddress": result.from,
                                                                     "fromAmount": fromamount.toString(),
                                                                     "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "txnHash": result.transactionHash,
                                                                     "network": Network1,
                                                                     "type": "claim",
                                                                     "status": "completed"
                                                                  }
                                                                  const fetch = await axios.post("bridge/claim", data)
                                                                  if (fetch.status == true) {
                                                                     toast.success("Transaction completed successfully")
                                                                     setCurrencyCoin("Select Currency")
                                                                     setCurrencyCoin1("Select Currency")
                                                                     setCurrencyimage("")
                                                                     setCurrencyimage1("")
                                                                     setFromamount("")
                                                                     setToamount("")
                                                                     setCurrencySymbol("")
                                                                     setCurrencySymbol1("")
                                                                     setfromCurrencybal("")
                                                                     settoCurrencybal("")
                                                                     setLoading(false)
                                                                     navigate("/bridgehistory")
                                                                     return
                                                                  }
                                                               }
                                                            }
                                                            getTransactionReceipt(hash)
                                                         }).on('receipt', async (approveresult) => {

                                                         })
                                                         :
                                                         currencysymbol1 == "WOR" ?
                                                            claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Wor_contract).send({ from: address }).on('transactionHash', async (hash) => {
                                                               const getTransactionReceipt = async (hash) => {
                                                                  const result = await web3.eth.getTransactionReceipt(hash)
                                                                  if (result === null) {
                                                                     getTransactionReceipt(hash)
                                                                  } else {
                                                                     let data = {
                                                                        "fromCurrency": currencysymbol,
                                                                        "toCurrency": currencysymbol1,
                                                                        "fromAddress": result.to,
                                                                        "toAddress": result.from,
                                                                        "fromAmount": fromamount.toString(),
                                                                        "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "txnHash": result.transactionHash,
                                                                        "network": Network1,
                                                                        "type": "claim",
                                                                        "status": "completed"
                                                                     }
                                                                     const fetch = await axios.post("bridge/claim", data)
                                                                     if (fetch.status == true) {
                                                                        toast.success("Transaction completed successfully")
                                                                        setCurrencyCoin("Select Currency")
                                                                        setCurrencyCoin1("Select Currency")
                                                                        setCurrencyimage("")
                                                                        setCurrencyimage1("")
                                                                        setFromamount("")
                                                                        setToamount("")
                                                                        setCurrencySymbol("")
                                                                        setCurrencySymbol1("")
                                                                        setfromCurrencybal("")
                                                                        settoCurrencybal("")
                                                                        setLoading(false)
                                                                        navigate("/bridgehistory")
                                                                        return
                                                                     }
                                                                  }
                                                               }
                                                               getTransactionReceipt(hash)
                                                            }).on('receipt', async (approveresult) => {

                                                            })
                                                            :
                                                            <></>
                                          }

                                          catch (err) {
                                             let data =
                                             {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                   currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : "",
                                                "toAddress": address,
                                                "fromAmount": fromamount.toString(),
                                                "toAmount": toamount.toString(),
                                                "network": Network1,
                                                "adminFee": "0",
                                                "type": "claim",
                                                "status": "cancelled"
                                             }
                                             let fetch = await axios.post("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.error("Transaction Rejected")
                                                setLoading(false)
                                             }
                                          }
                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount.toString(),
                                             "toAmount": toamount.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await axios.post("bridge/claim", data)
                                          if (fetch.status == true) {
                                             // toast.error("Transaction Rejected")
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }
                                    }
                                    catch (error) {
                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                             currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount.toString(),
                                          "toAmount": toamount.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await axios.post("bridge/claim", data)
                                       if (fetch.status == true) {
                                          toast.error("Transaction Rejected")
                                          setLoading(false)
                                       }
                                    }
                                 }
                              })
                           }
                           catch (error) {
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient eth balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "MATIC" && currencycointype == "coin") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "MATIC" &&
                           chainId !== 80001
                        ) {
                           console.log('chain 3')
                           await switchNetworkAsync(80001)
                        }
                     }

                     var balance = web3.eth.getBalance(address).then(async (bal) => {
                        const maticbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(maticbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           try {
                              const deposit = await polygoncontractMarket.methods.deposit("0x0000000000000000000000000000000000000000", buyprice1).send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {
                                 // if (approveresult.status == "true") {
                                 const fetch = await axios.post("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1000000000000000000).toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount.toString()), "status": "completed" })
                                 if (fetch.status == true) {
                                    try {
                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                       const buyprice3 = toamount * 1000000;
                                       console.log(buyprice3)

                                       const getvrs = await axios.post("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount.toString()) })
                                       if (getvrs.status == true) {

                                          try {

                                             if (walletType === 'okx') {
                                                if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                   await switchNetwork(chainIdMap[Network1])
                                                }
                                             } else {
                                                if (currencysymbol1 == "BNB" && chainId !== 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "BUSD" && chainId !== 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "WOR" && chainId !== 97) {
                                                   await switchNetworkAsync(97)
                                                }
                                                else if (currencysymbol1 == "ETH" && chainId !== 11155111) {
                                                   await switchNetworkAsync(11155111)
                                                } else if (currencysymbol1 == "USDT" && chainId !== 11155111) {
                                                   await switchNetworkAsync(11155111)
                                                }
                                                if (currencysymbol1 == "BNB" && chainId == 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "BUSD" && chainId == 97) {
                                                   await switchNetworkAsync(97)
                                                } else if (currencysymbol1 == "WOR" && chainId == 97) {
                                                   await switchNetworkAsync(97)
                                                }
                                                else if (currencysymbol1 == "ETH" && chainId == 11155111) {
                                                   await switchNetworkAsync(11155111)
                                                } else if (currencysymbol1 == "USDT" && chainId == 11155111) {
                                                   await switchNetworkAsync(11155111)
                                                }
                                             }
                                          }
                                          catch (error) {
                                             console.log(error)
                                             toast.error('User rejected the switch network')
                                             setLoading(false)

                                             return
                                          }
                                          try {
                                             currencysymbol1 == "BNB" ?
                                                claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await axios.post("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            return
                                                         }
                                                      }
                                                   }
                                                   getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {


                                                })

                                                :
                                                currencysymbol1 == "ETH" ?
                                                   claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await axios.post("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)
                                                   }).on('receipt', async (approveresult) => {

                                                   })
                                                   :
                                                   currencysymbol1 == "USDT" ?
                                                      claim = await contract.methods.claim(buyprice3, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Tether_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                         const getTransactionReceipt = async (hash) => {
                                                            const result = await web3.eth.getTransactionReceipt(hash)
                                                            if (result === null) {
                                                               getTransactionReceipt(hash)
                                                            } else {
                                                               let data = {
                                                                  "fromCurrency": currencysymbol,
                                                                  "toCurrency": currencysymbol1,
                                                                  "fromAddress": result.to,
                                                                  "toAddress": result.from,
                                                                  "fromAmount": fromamount.toString(),
                                                                  "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "txnHash": result.transactionHash,
                                                                  "network": Network1,
                                                                  "type": "claim",
                                                                  "status": "completed"
                                                               }
                                                               const fetch = await axios.post("bridge/claim", data)
                                                               if (fetch.status == true) {
                                                                  toast.success("Transaction completed successfully")
                                                                  setCurrencyCoin("Select Currency")
                                                                  setCurrencyCoin1("Select Currency")
                                                                  setCurrencyimage("")
                                                                  setCurrencyimage1("")
                                                                  setFromamount("")
                                                                  setToamount("")
                                                                  setCurrencySymbol("")
                                                                  setCurrencySymbol1("")
                                                                  setfromCurrencybal("")
                                                                  settoCurrencybal("")
                                                                  setLoading(false)
                                                                  navigate("/bridgehistory")
                                                                  return
                                                               }
                                                            }
                                                         }
                                                         getTransactionReceipt(hash)
                                                      }).on('receipt', async (approveresult) => {

                                                      })
                                                      :
                                                      currencysymbol1 == "BUSD" ?
                                                         claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Busd_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                            const getTransactionReceipt = async (hash) => {
                                                               const result = await web3.eth.getTransactionReceipt(hash)
                                                               if (result === null) {
                                                                  getTransactionReceipt(hash)
                                                               } else {
                                                                  let data = {
                                                                     "fromCurrency": currencysymbol,
                                                                     "toCurrency": currencysymbol1,
                                                                     "fromAddress": result.to,
                                                                     "toAddress": result.from,
                                                                     "fromAmount": fromamount.toString(),
                                                                     "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "txnHash": result.transactionHash,
                                                                     "network": Network1,
                                                                     "type": "claim",
                                                                     "status": "completed"
                                                                  }
                                                                  const fetch = await axios.post("bridge/claim", data)
                                                                  if (fetch.status == true) {
                                                                     toast.success("Transaction completed successfully")
                                                                     setCurrencyCoin("Select Currency")
                                                                     setCurrencyCoin1("Select Currency")
                                                                     setCurrencyimage("")
                                                                     setCurrencyimage1("")
                                                                     setFromamount("")
                                                                     setToamount("")
                                                                     setCurrencySymbol("")
                                                                     setCurrencySymbol1("")
                                                                     setfromCurrencybal("")
                                                                     settoCurrencybal("")
                                                                     setLoading(false)
                                                                     navigate("/bridgehistory")
                                                                     return
                                                                  }
                                                               }
                                                            }
                                                            getTransactionReceipt(hash)
                                                         }).on('receipt', async (approveresult) => {

                                                         })
                                                         :
                                                         currencysymbol1 == "WOR" ?
                                                            claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Wor_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                               const getTransactionReceipt = async (hash) => {
                                                                  const result = await web3.eth.getTransactionReceipt(hash)
                                                                  if (result === null) {
                                                                     getTransactionReceipt(hash)
                                                                  } else {
                                                                     let data = {
                                                                        "fromCurrency": currencysymbol,
                                                                        "toCurrency": currencysymbol1,
                                                                        "fromAddress": result.to,
                                                                        "toAddress": result.from,
                                                                        "fromAmount": fromamount.toString(),
                                                                        "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "txnHash": result.transactionHash,
                                                                        "network": Network1,
                                                                        "type": "claim",
                                                                        "status": "completed"
                                                                     }
                                                                     const fetch = await axios.post("bridge/claim", data)
                                                                     if (fetch.status == true) {
                                                                        toast.success("Transaction completed successfully")
                                                                        setCurrencyCoin("Select Currency")
                                                                        setCurrencyCoin1("Select Currency")
                                                                        setCurrencyimage("")
                                                                        setCurrencyimage1("")
                                                                        setFromamount("")
                                                                        setToamount("")
                                                                        setCurrencySymbol("")
                                                                        setCurrencySymbol1("")
                                                                        setfromCurrencybal("")
                                                                        settoCurrencybal("")
                                                                        setLoading(false)
                                                                        navigate("/bridgehistory")
                                                                        return
                                                                     }
                                                                  }
                                                               }
                                                               getTransactionReceipt(hash)
                                                            }).on('receipt', async (approveresult) => {

                                                            })
                                                            :
                                                            <></>
                                          }
                                          catch (err) {
                                             let data =
                                             {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                   currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                                "toAddress": address,
                                                "fromAmount": fromamount.toString(),
                                                "toAmount": toamount.toString(),
                                                "network": Network1,
                                                "adminFee": "0",
                                                "type": "claim",
                                                "status": "cancelled"
                                             }
                                             let fetch = await axios.post("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.error("Transaction Rejected")
                                                setLoading(false)
                                             }
                                          }

                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount.toString(),
                                             "toAmount": toamount.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await axios.post("bridge/claim", data)
                                          if (fetch.status == true) {
                                             // toast.error("Transaction Rejected")
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }
                                    }
                                    catch (error) {
                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                             currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount.toString(),
                                          "toAmount": toamount.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await axios.post("bridge/claim", data)
                                       if (fetch.status == true) {
                                          toast.error("Transaction Rejected")
                                          setLoading(false)
                                       }
                                    }
                                 }
                              })
                           }
                           catch (error) {
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient Matic balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "USDT" && currencycointype == "token") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "USDT" &&
                           chainId !== 11155111
                        ) {
                           console.log('chain 2')
                           await switchNetworkAsync(11155111)
                        }
                     }
                     const balance = await usdtcontractMarket.methods.balanceOf(address).call().then(async (bal) => {
                        const tetherbalance = bal / 1000000;
                        if (Number(tetherbalance) > Number(fromamount)) {
                           const buyprice1 = fromamount * 1000000;
                           try {
                              const approve = await usdtcontractMarket.methods.approve(ContractDetails.Eth_contract, buyprice1).send({ from: address }).on('transactionHash1', async (hash) => { }).on('receipt1', async (approveresult) => { })
                              const deposit = await ethcontractMarket.methods.deposit(ContractDetails.Tether_contract, buyprice1).send({ from: address, value: 0 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                                 const fetch = await axios.post("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1000000).toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount.toString()), "status": "completed" })
                                 if (fetch.status == true) {
                                    try {
                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');

                                       const getvrs = await axios.post("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount.toString()) })
                                       if (getvrs.status == true) {
                                          {

                                             try {
                                                if (walletType === 'okx') {
                                                   if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                      await switchNetwork(chainIdMap[Network1])
                                                   }
                                                } else {

                                                   if (currencysymbol1 == "BNB" && chainId !== 97) {
                                                      await switchNetworkAsync(97)
                                                   } else if (currencysymbol1 == "BUSD" && chainId !== 97) {
                                                      await switchNetworkAsync(97)
                                                   } else if (currencysymbol1 == "WOR" && chainId !== 97) {
                                                      await switchNetworkAsync(97)
                                                   }
                                                   else if (currencysymbol1 == "ETH" && chainId !== 11155111) {
                                                      console.log('chain 2')
                                                   }
                                                   else if (currencysymbol1 == "MATIC" && chainId !== 80001) {
                                                      await switchNetworkAsync(80001)
                                                   }
                                                   else if (currencysymbol1 == "BNB" && chainId == 97) {
                                                      await switchNetworkAsync(97)
                                                   } else if (currencysymbol1 == "BUSD" && chainId == 97) {
                                                      await switchNetworkAsync(97)
                                                   } else if (currencysymbol1 == "WOR" && chainId == 97) {
                                                      await switchNetworkAsync(97)
                                                   }
                                                   else if (currencysymbol1 == "ETH" && chainId == 11155111) {
                                                      console.log('chain 2')
                                                   }
                                                   else if (currencysymbol1 == "MATIC" && chainId == 80001) {
                                                      await switchNetworkAsync(80001)
                                                   }
                                                }
                                             }
                                             catch (error) {
                                                console.log(error)
                                                toast.error('User rejected the switch network')
                                                setLoading(false)

                                                return
                                             }

                                             try {
                                                currencysymbol1 == "ETH" ?
                                                   claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await axios.post("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)
                                                   }).on('receipt', async (approveresult) => {

                                                   })


                                                   :
                                                   currencysymbol1 == "MATIC" ?
                                                      claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                         const getTransactionReceipt = async (hash) => {
                                                            const result = await web3.eth.getTransactionReceipt(hash)
                                                            if (result === null) {
                                                               getTransactionReceipt(hash)
                                                            } else {
                                                               let data = {
                                                                  "fromCurrency": currencysymbol,
                                                                  "toCurrency": currencysymbol1,
                                                                  "fromAddress": result.to,
                                                                  "toAddress": result.from,
                                                                  "fromAmount": fromamount.toString(),
                                                                  "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "txnHash": result.transactionHash,
                                                                  "network": Network1,
                                                                  "type": "claim",
                                                                  "status": "completed"
                                                               }
                                                               const fetch = await axios.post("bridge/claim", data)
                                                               if (fetch.status == true) {
                                                                  toast.success("Transaction completed successfully")
                                                                  setCurrencyCoin("Select Currency")
                                                                  setCurrencyCoin1("Select Currency")
                                                                  setCurrencyimage("")
                                                                  setCurrencyimage1("")
                                                                  setFromamount("")
                                                                  setToamount("")
                                                                  setCurrencySymbol("")
                                                                  setCurrencySymbol1("")
                                                                  setfromCurrencybal("")
                                                                  settoCurrencybal("")
                                                                  setLoading(false)
                                                                  navigate("/bridgehistory")
                                                                  return
                                                               }
                                                            }
                                                         }
                                                         getTransactionReceipt(hash)
                                                      }).on('receipt', async (approveresult) => {

                                                      })
                                                      :
                                                      currencysymbol1 == "BNB" ?
                                                         claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                            const getTransactionReceipt = async (hash) => {
                                                               const result = await web3.eth.getTransactionReceipt(hash)
                                                               if (result === null) {
                                                                  getTransactionReceipt(hash)
                                                               } else {
                                                                  let data = {
                                                                     "fromCurrency": currencysymbol,
                                                                     "toCurrency": currencysymbol1,
                                                                     "fromAddress": result.to,
                                                                     "toAddress": result.from,
                                                                     "fromAmount": fromamount.toString(),
                                                                     "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "txnHash": result.transactionHash,
                                                                     "network": Network1,
                                                                     "type": "claim",
                                                                     "status": "completed"
                                                                  }
                                                                  const fetch = await axios.post("bridge/claim", data)
                                                                  if (fetch.status == true) {
                                                                     toast.success("Transaction completed successfully")
                                                                     setCurrencyCoin("Select Currency")
                                                                     setCurrencyCoin1("Select Currency")
                                                                     setCurrencyimage("")
                                                                     setCurrencyimage1("")
                                                                     setFromamount("")
                                                                     setToamount("")
                                                                     setCurrencySymbol("")
                                                                     setCurrencySymbol1("")
                                                                     setfromCurrencybal("")
                                                                     settoCurrencybal("")
                                                                     setLoading(false)
                                                                     navigate("/bridgehistory")
                                                                     return
                                                                  }
                                                               }
                                                            }
                                                            getTransactionReceipt(hash)
                                                         }).on('receipt', async (approveresult) => {

                                                         })
                                                         :
                                                         currencysymbol1 == "BUSD" ?
                                                            claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Busd_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                               const getTransactionReceipt = async (hash) => {
                                                                  const result = await web3.eth.getTransactionReceipt(hash)
                                                                  if (result === null) {
                                                                     getTransactionReceipt(hash)
                                                                  } else {
                                                                     let data = {
                                                                        "fromCurrency": currencysymbol,
                                                                        "toCurrency": currencysymbol1,
                                                                        "fromAddress": result.to,
                                                                        "toAddress": result.from,
                                                                        "fromAmount": fromamount.toString(),
                                                                        "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "txnHash": result.transactionHash,
                                                                        "network": Network1,
                                                                        "type": "claim",
                                                                        "status": "completed"
                                                                     }
                                                                     const fetch = await axios.post("bridge/claim", data)
                                                                     if (fetch.status == true) {
                                                                        toast.success("Transaction completed successfully")
                                                                        setCurrencyCoin("Select Currency")
                                                                        setCurrencyCoin1("Select Currency")
                                                                        setCurrencyimage("")
                                                                        setCurrencyimage1("")
                                                                        setFromamount("")
                                                                        setToamount("")
                                                                        setCurrencySymbol("")
                                                                        setCurrencySymbol1("")
                                                                        setfromCurrencybal("")
                                                                        settoCurrencybal("")
                                                                        setLoading(false)
                                                                        navigate("/bridgehistory")
                                                                        return
                                                                     }
                                                                  }
                                                               }
                                                               getTransactionReceipt(hash)
                                                            }).on('receipt', async (approveresult) => {

                                                            })
                                                            :
                                                            currencysymbol1 == "WOR" ?
                                                               claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Wor_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                                  const getTransactionReceipt = async (hash) => {
                                                                     const result = await web3.eth.getTransactionReceipt(hash)
                                                                     if (result === null) {
                                                                        getTransactionReceipt(hash)
                                                                     } else {
                                                                        let data = {
                                                                           "fromCurrency": currencysymbol,
                                                                           "toCurrency": currencysymbol1,
                                                                           "fromAddress": result.to,
                                                                           "toAddress": result.from,
                                                                           "fromAmount": fromamount.toString(),
                                                                           "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                           "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                           "txnHash": result.transactionHash,
                                                                           "network": Network1,
                                                                           "type": "claim",
                                                                           "status": "completed"
                                                                        }
                                                                        const fetch = await axios.post("bridge/claim", data)
                                                                        if (fetch.status == true) {
                                                                           toast.success("Transaction completed successfully")
                                                                           setCurrencyCoin("Select Currency")
                                                                           setCurrencyCoin1("Select Currency")
                                                                           setCurrencyimage("")
                                                                           setCurrencyimage1("")
                                                                           setFromamount("")
                                                                           setToamount("")
                                                                           setCurrencySymbol("")
                                                                           setCurrencySymbol1("")
                                                                           setfromCurrencybal("")
                                                                           settoCurrencybal("")
                                                                           setLoading(false)
                                                                           navigate("/bridgehistory")
                                                                           return
                                                                        }
                                                                     }
                                                                  }
                                                                  getTransactionReceipt(hash)
                                                               }).on('receipt', async (approveresult) => {

                                                               })
                                                               :
                                                               <></>
                                             }
                                             catch (err) {
                                                let data =
                                                {
                                                   "fromCurrency": currencysymbol,
                                                   "toCurrency": currencysymbol1,
                                                   "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                                   "toAddress": address,
                                                   "fromAmount": fromamount.toString(),
                                                   "toAmount": toamount.toString(),
                                                   "network": Network1,
                                                   "adminFee": "0",
                                                   "type": "claim",
                                                   "status": "cancelled"
                                                }
                                                let fetch = await axios.post("bridge/claim", data)
                                                if (fetch.status == true) {
                                                   toast.error("Transaction Rejected")
                                                   setLoading(false)
                                                }
                                             }
                                          }

                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencycoin1 == "BNB" ? ContractDetails.Bnb_contract : currencycoin1 == "Ethereum" ? ContractDetails.Eth_contract : currencycoin1 == "MATIC" ? ContractDetails.Polygon_contract :
                                                currencycoin1 == "BUSD" ? ContractDetails.Bnb_contract : currencycoin1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount.toString(),
                                             "toAmount": toamount.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await axios.post("bridge/claim", data)
                                          if (fetch.status == true) {
                                             // toast.error("Transaction Rejected")
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }
                                    }
                                    catch (error) {
                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencycoin1 == "BNB" ? ContractDetails.Bnb_contract : currencycoin1 == "Ethereum" ? ContractDetails.Eth_contract : currencycoin1 == "MATIC" ? ContractDetails.Polygon_contract :
                                             currencycoin1 == "BUSD" ? ContractDetails.Bnb_contract : currencycoin1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount.toString(),
                                          "toAmount": toamount.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await axios.post("bridge/claim", data)
                                       if (fetch.status == true) {
                                          toast.error("Transaction Rejected")
                                          setLoading(false)
                                       }
                                    }
                                 }
                              })
                           }
                           catch (error) {
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient tether(usdt) balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "BUSD" && currencycointype == "token") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "BUSD" &&
                           chainId !== 97
                        ) {
                           console.log('chain 1')
                           await switchNetworkAsync(97)
                        }
                     }
                     const balance = await busdcontractMarket.methods.balanceOf(address).call().then(async (bal) => {
                        const busdbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(busdbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           const approve = await busdcontractMarket.methods.approve(ContractDetails.Bnb_contract, buyprice1).send({ from: address }).on('transactionHash1', async (hash) => { }).on('receipt1', async (approveresult) => {
                           })
                           const deposit = await bnbcontractMarket.methods.deposit(ContractDetails.Busd_contract, buyprice1).send({ from: address, value: 0 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                              const fetch = await axios.post("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1000000000000000000).toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount.toString()), "status": "completed" })
                              if (fetch.status == true) {
                                 try {
                                    console.log(toamount)
                                    const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                    const buyprice3 = toamount * 1000000;
                                    console.log(buyprice2, buyprice3)

                                    const getvrs = await axios.post("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount.toString()) })
                                    if (getvrs.status == true) {
                                       try {

                                          if (walletType === 'okx') {
                                             if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                await switchNetwork(chainIdMap[Network1])
                                             }
                                          } else {
                                             if (currencysymbol1 == "BNB" && chainId !== 97) {
                                             } else if (currencysymbol1 == "WOR" && chainId !== 97) {
                                             } else if (currencysymbol1 == "ETH" && chainId !== 11155111) {
                                                await switchNetworkAsync(11155111)
                                             } else if (currencysymbol1 == "USDT" && chainId !== 11155111) {
                                                await switchNetworkAsync(11155111)
                                             }else if (currencysymbol1 == "MATIC" && chainId !== 80001) {
                                                await switchNetworkAsync(80001)
                                             }
                                             else if (currencysymbol1 == "BNB" && chainId == 97) {
                                             } else if (currencysymbol1 == "WOR" && chainId == 97) {
                                             } else if (currencysymbol1 == "ETH" && chainId == 11155111) {
                                                await switchNetworkAsync(11155111)
                                             } else if (currencysymbol1 == "USDT" && chainId == 11155111) {
                                                await switchNetworkAsync(11155111)
                                             }else if (currencysymbol1 == "MATIC" && chainId == 80001) {
                                                await switchNetworkAsync(80001)
                                             }
                                          }
                                       }
                                       catch (error) {
                                          console.log(error)
                                          toast.error('User rejected the switch network')
                                          setLoading(false)

                                          return
                                       }


                                       try {
                                          currencysymbol1 == "ETH" ?
                                             claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                const getTransactionReceipt = async (hash) => {
                                                   const result = await web3.eth.getTransactionReceipt(hash)
                                                   if (result === null) {
                                                      getTransactionReceipt(hash)
                                                   } else {
                                                      let data = {
                                                         "fromCurrency": currencysymbol,
                                                         "toCurrency": currencysymbol1,
                                                         "fromAddress": result.to,
                                                         "toAddress": result.from,
                                                         "fromAmount": fromamount.toString(),
                                                         "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                         "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                         "txnHash": result.transactionHash,
                                                         "network": Network1,
                                                         "type": "claim",
                                                         "status": "completed"
                                                      }
                                                      const fetch = await axios.post("bridge/claim", data)
                                                      if (fetch.status == true) {
                                                         toast.success("Transaction completed successfully")
                                                         setCurrencyCoin("Select Currency")
                                                         setCurrencyCoin1("Select Currency")
                                                         setCurrencyimage("")
                                                         setCurrencyimage1("")
                                                         setFromamount("")
                                                         setToamount("")
                                                         setCurrencySymbol("")
                                                         setCurrencySymbol1("")
                                                         setfromCurrencybal("")
                                                         settoCurrencybal("")
                                                         setLoading(false)
                                                         navigate("/bridgehistory")
                                                         return
                                                      }
                                                   }
                                                }
                                                getTransactionReceipt(hash)
                                             }).on('receipt', async (approveresult) => {

                                             })


                                             :
                                             currencysymbol1 == "MATIC" ?
                                                claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await axios.post("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            return
                                                         }
                                                      }
                                                   }
                                                   getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {

                                                })
                                                :
                                                currencysymbol1 == "BNB" ?
                                                   claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await axios.post("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)
                                                   }).on('receipt', async (approveresult) => {

                                                   })
                                                   :
                                                   currencysymbol1 == "USDT" ?
                                                      claim = await contract.methods.claim(buyprice3, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Tether_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                         const getTransactionReceipt = async (hash) => {
                                                            const result = await web3.eth.getTransactionReceipt(hash)
                                                            if (result === null) {
                                                               getTransactionReceipt(hash)
                                                            } else {
                                                               let data = {
                                                                  "fromCurrency": currencysymbol,
                                                                  "toCurrency": currencysymbol1,
                                                                  "fromAddress": result.to,
                                                                  "toAddress": result.from,
                                                                  "fromAmount": fromamount.toString(),
                                                                  "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "txnHash": result.transactionHash,
                                                                  "network": Network1,
                                                                  "type": "claim",
                                                                  "status": "completed"
                                                               }
                                                               const fetch = await axios.post("bridge/claim", data)
                                                               if (fetch.status == true) {
                                                                  toast.success("Transaction completed successfully")
                                                                  setCurrencyCoin("Select Currency")
                                                                  setCurrencyCoin1("Select Currency")
                                                                  setCurrencyimage("")
                                                                  setCurrencyimage1("")
                                                                  setFromamount("")
                                                                  setToamount("")
                                                                  setCurrencySymbol("")
                                                                  setCurrencySymbol1("")
                                                                  setfromCurrencybal("")
                                                                  settoCurrencybal("")
                                                                  setLoading(false)
                                                                  navigate("/bridgehistory")
                                                                  return
                                                               }
                                                            }
                                                         }
                                                         getTransactionReceipt(hash)
                                                      }).on('receipt', async (approveresult) => {

                                                      })
                                                      :
                                                      currencysymbol1 == "WOR" ?
                                                         claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Wor_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                            const getTransactionReceipt = async (hash) => {
                                                               const result = await web3.eth.getTransactionReceipt(hash)
                                                               if (result === null) {
                                                                  getTransactionReceipt(hash)
                                                               } else {
                                                                  let data = {
                                                                     "fromCurrency": currencysymbol,
                                                                     "toCurrency": currencysymbol1,
                                                                     "fromAddress": result.to,
                                                                     "toAddress": result.from,
                                                                     "fromAmount": fromamount.toString(),
                                                                     "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "txnHash": result.transactionHash,
                                                                     "network": Network1,
                                                                     "type": "claim",
                                                                     "status": "completed"
                                                                  }
                                                                  const fetch = await axios.post("bridge/claim", data)
                                                                  if (fetch.status == true) {
                                                                     toast.success("Transaction completed successfully")
                                                                     setCurrencyCoin("Select Currency")
                                                                     setCurrencyCoin1("Select Currency")
                                                                     setCurrencyimage("")
                                                                     setCurrencyimage1("")
                                                                     setFromamount("")
                                                                     setToamount("")
                                                                     setCurrencySymbol("")
                                                                     setCurrencySymbol1("")
                                                                     setfromCurrencybal("")
                                                                     settoCurrencybal("")
                                                                     setLoading(false)
                                                                     navigate("/bridgehistory")
                                                                     return
                                                                  }
                                                               }
                                                            }
                                                            getTransactionReceipt(hash)
                                                         }).on('receipt', async (approveresult) => {

                                                         })
                                                         :
                                                         <></>
                                       }
                                       catch (err) {
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount.toString(),
                                             "toAmount": toamount.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await axios.post("bridge/claim", data)
                                          if (fetch.status == true) {
                                             toast.error("Transaction Rejected")
                                             setLoading(false)
                                          }
                                       }
                                    }
                                    else {
                                       toast.error(getvrs.data)
                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract :
                                             currencysymbol1 == "USDT" ? ContractDetails.Eth_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount.toString(),
                                          "toAmount": toamount.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await axios.post("bridge/claim", data)
                                       if (fetch.status == true) {
                                          // toast.error("Transaction Rejected")
                                          navigate("/bridgehistory")
                                          setLoading(false)
                                       }
                                    }
                                 }
                                 catch (error) {
                                    let data =
                                    {
                                       "fromCurrency": currencysymbol,
                                       "toCurrency": currencysymbol1,
                                       "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract :
                                          currencysymbol1 == "USDT" ? ContractDetails.Eth_contract : currencysymbol1 == "WOR" ? ContractDetails.Bnb_contract : "",
                                       "toAddress": address,
                                       "fromAmount": fromamount.toString(),
                                       "toAmount": toamount.toString(),
                                       "network": Network1,
                                       "adminFee": "0",
                                       "type": "claim",
                                       "status": "cancelled"
                                    }
                                    let fetch = await axios.post("bridge/claim", data)
                                    if (fetch.status == true) {
                                       toast.error("Transaction Rejected")
                                       setLoading(false)
                                    }
                                 }
                              }
                           })
                        }
                        else {
                           toast.error("Insufficient busd balance")
                           setLoading(false)
                        }
                     })
                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "WOR" && currencycointype == "token") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "WOR" &&
                           chainId !== 97
                        ) {
                           console.log('chain 1')
                           await switchNetworkAsync(97)
                        }
                     }
                     const balance = await worcontractMarket.methods.balanceOf(address).call().then(async (bal) => {
                        const worbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(worbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           const approve = await worcontractMarket.methods.approve(ContractDetails.Bnb_contract, buyprice1).send({ from: address }).on('transactionHash1', async (hash) => { }).on('receipt1', async (approveresult) => {
                           })
                           const deposit = await bnbcontractMarket.methods.deposit(ContractDetails.Wor_contract, buyprice1).send({ from: address, value: 0 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                              const fetch = await axios.post("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1000000000000000000).toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount.toString()), "status": "completed" })
                              if (fetch.status == true) {
                                 try {
                                    const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                    const buyprice3 = toamount * 1000000;
                                    const getvrs = await axios.post("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount.toString()) })
                                    if (getvrs.status == true) {
                                       {
                                          
                                          try {
                                             if (walletType === 'okx') {
                                                if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                   await switchNetwork(chainIdMap[Network1])
                                                }
                                             } else {

                                                if (currencysymbol1 == "BNB" && chainId !== 97) {
                                                } else if (currencysymbol1 == "BUSD" && chainId !== 97) {
                                                } 
                                                else if (currencysymbol1 == "ETH" && chainId !== 11155111 ) {
                                                   await switchNetworkAsync(11155111)
                                                } else if (currencysymbol1 == "USDT" && chainId !== 11155111) {
                                                   await switchNetworkAsync(11155111)
                                                }
                                                else if (currencysymbol1 == "MATIC" && chainId !== 80001) {
                                                   await switchNetworkAsync(80001)
                                                }
                                                else if (currencysymbol1 == "BNB" && chainId == 97) {
                                                } else if (currencysymbol1 == "BUSD" && chainId == 97) {
                                                } 
                                                else if (currencysymbol1 == "ETH" && chainId == 11155111 ) {
                                                   await switchNetworkAsync(11155111)
                                                } else if (currencysymbol1 == "USDT" && chainId == 11155111) {
                                                   await switchNetworkAsync(11155111)
                                                }
                                                else if (currencysymbol1 == "MATIC" && chainId == 80001) {
                                                   await switchNetworkAsync(80001)
                                                }
                                             }
                                          }
                                          catch (error) {
                                             console.log(error)
                                             toast.error('User rejected the switch network')
                                             setLoading(false)

                                             return
                                          }
                                          try {
                                             currencysymbol1 == "ETH" ?
                                                claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await axios.post("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            return
                                                         }
                                                      }
                                                   }
                                                   getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {

                                                })


                                                :
                                                currencysymbol1 == "MATIC" ?
                                                   claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await axios.post("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)
                                                   }).on('receipt', async (approveresult) => {

                                                   })
                                                   :
                                                   currencysymbol1 == "BNB" ?
                                                      claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                         const getTransactionReceipt = async (hash) => {
                                                            const result = await web3.eth.getTransactionReceipt(hash)
                                                            if (result === null) {
                                                               getTransactionReceipt(hash)
                                                            } else {
                                                               let data = {
                                                                  "fromCurrency": currencysymbol,
                                                                  "toCurrency": currencysymbol1,
                                                                  "fromAddress": result.to,
                                                                  "toAddress": result.from,
                                                                  "fromAmount": fromamount.toString(),
                                                                  "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                  "txnHash": result.transactionHash,
                                                                  "network": Network1,
                                                                  "type": "claim",
                                                                  "status": "completed"
                                                               }
                                                               const fetch = await axios.post("bridge/claim", data)
                                                               if (fetch.status == true) {
                                                                  toast.success("Transaction completed successfully")
                                                                  setCurrencyCoin("Select Currency")
                                                                  setCurrencyCoin1("Select Currency")
                                                                  setCurrencyimage("")
                                                                  setCurrencyimage1("")
                                                                  setFromamount("")
                                                                  setToamount("")
                                                                  setCurrencySymbol("")
                                                                  setCurrencySymbol1("")
                                                                  setfromCurrencybal("")
                                                                  settoCurrencybal("")
                                                                  setLoading(false)
                                                                  navigate("/bridgehistory")
                                                                  return
                                                               }
                                                            }
                                                         }
                                                         getTransactionReceipt(hash)
                                                      }).on('receipt', async (approveresult) => {

                                                      })
                                                      :
                                                      currencysymbol1 == "USDT" ?
                                                         claim = await contract.methods.claim(buyprice3, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Tether_contract).send({ from: address }).on('transactionHash', async (hash) => {
                                                            const getTransactionReceipt = async (hash) => {
                                                               const result = await web3.eth.getTransactionReceipt(hash)
                                                               if (result === null) {
                                                                  getTransactionReceipt(hash)
                                                               } else {
                                                                  let data = {
                                                                     "fromCurrency": currencysymbol,
                                                                     "toCurrency": currencysymbol1,
                                                                     "fromAddress": result.to,
                                                                     "toAddress": result.from,
                                                                     "fromAmount": fromamount.toString(),
                                                                     "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                     "txnHash": result.transactionHash,
                                                                     "network": Network1,
                                                                     "type": "claim",
                                                                     "status": "completed"
                                                                  }
                                                                  const fetch = await axios.post("bridge/claim", data)
                                                                  if (fetch.status == true) {
                                                                     toast.success("Transaction completed successfully")
                                                                     setCurrencyCoin("Select Currency")
                                                                     setCurrencyCoin1("Select Currency")
                                                                     setCurrencyimage("")
                                                                     setCurrencyimage1("")
                                                                     setFromamount("")
                                                                     setToamount("")
                                                                     setCurrencySymbol("")
                                                                     setCurrencySymbol1("")
                                                                     setfromCurrencybal("")
                                                                     settoCurrencybal("")
                                                                     setLoading(false)
                                                                     navigate("/bridgehistory")
                                                                     return
                                                                  }
                                                               }
                                                            }
                                                            getTransactionReceipt(hash)
                                                         }).on('receipt', async (approveresult) => {

                                                         })
                                                         :
                                                         currencysymbol1 == "BUSD" ?
                                                            claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, ContractDetails.Busd_contract).send({ from: address }).on('transactionHash', async (hash) => {

                                                               const getTransactionReceipt = async (hash) => {
                                                                  const result = await web3.eth.getTransactionReceipt(hash)
                                                                  if (result === null) {
                                                                     getTransactionReceipt(hash)
                                                                  } else {
                                                                     let data = {
                                                                        "fromCurrency": currencysymbol,
                                                                        "toCurrency": currencysymbol1,
                                                                        "fromAddress": result.to,
                                                                        "toAddress": result.from,
                                                                        "fromAmount": fromamount.toString(),
                                                                        "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100)).toString(),
                                                                        "txnHash": result.transactionHash,
                                                                        "network": Network1,
                                                                        "type": "claim",
                                                                        "status": "completed"
                                                                     }
                                                                     const fetch = await axios.post("bridge/claim", data)
                                                                     if (fetch.status == true) {
                                                                        toast.success("Transaction completed successfully")
                                                                        setCurrencyCoin("Select Currency")
                                                                        setCurrencyCoin1("Select Currency")
                                                                        setCurrencyimage("")
                                                                        setCurrencyimage1("")
                                                                        setFromamount("")
                                                                        setToamount("")
                                                                        setCurrencySymbol("")
                                                                        setCurrencySymbol1("")
                                                                        setfromCurrencybal("")
                                                                        settoCurrencybal("")
                                                                        setLoading(false)
                                                                        navigate("/bridgehistory")
                                                                        return
                                                                     }
                                                                  }
                                                               }
                                                               getTransactionReceipt(hash)
                                                            }).on('receipt', async (approveresult) => {

                                                            })
                                                            :
                                                            <></>
                                          }
                                          catch (err) {
                                             let data =
                                             {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract : currencysymbol1 == "USDT" ? ContractDetails.Eth_contract :
                                                   currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : "",
                                                "toAddress": address,
                                                "fromAmount": fromamount.toString(),
                                                "toAmount": toamount.toString(),
                                                "network": Network1,
                                                "adminFee": "0",
                                                "type": "claim",
                                                "status": "cancelled"
                                             }
                                             let fetch = await axios.post("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.error("Transaction Rejected")
                                                setLoading(false)
                                             }
                                          }
                                       }
                                    }
                                    else {
                                       toast.error(getvrs.data)
                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract :
                                             currencysymbol1 == "USDT" ? ContractDetails.Eth_contract : currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount.toString(),
                                          "toAmount": toamount.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await axios.post("bridge/claim", data)
                                       if (fetch.status == true) {
                                          // toast.error("Transaction Rejected")
                                          navigate("/bridgehistory")
                                          setLoading(false)
                                       }
                                    }
                                 }
                                 catch (error) {
                                    let data =
                                    {
                                       "fromCurrency": currencysymbol,
                                       "toCurrency": currencysymbol1,
                                       "fromAddress": currencysymbol1 == "BNB" ? ContractDetails.Bnb_contract : currencysymbol1 == "ETH" ? ContractDetails.Eth_contract : currencysymbol1 == "MATIC" ? ContractDetails.Polygon_contract :
                                          currencysymbol1 == "USDT" ? ContractDetails.Eth_contract : currencysymbol1 == "BUSD" ? ContractDetails.Bnb_contract : "",
                                       "toAddress": address,
                                       "fromAmount": fromamount.toString(),
                                       "toAmount": toamount.toString(),
                                       "network": Network1,
                                       "adminFee": "0",
                                       "type": "claim",
                                       "status": "cancelled"
                                    }
                                    let fetch = await axios.post("bridge/claim", data)
                                    if (fetch.status == true) {
                                       toast.error("Transaction Rejected")
                                       setLoading(false)
                                    }
                                 }
                              }
                           })
                        }
                        else {
                           toast.error("Insufficient wor token balance")
                           setLoading(false)
                        }
                     })
                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
            }
            else {
               toast.error(balancecheck.message)
               setLoading(false)
            }
         }
      }
      else {
         toast.error("Please Connectwallet")
         setLoading(false)
      }
   }

   const Exchange = async () => {

      setCurrencyCoin(currencycoin1)
      setCurrencyCoinType(currencycointype1)
      setCurrencyCoin1(currencycoin)
      setCurrencyCoinType1(currencycointype)
      setFromamount(toamount)
      setCurrencySymbol(currencysymbol1)
      setCurrencySymbol1(currencysymbol)
      setCurrencyimage(currencyimage1)
      setCurrencyimage1(currencyimage)
      setNetwork(Network1)
      setNetwork1(Network)
      setfromCurrencybal(tocurrencybal)
      settoCurrencybal(fromcurrencybal)
      const fetch = await axios.post("admin/nft/get_single_pair", { "fromCurrency": currencysymbol1, "toCurrency": currencysymbol, "fromAmount": toamount })
      if (fetch.status == true) {
         setToamount(fetch.usdPrice)
         setUsergetBal((Number(fetch.usdPrice) - ((feecurrency * +fetch.usdPrice) / 100)))
         setAdminFeeAmount((formatAmount((feecurrency * +fetch.usdPrice) / 100)))
      }
      if (minamount && toamount && currencysymbol1) {

         if (minamount > Number(toamount)) {
            document.getElementById('fromamount1').innerHTML = 'Your Min Amount is ' + minamount
         }
         else if (maxamount < Number(toamount)) {
            document.getElementById('fromamount1').innerHTML = 'Your Max Amount is ' + maxamount
         }
         else {
            document.getElementById('fromamount1').innerHTML = ''
         }
      }
   }

   const fromsearchItems = async (searchValue) => {
      const search = await axios.get(`currencies?filter=${searchValue}`)
      if (search.status == true) {
         setCurrencies(search.data)
      }
   }
   const tosearchItems = async (searchValue) => {
      const search = await axios.get(`currencies?filter=${searchValue}`)
      if (search.status == true) {
         setCurrencies(search.data)
      }
   }

   return (
      <>
         <div className="withoutBannerHeaderPage">
            <div className={loading ? "spinner" : "d-none"}>
               <img src={loader} alt='' className='spinAnimate' />
            </div>
            {/* <Header /> */}

            <section class="BriSec inrPgMt inrPgMb">
               <div class="container">
                  <div class="brhsd text-center">
                     <h1>Bridge</h1>

                  </div>
                  <div class="row justify-content-center">
                     <div class="col-xl-6 col-lg-8">
                        <div class="crdbrg">
                           <div class="FrmIpt">

                              <div className="d-flex justify-content-between">
                                 <h1>You Send</h1>
                                 {currencysymbol ?
                                    <h1>Balance {fromcurrencybal ? fromcurrencybal.toFixed(4) : 0} {currencysymbol ? currencysymbol : ""}</h1>
                                    :
                                    ""
                                 }
                              </div>
                              <div class="d-flex justify-content-center justify-content-between">
                                 <div class="form-group">
                                    <NumericFormat class="form-control" name="fromamount" value={fromamount} onChange={getAmount} allowNegative={false} customInput={Input} />
                                    <span id='fromamount1' style={{ color: "red" }}></span>
                                 </div>

                                 <div>
                                    <button class="btn sltbtn" onClick={toggle}>
                                       {currencyimage ?
                                          <span><img src={currencyimage} style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt="" /></span>
                                          :
                                          ""
                                       }
                                       <span>
                                          {currencysymbol ? currencysymbol : "Select"}
                                       </span>
                                       <span>
                                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                             <path d="M1 1L5 5L9 1" stroke="white" />
                                          </svg>
                                       </span>
                                    </button>
                                    <span id='fromcurrency1' style={{ color: "red" }}></span>
                                 </div>
                              </div>
                           </div>
                           <div class="text-right my-4" >
                              <a href="#">
                                 <img src={swap} alt="swap" onClick={Exchange} />
                              </a>
                           </div>
                           <div class="FrmIpt mb-35">

                              <div className="d-flex justify-content-between">
                                 <h1>You Get</h1>
                                 {currencysymbol1 ?
                                    <h1>Balance {tocurrencybal ? tocurrencybal.toFixed(4) : 0} {currencysymbol1 ? currencysymbol1 : ""}</h1>
                                    :
                                    ""
                                 }
                              </div>
                              <div class="d-flex justify-content-center justify-content-between">
                                 <div class="form-group">
                                    <NumericFormat type="number" min="0" class="form-control" name="toamount" value={toamount} allowNegative={false} customInput={Input} disabled style={{ background: "#2E343D" }} />
                                    {/* <span id='toamount1' style={{ color: "red" }}></span> */}
                                 </div>

                                 <div>
                                    <button class="btn sltbtn" onClick={toggle1}>
                                       <span>
                                          {currencyimage1 ?
                                             <img src={currencyimage1} style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                                             :
                                             ""
                                          }</span>
                                       <span>
                                          {currencysymbol1 ? currencysymbol1 : "Select"}
                                       </span>
                                       <span>
                                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                             <path d="M1 1L5 5L9 1" stroke="white" />
                                          </svg>
                                       </span>
                                    </button>
                                    <span id='tocurrency1' style={{ color: "red" }}></span>

                                 </div>
                              </div>
                              {currencysymbol1 ?
                                 <div style={{ justifyContent: "center", display: "flex" }}>
                                    <span style={{ color: "red" }}>Admin Fee :{feecurrency}%</span>
                                 </div>

                                 :
                                 ""
                              }
                              {toamount && currencysymbol1 && currencysymbol && fromamount ?
                                 <div style={{ justifyContent: "space-between", display: "flex" }}>
                                    <span style={{ color: "#ffa900" }}>Net Amount : {Number(usetgetBal && usetgetBal).toFixed(8)}</span>
                                    <span style={{ color: "#ffa900" }}>Admin Fee : {Number(adminfeeamount && adminfeeamount).toFixed(8)}</span>

                                 </div>
                                 :
                                 ""
                              }

                           </div>
                           {fromcurrencybal == 0 || toamount == 0 || tocurrencybal == 0 ?

                              <button class="btn swpbtn" disabled> Swap</button>
                              :
                              <button class="btn swpbtn" onClick={Buy}> Swap</button>
                           }

                        </div>
                     </div>
                  </div>
               </div>
               <div class="shdcd">
                  <img class="img-fluid" src={elprd} alt="elprd" />
               </div>
            </section>


            <Modal isOpen={modal} toggle={toggle} centered="true" className="curMdl" backdropClassName="selCurBp">
               <div className="selCrHd">
                  <button className="btn">
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                     </svg>
                  </button>
                  <span>Select Currency</span>
                  <button className="btn" onClick={toggle}>
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                     </svg>
                  </button>
               </div>
               <div className="selCrSrh">
                  <InputGroup className="curSrh">
                     <button className="btn" type="button">
                        <img src={searchIcon} alt="search" />
                     </button>
                     <Input placeholder="Search" name="searchInput" onChange={(e) => fromsearchItems(e.target.value)} />
                  </InputGroup>
               </div>
               <div className="selCrLs">
                  {currencies && currencies.length > 0 ?
                     currencies && currencies.filter(item => item.currencySymbol !== currencysymbol1).map((user, index) => (
                        <button type="button" className="btn" onClick={() => getCurrency(user)}>
                           <img src={user.currencyImage} style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt="eth" />
                           <span>{user.currencyName}({user.currencySymbol})</span>
                        </button>
                     ))
                     :
                     <div className="not-found text-center">
                        <label>No Data Found</label>
                     </div>
                  }
               </div>
            </Modal>

            <Modal isOpen={modal1} toggle={toggle1} centered="true" className="curMdl" backdropClassName="selCurBp">
               <div className="selCrHd">
                  <button className="btn">
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                     </svg>
                  </button>
                  <span>Select Currency</span>
                  <button className="btn" onClick={toggle1}>
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                     </svg>
                  </button>
               </div>
               <div className="selCrSrh">
                  <InputGroup className="curSrh">
                     <button className="btn" type="button">
                        <img src={searchIcon} alt="search" />
                     </button>
                     <Input placeholder="Search" onChange={(e) => tosearchItems(e.target.value)} />
                  </InputGroup>
               </div>
               <div className="selCrLs">
                  {currencies && currencies.length > 0 ?
                     currencies && currencies.filter(item => item.currencySymbol !== currencysymbol).map((user, index) => (
                        <button type="button" className="btn" onClick={() => getCurrency1(user)}>
                           <img src={user.currencyImage} style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt="eth" />
                           <span>{user.currencyName}({user.currencySymbol})</span>
                        </button>
                     ))
                     :
                     <div className="not-found text-center">
                        <label>No Data Found</label>
                     </div>
                  }
               </div>
            </Modal>
         </div>
      </>
   )
}
export default Bridge;