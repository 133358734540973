import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Filters } from './components/Filters'
import { ItemsViewTabs } from './components/ItemsViewTabs'
import { fetchCollectionActivity } from './apis/fetchCollectionActivity'
import { NftCard } from './components/NftCard'
import { useFetchCollectionTraits } from './apis/fetchCollectionTraits'
import { NftList } from './components/NftList'
import CollectionActivity from './components/CollectionActivity'
import { useDebounce } from '../../utils/useDebounce'

import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import listIcon from '../../assets/images/collection/listIcon.svg';
import cart from '../../assets/images/cart.png'
import clock from '../../assets/images/clock.svg'
import offerIcon from '../../assets/images/collection/offerIcon.svg'
import transferIcon from '../../assets/images/transferIcon.svg'
import collectionOfferIcon from '../../assets/images/collection/collectionOfferIcon.svg'

import { parseQueryStringToObject } from '../../utils/parseQueryStringToObject'
import { convertObjectToQueryString } from '../../utils/convertObjectToQueryString'
import { calculateTimeSinceGivenDate } from '../../utils/formatRoundTime'
import { formatAddress } from '../../utils/formatAddress'

export function NoItemsFound({ onClick }) {
    return (
        <div className='noItemFound'>
            <div className='text-center'>
                <img
                    src={noItemfoundImage}
                    alt='noItemfoundImage'
                    className='img-fluid'
                />
            </div>
            <div className='nofounttxt mb-4'>
                No items were found. Try to broaden your search.
            </div>
            <div>
                <button className='applyBtn' onClick={onClick}>
                    Back to all items
                </button>
            </div>
        </div>
    )
}

const flattenFilters = (filters, parentKey = '') => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        const currentKey = parentKey ? `${parentKey}[${key}]` : key

        if (typeof value === 'object' && value !== null) {
            return { ...acc, ...flattenFilters(value, currentKey) }
        } else {
            return { ...acc, [currentKey]: value }
        }
    }, {})
}

export function Activity(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [filters, setFilters] = useState(
        parseQueryStringToObject(searchParams)
    )

    const handleFiltersChange = (filters) => {
        setFilters(filters)
        const flattenedFilters = flattenFilters(filters)
        const queryString = convertObjectToQueryString(flattenedFilters)
        setSearchParams(queryString)
    }

    const [activeTab, setActiveTab] = useState('grid')
    const [showFilters, setShowFilters] = useState(true)
    const [noOfItems, setNoOfItems] = useState(0)

    const navigate = useNavigate()


    const { data: collectionActivityList, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery({
            queryKey: ['activity', props.collectionAddress, searchParams.toString(), filters],
            queryFn: ({ pageParam = 1 }) =>
                fetchCollectionActivity(props.collectionAddress, filters, pageParam, (noOfItems) => {
                    setNoOfItems(noOfItems)
                }),
            getNextPageParam: (lastPage, allPages) => {
                return lastPage?.length ? allPages?.length + 1 : undefined
            },
            enabled: !!props.collectionAddress
        })

    const { data: traitsData } = useFetchCollectionTraits(props.collectionAddress)

    const intersectionObserverRef = useRef()
    const lastNftRef = useCallback(
        (nft) => {
            if (isFetchingNextPage) {
                return
            }

            if (intersectionObserverRef.current) {
                intersectionObserverRef.current.disconnect()
            }

            intersectionObserverRef.current = new IntersectionObserver(
                (nfts) => {
                    if (
                        nfts[0].isIntersecting &&
                        hasNextPage &&
                        !isFetchingNextPage
                    ) {
                        fetchNextPage()
                    }
                }
            )

            if (nft) {
                intersectionObserverRef.current.observe(nft)
            }
        },
        [isFetchingNextPage, hasNextPage, fetchNextPage]
    )

    const nftCards = collectionActivityList?.pages.map((page) => {
        return page?.map((item, i) => {
            const usdPrice = item.price * item.usdPrice
            if (page?.length === i + 1) {
                return (
                    <tr ref={lastNftRef}>
                        <td>
                            <button className='btnBuynow'>
                                <img src={item.type === 'list' ? listIcon : item.type === 'sale' ? cart : item.type === 'make-collection-offer' ? collectionOfferIcon : item.type === 'make-offer' ? offerIcon : cart} alt='offerIcon' />
                                {item.type === 'make-offer' ? 'Offer' : item.type === 'make-collection-offer' ? 'Collection Offer' : item.type}
                            </button>
                        </td>
                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='tblImg'><img src={item.asset} alt={`item.imgsrc`} /></div>
                                <div className='fw-600'>{item.name}</div>
                            </div>
                        </td>
                        <td className='text-right'>
                            <div>{parseFloat(item.price?.toFixed(6))} {item.currency}</div>
                            <div className='fs-12 dark-text-secondary'>${usdPrice?.toFixed(2)}</div>
                        </td>
                        {/* <td><span className="dark-text-grey">--</span></td> */}
                        <td className='text-right'>{item.quantity}</td>
                        <td className='text-right'><span className="dark-primary ellipsisTxt">{[null].includes(item.from) ? '-' : item.from === '' ? formatAddress(item.fromAddress, 3) : item.from}</span></td>
                        <td className='text-right dark-text-grey'><span className="dark-primary ellipsisTxt">{[null].includes(item.to) ? '-' : item.to === '' ? formatAddress(item.toAddress, 3) : item.to}</span></td>
                        <td><span className="dark-text-secondary">{calculateTimeSinceGivenDate(item.createdAt)}</span></td>
                    </tr>
                )
            }
            return (
                <tr>
                    <td>
                        <button className='btnBuynow'>
                            <img src={item.type === 'list' ? listIcon : item.type === 'sale' ? cart : item.type === 'make-collection-offer' ? collectionOfferIcon : item.type === 'make-offer' ? offerIcon : cart} alt='offerIcon' />
                            {item.type === 'make-offer' ? 'Offer' : item.type === 'make-collection-offer' ? 'Collection Offer' : item.type}
                        </button>
                    </td>
                    <td>
                        <div className='d-flex align-items-center'>
                            <div className='tblImg'><img src={item.asset} alt={`item.imgsrc`} /></div>
                            <div className='fw-600'>{item.name}</div>
                        </div>
                    </td>
                    <td className='text-right'>
                        <div>{parseFloat(item.price?.toFixed(6))} {item.currency}</div>
                        <div className='fs-12 dark-text-secondary'>${usdPrice?.toFixed(2)}</div>
                    </td>
                    {/* <td><span className="dark-text-grey">--</span></td> */}
                    <td className='text-right'>{item.quantity}</td>
                    <td className='text-right'><span className="dark-primary ellipsisTxt">{[null].includes(item.from) ? '-' : item.from === '' ? formatAddress(item.fromAddress, 3) : item.from}</span></td>
                    <td className='text-right dark-text-grey'><span className="dark-primary ellipsisTxt">{[null].includes(item.to) ? '-' : item.to === '' ? formatAddress(item.toAddress, 3) : item.to}</span></td>
                    <td><span className="dark-text-secondary">{calculateTimeSinceGivenDate(item.createdAt)}</span></td>
                </tr>
            )
        })
    })

    const hasNoData = nftCards?.[0]?.length === 0

    return (
        <Filters
            itemsViewTabsContent={
                <CollectionActivity
                    activeTab={activeTab}
                    showFilters={showFilters}
                    collectionDetails={props.collectionDetails}
                    noOfItems={noOfItems}
                    nfts={nftCards}
                    hasNoData={hasNoData}
                    filters={filters}
                    handleFiltersChange={handleFiltersChange}
                    collectionActivityList={collectionActivityList}
                    ref={lastNftRef}
                />
            }
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            traits={traitsData}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
            collectionDetails={props.collectionDetails}
            tab={props.tab}
        >
            {props.tab === '3' ? null :
                <ItemsViewTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                ></ItemsViewTabs>
            }
        </Filters>
    )
}
