import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap'

import { CheckIcon } from '../../../components/CheckIcon'

const sortableFilters = [
	{ label: 'Price_Low_High' },
	{ label: 'Price_High_Low' },
	{ label: 'Recently Listed' },
	{ label: 'Recently Created' },
	{ label: 'Recently Sold' },
	{ label: 'Most Viewed' },
	{ label: 'Most Favorited' },
	{ label: 'Ending Soon' },
	{ label: 'Highest Bid' },
	{ label: 'Highest Last Sale' },
	{ label: 'Recently Received' },
	{ label: 'Oldest' }
]

const filterValueMap = {
	['Recently Listed']: 'LISTING_DATE',
	['Recently Created']: 'CREATED_DATE',
	['Recently Sold']: 'LAST_SALE_DATE',
	['Most Viewed']: 'VIEWER_COUNT',
	['Most Favorited']: 'FAVORITE_COUNT',
	['Ending Soon']: 'EXPIRATION_DATE',
	['Highest Bid']: 'BEST_BID',
	['Highest Last Sale']: 'LAST_SALE_PRICE',
	['Recently Received']: 'LAST_TRANSFER_DATE',
	['Oldest']: 'CREATED_DATE'
}

const filterKeyMap = {
	LISTING_DATE: 'Recently Listed',
	CREATED_DATE: 'Recently Created',
	LAST_SALE_DATE: 'Recently Sold',
	VIEWER_COUNT: 'Most Viewed',
	FAVORITE_COUNT: 'Most Favorited',
	EXPIRATION_DATE: 'Ending Soon',
	BEST_BID: 'Highest Bid',
	LAST_SALE_PRICE: 'Highest Last Sale',
	LAST_TRANSFER_DATE: 'Recently Received',
	CREATED_DATE: 'Oldest'
}

export function FiltersDropdown({ filters, handleFiltersChange }) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [sort, setSort] = useState(
		filters.search.sortBy
			? filterKeyMap[filters.search.sortBy]
			: filters.search.sortAscending === 'true'
			? 'Price_Low_High'
			: 'Price_High_Low'
	)

	const handleFilterClick = (filter) => {
		setSort(filter.label)

		let updatedFilters = { ...filters }

		switch (filter.label) {
			case 'Price_High_Low':
				updatedFilters.search.sortAscending = 'false'
				updatedFilters.search.sortBy = null
				break

			case 'Price_Low_High':
				updatedFilters.search.sortAscending = 'true'
				updatedFilters.search.sortBy = null
				break

			case 'Recently Listed':
				updatedFilters.search.sortAscending = 'false'
				updatedFilters.search.sortBy = filterValueMap[filter.label]
				break

			case 'Ending Soon':
				updatedFilters.search.sortAscending = 'true'
				updatedFilters.search.sortBy = filterValueMap[filter.label]
				break

			case 'Oldest':
				updatedFilters.search.sortAscending = 'true'
				updatedFilters.search.sortBy = filterValueMap[filter.label]
				break

			default:
				updatedFilters.search.sortAscending = 'false'
				updatedFilters.search.sortBy = filterValueMap[filter.label]
				break
		}
		handleFiltersChange(updatedFilters)
	}

	return (
		<div className='form-group formInputs mr-3 mb-2'>
			<Dropdown
				isOpen={isOpen}
				toggle={() => setIsOpen(!isOpen)}
				className='customdropdown'
			>
				<DropdownToggle>
					<span>{t(sort)}</span>{' '}
					<i
						className={`fas ${
							isOpen ? 'fa-angle-up' : 'fa-angle-down'
						}`}
					></i>
				</DropdownToggle>
				<DropdownMenu>
					{sortableFilters.map((filter, i) => (
						<DropdownItem
							key={i}
							onClick={() => handleFilterClick(filter)}
						>
							<div>{t(filter.label)}</div>
							{sort.includes(filter.label) && (
								<div>
									<CheckIcon />
								</div>
							)}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}
