import React from 'react'
import { CloseIcon } from '../makeOffer/CloseIcon'
import { useReportModalActions, useReportModalState } from './store'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap'
import { useAccount } from 'wagmi'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useToast } from '../../../utils/toast/customSuccess'
import { axios } from '../../../lib/axios'

const ReportModal = () => {
    const { t } = useTranslation()
    const { address, isConnected } = useAccount()
    const toast = useToast()
    const location = useLocation()
    const url = location.pathname;
    const getRoute = url.split('/');

    const { isOpen, asset } = useReportModalState()
    const { toggleReportModal } = useReportModalActions()

    const schema = yup.object().shape({
        reason: yup
            .string()
            .required('Required')
            .min(3, 'Reason should be atleast 3 character'),

    })

    const {
        register,
        control,
        handleSubmit,
        formState,
        reset
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema)
    })

    const { isValid, isDirty, errors } = formState

    const onSubmit = async data => {
        let detail = {
            "nftId": asset?.details?.nftId.toString(),
            "contractAddress": asset?.details?.contractAddress,
            "reason": data.reason,
            "type": "nft",
            "address": address
        }
        let collectionDetail = {
            "collectionName": asset?.collectionName,
            "contractAddress": asset?.contractAddress,
            "reason": data.reason,
            "type": "collection",
            "address": address
        }
        const res = await axios.post("/user/report_data", getRoute[1] === 'collection' ? collectionDetail : detail)
        if (res.status == true) {
            reset()
            toast.success(`${getRoute[1] === 'collection' ? asset?.collectionName : asset?.details?.name} has been reported.`)
            toggleReportModal()
        } else {
            reset()
            toast.error(res.message)
            toggleReportModal()
        }

    }

    return (
        <Modal
            isOpen={isOpen}
            centered='true'
            className='mymodal'
            backdropClassName='selCurBp'
        >
            <ModalHeader toggle={toggleReportModal}
                close={<CloseIcon onClose={toggleReportModal} />}>
                {t("Report")}
            </ModalHeader>
            <ModalBody className='pt-0'>
                <p className='dark-text-secondary text-center'>
                    Please, describe the reason.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <span className='formLabel'>Reason</span>

                    <textarea
                        {...register('reason')}
                        className={`form-control ${errors.reason && 'err'}`}
                        placeholder='Enter Something...'
                        style={{color:"white"}}
                    ></textarea>
                    {errors.reason && (
                        <label className='text-danger errLabel ml-auto'>
                            {errors.reason.message}
                        </label>
                    )}
                    <div className='mt-4'>
                        <button type='submit' className='btn btn-block darkBtn mb-2 text-danger'>
                            {t("Report")}
                        </button>
                        <button type='button' className='btn btn-block darkBtn mb-2' onClick={toggleReportModal}>Cancel</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default ReportModal
