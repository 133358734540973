import { toast as reactHotToast } from 'react-hot-toast'
import toastImgIcon from '../../assets/images/checkIcon.svg'
import closeToastIcon from '../../assets/images/closeToast.svg'
import removeIcon from '../../assets/images/removeIcon.svg'
import star from '../../assets/images/star.png'


export const useToast = () => {
	return {
		success: (message) => {
			reactHotToast((t) => (
				<div className='alertToast show'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex'>
							<span>
								<img src={toastImgIcon} alt='toastIcon' />
							</span>
							<span>{message}</span>
						</div>
						<div>
							<button
								className='closeToastBtn'
								onClick={() => reactHotToast.dismiss(t.id)}
							>
								<img
									src={closeToastIcon}
									alt='closeToastIcon'
								/>
							</button>
						</div>
					</div>
				</div>
			))
		},
		error: (message) => {
			reactHotToast((t) => (
				<div className='alertToast show'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex'>
							<span>
								<img src={removeIcon} alt='toastIcon' />
							</span>
							<span>{message}</span>
						</div>
						<div>
							<button
								className='closeToastBtn'
								onClick={() => reactHotToast.dismiss(t.id)}
							>
								<img
									src={closeToastIcon}
									alt='closeToastIcon'
								/>
							</button>
						</div>
					</div>
				</div>
			))
		},
		star: (message) =>
			reactHotToast((t) => (
				<div className='alertToast show'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='d-flex'>
							<span>
								<img src={star} alt='toastIcon'/>
							</span>
							<span>{message}</span>
						</div>
						<div className='mb-3'>
							<button
								className='closeToastBtn'
								onClick={() => reactHotToast.dismiss(t.id)}
							>
								<img
									src={closeToastIcon}
									alt='closeToastIcon'
								/>
							</button>
						</div>
					</div>
				</div>
			))
	}
}
