import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'
import { flattenFilters } from '../../../utils/flattenFilters'

export async function fetchCollectionOwnerList(collectionAddress, pageParam) {
	const url = new URL(BACKEND_URL + 'collection/owners_list')
	url.searchParams.set('contractAddress', collectionAddress)
	url.searchParams.set('page', pageParam)

	const response = await axios.get(url)
	return response.data
}

export function useFetchCollectionOwnerList(collectionAddress) {
	return useQuery({
		queryKey: ['nfts', collectionAddress],
		queryFn: () => fetchCollectionOwnerList(collectionAddress),
		enabled: !!collectionAddress
	})
}

export const fetchDistributionList = ({ contractAddress }) => {
	return axios.get(BACKEND_URL + `collection/owners_distribution?contractAddress=${contractAddress}&page=1`)

}

export const useFetchDistributionList = ({ contractAddress }) => {
	return useQuery({
		queryKey: ['distribution_list', contractAddress],
		queryFn: () => fetchDistributionList({ contractAddress }),
		enabled: !!contractAddress

	})

}