import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import okxWeb3 from '@okwallet/extension';


let web3;
var check = false;

export const ChangeNetwork = {
    

    Ethnetwork: async (wallet) => {

        // const toast = useToast()
        if (typeof window.web3 !== "undefined") {
            try {
                await wallet.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0xAA36A7' }],
                }).then(() => {
                    const sucMsg = {
                        status: true,
                        message: "Correct Network",
                    };
                    // resolve (sucMsg);Please
                })
            } catch (switchError) {
                if (switchError.code === 4902) {

                    try {
                        await wallet.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: "0xAA36A7",
                                    chainName: "ETH",
                                    blockExplorerUrls: ["https://sepolia.etherscan.io"],
                                    nativeCurrency: {
                                        name: "SepoliaEth",
                                        decimals: 18,
                                        symbol: "SepoliaEth",
                                    },
                                    rpcUrls: ["https://chain-proxy.wallet.coinbase.com?targetName=ethereum-sepolia"],
                                },
                            ],
                        }).then(() => {
                        });
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await wallet.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: "0xAA36A7",
                                            chainName: "ETH",
                                            blockExplorerUrls: ["https://sepolia.etherscan.io/"],
                                            nativeCurrency: {
                                                name: "SepoliaEth",
                                                decimals: 18,
                                                symbol: "SepoliaEth",
                                            },
                                            rpcUrls: ["https://chain-proxy.wallet.coinbase.com?targetName=ethereum-sepolia"],
                                        },
                                    ],
                                }).then(() => {
                                });
                            } catch (addError) {
                            }
                        }
                    }
                }
            }
        }
    },
    Bnbnetwork: async (wallet) => {

        if (typeof window.web3 !== "undefined") {

            try {

                await wallet.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x61' }],
                }).then(() => {

                    const sucMsg = {
                        status: true,
                        message: "Correct Network",
                    };
                    // resolve (sucMsg);
                })
            } catch (switchError) {

                if (switchError.code === 4902) {
                    try {
                        await wallet.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: "0x61",
                                    chainName: "Smart Chain - Testnet",
                                    blockExplorerUrls: ["https://testnet.bscscan.com"],
                                    nativeCurrency: {
                                        name: "BTA Testnet",
                                        decimals: 18,
                                        symbol: "BNB",
                                    },
                                    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                                },
                            ],
                        }).then((ss, addError) => {
                        });

                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await wallet.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: "0x61",
                                            chainName: "Smart Chain - Testnet",
                                            blockExplorerUrls: ["https://testnet.bscscan.com"],
                                            nativeCurrency: {
                                                name: "BTA Testnet",
                                                decimals: 18,
                                                symbol: "BNB",
                                            },
                                            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                                        },
                                    ],
                                }).then((ss, addError) => {
                                    return switchError
                                });
                            } catch (addError) {
                                return switchError
                            }
                        }
                        else {
                            return switchError
                        }
                    }
                }
                else {
                    return switchError

                }
            }

        }
    },
    Polygonnetwork: async (wallet) => {

        if (typeof window.web3 !== "undefined") {
            try {

                await wallet.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x13881' }],
                }).then(() => {
                    const sucMsg = {
                        status: true,
                        message: "Correct Network",
                    };
                    // resolve (sucMsg);
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    try {
                        await wallet.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: "0x13881",
                                    chainName: "Polygon Mumbai Testnet",
                                    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
                                    nativeCurrency: {
                                        name: "Polygon Mumbai Testnet",
                                        decimals: 18,
                                        symbol: "Polygon Mumbai Testnet",
                                    },
                                    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                                },
                            ],
                        }).then(() => {
                        });

                    } catch (switchError) {

                        if (switchError.code === 4902) {
                            try {
                                await wallet.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: "0x13881",
                                            chainName: "Polygon Mumbai Testnet",
                                            blockExplorerUrls: ["https://mumbai.polygonscan.com"],
                                            nativeCurrency: {
                                                name: "Polygon Mumbai Testnet",
                                                decimals: 18,
                                                symbol: "Polygon Mumbai Testnet",
                                            },
                                            rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                                        },
                                    ],
                                }).then(() => {
                                });
                            } catch (addError) {
                            }
                        }
                    }
                }
            }

        }
    },

    OexEthnetwork: async () => {
        try {
            await window.okxwallet.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xAA36A7' }],
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to OKX.
            if (switchError.code === 4902) {
                try {
                    await window.okxwallet.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0xAA36A7',
                                chainName: "ETH",
                                rpcUrls: ["https://eth-sepolia.g.alchemy.com/v2/[YOUR-API-KEY]"] /* ... */,
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
    },

    OexBscnetwork: async () => {
        try {
            await window.okxwallet.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x61' }],
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to OKX.
            if (switchError.code === 4902) {
                try {
                    await window.okxwallet.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x61',
                                chainName: "Bsc",
                                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"] /* ... */,
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
    },

    Oexpolygonnetwork: async () => {
        try {
            await window.okxwallet.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x13881' }],
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to OKX.
            if (switchError.code === 4902) {
                try {
                    await window.okxwallet.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x13881',
                                chainName: "Polygon",
                                rpcUrls: ["https://rpc-mumbai.maticvigil.com"] /* ... */,
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
    }


}

export const accountChange = {

    changeAccount: async (wallet) => {
        try {
            let method = wallet
            method.on('accountsChanged', async function (accounts) {
                sessionStorage.removeItem("WorAddress")
                if (typeof window !== undefined) {
                    web3 = new Web3(method);
                    if (typeof window.web3 !== undefined) {
                        try {
                            await method.enable();
                            sessionStorage.setItem("WorAddress", accounts[0])
                            var balance = web3.eth.getBalance(accounts[0]).then(async (bal) => {
                                const ethbalance = web3.utils.fromWei(bal, 'ether');
                                sessionStorage.setItem("balance", ethbalance)
                            })
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)

                        }
                        catch (err) {

                        }
                    }
                }

            })
        }
        catch (err) {
            // toast.error("Please Install Metamask")
            return err;
        }

    }
}

export const changed1 = () => {
    okxWeb3
        .init({
            changed,
        })
        .then((wallet) => {


        })
        .catch((error) => {
            // Error returned when rejected
            console.error(error);
        });
};

const changed = (wallet) => {

    sessionStorage.setItem("WorAddress", wallet ? wallet.addresses.bsc.address : "")
    setTimeout(() => {
        window.location.reload()
    }, 1000)
};

export const connectAccount = {

    accountConnect: async () => {



    }
}