import { useQuery, useMutation } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

function fetchAssetDetails(collectionName, nftId, supply) {
	const url = new URL(BACKEND_URL + 'asset/details')
	url.searchParams.set('collection', collectionName)
	url.searchParams.set('id', nftId)
	url.searchParams.set('supplyId', supply)

	return axios.get(url)
}

export function useFetchListAssetDetails(
	collectionName,
	nftId,
	supply
) {
	return useQuery({
		queryKey: ['listsale', collectionName, nftId, supply],
		queryFn: () => fetchAssetDetails(collectionName, nftId, supply),
		enabled: !!(collectionName && nftId && supply)
	})
}

function getFixedPriceSell(data) {
	return axios.post('asset/sell', { ...data })
}

export function useFixedPriceSell() {
	return useMutation({
		mutationFn: getFixedPriceSell
	})
}

function fetchOwnedCopies(collectionName, nftId, address) {
	const url = new URL(BACKEND_URL + 'asset/owned-copies')
	url.searchParams.set('collection', collectionName)
	url.searchParams.set('nftId', nftId)
	url.searchParams.set('user', address)

	return axios.get(url)
}

export function useFetchOwnedCopies(collectionName, nftId, address) {
	return useQuery({
		queryKey: ['ownedcopies', collectionName, nftId, address],
		queryFn: () => fetchOwnedCopies(collectionName, nftId, address),
		enabled: !!(collectionName && nftId && address)
	})
}
