import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import style from './bnr.module.scss'
import bgImg from './assets/bgImg.jpg'
import author from './assets/author.jpg'
import Verefied from './assets/Verefied.svg'
import { Link } from 'react-router-dom'

import arrowLeft from './assets/arrow-left.svg'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { axios } from '../../lib/axios'
import loader from '../../assets/images/Loader.png'

const HomeBanner = () => {

   const { t } = useTranslation()

   const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
   };

   const slider = useRef();
   const [loading, setLoading] = useState(false);

   const next = () => {
      slider.current.slickNext();
   };
   const previous = () => {
      slider.current.slickPrev();
   };

   useEffect(() => {
      getCollection()
   }, [])

   const [collection, setCollection] = useState("")

   const getCollection = async () => {
      setLoading(true)
      const fetch = await axios.get("home/get_banner_collection")
      if (fetch.status == true) {
         setCollection(fetch.data)
         setLoading(false)
      }
      else {
         setLoading(false)
         setCollection()

      }
   }


   return (
      <>
         <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>
         <div className={style.bnr}>
            <div className={style.bnrMn}>
               <div className="">

                  <div className={style.bnrSld}>

                     <Slider {...settings} ref={(c) => (slider.current = c)} >
                        {
                           collection && collection.map((data, index) => (
                              <div className="position-relative">
                                 <div className={style.bnrIg}>
                                    {typeof data.bannerImage == "string" && data.bannerImage !== undefined ?

                                       data.imageFormat.startsWith('video') ?
                                          <video
                                             className='img-fluid w-100 itemImage'
                                             src={data.bannerImage}
                                             alt="Uploaded"
                                             muted
                                             autoPlay
                                             width={1440} height={552}
                                             style={{ objectFit: "cover" }}
                                          />
                                          :
                                          data.bannerImage !== 'undefined' ?
                                             <img src={data.bannerImage} alt="Itemimg1" width={1440} height={552} />
                                             :
                                             ""
                                       :
                                       ""
                                    }
                                 </div>

                                 <div className={style.bnrCon}>
                                    <div className={style.atrIg}>
                                       <img src={data.logoImage} alt="author" width={88} height={88} />
                                    </div>
                                    <h2>
                                       <span>{data.collectionName}</span>
                                       {data.collectionVerification === "Verified" &&
                                       <img src={Verefied} alt="Verified" width={40} height={40} />                                       
                                       }
                                    </h2>
                                    <div className='text-center'>
                                       <p className={style.atrNm}>
                                          <span>{t("By")} <strong>{data.userName}</strong></span>
                                          {data.profileVerification == "Verified" ?
                                             <img src={Verefied} alt="Verified" width={20} height={20} />
                                             :
                                             ""
                                          }
                                       </p>
                                       <p className={style.vlmFlr}>
                                          <span className={style.vlmCon}>
                                             {t("Volume")} <strong>{data.volume == "0" ? 0 : data.volume.toFixed(8)} {data.chain == "binance" ? t("BNB") : data.chain == "ethereum" ? t("ETH") : data.chain == "polygon" ? t("MATIC") : ""}</strong>
                                          </span>
                                          <span className={style.flrCon}>
                                             {t("Floor")} <strong>{data.floorPrice == "0" ? 0 : data.floorPrice.toFixed(8)} {data.chain == "binance" ? t("BNB") : data.chain == "ethereum" ? t("ETH") : data.chain == "polygon" ? t("MATIC") : ""}</strong>
                                          </span>
                                       </p>
                                    </div>
                                    <div className={style.bnrLk} >
                                       <Link to={`/collection/${data.contractAddress}`}>
                                          {t("View Collection")}
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           ))

                        }
                     </Slider>
                     <div className={`container-fluid ${style.containAbsolute}`} >
                        <button className={`btn ${style.btnSld} ${style.btnSldLt}`} onClick={previous}>
                           <img src={arrowLeft} alt="arrow left" width={20} height={20} />
                        </button>

                        <button className={`btn ${style.btnSld} ${style.btnSldRt}`} onClick={next}>
                           <img src={arrowLeft} alt="arrow left" width={20} height={20} />
                        </button>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </>
   );
}
export default HomeBanner
