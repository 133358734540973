import { parseUnits } from 'viem'

const assetTypeMap = {
	erc20: 0,
	erc721: 1,
	erc1155: 2
}

const orderTypeMap = {
	sell: 1,
	buy: 2,
	bid: 3
}

export function getOrder({ asset, assetType, orderType, expiry }) {
	const salt = Math.floor(Math.random() * new Date().getTime())
	const yearInSeconds = 31_536_000
	const deadline = expiry
		? expiry
		: Math.floor(new Date().getTime() / 1000) + yearInSeconds
	const copies = 1

	let key
	let units
	if (orderType === 'buy') {
		key = [
			[asset.details.currencyAddress, '0', '0'],
			[
				asset.details.contractAddress,
				asset.details.nftId,
				assetTypeMap[assetType]
			]
		]
		units = [
			parseUnits(
				asset.details.price.toString(),
				asset.details.decimals
			).toString(),
			copies
		]
	} else {
		key = [
			[
				asset.details.contractAddress,
				asset.details.nftId,
				assetTypeMap[assetType]
			],
			[asset.details.currencyAddress, '0', '0']
		]
		units = [
			copies,
			parseUnits(
				asset.details.price.toString(),
				asset.details.decimals
			).toString()
		]
	}

	return [
		[asset.owner.address, ...key],
		...units,
		asset.details.royalty,
		salt,
		deadline,
		orderTypeMap[orderType]
	]
}


export function getOrder1({ asset, assetType, orderType, expiry }) {
	const salt = Math.floor(Math.random() * new Date().getTime())
	const yearInSeconds = 31_536_000
	const deadline = expiry
		? expiry
		: Math.floor(new Date().getTime() / 1000) + yearInSeconds
	const copies = 1

	let key
	let units
	if (orderType === 'buy') {
		key = [
			[asset.details.currencyAddress, '0', '0'],
			[
				asset.details.contractAddress,
				asset.details.nftId,
				assetTypeMap[assetType]
			]
		]
		units = [
			parseUnits(
				asset.details.price.toString(),
				asset.details.currencyDecimal
			).toString(),
			copies
		]
	} else {
		key = [
			[
				asset.details.contractAddress,
				asset.details.nftId,
				assetTypeMap[assetType]
			],
			[asset.details.currencyAddress, '0', '0']
		]
		units = [
			copies,
			parseUnits(
				asset.details.price.toString(),
				asset.details.currencyDecimal
			).toString()
		]
	}

	return [
		[asset.owner.address, ...key],
		...units,
		asset.details.royaltyFee,
		salt,
		deadline,
		orderTypeMap[orderType]
	]
}