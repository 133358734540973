import { useAccount } from 'wagmi'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'
import {
	EmailShareButton,
	TelegramShareButton,
	FacebookShareButton,
	TwitterShareButton
} from 'react-share'
import { useTranslation } from 'react-i18next'

import StatBox, { StatBoxItem } from './components/StatBox'
import CollectionBanner from './components/CollectionBanner'
import CollectionDetails from './components/CollectionDetails'
import { useFetchCollectionDetails } from './apis/fetchCollectionDetails'
import {
	CollectionBannerPlaceholder,
	CollectionDetailsPlaceholder,
	CollectionStatBoxPlaceholder
} from './components/loader/CollectionHeaderPlaceholder'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import {
	BINANCE_BLOCK_EXPLORER_URL,
	ETHEREUM_BLOCK_EXPLORER_URL,
	POLYGON_BLOCK_EXPLORER_URL
} from '../../config/constants'
import { useToast } from '../../utils/toast/customSuccess'
import { useReportModalActions } from '../../components/modals/report-modal/store'
import { useToggleWatchlist } from './apis/toggleWatchlist'
import { formatAmount } from '../../utils/formatAmount'

import discord from '../../assets/images/collection/discord.svg'
import twittericon from '../../assets/images/collection/twitter.svg'
import icongroup from '../../assets/images/collection/group.svg'
import shareIcon from '../../assets/images/collection/shareIcon.svg'
import ellipsisV from '../../assets/images/collection/ellipsis-v.svg'
import pennant from '../../assets/images/collection/pennant.svg'

const blockExplorerUrlMap = {
	ethereum: ETHEREUM_BLOCK_EXPLORER_URL,
	binance: BINANCE_BLOCK_EXPLORER_URL,
	polygon: POLYGON_BLOCK_EXPLORER_URL
}

const coinMap = {
	binance: 'BNB',
	polygon: 'MATIC',
	ethereum: 'ETH'
}

export default function CollectionHeader({ collectionAddress }) {
	const { t } = useTranslation()
	const toast = useToast()
	const { address } = useAccount()

	const { data, isLoading } = useFetchCollectionDetails(collectionAddress)
	const { toggleReportModal, setAsset: setReportAssetState } =
		useReportModalActions()
	const { mutateAsync: toggleWatchList } = useToggleWatchlist(address)

	const blockExplorerUrl =
		blockExplorerUrlMap[data?.data?.analytics.chain] +
		'address/' +
		data?.data?.contractAddress

	const url = `https://localhost/collection/${collectionAddress}`
	const copyToLink = () => {
		navigator?.clipboard?.writeText(url)
		toast.success('Link Copied!')
	}

	const handleReport = () => {
		const asset = {
			contractAddress: data?.data?.contractAddress,
			collectionName: data?.data?.collectionName
		}
		setReportAssetState(asset)
		toggleReportModal()
	}

	const handleWatchlist = async (e) => {
		e.preventDefault()
		if (address) {
			const a = data?.data?.watchlistedBy?.includes(
				address ? address.toLowerCase() : ''
			)

			if (a == true) {
				const remove = {
					id: data?.data?._id,
					userAddress: address ? address.toLowerCase() : '',
					isWatchlist: false
				}
				const res = await toggleWatchList(remove)
				if (res.status) {
					toast.success(res.message)
				} else {
					toast.error(res.message)
				}
			} else {
				const add = {
					id: data?.data?._id,
					userAddress: address ? address.toLowerCase() : '',
					isWatchlist: true
				}
				const res1 = await toggleWatchList(add)
				if (res1.status) {
					toast.success(res1.message)
				} else {
					toast.error(res1.message)
				}
			}
		}
	}

	return (
		<>
			{isLoading ? (
				<CollectionBannerPlaceholder />
			) : (
				<CollectionBanner collection={data.data} />
			)}
			<div className='container-fluid'>
				<div className='row'>
					{isLoading ? (
						<CollectionDetailsPlaceholder />
					) : (
						<CollectionDetails collection={data.data} />
					)}
					<div className='col-lg-3 order-lg-1 text-center text-lg-left'>
						<div className='socialmediaBtnGrid justify-content-center justify-content-lg-start'>
							{data?.data?.discord !== '' && (
								<a
									className='iconBtn hover'
									target='_blank'
									href={data?.data?.discord}
								>
									<img src={discord} alt='discord' />
								</a>
							)}
							{data?.data?.twitter !== '' && (
								<a
									className='iconBtn hover'
									target='_'
									href={data?.data?.twitter}
								>
									<img src={twittericon} alt='twittericon' />
								</a>
							)}
							<a
								className='iconBtn hover'
								target='_'
								href={blockExplorerUrl}
							>
								<img src={icongroup} alt='icongroup' />
							</a>
						</div>
					</div>
					<div className='col-lg-3 order-lg-3 text-center text-lg-right mb-4 mb-lg-0'>
						<div className='justify-content-center justify-content-lg-end socialmediaBtnGrid'>
							<button
								className='iconBtn'
								onClick={(e) => handleWatchlist(e)}
								style={{
									pointerEvents: `${isLoading ? 'none' : 'auto'
										}`,
									cursor: `${isLoading ? 'cursor-default' : 'pointer'
										}`
								}}
							>
								{data?.data?.watchlistedBy.includes(
									address ? address.toLowerCase() : ''
								) == true ? (
									<svg
										id='tooltip2'
										width='19'
										height='18'
										viewBox='0 0 19 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z'
											fill='#FFA900'
										/>
									</svg>
								) : (
									<svg
										id='tooltip1'
										width='19'
										height='18'
										viewBox='0 0 19 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375ZM12.5537 10.7812L15.71 7.71875L11.3662 7.09375L9.42871 3.15625L7.45996 7.09375L3.11621 7.71875L6.27246 10.7812L5.52246 15.0938L9.42871 13.0625L13.3037 15.0938L12.5537 10.7812Z'
											fill='#595F6A'
										/>
									</svg>
								)}
							</button>
							<UncontrolledDropdown>
								<DropdownToggle className='iconBtn'>
									<img src={shareIcon} alt='shareIcon' />
								</DropdownToggle>
								<DropdownMenu right className='dropdownMenu'>
									<DropdownItem className='mb-2'>
										<i class='fab fa-twitter-square mr-2'></i>{' '}
										<TwitterShareButton
											title={
												'Check out this account on wornft'
											}
											url={`https://localhost/collection/${collectionAddress}}`}
											via={['WorNft']}
										>
											{' '}
											Twitter
										</TwitterShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2'>
										<i class='fab fa-telegram mr-2'></i>{' '}
										<TelegramShareButton
											url={`https://localhost/collection/${collectionAddress}`}
											title={
												'Check out this account on wornft'
											}
											className='share-button'
											via={['WorNft']}
										>
											Telegram
										</TelegramShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2'>
										<i class='fab fa-facebook mr-2'></i>{' '}
										<FacebookShareButton
											url={`https://localhost/collection/${collectionAddress}`}
											subject={
												'Check out this account on wornft'
											}
										>
											Facebook
										</FacebookShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2'>
										<i class='fas fa-envelope mr-2'></i>{' '}
										<EmailShareButton
											title={
												'Check out this account on wornft'
											}
											url={`https://localhost/collection/${collectionAddress}`}
											via={['WorNft']}
											className='share-button'
										>
											Email
										</EmailShareButton>
									</DropdownItem>
									<DropdownItem
										className='mb-2'
										onClick={copyToLink}
									>
										<i class='fas fa-link mr-2'></i> Copy
										Link
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>

							{address ?
								<UncontrolledDropdown>
									<DropdownToggle className='iconBtn'>
										<img src={ellipsisV} alt='ellipsisV' />
									</DropdownToggle>
									<DropdownMenu right className='dropdownMenu'>
										<DropdownItem onClick={handleReport}>
											<img
												src={pennant}
												alt='pennant'
												className='mr-2'
											/>{' '}
											{t('Report')}
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								:
								""
							}
						</div>
					</div>
				</div>

				{isLoading ? (
					<CollectionStatBoxPlaceholder />
				) : (
					<StatBox>
						<StatBoxItem
							title={t('Floor Price')}
							content={
								data?.data?.analytics.floorPrice !== 0
									? `${parseFloat(
										data?.data?.analytics.floorPrice?.toFixed(
											8
										)
									)} ${coinMap[data?.data?.analytics.chain]
									}`
									: '--'
							}
						/>
						<StatBoxItem
							title={t('Total Volume')}
							content={
								data?.data?.analytics.totalVolume !== 0
									? `${formatAmount(
										data?.data?.analytics.totalVolume,
										10
									)} ${coinMap[data?.data?.analytics.chain]
									}`
									: '--'
							}
						/>
						<StatBoxItem
							title={t('24h Volume')}
							content={
								data?.data?.analytics.volumeIn24h !== 0
									? `${formatAmount(
										data?.data?.analytics.volumeIn24h,
										10
									)} ${coinMap[data?.data?.analytics.chain]
									}`
									: '--'
							}
							label={formatAmount(
								data?.data?.analytics.volumeChangeIn24h,
								2
							)}
						/>
						<StatBoxItem
							title={t('7d Volume')}
							content={
								data?.data?.analytics.volumeIn7d !== 0
									? `${formatAmount(
										data?.data?.analytics.volumeIn7d,
										10
									)} ${coinMap[data?.data?.analytics.chain]
									}`
									: '--'
							}
							label={formatAmount(
								data?.data?.analytics.volumeChangeIn7d,
								2
							)}
						/>
						<StatBoxItem
							title={t('Highest Bid')}
							content={
								data?.data?.analytics.highestBid !== 0
									? `${data?.data?.analytics.highestBid} ${coinMap[data?.data?.analytics.chain]
									}`
									: '--'
							}
						/>
						<StatBoxItem
							title={t('Listed / Total Items')}
							content={
								data?.data?.analytics.totalItems !== 0
									? `${data?.data?.analytics.listedItems}/${data?.data?.analytics.totalItems}`
									: '--'
							}
						/>
						<StatBoxItem
							title={t('Owner')}
							content={
								data?.data?.analytics.owners !== 0
									? data?.data?.analytics.owners
									: '--'
							}
						/>
						<StatBoxItem
							title={t('Unique Owner')}
							content={
								data?.data?.analytics.uniqueOwners !== 0
									? `${data?.data?.analytics.uniqueOwners} %`
									: '--'
							}
						/>
						<StatBoxItem
							title={t('Royalty Fee')}
							content={
								data?.data?.analytics.royaltyFee !== '0'
									? `${data?.data?.analytics.royaltyFee}%`
									: '--'
							}
						/>
						<StatBoxItem
							title={t('Chain')}
							content={capitalizeFirstLetter(
								data?.data?.analytics.chain
							)}
						/>
					</StatBox>
				)}
			</div>
			<div className='mb-4 borderbottom'></div>
		</>
	)
}
