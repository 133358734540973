import React, { useEffect, useState, useContext } from 'react'
import style from './profile.module.scss'
import profileProfPic from '../../assets/images/profile/profileProfPic.png'
import yellowTick24px from '../../assets/images/collection/yellow-tick-24px.svg'
import shareIcon from '../../assets/images/collection/shareIcon.svg'
import ellipsisV from '../../assets/images/collection/ellipsis-v.svg'
import pennant from '../../assets/images/collection/pennant.svg'
import transferIcon from '../../assets/images/collection/transferIcon.svg'
import listIcon from '../../assets/images/collection/listIcon.svg'
import offerIcon from './assets/bestOfferIcon.svg'
import selectIcon from './assets/selectIcon.svg'
import hideIcon from './assets/hideIcon.svg'
import copyIcon from './assets/copyIcon.svg'
import settingsIcon from './assets/settingsIcon.svg'
import copyClickIcon from '../../assets/images/copyIcon.svg'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap'
import classnames from 'classnames'
import ProfileCollection from './Collected/profileItem'
import OffersProfileMain from './profileOffersMain'
import ActiveListing from './activeListing/profileActiveMain'
import AnalyticsProfileMain from './profileAnalyticsMain'
import DealsCollection from './deals/dealsMain'
import ProfileCreate from './Created/profileCreateMain'
import ProfileFavorite from './Favorites/profileFavoriteMain'
import ProfileActivity from './Activity/profileActivityMain'
import OfferReceived from './offerReceived/profileOfferReceivedMain'
import OfferMade from './offerMade/profileOfferMadeMain'
import { useToast } from '../../utils/toast/customSuccess'
import copy from 'copy-to-clipboard'
import { useOutletContext } from 'react-router-dom'
import {
	EmailShareButton,
	TelegramShareButton,
	FacebookShareButton,
	TwitterShareButton
} from 'react-share'

import { useAccount } from 'wagmi'
import { axios } from '../../lib/axios'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import loader from '../../assets/images/Loader.png'
import { ThemeContext } from '../context/context'

export const ProfilePage = (props) => {

	const { favouriteTab, activeTab5, setActiveTab5, setFavouriteTab, activeTab6, setActiveTab6, offerreceivedTab, setOfferReceivedTab } = useContext(ThemeContext)

	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState('1')
	const [loading, setLoading] = useState(false);

	const toast = useToast()

	const { address, isConnected } = useAccount()

	const { id } = useParams()



	const location = useLocation()

	const data = location.state

	const schema = yup.object().shape({
		reason: yup
			.string()
			.required('Required')
			.min(3, 'Reason should be atleast 3 character'),
	})

	const {
		register,
		control,
		handleSubmit,
		formState,
		reset
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const { isValid, isDirty, errors } = formState

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		if (data === null || '') {
			setActiveTab5(activeTab5)
		} else {
			setActiveTab5(data)
		}
	}, [data?.tab])

	const toggle = tab => {
		if (activeTab5 !== tab) setActiveTab5(tab)
	}

	const [modal, setModal] = useState(false)
	const toggle1 = () => setModal(!modal)

	const closeBtn = (
		<button className='close'>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z'
					fill='#6E737E'
				/>
			</svg>
		</button>
	)

	useEffect(() => {
		GetProfile()
	}, [id, address])

	const [copyText, setCopyText] = useState(id ? id : address)

	const copyToClipboard = () => {
		copy(copyText)
		toast.success('Address Copied')
	}

	const [copylink, setCopyLink] = useState()

	const copyToLink = () => {
		copy(`https://localhost/profile/${id ? id : address}`)
	}

	// const [getprofile] = useOutletContext()
	const [getprofile, setGetProfile] = useState("")
	const navigate = useNavigate()
	const settings = () => {
		navigate('/settingsprofile')
	}

	const onSubmit = async data => {
		setLoading(true)
		let detail = {
			address: address,
			profileAddress: copyText,
			reason: data.reason,
			"type": "profile"
		}
		const res = await axios.post("/user/report_data", detail)
		if (res.status == true) {
			setLoading(false);
			setModal(false)
			toast.success(res.message, {
				showOnlyTheLastOne: true,
				clickToClose: true,
				timeout: 2000
			})
		} else {
			setLoading(false)
			setModal(false)
			reset()
			toast.error(res.message, {
				showOnlyTheLastOne: true,
				clickToClose: true,
				timeout: 2000
			})
		}

	}

	const GetProfile = async () => {
		setLoading(true)
		const update = await axios.post("user/getSingleProfile", { "address": id ? id : address })
		if (update.status == true) {
			setLoading(false)
			setGetProfile(update.data)
			// props.setProfilePic(update.data.logo)
		}
		else {
			setLoading(false)
			setGetProfile('')
		}
	}


	const imageupload1 = async (e) => {
		let imageFile1 = e.target.files[0];
		if (!imageFile1.name.match(/\.(webp|jpg|jpeg|png|gif)$/)) {
			toast.error('Please select valid image for WEBP,PNG,GIF,JPEG')
		}
		else if (imageFile1 == "" || imageFile1 == undefined) {
			toast.error('Please choose logo image')
		}
		else {
			setLoading(true)
			const formData = new FormData()
			formData.append('address', address.toLowerCase())
			formData.append('name', getprofile.name)
			formData.append('logo', imageFile1)
			formData.append('cover', getprofile.cover)
			formData.append('bio', getprofile.bio)
			formData.append('email', getprofile.email)
			formData.append('twitter', getprofile.twitter)
			formData.append('instagram', getprofile.instagram)
			formData.append('website', getprofile.website)
			formData.append('verification', getprofile.verification)
			formData.append('twitterVerify', getprofile.twitterVerify)
			formData.append('nameVerify', getprofile.nameVerify)
			formData.append('logoVerify', getprofile.logoVerify)
			formData.append('emailVerify', getprofile.emailVerify)
			formData.append('coverImageType', getprofile.coverImageType)
			formData.append('logoImageType', imageFile1.type)

			const update = await axios.post("user/update-profile", formData)
			if (update.status == true) {
				toast.success(update.message)
				props.setProfilePic(update.data.logo)
				setLoading(false)
				GetProfile()
			}
			else {
				toast.error(update.message)
				setLoading(false)
			}

		}
	}


	return (
		<>
			<div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>

			<div className='container-fluid'>
				<div className='row pb-3'>
					<div className='col-lg-6 order-lg-2 text-center ml-auto '>
						<div className={style.userDetails}>
							{/* <div className='imgtag'>
								<img
									src={
										getprofile && getprofile.logo !== 'undefined'
											? getprofile.logo
											: profileProfPic
									}
									alt='userImg'
								/>
							</div> */}


							<div className="imgtag">
								<div className={style.editCnt}>
									<img src={
										getprofile && getprofile.logo !== 'undefined'
											? getprofile.logo
											: profileProfPic
									} alt="userImg" />

									{getprofile && getprofile.verification == 'Verified' ?
										""
										:
										id === address || id == undefined ?
											<label htmlFor='userImg' className={style.editIconCnt} style={{ cursor: "pointer" }}>
												<input id="userImg" type='file' className={style.fileInputType} onChange={(e) => imageupload1(e)} />
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M16.4062 2.78125C17.1875 3.5625 17.1875 4.8125 16.4062 5.59375L5.375 16.625L1.8125 17C1.34375 17.0625 0.9375 16.6562 1 16.1875L1.375 12.625L12.4062 1.59375C13.1875 0.8125 14.4375 0.8125 15.2188 1.59375L16.4062 2.78125ZM13.4375 7.15625L10.8438 4.5625L3.84375 11.5625H4.9375V13.0625H6.4375V14.1562L13.4375 7.15625ZM4.9375 15.6562L5.5625 15.0312V13.9375H4.0625V12.4375H2.96875L2.34375 13.0625L2.125 14.9062L3.09375 15.875L4.9375 15.6562ZM15.6875 4.90625C16.0938 4.5 16.0938 3.875 15.6875 3.46875L14.5312 2.3125C14.125 1.90625 13.5 1.90625 13.0938 2.3125L11.5312 3.875L14.125 6.46875L15.6875 4.90625Z" fill="#0A0A0B" />
												</svg>
											</label>
											:
											""
									}
								</div>
							</div>

							<div className='text-center'>
								<div className='userName'>
									{getprofile && getprofile ? getprofile.name : ''}
									{getprofile && getprofile.verification == 'Verified' ? (
										<img
											src={yellowTick24px}
											alt='yellowTick24px'
											className='ml-2'
										/>
									) : (
										''
									)}
								</div>
							</div>
							<div className='text-center d-flex justify-content-center mt-3'>
								<div className='userName d-flex mb-3'>
									{getprofile.logo !== 'undefined' ? (
										<>
											<span>
												Joined{' '}
												{new Date(
													getprofile && getprofile.createdAt
												).toLocaleDateString('en-us', {
													year: 'numeric',
													month: 'short'
												})}
											</span>
											<span className='mx-2 mt-1'>|</span>
										</>
									) : (
										''
									)}

									<span>
										{getprofile.address
											? getprofile.address.slice(0, 5) +
											'..' +
											getprofile.address.slice(38, 42)
											: ''}
										<img src={copyClickIcon} alt='copyClickIcon' className='ml-2 hover' onClick={copyToClipboard} />
									</span>

								</div>
							</div>
						</div>
					</div>

					<div className='col-lg-3 order-lg-3 text-center text-lg-right mb-4 mb-lg-0'>
						<div
							className={`justify-content-center justify-content-lg-end ${style.socialmediaBtnGrid}`}
						>
							<UncontrolledDropdown>
								<DropdownToggle className={style.iconBtn}>
									<img src={shareIcon} alt='shareIcon' />
								</DropdownToggle>
								<DropdownMenu right className={style.dropdownMenu}>
									<DropdownItem className='mb-2'>
										<i class='fab fa-twitter-square mr-2'></i>
										<TwitterShareButton
											title={'Check out this account on wornft'}
											url={`https://localhost/profile/${address}`}
											via={['WorNft']}
										>
											{' '}
											Twitter
										</TwitterShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2'>
										<i class='fab fa-telegram mr-2'></i>{' '}
										<TelegramShareButton
											url={`https://localhost/profile/${address}`}
											title={'Check out this account on wornft'}
											className='share-button'
											via={['WorNft']}
										>
											Telegram
										</TelegramShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2'>
										<i class='fab fa-facebook mr-2'></i>{' '}
										<FacebookShareButton
											image={
												getprofile && getprofile.logo !== 'undefined'
													? getprofile.logo
													: profileProfPic
											}
											url={`https://localhost/profile/${address}`}
											subject={'Check out this account on wornft'}
										>
											Facebook
										</FacebookShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2'>
										<i class='fas fa-envelope mr-2'></i>{' '}
										<EmailShareButton
											title={'Check out this account on wornft'}
											url={`https://localhost/profile/${address}`}
											via={['WorNft']}
											className='share-button'
										>
											Email
										</EmailShareButton>
									</DropdownItem>
									<DropdownItem className='mb-2' onClick={copyToLink}>
										<i class='fas fa-link mr-2'></i> Copy Link
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>

							<UncontrolledDropdown>
								<DropdownToggle className={style.iconBtn}>
									<img src={ellipsisV} alt='ellipsisV' />
								</DropdownToggle>
								<DropdownMenu right className={style.dropdownMenu}>
									<DropdownItem onClick={toggle1}>
										<img src={pennant} alt='pennant' className='mr-2' />{' '}
										{t('Report')}
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</div>
				</div>
			</div>
			<div className={`mb-4 ${style.borderbottom}`}></div>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-lg-10 d-flex flex-wrap'>
						<Nav tabs className={style.mycollectiontabs}>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab5 === '1' })}
									onClick={() => {
										toggle('1')
									}}
									style={{ cursor: "pointer" }}
								>
									{t('Collected')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab5 === '2' })}
									onClick={() => {
										toggle('2')
									}}
									style={{ cursor: "pointer" }}
								>
									{t('Active Listings')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab5 === '3' })}
									onClick={() => {
										toggle('3')
									}}
									style={{ cursor: "pointer" }}
								>
									{t('Created')}
								</NavLink>
							</NavItem>
							{/* <NavItem>
                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                  Deals
                </NavLink>
              </NavItem> */}
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab5 === '5' })}
									onClick={() => {
										toggle('5')
										setFavouriteTab('favorites')
									}}
									style={{ cursor: "pointer" }}
								>
									{t('Favorites')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab5 === '6' })}
									onClick={() => {
										toggle('6')
									}}
									style={{ cursor: "pointer" }}
								>
									{t('Activity')}
								</NavLink>
							</NavItem>
							{/* <NavItem>
								<NavLink
									className={classnames({ active: activeTab5 === '7' })}
									onClick={() => {
										toggle('7')
										setOfferReceivedTab('OfferReceived')
									}}
								>
									{t('OfferReceived')}
								</NavLink>
							</NavItem> */}
						</Nav>
						<UncontrolledDropdown>
							<DropdownToggle className={`ml-2 mb-4 ${style.collectOfferBtn}`}>
								{t('More')}
								<svg className='ml-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.71875 12.2188L6.09375 8.5625C5.9375 8.40625 5.9375 8.15625 6.09375 8.03125L6.3125 7.78125C6.46875 7.65625 6.6875 7.65625 6.84375 7.78125L10 11L13.125 7.8125C13.2812 7.65625 13.5 7.65625 13.6562 7.8125L13.875 8.03125C14.0312 8.15625 14.0312 8.40625 13.875 8.5625L10.25 12.2188C10.0938 12.375 9.875 12.375 9.71875 12.2188Z" fill="#F7F7F7" />
								</svg>
							</DropdownToggle>
							<DropdownMenu right className={style.dropdownMenu}>
								<DropdownItem className={classnames({ active: activeTab5 === '7' })}
									onClick={() => {
										toggle('7')
										setOfferReceivedTab('OfferReceived')

									}} style={{ cursor: "pointer" }}>{t('Offers Received')}</DropdownItem>
								<DropdownItem className={classnames({ active: activeTab5 === '8' })}
									onClick={() => {
										toggle('8')
									}} style={{ cursor: "pointer" }}>{t('Offers Made')}</DropdownItem>
								{/* <DropdownItem>{t('Hidden')}</DropdownItem> */}
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>

					{console.log(id !== address, id, address)}

					{id === address || id == undefined ?
						<div className='col-lg-2 text-right'>
							<button className={style.iconBtn} onClick={settings}>
								<img src={settingsIcon} alt='settingsIcon' />
							</button>
						</div>
						:
						""
					}
				</div>
				<div className='row'>
					<div className='col-12'>
						<TabContent activeTab={activeTab5}>
							<TabPane tabId='1'>
								{activeTab5 === '1' && <ProfileCollection />}
							</TabPane>
							<TabPane tabId='2'>
								{activeTab5 === '2' && <ActiveListing />}
							</TabPane>
							<TabPane tabId='3'>
								{activeTab5 === '3' && <ProfileCreate />}
							</TabPane>
							{/* <TabPane tabId="4">
                {activeTab === "4" &&
                  <DealsCollection />
                }
              </TabPane> */}
							<TabPane tabId='5'>
								{activeTab5 === '5' && <ProfileFavorite />}
							</TabPane>
							<TabPane tabId='6'>
								{activeTab5 === '6' && <ProfileActivity />}
							</TabPane>
							<TabPane tabId='7'>
								{activeTab5 === '7' && <OfferReceived />}
							</TabPane>
							<TabPane tabId='8'>
								{activeTab5 === '8' && <OfferMade />}
							</TabPane>
						</TabContent>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle1}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
			>
				<ModalHeader onClick={toggle1} close={closeBtn}>
					{t("Report")}
				</ModalHeader>
				<ModalBody className='pt-0'>
					<p className='dark-text-secondary text-center'>
						Please, describe the reason.
					</p>
					<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
						<span className='formLabel'>Reason</span>

						<textarea
							{...register('reason')}
							className={`form-control ${errors.reason && 'err'}`}
							placeholder='Enter Something...'
							style={{ color: "white" }}
						></textarea>
						{errors.reason && (
							<label className='text-danger errLabel ml-auto'>
								{errors.reason.message}
							</label>
						)}
						<div className='mt-4'>
							<button type='submit' className='btn btn-block darkBtn mb-2 text-danger'>
								{t("Report")}
							</button>
							<button type='button' className='btn btn-block darkBtn mb-2' onClick={() => { setModal(false); reset() }}>Cancel</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ProfilePage
