import { useCallback, useEffect } from 'react'
import { useAccount } from 'wagmi'
import { useToast } from '../utils/toast/customSuccess'

export const useOkxSwitchNetwork = () => {
	const { isConnected } = useAccount()
	const toast = useToast()

	const okxwallet = window.okxwallet

	const switchNetwork = useCallback(
		async chainId => {
			console.log("🚀 ~ file: useOkxSwitchNetwork.js:13 ~ useOkxSwitchNetwork ~ chainId:", chainId)
			if (okxwallet) {
				try {
					await okxwallet.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: `0x${chainId.toString(16)}` }]
					})
				} catch (error) {
					if (error.code === 4902) {
						// Chain not added, add the chain
						await okxwallet.request({
							method: 'wallet_addEthereumChain',
							params: [
								{
									chainId: `0x${chainId.toString(16)}`,
									rpcUrl: 'your-custom-rpc-url'
								}
							]
						})
					} else {
						// Handle other errors
						console.error(error)
					}
				}
			}
		},
		[isConnected, toast]
	)

	return {
		switchNetwork
	}
}
