import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { flattenFilters } from '../../../utils/flattenFilters'
import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'

export async function collectionOffer(
	collectionAddress,
	account,
	filters,
	pageParam
) {
	const url = new URL(BACKEND_URL + 'collection/offers')
	url.searchParams.set('address', collectionAddress)
	url.searchParams.set('user', account)
	url.searchParams.set('page', pageParam)
	const flattenedFilters = flattenFilters(filters)
	const queryString = convertObjectToQueryString(flattenedFilters)
	url.search += queryString

	const response = await axios.get(url)
	return response.data
}

export function useCollectionOffer(collectionAddress) {
	return useQuery({
		queryKey: ['collection_offer', collectionAddress],
		queryFn: () => collectionOffer(collectionAddress),
		enabled: !!collectionAddress
	})
}

function collectionFilterOffer(collectionAddress) {
	const url = new URL(BACKEND_URL + 'collection/filter-for-offers')
	url.searchParams.set('address', collectionAddress)

	return axios.get(url)
}

export function useCollectionFilterOffer(collectionAddress) {
	return useQuery({
		queryKey: ['collection_filter_offer', collectionAddress],
		queryFn: () => collectionFilterOffer(collectionAddress),
		enabled: !!collectionAddress
	})
}
