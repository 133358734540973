import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { usePurchaseLoadingModalState } from './store'

import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'

export function PurchaseLoadingModal() {
	const { isOpen, heading } = usePurchaseLoadingModalState()
	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
		>
			<ModalHeader>{heading}</ModalHeader>
			<ModalBody>
				<h3 className='walletSubHeading'>
					To continue send transaction with your wallet.
				</h3>
				<div className='d-flex justify-content-center align-items-center loaderCont'>
					<img src={walletLoaderCnt} />
					<div className='loaderAnimCnt'>
						<img src={walletLoader} className='loaderAnimation' />
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}
