import React, { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Line, ResponsiveContainer } from 'recharts';
import greenUpArrow from '../../../../assets/images/collection/greenUpArrow.svg';
import redDownArrow from '../../../../assets/images/collection/redDownArrow.svg';
import { useParams } from 'react-router-dom';
import { useCollectionSaleChart } from '../../apis/collectionSaleChart';
import moment from 'moment';
import cndds from '../../../../assets/images/cndds.png'
import { Spinner } from 'reactstrap';
import { formatAmount } from '../../../../utils/formatAmount';
const gradientOffset = () => {
    const data = [
        { stop: 0, color: '#dcac38' },
        { stop: 1, color: '#dcac38', opacity: 0.1 },
    ];

    return data.map((entry, index) => (
        <stop key={`stop-${index}`} offset={`${entry.stop * 100}%`} stopColor={entry.color} stopOpacity={entry.opacity || 1} />
    ));
};

const linearGradient = (
    <defs>
        <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
            {gradientOffset()}
        </linearGradient>
    </defs>
);

const CustomTooltip = ({ active, payload, label }) => {
    if (payload.length) {
        const dataPoint = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <div className="tooltip-image">
                    <img src={dataPoint.nftImage} alt="Tooltip Image" style={{ width: '100px' }} />
                </div>
                <div className="tooltip-content">
                    <h3 className="label-bold">{`${dataPoint.nftName}`}</h3>
                    <h3 className="label mt-1">{`${dataPoint.value} ${dataPoint.currency}`}</h3>
                    <p className="label">{`${dataPoint.dateTime}`}</p>
                </div>
            </div>
        );
    }
    return null;
};



const SalesChart = () => {

    const params = useParams()
    const [saleData, setSaleData] = useState([]);

    const { data, isLoading, isError, error, isSuccess } = useCollectionSaleChart(
        params.collectionAddress
    )

    useEffect(() => {
        const processedData = data?.status === false ? [] : data?.data.map(item => ({
            name: moment(item?.createdAt).format("MMM D"),
            dateTime: moment(item?.createdAt).format("MMM D, LT"),
            value: formatAmount(item?.price, 10),
            currency: item?.chain,
            nftImage: item?.asset,
            nftName: item?.nftName
        }));
        setSaleData(processedData);
    }, [isSuccess, data])

    if (isLoading) {
        return (
            <div className="col-12 my-auto">
                <div class="noResf text-center" style={{ height: '200px' }}>
                    <Spinner />
                </div>
            </div>
        )
    }

    if (data?.status === false) {
        return (
            <div className="col-12 my-auto">
                <div class="noResf text-center" >
                    <img class="img-fluid" src={cndds} alt="" />
                    <h1>No Data</h1>
                </div>
            </div>
        )
    }

    return (
        <>

            <div className='graphvalue mb-2 d-flex align-items-center'>{formatAmount(data.currentSales, 10)} <span className={data.isUp ? "greenTxt pl-2" : "redTxt pl-2"} ><img src={data.isUp ? greenUpArrow : redDownArrow} alt="greenUpArrow" /> {data.saleDifference}%</span></div>

            <div>
                <ResponsiveContainer width="100%" height={250} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <ScatterChart                >
                        {/* <CartesianGrid /> */}

                        <XAxis type="category" dataKey="name" name="X-axis" />
                        <YAxis type="number" dataKey="value" name="Y-axis" />
                        <Tooltip content={CustomTooltip} />
                        <Scatter data={saleData} fill="#dcac38" />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
        </>
    )
};

export default SalesChart;