import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import '../Activity/profileActivity.scss';
import './profileOfferMade.scss';

import item1 from '../../../assets/images/collection/modalItem1.png';
import copyIcon from '../../../assets/images/copyIcon.svg';


import tblUserImg1 from '../../../assets/images/collection/tblUserImg1.png';
import tblUserImg2 from '../../../assets/images/collection/tblUserImg2.png';
import tblUserImg3 from '../../../assets/images/collection/tblUserImg3.png';
import tblUserImg4 from '../../../assets/images/collection/tblUserImg4.png';
import tblUserImg5 from '../../../assets/images/collection/tblUserImg5.png';
import tblUserImg6 from '../../../assets/images/collection/tblUserImg6.png';
import tblUserImg7 from '../../../assets/images/collection/tblUserImg7.png';
import tblUserImg8 from '../../../assets/images/collection/tblUserImg8.png';
import tblUserImg9 from '../../../assets/images/collection/tblUserImg9.png';
import tblUserImg10 from '../../../assets/images/collection/tblUserImg10.png';
import tblUserImg11 from '../../../assets/images/collection/tblUserImg11.png';
import tblUserImg12 from '../../../assets/images/collection/tblUserImg12.png';

import saleIcon from '../../../assets/images/collection/saleIcon.svg';
import transferIcon from '../../../assets/images/collection/transferIcon.svg';
import listIcon from '../../../assets/images/collection/listIcon.svg';
import collectionOfferIcon from '../../../assets/images/collection/collectionOfferIcon.svg';
import offerIcon from '../../../assets/images/collection/offerIcon.svg';
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import redClose from '../../../assets/images/redClose.svg';
import walletLoaderCnt from "../../../assets/images/walletLoader.png"
import walletLoader from "../../../assets/images/loaderCircle.png"
import { ThemeContext } from '../../context/context';
import { axios } from '../../../lib/axios'
import {
    useAccount,
} from 'wagmi'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../utils/useDebounce'
import loader from '../../../assets/images/Loader.png'
import moment from 'moment';
import PaginationComponent from 'react-reactstrap-pagination';
import { useToast } from '../../../components/alertToast';
import ResponsivePagination from 'react-responsive-pagination';

const landPerRow = 6

const tableImg = [
    {
        imgsrc: tblUserImg1,
        received: '13s',
    },
    {
        imgsrc: tblUserImg2,
        received: '2 mins',
    },
    {
        imgsrc: tblUserImg3,
        received: '2 mins',
    },
    {
        imgsrc: tblUserImg4,
        received: '2 mins',
    },
    {
        imgsrc: tblUserImg5,
        received: '13s',
    },
    {
        imgsrc: tblUserImg6,
        received: '13s',
    },
    {
        imgsrc: tblUserImg7,
        received: '13s',
    },
    {
        imgsrc: tblUserImg8,
        received: '13s',
    },
    {
        imgsrc: tblUserImg9,
        received: '13s',
    },
    {
        imgsrc: tblUserImg10,
        received: '13s',
    },
    {
        imgsrc: tblUserImg11,
        received: '13s',
    },
    {
        imgsrc: tblUserImg12,
        received: '13s',
    },

]

const ActivityCollection = (props) => {
    const {
        buttonLabel,
        className
    } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);

    const closeBtn = <button className="close">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z" fill="#6E737E" />
        </svg>
    </button>;


    const navigate = useNavigate()
    const toast = useToast()
    const { address, isConnected } = useAccount()
    const { id } = useParams()

    const gridview = (props.filterview) ? 'g-col-5' : 'g-col-6';
    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState('#ffffff')
    const { offermadestatus, setOfferMadeStatus, offermadetype, setOfferMadeType, offermadecollection, setOfferMadeCollection, offermadesearch, setOffermadeSearch } = useContext(ThemeContext)


    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [count1, setCount] = useState('')

    const [totalnftcount, setTotalNftCount] = useState(0)
    // const debouncedSearchText = useDebounce(offermadesearch, 500)

    function handlePageChange(page) {
        setPage(page);
        // ... do something with `page`
    }

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

        GetOfferMade()

    }, [address, id, page, offermadestatus, offermadetype, offermadecollection, offermadesearch])

    let forDeletion = ["traitoffer"]

    const [offerMade, setOfferMade] = useState("")

    console.log("offermadecollection", offermadecollection.length > 0 || offermadesearch !== "" || offermadestatus[0] != [''] ? 1 : page)

    const GetOfferMade = async () => {
        setLoading(true)
        const fetch = await axios.get(`user/offers_made?address=${id ? id : address}&page=${offermadecollection.length > 0 || offermadesearch !== "" || offermadestatus[0] != [''] ? 1 : page}&limit=15&collectionName=${offermadecollection}&filter=${offermadesearch}&status=${offermadestatus}&type=${offermadetype.filter(item => !forDeletion.includes(item))}&trait=${offermadetype.includes("traitoffer") ? "traitoffer" : ""}`)
        if (fetch.status == true) {
            setOfferMade(fetch.data)
            setLoading(false)
            setCount(Math.ceil(fetch.count / 15))
            setTotalNftCount(fetch.count)
        }
        else {
            setLoading(false)
            setOfferMade("")
            setTotalNftCount('')
            setCount("")
        }
    }


    const litstedCollection =
        offermadecollection &&
        offermadecollection.map(item => {
            return (
                <>
                    {item == '' ? (
                        ''
                    ) : (
                        <>
                            <div className='tagLabel'>
                                <span>{item}</span>
                                <i
                                    className='fas fa-times ml-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => closeCollection(item)}
                                ></i>
                            </div>
                        </>
                    )}
                </>
            )
        })

    const closeCollection = item => {
        let a = offermadecollection.includes(item)
        if (a == true) {
            const index = offermadecollection.indexOf(item)
            if (index > -1) {
                // only splice array when item is found
                offermadecollection.splice(index, 1) // 2nd parameter means remove one item only
                const nerArr = [...offermadecollection]
                setOfferMadeCollection(nerArr)
            }
        }
    }


    const clearAll = () => {
        setOfferMadeStatus([""])
        setOfferMadeType([""])
        setOfferMadeCollection([])
        setOffermadeSearch("")
    }

    const closesearch = () => {
        setOffermadeSearch("")
    }

    const closestatus = (data) => {
        let a = offermadestatus.includes(data)
        if (a == true) {
            const index = offermadestatus.indexOf(data);
            if (index > -1) { // only splice array when item is found
                offermadestatus.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...offermadestatus]
                setOfferMadeStatus(nerArr);
                if (nerArr.length == 0) {
                    setOfferMadeStatus([""]);
                }
            }

        }
    }

    const closetype = (data) => {
        let a = offermadetype.includes(data)
        if (a == true) {
            const index = offermadetype.indexOf(data);
            if (index > -1) { // only splice array when item is found
                offermadetype.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...offermadetype]
                setOfferMadeType(nerArr);
                if (nerArr.length == 0) {
                    setOfferMadeType([""]);
                }
            }

        }
    }

    const liststatus = offermadestatus && offermadestatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item == 1 ? "Active" : item == 4 ? "InActive" : ""}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });
    const listtype = offermadetype && offermadetype.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closetype(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });



    const listViewrow = offerMade && offerMade.map((item, index) =>
        <>
            <tr
                onClick={() =>
                    navigate(
                        `/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`
                    )
                }
                style={{ cursor: 'pointer' }}
            >
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='tblImg'>
                            {/* <img src={item.asset} alt={`item.imgsrc`} /> */}
                            {typeof item.asset == "string" && item.asset !== undefined ?

                                item.imageFormat.startsWith('video') ?
                                    <video
                                        src={item.asset}
                                        alt="Uploaded"
                                        muted
                                        autoPlay
                                        style={{ width: "60px", height: "70px", borderRadius: "12px" }}
                                    />

                                    :
                                    item.asset !== 'undefined' ?
                                        <img src={item.asset} alt={`item.imgsrc`} />
                                        :
                                        ""
                                :
                                ""
                            }

                        </div>
                        <div>
                            <div className='fw-600'>{item.nftName} <img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /></div>
                        </div>
                    </div>
                </td>
                <td className='text-right'>
                    <div>{item.price ? item.price.toFixed(8) : ""} {item.chain == "binance" ? "BNB" : item.chain == "ethereum" ? "ETH" : item.chain == "polygon" ? "MATIC" : ""}</div>
                    <div className='fs-12 dark-text-secondary'>${(item.price * item.usdPrice).toFixed(8)}</div>
                </td>
                <td className='text-right'>{item.quantity}</td>
                <td className='text-right'>{item.price ? item.price.toFixed(8) : ""}</td>
                <td className='text-right'>{item.saleExpiry == 0 ? '-' : moment(item.saleExpiry).fromNow()}</td>
                <td className='text-right'>{item.createdAt == 0 ? '-' : moment(item.createdAt).fromNow()}</td>
                {!!address && address.toLowerCase() === item.fromAddress ?
                    <td className='text-right'><span className="cancelTag" onClick={(e) => e.stopPropagation(canceloffer(item))}>Cancel</span></td>
                    : null}
            </tr>
        </>
    )


    const [canceloffermade, setCancelOfferMade] = useState("")
    const [loaders, setloaders] = useState(false)

    const canceloffer = (data) => {
        setCancelOfferMade(data)
        toggle()
    }

    const offer = async () => {
        setloaders(true)

        if (address) {
            let data = {
                _id: canceloffermade._id,
            }

            const fetch = await axios.post(`user/cancel_offerMade`, data)
            if (fetch.status == true) {
                setTimeout(() => {
                    setModal(!modal)
                    toast.success(fetch.message)
                    GetOfferMade()
                    setloaders(false)
                }, 1000)
            } else {
                setModal(!modal)
                setloaders(false)
                toast.error(fetch.message)
            }
        }
        else {
            toast.error("Please connect wallet")
        }
    }

    const Back = () => {
        setModal(!modal)
    }


    return (

        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className='col-lg-6'><span class="cmntxt">{totalnftcount ? totalnftcount : ""} {totalnftcount ? "items" : ""}</span></div>

            <div className='fiterSelectlabel mb-4 mt-2'>
                {offermadesearch ?
                    <div className='tagLabel'>
                        <span>{offermadesearch}</span>
                        <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch}></i>
                    </div>
                    :
                    ""}

                {offermadestatus.includes("All") ? "" : liststatus}{offermadetype.includes("All") ? "" : listtype}{litstedCollection}
                {offermadesearch || offermadecollection.length > 0 || !offermadestatus.includes("") || !offermadetype.includes("") ?
                    <button className='clearAllBtn' onClick={clearAll}>Clear All</button>
                    :
                    ""
                }


            </div>
            <div className='table-responsive'>
                <table className='table table-borderless activityTable offerTable'>
                    {offerMade && offerMade.length > 0 ?
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th className='text-right'>Price</th>
                                <th className='text-right'>Quantity</th>
                                <th className='text-right'>Total</th>
                                <th className='text-right'>Expiration</th>
                                <th className='text-right'>Created</th>
                                <th></th>
                            </tr>
                        </thead>
                        :
                        ""
                    }
                    <tbody>
                        {offerMade && offerMade.length > 0 ?
                            listViewrow
                            :
                            <div className='noItemFound'>
                                <div className='text-center'>
                                    <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                </div>
                                <div className='nofounttxt mb-4'>
                                    No items were found.Try to broaden your search.
                                </div>

                            </div>

                        }

                    </tbody>
                </table>
            </div>

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                        {offerMade && offerMade.length > 0 ?
                            <div class=''>
                                <nav aria-label="Page ">
                                    <ResponsivePagination
                                        total={count1}
                                        current={page}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                        nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                        previousClassName="page-link-1"
                                        nextClassName="page-link-1"
                                    />
                                </nav>
                                {/* <PaginationComponent
                        size='sm'
                        defaultActivePage={1}
                        totalItems={count1}
                        maxPaginationNumbers={3}
                        pageSize={15}
                        onSelect={(page) => setPage(page)}
                    /> */}
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>

            {/* 
            <Modal isOpen={modal1} toggle={toggle1} centered="true" className="mymodal" backdropClassName="selCurBp" backdrop='static'>
                <ModalHeader >Cancel Offer</ModalHeader>
                <ModalBody>
                    <p className="dark-text-secondary text-center">To continue send transaction with your wallet.</p>
                    <div className="d-flex justify-content-center align-items-center loaderCont">
                        <img src={walletLoaderCnt} />
                        <div className="loaderAnimCnt">
                            <img src={walletLoader} className="loaderAnimation" />
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}

            <Modal isOpen={modal} toggle={toggle} centered="true" className="mymodal" backdropClassName="selCurBp" backdrop='static'>
                {loaders == true ? (
                    <>
                        <ModalHeader >Cancel Offer</ModalHeader>
                        <ModalBody>
                            <p className="dark-text-secondary text-center">To continue send transaction with your wallet.</p>
                            <div className="d-flex justify-content-center align-items-center loaderCont">
                                <img src={walletLoaderCnt} />
                                <div className="loaderAnimCnt">
                                    <img src={walletLoader} className="loaderAnimation" />
                                </div>
                            </div>
                        </ModalBody>
                    </>
                ) : (
                    <>
                        <ModalHeader >
                            <div className="d-flex flex-column align-items-center px-4">
                                <img src={redClose} className="mb-3" alt="greenCheck" />
                                Are you sure you want to cancel your offer?
                            </div>
                        </ModalHeader>
                        <ModalBody className="pt-0">
                            <div className='mt-4'>
                                <button className='btn btn-block darkBtn mb-2 text-danger' onClick={offer}>Cancel Offer</button>
                                <button className='btn btn-block darkBtn mb-2' onClick={Back}>Get Back</button>
                            </div>
                        </ModalBody>
                    </>
                )}
            </Modal>
        </>
    )
}

export default ActivityCollection;