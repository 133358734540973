import React, { useState, useContext } from 'react';
import './home-explorer.scss';
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { ThemeContext } from '../context/context';

import classnames from 'classnames';
import AllNFTPage from './allNFT';
import { useTranslation } from 'react-i18next';

const HomeExplorerMain = () => {

    const { t } = useTranslation()
    const { exploreTab, setExploreTab, activeTab, setActiveTab } = useContext(ThemeContext)
    // const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <>
            <div className='container-fluid mt-set'>
                <div className='row'>
                    <div className='col-12'>
                        <Nav tabs className='myhmeExplorertabs'>
                            <NavItem>

                                <NavLink className={`nav-link ${classnames({ active: activeTab == '1' })}`} onClick={() => { toggle('1'); setExploreTab("all") }} style={{ cursor: "pointer" }}>
                                    {t("All NFT's")}
                                </NavLink>

                            </NavItem>
                            <NavItem>
                                <NavLink className={`nav-link ${classnames({ active: activeTab == '2' })}`} onClick={() => { toggle('2'); setExploreTab("Art") }} style={{ cursor: "pointer" }}>
                                    {t("Art")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`nav-link ${classnames({ active: activeTab == '3' })}`} onClick={() => { toggle('3'); setExploreTab("Collectibles") }} style={{ cursor: "pointer" }}>
                                    {t("Collectibles")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`nav-link ${classnames({ active: activeTab == '4' })}`} onClick={() => { toggle('4'); setExploreTab("Music") }} style={{ cursor: "pointer" }}>
                                    {t("Music")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`nav-link ${classnames({ active: activeTab == '5' })}`} onClick={() => { toggle('5'); setExploreTab("Photography") }} style={{ cursor: "pointer" }}>
                                    {t("Photography")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`nav-link ${classnames({ active: activeTab == '6' })}`} onClick={() => { toggle('6'); setExploreTab("Sports") }} style={{ cursor: "pointer" }}>
                                    {t("Sports")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <TabContent activeTab={activeTab} className='mytabcontent'>
                            <TabPane tabId="1">
                                {activeTab === "1" &&
                                    < AllNFTPage />
                                }
                            </TabPane>
                            <TabPane tabId="2">
                                {activeTab === "2" &&
                                    < AllNFTPage />
                                }
                            </TabPane>
                            <TabPane tabId="3">
                                {activeTab === "3" &&
                                    < AllNFTPage />
                                }
                            </TabPane>
                            <TabPane tabId="4">
                                {activeTab === "4" &&
                                    < AllNFTPage />
                                }
                            </TabPane>
                            <TabPane tabId="5">
                                {activeTab === "5" &&
                                    < AllNFTPage />
                                }
                            </TabPane>
                            <TabPane tabId="6">
                                {activeTab === "6" &&
                                    < AllNFTPage />
                                }
                            </TabPane>

                        </TabContent>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeExplorerMain;