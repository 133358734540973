import { useCallback } from 'react'
import {
	ConnectorNotFoundError,
	SwitchChainNotSupportedError,
	useConnect,
	useDisconnect
} from 'wagmi'
import { useNavigate } from 'react-router-dom'

export class WalletConnectorNotFoundError extends Error {}

export class WalletSwitchChainError extends Error {}

export default function useAuth() {
	const { connectAsync, connectors } = useConnect()
	const { disconnectAsync } = useDisconnect()

	const navigate = useNavigate()
	const login = useCallback(
		async ({ connectorId, chainId }) => {
			const connector = connectors.find(c => c.id === connectorId)

			try {
				const connected = await connectAsync({
					connector,
					chainId
				})
				return connected
			} catch (error) {
				if (error instanceof ConnectorNotFoundError) {
					throw new WalletConnectorNotFoundError()
				}
				if (error instanceof SwitchChainNotSupportedError) {
					throw new WalletSwitchChainError(
						'Unable to switch network. Please try it on your wallet'
					)
				}
				if (error.message.includes('User rejected the request')) {
					throw new Error('You rejected the request in your wallet.')
				}
				throw new Error(error)
			}
			// return undefined
		},
		[connectors, connectAsync]
	)

	const logout = useCallback(async () => {
		try {
			await disconnectAsync()
			localStorage.removeItem('access-token')
			sessionStorage.removeItem('WorAddress')
			// localStorage.clear()
			localStorage.setItem('isLoggedIn', 'false')
			dispatchEvent(new Event('storage'))
			navigate('/')
		} catch (error) {
			console.error(error)
		}
	}, [disconnectAsync])

	return { login, logout }
}
