export const StatBoxItem = ({ title, content, label }) => {
	return (
		<div className='listDetails'>
			<div
				className='listchild1'
				style={{ opacity: content !== '--' ? '1' : '0.5' }}
			>
				{title}
				{label !== undefined && (
					<span
						className={`${
							label < 0 ? 'text-red' : 'text-green'
						} ml-1`}
					>
						{label} %
					</span>
				)}
			</div>
			<div
				className='listchild2'
				style={{ opacity: content !== '--' ? '1' : '0.5' }}
			>
				{content}
			</div>
		</div>
	)
}

export default function StatBox({ children }) {
	return (
		<div className='row justify-content-center'>
			<div className='col-lg-11'>
				<div className='moredetailsCnt'>{children}</div>
			</div>
		</div>
	)
}
