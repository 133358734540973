import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { io } from 'socket.io-client'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'

import { AssetCard } from './components/AssetCard'
import { AssetHeader } from './components/AssetHeader'
import { AssetDetails } from './components/AssetDetails'
import { useFetchAssetDetails } from './apis/fetchAssetDetails'
import { Spinner } from '../../components/loader/spinner/Spinner'
import { AssetTraits } from './components/AssetTraits'
import { AssetTradeStation } from './components/AssetTradeStation'
import { AssetNotFound } from './components/AssetNotFound'
import { useBuyNowModalActions } from '../../components/modals/buy-now/store'
import { OfferList } from './components/OfferList'
import { useScollToTop } from '../../hooks/useScrollToTop'
import { AssetListings } from './components/AssetListings'
import { AssetActivity } from './components/AssetActivity'
import { MoreFromTheCollection } from './components/MoreFromTheCollection'
import PriceHistory from './components/PriceHistory'

// import './nft.scss'

const NftDetails = () => {
	const [activeTab, setActiveTab] = useState('1')
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	useScollToTop()
	const { t } = useTranslation()
	const [socket, setSocket] = useState()
	const [currentPrice, setCurrentPrice] = useState()
	const { address: account } = useAccount()
	const params = useParams()
	const { data, isLoading, isError, isFetching } = useFetchAssetDetails(
		params.collectionAddress,
		params.nftId,
		params.supplyId,
		account
	)
	const { setAsset: setBuyNowModalAssetState } = useBuyNowModalActions()
	const hasRendered = useRef(null)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		if (!hasRendered.current) {
			hasRendered.current = true
		}
	}, [])

	useEffect(() => {
		if (!data?.data?.details?.isMarketplace) return
		if (data?.data?.details?.saleType !== 'dutch-auction') return

		const s = io('https://localhost')
		setSocket(s)

		return () => {
			s.disconnect()
		}
	}, [data])

	useEffect(() => {
		if (socket && data) {
			socket.emit('get-order-id', data?.data?.details?.orderId)
		}
	}, [socket, data])

	useEffect(() => {
		if (socket && data) {
			socket.on('dutch-price', (price) => {
				setCurrentPrice(price)
				const asset = {
					...data?.data,
					details: {
						...data?.data?.details,
						price: price ? price : data?.data?.details?.price
					}
				}
				setBuyNowModalAssetState(asset)
			})
		}
	}, [socket, data, setBuyNowModalAssetState])

	if (isLoading || (isFetching && !hasRendered.current)) {
		return (
			<div className='withoutBannerHeaderPage'>
				<section class='TraSec'>
					<div class='container' style={{ height: '85vh ' }}>
						<div
							className='row align-items-center justify-content-center'
							style={{ height: '85vh ' }}
						>
							<Spinner />
						</div>
					</div>
				</section>
			</div>
		)
	}

	if (isError || (!!data && !data.status)) {
		return <AssetNotFound />
	}

	const asset = {
		...data?.data,
		details: {
			...data?.data?.details,
			price: currentPrice ? currentPrice : data?.data?.details?.price
		}
	}

	return (
		<div className='withoutBannerHeaderPage'>
			<section class='TraSec'>
				<div class='container'>
					<div className='row'>
						<div className='col-lg-6 col-xl-5'>
							<AssetCard assetDetails={asset.details} />

							<AssetTradeStation asset={asset} />
						</div>

						<div className='col-lg-6 col-xl-7'>
							<AssetHeader asset={asset} />

							<div className='nftCard detCard'>
								<Nav tabs className='darkTabs mb-3'>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '1'
											})}
											onClick={() => {
												toggle('1')
											}}
										>
											{t('Details')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '2'
											})}
											onClick={() => {
												toggle('2')
											}}
										>
											{t('Offers')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '3'
											})}
											onClick={() => {
												toggle('3')
											}}
										>
											{t('Traits')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '4'
											})}
											onClick={() => {
												toggle('4')
											}}
										>
											{t('Listings')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '5'
											})}
											onClick={() => {
												toggle('5')
											}}
										>
											{t('Price History')}
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent
									activeTab={activeTab}
									className='w-100 scroller'
								>
									<TabPane tabId='1'>
										<AssetDetails asset={asset} />
									</TabPane>
									<TabPane tabId='2'>
										<OfferList asset={asset} />
									</TabPane>
									<TabPane tabId='3'>
										<AssetTraits asset={asset} />
									</TabPane>
									<TabPane tabId='4'>
										<AssetListings asset={asset} />
									</TabPane>
									<TabPane tabId='5'>
										<PriceHistory />
									</TabPane>
								</TabContent>
							</div>
						</div>

						<AssetActivity asset={asset} />

						<MoreFromTheCollection />
					</div>
				</div>
			</section>
		</div>
	)
}
export default NftDetails
