import { Link } from "react-router-dom";
import style from "./Footer.module.scss"
import logo from "./assets/logo.svg";
import logoLight from "./assets/logo-light.svg"
import { Input } from "reactstrap";

import twitter from "./assets/twitter.svg"
import instagram from "./assets/instagram.svg"
import facebook from "./assets/facebook.svg"
import telegram from "./assets/telegram-plane.svg"
import discord from "./assets/discord.svg"
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../views/context/context";
import { axios } from '../../lib/axios'
import loader from '../../assets/images/Loader.png'
import { useToast } from "../../utils/toast/customSuccess";
const Footer = () => {
   const { t } = useTranslation()
   const [scroll, setScroll] = useState(false)
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })
      socialMedia()
   }, [])

   const { exploreTab, setExploreTab, activeTab, setActiveTab, setFavouriteTab, setActiveTab5, themeClr, setThemeClr, socialmedia, setSocialMedia,
      activeTab4, setActiveTab4, watchlistTab, setWatchlistTab } = useContext(ThemeContext)


   const navigateTab = (data) => {
      setExploreTab(data)
   }
   const navigateTab1 = (data) => {
      setWatchlistTab(data)
   }

   const handleFavourite = (data) => {
      setFavouriteTab(data)
   }

   const toast = useToast()
   // const [socialmedia, setSocialMedia] = useState("")


   const socialMedia = async () => {
      setLoading(true)
      const social = await axios.get("home/social-media")
      if (social.status == true) {
         setLoading(false)
         setSocialMedia(social.data)

      }
      else {
         setLoading(false)
         setSocialMedia("")
      }
   }

   const [email, setEmail] = useState("")
   let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

   const handleEmail = async (e) => {
      if (e.target.value == "" || e.target.value == undefined) {
         document.getElementById('email1').innerHTML = 'Please enter email address'
      }
      else
         if (!regex.test(e.target.value)) {
            setEmail(e.target.value)
            document.getElementById('email1').innerHTML = 'Invalid email address'
         }
         else {
            setEmail(e.target.value)
            document.getElementById('email1').innerHTML = ''

         }
   }

   const Emailupdate = async () => {
      setLoading(true)
      if (email == "" || email == undefined) {
         document.getElementById('email1').innerHTML = 'Please enter email address'
         setLoading(false)
      }
      else if (!regex.test(email)) {
         document.getElementById('email1').innerHTML = 'Invalid email address'
         setLoading(false)
      }
      else {
         document.getElementById('email1').innerHTML = ''
         const subscribeemail = await axios.post("home/subscribe", { "email": email })
         if (subscribeemail.status == true) {
            setLoading(false)
            toast.success(subscribeemail.message)
            setEmail("")

         }
         else {
            setLoading(false)
            toast.error(subscribeemail.message)
            setEmail("")
         }
      }
   }


   return (
      <>
         <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>
         <footer className={style.ftr}>
            <div className={style.ftrTp}>
               <div className={style.glow1}></div>
               <div className={style.glow2}></div>
               <div className={style.glow3}></div>
               <div className="container-fluid">
                  <div className={style.ftLogo}>
                     <Link to="/">
                        <img
                           className={`${themeClr ? 'd-none' : ''}`}
                           src={logo} alt="logo" width={115} height={54} />
                        <img
                           className={`${themeClr ? style.lightLogo : 'd-none'}`}
                           src={logoLight} alt="logo"
                           width={115} height={54}
                        />
                     </Link>
                  </div>

                  <div className={`row ${style.ftrR || ""}`}>
                     <div className={`col-md-6 col-lg-4 order-1 order-md-1  ${style.ftrC}`}>
                        <p className={style.ftrMuTl}>
                           {t("Stay in the loop")}
                        </p>
                        <div className={style.ftrSbs}>
                           <Input type="text" placeholder={t("Your Email")} name="email" onChange={handleEmail} value={email} />
                           <span id='email1' style={{ color: "red" }} />
                           <button className="btn gradientBtn mt-3" onClick={Emailupdate}>Sign up</button>
                        </div>
                     </div>
                     <div className={`col-md-6 col-lg-4 col-xl-3 order-2 order-md-2 ${style.ftrC}`}>
                        <p className={style.ftrMuTl}>
                           {t("Marketplace")}
                        </p>
                        <ul className={style.ftMnu}>
                           <li>
                              <Link to="/explorer" onClick={() => { navigateTab("all"); setActiveTab("1") }}>
                                 {t("All NFT's")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/explorer" onClick={() => { navigateTab("Art"); setActiveTab("2") }}>{t("Art")}</Link>
                           </li>
                           <li>
                              <Link to="/explorer" onClick={() => { navigateTab("Music"); setActiveTab("4") }}>{t("Music")}</Link>
                           </li>
                           <li>
                              <Link to="/explorer" onClick={() => { navigateTab("Photography"); setActiveTab("5") }}>{t("Photography")}</Link>
                           </li>
                           <li>
                              <Link to="/explorer" onClick={() => { navigateTab("Sports"); setActiveTab("6") }}>{t("Sports")}</Link>
                           </li>
                        </ul>
                     </div>
                     <div className={`col-md-6 col-lg-4 col-xl-3 order-3 order-md-3 ${style.ftrC}`}>
                        <p className={style.ftrMuTl}>
                           {t("My Account")}
                        </p>
                        <ul className={style.ftMnu}>
                           <li>
                              <Link to="/profile">{t("Profile")}</Link>
                           </li>
                           <li>
                              <Link to="/profile" onClick={() => { handleFavourite("favorites"); setActiveTab5("5") }}>{t("Favorites")}</Link>
                           </li>
                           <li>
                              <Link to='/homecollection' onClick={() => { navigateTab1("Watchlist"); setActiveTab4("3") }}>{t("Watchlist")}</Link>
                           </li>
                           <li>
                              <Link to="/mycollection">{t("My Collections")}</Link>
                           </li>
                           <li>
                              <Link to="/settingsprofile">{t("Settings")}</Link>
                           </li>
                        </ul>
                     </div>
                     <div className={`col-md-6 col-lg-4 col-xl-2  order-4 order-md-4 ${style.ftrC}`}>
                        <p className={style.ftrMuTl}>
                           {t("Resources")}
                        </p>
                        <ul className={style.ftMnu}>
                           <li>
                              <Link to="/">{t("Learn Center")}</Link>
                           </li>
                           <li>
                              <Link to="/helpcenter">{t("Help Center")}</Link>
                           </li>
                           {/* <li>
                              <Link to="/">{t("Community Standards")}</Link>
                           </li>
                           <li>
                              <Link to="/">{t("Partners")}</Link>
                           </li>
                           <li>
                              <Link to="/">{t("Developer Platform")}</Link>
                           </li> */}
                        </ul>
                     </div>

                     <div className={`col-md-6 col-lg-4 order-6 order-md-5 ${style.ftrC}`}>
                        <p className={style.ftrMuTl}>
                           {t("Join the community")}
                        </p>
                        <ul className={style.ftrScl}>
                           <li>
                              <a href={socialmedia && socialmedia.twitter} target="_blank">
                                 <img src={twitter} alt="twitter" width={16} height={14} />
                              </a>
                           </li>
                           <li>
                              <a href={socialmedia && socialmedia.instagram} target="_blank">
                                 <img src={instagram} alt="instagram" width={15} height={15} />
                              </a>
                           </li>
                           <li>
                              <a href={socialmedia && socialmedia.facebook} target="_blank">
                                 <img src={facebook} alt="facebook" width={16} height={16} />
                              </a>
                           </li>
                           <li>
                              <a href={socialmedia && socialmedia.telegram} target="_blank">
                                 <img src={telegram} alt="facebook" width={16} height={16} />
                              </a>
                           </li>
                           <li>
                              <a href={socialmedia && socialmedia.discord} target="_blank">
                                 <img src={discord} alt="discord" width={20} height={15} />
                              </a>
                           </li>
                        </ul>
                     </div>
                     <div className={`col-md-6 col-lg-4 col-xl-2 order-5 order-md-6 ${style.ftrC}`}>
                        <p className={style.ftrMuTl}>
                           {t("Stats")}
                        </p>
                        <ul className={style.ftMnu}>
                           <li>
                              <Link to="/homecollection">{t("Ranking")}</Link>
                           </li>
                           <li>
                              <Link to="/activity">{t("Activity")}</Link>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div className={style.ftrBm}>
               <div className="container-fluid">
                  <div className="row align-items-center justify-content-between">
                     <div className="col-lg-auto">
                        <ul className={style.ftBmMu}>
                           <li>
                              <Link to="/privacy">
                                 {t("Privacy Policy")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/terms">
                                 {t("Terms of Service")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/Legalnotice">
                                 {t("Legal Notices")}
                              </Link>
                           </li>
                        </ul>
                     </div>
                     <div className="col-lg-auto">
                        <p className={style.ftrCpr}>© 2023 WARRIOR. All rights reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   )
}
export default Footer;