import React, { useContext, useEffect, useState } from 'react';
//import '../gridviewlist.scss';
import style from '../profile.module.scss';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Itemimg1 from '../../../assets/images/collection/Itemimg1.png';
import Itemimg2 from '../../../assets/images/collection/Itemimg2.png';
import Itemimg3 from '../../../assets/images/collection/Itemimg3.png';
import Itemimg4 from '../../../assets/images/collection/Itemimg4.png';
import Itemimg5 from '../../../assets/images/collection/Itemimg5.png';
import Itemimg6 from '../../../assets/images/collection/Itemimg6.png';
import Itemimg7 from '../../../assets/images/collection/Itemimg7.png';
import Itemimg8 from '../../../assets/images/collection/Itemimg8.png';
import Itemimg9 from '../../../assets/images/collection/Itemimg9.png';
import Itemimg10 from '../../../assets/images/collection/Itemimg10.png';

import ItemimgSm1 from '../../../assets/images/collection/Itemimg-sm1.png';
import ItemimgSm2 from '../../../assets/images/collection/Itemimg-sm2.png';
import ItemimgSm3 from '../../../assets/images/collection/Itemimg-sm3.png';
import ItemimgSm4 from '../../../assets/images/collection/Itemimg-sm4.png';
import ItemimgSm5 from '../../../assets/images/collection/Itemimg-sm5.png';
import ItemimgSm6 from '../../../assets/images/collection/Itemimg-sm6.png';
import ItemimgSm7 from '../../../assets/images/collection/Itemimg-sm7.png';
import ItemimgSm8 from '../../../assets/images/collection/Itemimg-sm8.png';
import ItemimgSm9 from '../../../assets/images/collection/Itemimg-sm9.png';
import ItemimgSm10 from '../../../assets/images/collection/Itemimg-sm10.png';

import transferIcon from '../../../assets/images/collection/transferIcon.svg';
import listIcon from '../../../assets/images/collection/listIcon.svg';
import offerIcon from '../assets/bestOfferIcon.svg';
import selectIcon from '../assets/selectIcon.svg';
import hideIcon from '../assets/hideIcon.svg';
import copyIcon from '../assets/copyIcon.svg';
import settingsIcon from '../assets/settingsIcon.svg';

import ETHIcon from '../../../assets/images/collection/ETHIcon.png';
import BNBIcon from '../../../assets/images/collection/bnbCoin.png';
import MATICIcon from '../../../assets/images/collection/maticIconSm.png'



import shoppingcart from '../../../assets/images/collection/shopping-cart.svg';

import checkTickIcon from '../../../assets/images/toast/checkIcon.svg';
import removeIcon from '../../../assets/images/toast/checkIcon.svg';
import yellowTick20px from '../../../assets/images/collection/yellow-tick_20px.svg';
import moreIcon from '../assets/moreIcon.svg';
import PaginationComponent from 'react-reactstrap-pagination'

import useToast from '../../../components/alertToast';
import { ThemeContext } from '../../context/context';
import { axios } from '../../../lib/axios'
import {
    useAccount,
} from 'wagmi'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../utils/useDebounce'
import loader from '../../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';

const landPerRow = 6

const GridViewItem = (props) => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { address, isConnected } = useAccount()
    const { id } = useParams()

    const gridview = (props.filterview) ? 'g-col-5' : 'g-col-6';
    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState('#ffffff')

    const { createfilter, setCreateFilter } = useContext(ThemeContext)
    const { createsortFieldfilter, setCreatesortFieldFilter } = useContext(ThemeContext)
    const { createsortOrderfilter, setCreatesortOrderFilter } = useContext(ThemeContext)
    const { createdsearch, setCreatedSearch } = useContext(ThemeContext)
    const { createstatus, setCreateStatus } = useContext(ThemeContext)
    const { createSortStatusField, setCreateSortStatusField } = useContext(ThemeContext)
    const { createSortStatus, setCreateSortStatus } = useContext(ThemeContext)
    const { checkcreateChain, setcreateCheckChain } = useContext(ThemeContext)
    const { checkcreatecollection, setCheckcreateCollection } = useContext(ThemeContext)
    const { checkcreatetype, setCheckcreateType } = useContext(ThemeContext)




    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [getcreatednft, setGetCreatedNft] = useState('')
    // const [count1, setCount] = useState('')

    const [totalnftcount, setTotalNftCount] = useState(0)
    const [count, setCount] = useState('')
    // const debouncedSearchText = useDebounce(createdsearch, 500)

    function handlePageChange(page) {
        setPage(page);
        // ... do something with `page`
    }

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

        GetCreatedNFT()
    }, [address, page, createfilter, createsortFieldfilter, createsortOrderfilter, createdsearch, createstatus, createSortStatusField, createSortStatus, checkcreateChain, checkcreatecollection, checkcreatetype, id])

   
    const GetCreatedNFT = async () => {
        setLoading(true)
        const fetch = await axios.get(
            `user/getcreatedNFTS?ownerAddress=${id ? id : address}&page=${createsortFieldfilter!=="_id" || createsortOrderfilter!=="desc" || createdsearch!=="" || checkcreatecollection.length>0 || checkcreateChain.length>0,createstatus[0] != [''] ? 1 : page}&limit=15&sortField=${createsortFieldfilter}&sortOrder=${createsortOrderfilter}&filter=${createdsearch}&collectionName=${checkcreatecollection}&chain=${checkcreateChain}&sortStatusField=${createstatus}`
        )
        if (fetch.status == true) {
            let cnVerify = fetch.data;

            const getcollection = await axios.post("user/getcollectionandNFTCount", { "address": id ? id : address })
            if (getcollection.status == true) {
                let getcollectionverify = getcollection.data;
                for (let i = 0; i < cnVerify.length; i++) {
                    for (let j = 0; j < getcollectionverify.length; j++) {
                        if (getcollectionverify[j].collectionName === cnVerify[i].collectionName) {
                            let data = getcollectionverify[j].verification;
                            cnVerify[i]['verification'] = data;
                        }
                    }
                }
                setGetCreatedNft(cnVerify)
            }

            setTotalNftCount(Math.ceil(fetch.count / 15))
            setCount(fetch.count)
            setLoading(false)
        }
        else {
            setGetCreatedNft('')
            setTotalNftCount('')
            setCount('')
            setLoading(false)

        }
    }

    const clearAll = () => {
        setCreateFilter("Recently Received")
        setCreatesortFieldFilter("_id")
        setCreatesortOrderFilter("desc")
        setCreatedSearch("")
        setCreateStatus([""])
        setCreateSortStatusField("")
        setCreateSortStatus("")
        setcreateCheckChain([])
        setCheckcreateCollection([])
        GetCreatedNFT()
    }


    const closechain = (item) => {
        let a = checkcreateChain.includes(item)

        if (a == true) {

            const index = checkcreateChain.indexOf(item);
            if (index > -1) { // only splice array when item is found
                checkcreateChain.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...checkcreateChain]
                setcreateCheckChain(nerArr);
            }
        }
    }


    const closecollection = (item) => {
        let a = checkcreatecollection.includes(item)

        if (a == true) {

            const index = checkcreatecollection.indexOf(item);
            if (index > -1) { // only splice array when item is found
                checkcreatecollection.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...checkcreatecollection]
                setCheckcreateCollection(nerArr);
            }

        }
    }


    const closesearch = () => {
        setCreatedSearch("")

    }
    const closefilter = () => {
        setCreateFilter("Recently Received")
        setCreatesortFieldFilter("_id")
        setCreatesortOrderFilter("desc")

    }
    const closestatus = (data) => {
        // setCreateStatus(["All"])
        setCreateSortStatusField("")
        setCreateSortStatus("")
        setCreatesortFieldFilter("")
        setCreatesortOrderFilter("")
        let a = createstatus.includes(data)
        if (a == true) {
            const index = createstatus.indexOf(data);
            if (index > -1) { // only splice array when item is found
                createstatus.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...createstatus]
                setCreateStatus(nerArr);
                if (nerArr.length == 0) {
                    setCreateStatus([""]);
                }
            }

        }
    }

    const listNavigate = (e, item) => {
        e.stopPropagation()
        navigate(`/listforsale/${item.contractAddress}/${item.nftId}`)
    }


    const litstedTagLoop3 = createstatus && createstatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });



    const litstedTagLoop1 = checkcreatecollection && checkcreatecollection.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = checkcreateChain && checkcreateChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closechain(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const ItemCard = getcreatednft && getcreatednft.map((item) => {
        return (
            <>
                <div className='grid-col' style={{ width: '100%', height: "100%", objectFit: "cover" }}>
                    <div className={`gridItemCard ${item.itemadded ? 'active' : ''}`}>
                        <div className='gItemImg'>
                            {typeof item.asset == "string" && item.asset !== undefined ?

                                item.imageFormat.startsWith('video') ?
                                    <video
                                        className='img-fluid w-100 itemImage'
                                        src={item.asset}
                                        alt="Uploaded"
                                        muted
                                        autoPlay
                                        style={{ width: "100px", height: "150px", objectFit: "cover" }}
                                    />
                                    :
                                    item.asset !== 'undefined' ?
                                        <img src={item.asset} alt="Itemimg1" className='img-fluid w-100 itemImage' />
                                        :
                                        ""
                                :
                                ""
                            }

                            <div className='idTag'>
                                #{item.supplyId}
                            </div>
                            <div className='hoverImgCnt' onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="d-flex align-items-center">
                                        <img src={item.chain == "binance" ? BNBIcon : item.chain == "ethereum" ? ETHIcon : item.chain == "polygon" ? MATICIcon : ""} alt="ETHIcon" className='cointypeImg' />
                                        {/* <div className='idTag'>
                                            #{item.supply}
                                        </div> */}
                                    </div>
                                    {/* <div className='plusbadge'>
                                        <i className='fas fa-plus'></i>
                                    </div> */}
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>

                                </div>
                            </div>
                        </div>
                        <div className='botcntCard'>
                            <div className='bottomSmImg'>
                                <img src={item.asset} height={73} alt="ItemimgSm1" />
                            </div>
                            <div className='botcntTxt'>
                                <div className='itemLabel'>{item.collectionName} <img src={item.verification == "Verified" ? yellowTick20px : ""} height="12" /></div>
                                <div className='itemName'>{item.nftName}</div>
                                {/* <div className='d-flex justify-content-between'>
                                    <div className="itemPricetxt">Highest Bid</div>
                                    <div className="itemPriceval">1.4 ETH</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <div className={loading ? 'spinner' : 'd-none'}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>

            <div className='py-3'>
                <div className='row mb-4'>
                    <div className='col-lg-6'><span class="cmntxt">{count > 0 ? count : ""} {count > 0 ? t("Items") : ""}</span></div>

                </div>
                <div className='fiterSelectlabel mb-4'>
                    {createdsearch ?
                        <div className='tagLabel'>
                            <span>{createdsearch}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch}></i>
                        </div>
                        :
                        ""}
                    {createfilter == "Recently Received" ? "" :
                        createfilter ?
                            <div className='tagLabel'>
                                <span>{createfilter}</span>
                                <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closefilter}></i>
                            </div>
                            :
                            ""
                    }

                    {/* {createstatus ?
                        <div className='tagLabel'>
                            <span>{createstatus}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closestatus}></i>
                        </div>
                        :
                        ""
                    } */}

                    {createstatus.includes("All") ? "" : litstedTagLoop3}{litstedTagLoop1}{litstedTagLoop2}
                    {
                        createdsearch || (createfilter == "Recently Received" ? "" :
                            createfilter) || checkcreateChain.length > 0 || checkcreatecollection.length > 0 ||
                            !createstatus.includes("") ?
                            <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                            :
                            ""
                    }


                </div>


                {getcreatednft && getcreatednft.length > 0 ?
                    <div className={`gridrow ${gridview}`}>
                        {ItemCard}
                    </div>
                    :
                    // <div className={`gridrow ${gridview} justify-content-center`}>
                    <div className='noItemFound'>
                        <div className='text-center'>
                            <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                        </div>
                        <div className='nofounttxt mb-4'>
                            {t("No items were found.Try to broaden your search.")}
                        </div>
                        {/* <div>
                                <button className='applyBtn'>{t("Apply")}</button>
                            </div> */}
                    </div>
                    // </div>
                }


                <div className='row justify-content-center'>
                    <div className='col-lg-4 col-md-6 col-sm-8'>
                        <div class="bri_pagenation2">
                            {getcreatednft && getcreatednft.length > 0 ?
                                <div class=''>
                                    <nav aria-label="Page ">
                                        <ResponsivePagination
                                            total={totalnftcount}
                                            current={page}
                                            onPageChange={page => handlePageChange(page)}
                                            previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                            nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                            previousClassName="page-link-1"
                                            nextClassName="page-link-1"
                                        />
                                    </nav>
                                    {/* <PaginationComponent
                            size='sm'
                            defaultActivePage={1}
                            totalItems={totalnftcount}
                            maxPaginationNumbers={3}
                            pageSize={15}
                            onSelect={(page) => setPage(page)}
                        /> */}
                                </div>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
                <useToast toastImgIcon={checkTickIcon} toastmassage={t("Added to Cart")} />
                <useToast toastImgIcon={removeIcon} toastmassage={t("Removed from Cart")} />


            </div>
        </>

    )
}

export default GridViewItem;