import React, { useCallback, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query'
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';

import { fetchCollectionOwnerList, useFetchDistributionList } from '../apis/fetchCollectionOwnerList';
import profileImg from '../../../assets/images/header/userProfileImg.png'
import cndds from '../../../assets/images/cndds.png'
import { formatAddress } from '../../../utils/formatAddress';
import FloorChart from './charts/FloorChart';
import SalesChart from './charts/SalesChart';
import VolumeChart from './charts/VolumeChart';

const CollectionAnalytics = () => {

    const { t } = useTranslation();

    const params = useParams()
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery({
            queryKey: ['ownersList', params.collectionAddress],
            queryFn: ({ pageParam = 1 }) =>
                fetchCollectionOwnerList(params.collectionAddress, pageParam),
            getNextPageParam: (lastPage, allPages) => {
                return lastPage?.length ? allPages?.length + 1 : undefined
            },
            enabled: !!params.collectionAddress
        })

    const distributionMutation = useFetchDistributionList({
        contractAddress: params.collectionAddress
    })

    const intersectionObserverRef = useRef()
    const lastNftRef = useCallback(
        (nft) => {
            if (isFetchingNextPage) {
                return
            }

            if (intersectionObserverRef.current) {
                intersectionObserverRef.current.disconnect()
            }

            intersectionObserverRef.current = new IntersectionObserver(
                (nfts) => {
                    if (
                        nfts[0].isIntersecting &&
                        hasNextPage &&
                        !isFetchingNextPage
                    ) {
                        fetchNextPage()
                    }
                }
            )

            if (nft) {
                intersectionObserverRef.current.observe(nft)
            }
        },
        [isFetchingNextPage, hasNextPage, fetchNextPage]
    )
    const listViewrow = data?.pages?.map((page) => {
        return page?.map((item, i) => {
            if (page?.length === i + 1) {
                return (
                    <tr ref={lastNftRef}>
                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='tblImg'><img src={item.profile === "undefined" || "" ? profileImg : item.profile} alt='owner' /></div>
                                <div className='fw-400 dark-primary'>{item.name === "" ? "Unnamed" : item.name}</div>
                            </div>
                        </td>
                        <td className='text-right'>{formatAddress(item.owner, 8)}</td>
                        <td className='text-right'>{item.owned}</td>
                        <td className='text-right'>{parseFloat(item?.ownedPercentage?.toFixed(2))}%</td>
                    </tr>
                )
            }
            return (
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                            <div className='tblImg'><img src={item.profile === "undefined" || "" ? profileImg : item.profile} alt='owner' /></div>
                            <div className='fw-400 dark-primary'>{item.name === "" ? "Unnamed" : item.name}</div>
                        </div>
                    </td>
                    <td className='text-right'>{formatAddress(item.owner, 8)}</td>
                    <td className='text-right'>{item.owned}</td>
                    <td className='text-right'>{parseFloat(item?.ownedPercentage?.toFixed(2))}%</td>
                </tr>
            )
        })
    })

    const hasNoOwner = listViewrow?.[0]?.length === 0

    return (
        <>
        
            <div className='row mt-3'>
                <div className="col-lg-12">
                    <div className="analyticsCard">
                        <div className='cardTit mb-1'>Floor Chart</div>
                        <FloorChart />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-lg-6">
                    <div className="analyticsCard">
                        <div className='cardTit mb-1'>{t("Sales")}</div>
                        {/* <div> */}
                        {/* <img src={Graph2} alt='graph2' className='img-fluid' /> */}
                        <SalesChart />
                        {/* </div> */}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="analyticsCard">
                        <div className='cardTit mb-1'>{t("Volume")}</div>
                        <div>
                            <VolumeChart />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-lg-6">
                    <div className="analyticsCard" style={{ minHeight: '463px' }}>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className='fs-16 fw-600 dark-text'>{t("Owner")}</div>
                            <div className='dark-text-secondary fs-14 fw-400'>{t("Top")} 100</div>
                        </div>
                        <div className='table-responsive heightTbl scroller'>
                            <table className='table table-borderless analyticsTable'>
                                <thead>
                                    <tr>
                                        <th>{t("Name")}</th>
                                        <th className='text-right'>{t("Wallet")}</th>
                                        <th className='text-right'>Owned</th>
                                        <th className='text-right text-nowrap'>Owned %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listViewrow}
                                </tbody>
                            </table>
                        </div>
                        {hasNoOwner && (
                            <div className='col-12'>
                                <div class='noResf text-center'>
                                    <img
                                        class='img-fluid'
                                        src={cndds}
                                        alt=''
                                    />
                                    <h1>{t("No Data")}</h1>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="analyticsCard">
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className='fs-16 fw-600 dark-text'>{t("Owner Distribution")}</div>
                            <div className='dark-text-secondary fs-14 fw-400'>{distributionMutation?.data?.data?.owners} {t("Owner")}</div>
                        </div>
                        <div className="progressList">
                            <div className='d-flex justify-content-between align-items-center mb12px'>
                                <div className='fs-14 fw-600 dark-text'>{distributionMutation?.data?.data?.onePercentage == undefined ? 0 : parseFloat(distributionMutation?.data?.data?.onePercentage?.toFixed(2))}%</div>
                                <div className='dark-text-secondary fs-14 fw-400'>1 {t("Items")}</div>
                            </div>
                            <Progress value={distributionMutation?.data?.data?.onePercentage} className='cusProgress' />
                        </div>
                        <div className="progressList">
                            <div className='d-flex justify-content-between align-items-center mb12px'>
                                <div className='fs-14 fw-600 dark-text'>{distributionMutation?.data?.data?.twoToThreePercentage == undefined ? 0 : parseFloat(distributionMutation?.data?.data?.twoToThreePercentage?.toFixed(2))}%</div>
                                <div className='dark-text-secondary fs-14 fw-400'>2-3 {t("Items")}</div>
                            </div>
                            <Progress value={distributionMutation?.data?.data?.twoToThreePercentage} className='cusProgress' />
                        </div>
                        <div className="progressList">
                            <div className='d-flex justify-content-between align-items-center mb12px'>
                                <div className='fs-14 fw-600 dark-text'>{distributionMutation?.data?.data?.fourToTenPercentage == undefined ? 0 : parseFloat(distributionMutation?.data?.data?.fourToTenPercentage?.toFixed(2))}%</div>
                                <div className='dark-text-secondary fs-14 fw-400'>4-10 {t("Items")}</div>
                            </div>
                            <Progress value={distributionMutation?.data?.data?.fourToTenPercentage} className='cusProgress' />
                        </div>
                        <div className="progressList">
                            <div className='d-flex justify-content-between align-items-center mb12px'>
                                <div className='fs-14 fw-600 dark-text'>{distributionMutation?.data?.data?.elevenToTwentyfivePercentage == undefined ? 0 : parseFloat(distributionMutation?.data?.data?.elevenToTwentyfivePercentage?.toFixed(2))}%</div>
                                <div className='dark-text-secondary fs-14 fw-400'>11-25 {t("Items")}</div>
                            </div>
                            <Progress value={distributionMutation?.data?.data?.elevenToTwentyfivePercentage} className='cusProgress' />
                        </div>
                        <div className="progressList">
                            <div className='d-flex justify-content-between align-items-center mb12px'>
                                <div className='fs-14 fw-600 dark-text'>{distributionMutation?.data?.data?.twentySixToFiftyPercentage == undefined ? 0 : parseFloat(distributionMutation?.data?.data?.twentySixToFiftyPercentage?.toFixed(2))}%</div>
                                <div className='dark-text-secondary fs-14 fw-400'>26-50 {t("Items")}</div>
                            </div>
                            <Progress value={distributionMutation?.data?.data?.twentySixToFiftyPercentage} className='cusProgress' />
                        </div>
                        <div className="progressList">
                            <div className='d-flex justify-content-between align-items-center mb12px'>
                                <div className='fs-14 fw-600 dark-text'>{distributionMutation?.data?.data?.FiftyOnePercentage == undefined ? 0 : parseFloat(distributionMutation?.data?.data?.FiftyOnePercentage?.toFixed(2))}%</div>
                                <div className='dark-text-secondary fs-14 fw-400'>50+ {t("Items")}</div>
                            </div>
                            <Progress value={distributionMutation?.data?.data?.FiftyOnePercentage} className='cusProgress' />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CollectionAnalytics;