import React, { useState, useEffect } from 'react'
import alertDanger from '../../assets/images/alertDanger.svg'
import aiPfpClub from '../../assets/images/itemIcons/aiPfpClub.png'
import IconModal from '../../assets/images/IconModal.png'
import walletLoaderCnt from '../../assets/images/walletLoader.png'
import walletLoader from '../../assets/images/loaderCircle.png'
import angleDown from '../../assets/images/angleDown.svg'
import coverImg from '../../assets/images/coverImg.png'
import Pencil from '../../assets/images/Pencil.svg'
import plus from '../../assets/images/plus.svg'
import userImage from '../../assets/images/collection/userImage.png'
import deGods from '../../assets/images/itemIcons/deGods.png'
import tick from '../../assets/images/collection/yellow-tick_20px.svg'
import mintBanner from '../../assets/images/mintBanner.png'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledDropdown,
	Modal,
	Input
} from 'reactstrap'
import classnames from 'classnames'
//import './mint.scss'
import { useDropzone } from 'react-dropzone'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { Form, useNavigate } from 'react-router-dom'
import {
	useGetCollection,
	useGetRoyalityFee,
	useCreateNFT,
	useGetName
} from './api'
import WorABIJSON from '../AbiArray/NftWorAbi.json'
import Web3 from 'web3'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
	useDisconnect
} from 'wagmi'
import { useDebounce } from '../../utils/useDebounce'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'
import { chainId } from '../../config/constants/chains'

const validUrl =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const SUPPORTED_FORMATS = ['image/webp', 'image/png', 'image/gif', 'video/mp4']

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

const schema = yup.object().shape({
	nftName: yup
		.string()
		.required('Required')
		.max(30, 'Nft name must be at most 30 characters')
		.min(3, 'Nft name should be atleast 3 characters'),
	// .matches(/^[A-Za-z0-9 ]+$/, 'Enter only Alphanumeric characters'),
	url: yup.string().required('Required').matches(validUrl, 'Enter a valid URL'),
	collectionName: yup.string().required('Required'),
	supply: yup
		.number()
		.typeError('Please enter only number')
		.test('not-zero', 'The value should be atleast one', value => value !== 0)
		.test('no-decimals', 'Decimals are not allowed.', value =>
			Number.isInteger(value)
		)
		.required('Required'),
	asset: yup
		.mixed()
		.required('Upload an image')
		.test(
			'fileFormat',
			'Unsupported format. Upload an image',
			value => value && SUPPORTED_FORMATS.includes(value.type)
		)
})

export const ChooseCollection = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { pro } = useContractProvider()

	const { address, isConnected } = useAccount()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainId = network?.id

	const getCollectionMutation = useGetCollection()
	const getRoyalityFeeMutation = useGetRoyalityFee()
	const createNftMutation = useCreateNFT()
	const fetchNftNameMutation = useGetName()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const [modal1, setModal1] = useState(false)
	const toggle1 = () => setModal1(!modal1)

	useEffect(() => {
		const getCollection = async () => {
			let data = {
				creator: address,
				chain: sessionStorage.getItem('Nftblockchain')
			}
			let res = await getCollectionMutation.mutateAsync(data)
		}

		getCollection()
	}, [])

	const chain = sessionStorage.getItem('Nftblockchain')

	const {
		register,
		control,
		handleSubmit,
		watch,
		formState,
		reset,
		setError,
		setValue,
		clearErrors,
		getValues
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const { isValid, isDirty, errors } = formState

	const getNftName = getValues('nftName')

	const debouncedSearchText = useDebounce(getNftName, 500)

	useEffect(() => {
		const getName = async () => {
			if (debouncedSearchText) {
				let res = await fetchNftNameMutation.mutateAsync({
					nftName: debouncedSearchText
				})
				// if (res?.status) {
				// } else {
				// 	setError('nftName', {
				// 		type: 'required',
				// 		message: 'NFT Name already exists'
				// 	})
				// }
			}
		}

		getName()
	}, [debouncedSearchText])

	useEffect(() => {
		if (fetchNftNameMutation.isSuccess) {
			if (fetchNftNameMutation.data.status) {
			} else {
				setError('nftName', {
					type: 'required',
					message: 'NFT Name already exists'
				})
			}
		}
	}, [fetchNftNameMutation.isSuccess])

	const [fileType, setFileType] = useState()

	const [nftImg, setNftImg] = useState(null)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: 'image/png, image/gif, image/webp, video/mp4',
		maxFiles: 1,
		onDrop: acceptedFiles => {
			setFileType(acceptedFiles[0].type)
			const selectedImage = acceptedFiles[0]
			const maxSize = 100 * 1024 * 1024
			if (acceptedFiles[0].size > maxSize) {
				setError('asset', {
					type: 'required',
					message: 'File size must be less than 100MB.'
				})
				setNftImg('')
			} else if (
				!['image/png', 'image/webp', 'image/gif', 'video/mp4'].includes(
					acceptedFiles[0].type
				)
			) {
				setError('asset', {
					type: 'required',
					message: 'Unsupported file type. Use png, webp, gif, mp4 only'
				})
				setNftImg('')
			} else {
				setNftImg(URL.createObjectURL(selectedImage))
				clearErrors('asset')
				setValue('asset', acceptedFiles[0])
			}
		}
	})

	const [input1, setInput1] = useState('')
	const [input2, setInput2] = useState('')
	const [valueList, setValueList] = useState([])

	function handleOpenDialog(cb = () => {}) {
		setModal1(true)
		cb()
	}

	function handleCloseDialog() {
		setModal1(false)
		setInput1('')
		setInput2('')
		setTraitToEdit(null)
	}

	const handleInput1Change = e => {
		setInput1(e.target.value)
	}

	const handleInput2Change = e => {
		setInput2(e.target.value)
	}

	const [traitToEdit, setTraitToEdit] = useState(null)

	const handleTraits = e => {
		e.preventDefault()
		// Add the values to the list
		if (traitToEdit) {
			const indexOfTraitToEdit = valueList.findIndex(
				v => JSON.stringify(v) === JSON.stringify(traitToEdit)
			)
			const editedTrait = { [input1]: input2 }
			const valueListCopy = [...valueList]
			valueListCopy.splice(indexOfTraitToEdit, 1, editedTrait)
			setValueList([...valueListCopy])
			setTraitToEdit(null)
			setInput1('')
			setInput2('')
			setModal1(false)
			return
		}

		setValueList([...valueList, { [input1]: input2 }])
		// Clear the input fields
		setInput1('')
		setInput2('')
		setTraitToEdit(null)
		setModal1(false)
	}

	const back = () => {
		navigate('/chooseNftChain')
	}

	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const [selectCollection, setSelectCollection] = useState('')
	const [collectionAddress, setCollectionAddress] = useState('')
	const [collectionError, setCollectionError] = useState('')

	const chooseCollectionData = async data => {
		setSelectCollection(data.collectionName)
		setCollectionAddress(data.contractAddress)
		setValue('collectionName', data.collectionName)
		setValue('chain', localStorage.getItem('Nftblockchain'))
		await getRoyalityFeeMutation.mutateAsync({
			contractAddress: data.contractAddress
		})
	}

	const [isLoading, setIsLoading] = useState(false)
	const [mintloading, setMintLoading] = useState(false)
	const [errorMint, setErrorMint] = useState(false)

	const web3 = new Web3(pro)
	const tetherContract = new web3.eth.Contract(WorABIJSON, collectionAddress)

	const onSubmit = async data => {
		if (selectCollection === '') {
			setCollectionError('Please select collection')
			return
		}
		toggle()
		setIsLoading(true)
		setErrorMint(false)

		try {
			if (walletType === 'okx') {
				if (
					sessionStorage.getItem('Nftblockchain') !==
					chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(
						chainIdMap[sessionStorage.getItem('Nftblockchain')]
					)
				}
			} else {
				if (
					sessionStorage.getItem('Nftblockchain') === 'binance' &&
					chainId !== 97
				) {
					await switchNetworkAsync(97)
				} else if (
					sessionStorage.getItem('Nftblockchain') === 'ethereum' &&
					chainId !== 11155111
				) {
					await switchNetworkAsync(11155111)
				} else if (
					sessionStorage.getItem('Nftblockchain') === 'polygon' &&
					chainId !== 80001
				) {
					await switchNetworkAsync(80001)
				}
			}
		} catch (error) {
			setIsLoading(false)
			setErrorMint(true)
			return
		}

		const nftAdd = new FormData()
		nftAdd.append('collectionName', selectCollection)
		nftAdd.append('nftName', data.nftName)
		nftAdd.append('description', data.description)
		nftAdd.append('contractAddress', collectionAddress)
		nftAdd.append('supply', data.supply)
		nftAdd.append('url', data.url)
		nftAdd.append('traits', JSON.stringify(valueList))
		nftAdd.append('chain', getRoyalityFeeMutation?.data?.chain)
		nftAdd.append('asset', data.asset)
		nftAdd.append('nftId', getRoyalityFeeMutation?.data?.nftId)
		nftAdd.append('isMetadata', data.isMetadata)
		nftAdd.append('isUri', 'create')
		nftAdd.append('type', 'user')
		nftAdd.append('creator', address)
		nftAdd.append('imageFormat', fileType)

		const res = await createNftMutation.mutateAsync(nftAdd)

		if (res.status) {
			try {
				await tetherContract.methods
					.mint(
						address.toString(),
						address.toString(),
						getRoyalityFeeMutation?.data?.nftId,
						data.supply.toString(),
						res.uri
					)
					.send({
						from: address.toString()
					})
					.on('transactionHash', async hash => {})
					.on('receipt', async approveresult => {
						const nftUpdate = new FormData()
						nftUpdate.append('collectionName', selectCollection)
						nftUpdate.append('nftName', data.nftName)
						nftUpdate.append('description', data.description)
						nftUpdate.append('contractAddress', collectionAddress)
						nftUpdate.append('supply', data.supply)
						nftUpdate.append('url', data.url)
						nftUpdate.append('traits', JSON.stringify(valueList))
						nftUpdate.append('chain', getRoyalityFeeMutation?.data?.chain)
						nftUpdate.append('asset', data.asset)
						nftUpdate.append('nftId', getRoyalityFeeMutation?.data?.nftId)
						nftUpdate.append('isMetadata', data.isMetadata)
						nftUpdate.append('isUri', 'update')
						nftUpdate.append('uri', res.uri)
						nftUpdate.append('type', 'user')
						nftUpdate.append('creator', address)
						nftUpdate.append('imageFormat', fileType)

						if (approveresult.status === true) {
							const uripass = await createNftMutation.mutateAsync(nftUpdate)

							if (uripass.status) {
								setIsLoading(false)
								setMintLoading(true)
							} else {
								setErrorMint(true)
							}
						}
					})
			} catch (err) {
				setIsLoading(false)
				setErrorMint(true)
			}
		} else {
			setIsLoading(false)
			setErrorMint(true)
			return
		}
	}

	const viewNftDetail = () => {
		navigate(
			`/assets/${chain}/${collectionAddress}/${getRoyalityFeeMutation?.data?.nftId}/1`
		)
	}

	return (
		<>
			<div className='row'>
				<div className='col-lg-2 mb-3 text-lg-right pr-lg-5' onClick={back}>
					<button className='backIcon ml-0'>
						<i className='fas fa-angle-left'></i>
					</button>
				</div>
				<div className='col-lg-3 mb-3'>
					<h3 className='collectionSecHeading text-left'>Create Item</h3>
					<h3 className='collectionSecSubHeading text-left pr-lg-5'>
						Once your NFT minted, you will no longer able to edit it.
					</h3>
				</div>
				<div className='col-lg-6'>
					<div className='createCollectionCard mb-3'>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							{isConnected ? (
								<div className='walletCnt mb-3'>
									<h3>
										{address
											? address.slice(0, 5) + '...' + address.slice(38, 42)
											: ''}
									</h3>
									<span className='walletLabel successLabel ml-auto'>
										{t('Wallet Connected')}
									</span>
								</div>
							) : (
								<div className='walletCnt mb-3'>
									<h3></h3>
									<span
										className='walletLabel successLabel ml-auto'
										style={{ color: 'red' }}
									>
										Please Connect Wallet
									</span>
								</div>
							)}

							<hr />
							<div className='form-row'>
								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel mr-2'>Collection</span>
										<UncontrolledDropdown
											className={`ml-auto collectionDD ${
												errors.collectionName && 'err'
											}`}
										>
											<DropdownToggle
												caret
												className='d-flex align-items-center'
											>
												{selectCollection
													? selectCollection
													: 'Select Collection'}
												<img src={angleDown} className='ml-auto' />
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem
													tag='a'
													onClick={() => navigate('/choosecollection')}
												>
													<div className='d-flex align-items-center createCollectionrow'>
														<div className='addIconCnt mr-2'>
															<img src={plus} className='plusIcon' />
														</div>
														Create Collection
													</div>
												</DropdownItem>
												{getCollectionMutation?.data?.data?.map(collection => (
													<DropdownItem
														tag='a'
														onClick={() => chooseCollectionData(collection)}
														{...register('collectionName')}
														value={collection.collectionName}
													>
														<div className='d-flex align-items-center'>
															<img
																src={collection.logoImage}
																className='mr-2'
																style={{
																	height: '40px',
																	aspectRatio: '1',
																	borderRadius: '12px'
																}}
															/>
															<div className='d-flex flex-column'>
																<h3>
																	{collection.collectionName}{' '}
																	{/* <img src={tick} className='ml-1' /> */}
																</h3>
																{/* <p>CODE-248298</p> */}
															</div>
														</div>
													</DropdownItem>
												))}
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
									{collectionError && (
										<label className='text-danger errLabel ml-auto'>
											{collectionError}
										</label>
									)}
									<hr />
								</div>
								<div className='col-12 mb-4'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>Upload File</span>
										{errors.asset && (
											<label className='text-danger errLabel ml-auto'>
												{errors.asset.message}
											</label>
										)}
									</div>
									<div
										className={
											errors.asset
												? 'errorcoverCnt coverCnt d-flex flex-column'
												: 'coverCnt d-flex flex-column'
										}
									>
										<Controller
											name='asset'
											control={control}
											render={({ field: { onChange } }) => (
												<>
													{nftImg ? (
														<>
															{fileType?.startsWith('image') ? (
																<>
																	<img className='coverImg' src={nftImg} />
																	<div
																		className='justify-content-center align-items-center editIcon'
																		{...getRootProps()}
																		onClick={e => e.stopPropagation()}
																	>
																		<div className='editUplCnt'>
																			<label for='editUplCnt'>
																				<img src={Pencil} />
																			</label>
																			<input
																				{...getInputProps()}
																				id='editUplCnt'
																			/>
																		</div>
																	</div>
																</>
															) : fileType?.startsWith('video') ? (
																<>
																	<video
																		src={nftImg}
																		alt='Uploaded'
																		controls
																		muted
																		autoPlay
																		className='coverImg img-fluid'
																	/>
																	<div
																		className='justify-content-center align-items-center editIcon'
																		{...getRootProps()}
																		onClick={e => e.stopPropagation()}
																	>
																		<div className='editUplCnt'>
																			<label for='editUplCnt'>
																				<img src={Pencil} />
																			</label>
																			<input
																				{...getInputProps()}
																				id='editUplCnt'
																			/>
																		</div>
																	</div>
																</>
															) : (
																<div
																	className='d-flex flex-column align-items-center'
																	{...getRootProps()}
																	onClick={e => e.stopPropagation()}
																>
																	{isDragActive ? (
																		<p>Drop the image here ...</p>
																	) : (
																		<>
																			<span>PNG, GIF, WEBP or MP4.</span>
																			<span className='fs-12 greyTxt'>
																				Max 100mb. At least 1400*360 pixels.
																			</span>
																		</>
																	)}
																	<div className='chooseFileBtn mt-2'>
																		<input
																			{...getInputProps()}
																			id='chooseBtn'
																		/>
																		<label for='chooseBtn'>Choose File</label>
																	</div>
																</div>
															)}
														</>
													) : (
														<div
															className='d-flex flex-column align-items-center'
															{...getRootProps()}
															onClick={e => e.stopPropagation()}
														>
															{isDragActive ? (
																<p>Drop the image here ...</p>
															) : (
																<>
																	<span>PNG, GIF, WEBP or MP4.</span>
																	<span className='fs-12 greyTxt'>
																		Max 100mb. At least 1400*360 pixels.
																	</span>
																</>
															)}
															<div className='chooseFileBtn mt-2'>
																<input {...getInputProps()} id='chooseBtn' />
																<label for='chooseBtn'>Choose File</label>
															</div>
														</div>
													)}
												</>
											)}
										/>
									</div>
								</div>

								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>Name</span>
										{errors.nftName && (
											<label className='text-danger errLabel ml-auto'>
												{errors.nftName.message}
											</label>
										)}
									</div>

									<input
										{...register('nftName')}
										type='text'
										placeholder='Name your NFT'
										className={`form-control ${errors.nftName && 'err'}`}
									/>
								</div>
								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>Supply</span>
										{errors.supply && (
											<label className='text-danger errLabel ml-auto'>
												{errors.supply.message}
											</label>
										)}
									</div>
									{/* <NumericFormat
										{...register('supply')}
										className={`form-control ${errors.supply && 'err'}`}
										label='Supply'
										placeholder='Supply'
										id='supply'
										required
										allowNegative={false}
										decimalScale={0}
										customInput={Input}
									/> */}
									<input
										{...register('supply')}
										type='text'
										placeholder='Supply'
										className={`form-control ${errors.supply && 'err'}`}
									/>
								</div>

								<div className='col-12 mb-3'>
									<span className='formLabel'>Description (Optional)</span>
									{errors.description && (
										<label className='text-danger errLabel ml-auto'>
											{errors.description.message}
										</label>
									)}
									<textarea
										{...register('description')}
										className={`form-control ${errors.description && 'err'}`}
										placeholder='Enter Description'
									/>
									<hr />
								</div>

								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>External Link</span>
										{errors.url && (
											<label className='text-danger errLabel ml-auto'>
												{errors.url.message}
											</label>
										)}
									</div>
									<input
										{...register('url')}
										type='text'
										placeholder='Enter Link Here'
										className={`form-control ${errors.url && 'err'}`}
									/>

									<hr />
								</div>
								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t('Traits')}</span>
									</div>
									{valueList.length > 0 &&
										valueList.map(value => {
											const key = Object.keys(value)[0]
											return (
												<div className='d-flex urlFieldCnt traitsCnt mb-2'>
													<div class='input-group mb-2'>
														<input
															type='text'
															class='form-control'
															value={key}
														/>
														<div class='input-group-append'>
															<div class='input-group-text'>{value[key]}</div>
														</div>
													</div>
													<div
														className='socilaMediaIconCnt ml-2'
														onClick={() => {
															setInput1(key)
															setInput2(value[key])
															handleOpenDialog(() => {
																setTraitToEdit({
																	[key]: value[key]
																})
															})
														}}
													>
														<img src={Pencil} />
													</div>
													<div
														className='socilaMediaIconCnt ml-2'
														onClick={() => {
															setValueList(
																valueList.filter(
																	v =>
																		JSON.stringify(v) !== JSON.stringify(value)
																)
															)
														}}
													>
														-
													</div>
												</div>
											)
										})}
									<div
										className='d-flex align-items-center cursor'
										onClick={toggle1}
									>
										<div className='addIconCnt mr-2'>
											<img src={plus} />
										</div>
										Add Trait
									</div>
									<hr />
								</div>

								<div className='col-12 mb-3'>
									<div className='freezeCnt'>
										<div>
											<span className='formLabel'>Freeze Metadata</span>
											<p>
												Traits describe attributes of your item. They appear as
												filters inside your collection page and are also listed
												out inside your item page.
											</p>
										</div>
										<div className='ml-auto'>
											<label class='switch'>
												<Controller
													name='isMetadata'
													control={control}
													defaultValue={false}
													render={({ field: { onChange, value } }) => (
														<input
															type='checkbox'
															checked={true}
															// onChange={e => onChange(e.target.checked)}
														/>
													)}
												/>
												<span class='slider round'></span>
											</label>
										</div>
									</div>
								</div>

								{/* <div className="col-12 mb-3">
                    <div className="alert alert-danger">
                      <img src={alertDanger} className="mr-2" />Please, fill the missing fields.
                    </div>
                  </div> */}

								<div className='col-12 mb-3'>
									<button type='submit' className='btn btn-block gradientBtn'>
										Create Item
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
			>
				{isLoading ? (
					<div>
						<h3 className='walletHeading my-3'>Loading</h3>
						<h3 className='walletSubHeading'>
							To continue send transaction with your wallet.
						</h3>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							<img src={walletLoaderCnt} />
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</div>
				) : null}
				{mintloading ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							{fileType?.startsWith('image') ? (
								<img src={nftImg ? nftImg : aiPfpClub} width='50px' />
							) : null}
						</div>
						<h3 className='walletHeading my-3'>Item Minted Successfully</h3>
						<button
							type='button'
							className='btn btn-block gradientBtn'
							onClick={() => viewNftDetail()}
						>
							View Item
						</button>
					</div>
				) : null}

				{errorMint ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							<img src={IconModal} />
						</div>
						<h3 className='walletHeading my-3'>Something went wrong</h3>
						<button
							type='button'
							className='btn btn-block darkBtn'
							onClick={toggle}
						>
							{t('Okay')}
						</button>
					</div>
				) : null}
			</Modal>

			<Modal
				isOpen={modal1}
				toggle={handleOpenDialog}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
			>
				<div className='createContent'>
					<button className='btn closeBtn' onClick={handleCloseDialog}>
						<svg
							width='12'
							height='12'
							viewBox='0 0 12 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
								fill='#595F6A'
							/>
						</svg>
					</button>
					<div className='d-flex justify-content-center'>
						{fileType?.startsWith('image') ? (
							<img src={nftImg ? nftImg : aiPfpClub} width='50px' />
						) : null}
					</div>
					<h3 className='walletHeading my-3'>Add Trait</h3>
					<form>
						<div className='row'>
							<div className='col-12 mb-3'>
								<div className='d-flex align-items-center flex-wrap'>
									<span className='formLabel mb-2'>{t('Type')}</span>
								</div>
								<input
									type='text'
									placeholder='Enter Type'
									className='form-control'
									value={input1}
									onChange={handleInput1Change}
								/>
							</div>
							<div className='col-12 mb-3'>
								<div className='d-flex align-items-center flex-wrap'>
									<span className='formLabel mb-2'>{t('Name')}</span>
								</div>
								<input
									type='text'
									placeholder='Enter Name'
									className='form-control'
									value={input2}
									onChange={handleInput2Change}
								/>
							</div>
						</div>
					</form>
					<button
						type='button'
						className='btn btn-block gradientBtn'
						onClick={handleTraits}
						disabled={!input1 || !input2}
					>
						Add Trait
					</button>
				</div>
			</Modal>
		</>
	)
}

export default ChooseCollection
