import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useCollectionFloorChart } from '../../apis/collectionFloorChart';
import moment from 'moment';
import cndds from '../../../../assets/images/cndds.png'
import greenUpArrow from '../../../../assets/images/collection/greenUpArrow.svg';
import redDownArrow from '../../../../assets/images/collection/redDownArrow.svg';
import { Spinner } from 'reactstrap';

const gradientOffset = () => {
    const data = [
        { stop: 0, color: '#dcac38' },
        { stop: 1, color: '#dcac38', opacity: 0.1 },
    ];

    return data.map((entry, index) => (
        <stop key={`stop-${index}`} offset={`${entry.stop * 100}%`} stopColor={entry.color} stopOpacity={entry.opacity || 1} />
    ));
};

const linearGradient = (
    <defs>
        <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
            {gradientOffset()}
        </linearGradient>
    </defs>
);

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <div className="tooltip-image">
                    <img src={dataPoint.nftImage} alt="Tooltip Image" style={{ width: '100px' }} />
                </div>
                <div className="tooltip-content">
                    <h3 className="label-bold">{`${payload[0].value} ${payload[0].payload.currency}`}</h3>
                    <p className="label">{`${payload[0].payload.dateTime}`}</p>
                </div>
            </div>
        );
    }
    return null;
};


const FloorChart = () => {

    const params = useParams()
    const [chartData, setChartData] = useState([]);

    const { data, isLoading, isError, error, isSuccess } = useCollectionFloorChart(
        params.collectionAddress
    )

    useEffect(() => {
        const processedData = data?.status === false ? [] : data?.data.map(item => ({
            time: moment(item?.createdAt).format("MMM D"),
            dateTime: moment(item?.createdAt).format("MMM D, LT"),
            price: item?.price,
            currency: item?.chain,
            nftImage: item?.asset
        }));
        setChartData(processedData);
    }, [isSuccess, data])

    if (isLoading) {
        return (
            <div className="col-12 my-auto">
                <div class="noResf text-center" style={{ height: '200px' }}>
                    <Spinner />
                </div>
            </div>
        )
    }

    if (data?.status === false) {
        return (
            <div className="col-12 my-auto">
                <div class="noResf text-center" >
                    <img class="img-fluid" src={cndds} alt="" />
                    <h1>No Data</h1>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='graphvalue mb-2 d-flex align-items-center'>{data.currentPrice} {data.chain} <span className={data.isUp ? "greenTxt pl-2" : "redTxt pl-2"} ><img src={data.isUp ? greenUpArrow : redDownArrow} alt="greenUpArrow" /> {data.floorDifference}%</span></div>
            <div>
                <ResponsiveContainer width="100%" height={300} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <AreaChart data={chartData}>
                        {linearGradient}
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey="price" stroke="#dcac38" fill="url(#areaGradient)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </>
    )
};

export default FloorChart;