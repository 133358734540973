import { useMemo } from 'react'
import { erc20ABI, usePublicClient, useWalletClient } from 'wagmi'

import { getContract } from '../utils/getContract'
import nftAbi from '../config/abi/nft.json'
import marketplaceAbi from '../config/abi/marketplace.json'

export function useContract(address, abi) {
	const { data: walletClient } = useWalletClient()
	const publicClient = usePublicClient()

	return useMemo(() => {
		if (!address || !abi) return null

		try {
			return getContract({
				abi,
				address,
				signer: walletClient ?? undefined,
				publicClient
			})
		} catch (error) {
			console.error('Failed to get contract', error)
			return null
		}
	}, [address, abi, walletClient])
}

export function useTokenContract(tokenAddress) {
	return useContract(tokenAddress, erc20ABI)
}

export function useNftContract(address) {
	return useContract(address, nftAbi)
}

export function useMarketplaceContract(address) {
	return useContract(address, marketplaceAbi)
}
