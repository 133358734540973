import React, { useState } from 'react';
//import style from './myCollection.scss';
import profileProfPic from '../../assets/images/profile/profileProfPic.png';
import yellowTick24px from '../../assets/images/collection/yellow-tick-24px.svg';
import shareIcon from '../../assets/images/collection/shareIcon.svg';
import ellipsisV from '../../assets/images/collection/ellipsis-v.svg';
import pennant from '../../assets/images/collection/pennant.svg';
import transferIcon from '../../assets/images/collection/transferIcon.svg';
import listIcon from '../../assets/images/collection/listIcon.svg';
import offerIcon from '../Profile/assets/bestOfferIcon.svg';
import selectIcon from '../Profile/assets/selectIcon.svg';
import hideIcon from '../Profile/assets/hideIcon.svg';
import copyIcon from '../Profile/assets/copyIcon.svg';
import settingsIcon from '../Profile/assets/settingsIcon.svg';
import copyClickIcon from '../../assets/images/copyIcon.svg';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import MyCollectionData from './MyCollectionData';
// import './myCollection.scss';
import { useNavigate } from 'react-router-dom';
//import'../../assets/scss/mycollecion_mint.scss'
import { useTranslation } from 'react-i18next';

export const MyCollection = () => {

  const{t}=useTranslation()
  const navigate = useNavigate()

  const createcollection = () => {
    navigate("/choosecollection")
  }



  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <h3 className="collectionSecHeading">{t("My Collections")}</h3>
          <button className='btn gradientBtn ml-auto' onClick={createcollection}>Create Collection</button>
        </div>

        <div className="col-12">
          <div className="table-responsive">
            <MyCollectionData />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCollection;