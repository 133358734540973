import { useAccount, useNetwork } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Web3 from 'web3'

import { chainId } from '../../../config/constants/chains'
import { useMakeOfferModalActions } from '../../../components/modals/makeOffer/store'
import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import { usePurchaseLoadingModalActions } from '../../../components/modals/purchase-loading/store'
import { getOrder } from '../../../utils/getOrder'
import { useSomethingWentWrongModalActions } from '../../../components/modals/error/store'
import { axios } from '../../../lib/axios'
import { useContractProvider } from '../../../utils/useContractProvider'
import contracts from '../../../config/constants/contracts'
import marketplaceAbi from '../../../config/abi/marketplace.json'
import nftAbi from '../../../config/abi/nft.json'
import { useApproveOffer } from '../apis/fetchAssetDetails'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export function ListStation({ asset }) {
	const { t } = useTranslation()

	const { address: account } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { chain } = useNetwork()
	const { pro } = useContractProvider()
	const { mutateAsync: approveOffer } = useApproveOffer(account)

	const navigate = useNavigate()

	const { toggleMakeOfferModal, setAsset: setMakeOfferAssetState } =
		useMakeOfferModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { togglePurchaseLoadingModal, closePurchaseLoadingModal } =
		usePurchaseLoadingModalActions()
	const { openSomethingWentWrongModal } = useSomethingWentWrongModalActions()

	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null

	const handleMakeOffer = async () => {
		setMakeOfferAssetState(asset)

		setConnectWalletModalState({ chainId: chainIdMap[asset.details.chain] })
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleMakeOfferModal
			})
			return
		}

		try {
			if (asset.details.chain !== chainMap[chain.id]) {
				console.log('object')
				await switchNetworkAsync(chainIdMap[asset.details.chain])
			}

			toggleMakeOfferModal()
		} catch (error) {
			console.error(error, 'dfsfd')
		}
	}

	const handleAcceptOffer = async () => {
		togglePurchaseLoadingModal()

		const web3 = new Web3(pro)
		const marketplaceContract = new web3.eth.Contract(
			marketplaceAbi,
			marketplaceContractAddress
		)
		const nftContract = new web3.eth.Contract(
			nftAbi,
			asset.details.contractAddress
		)

		const royaltyFee = asset.details.royalty
		const royaltyReceipt = asset.details.creator
		const isStore = !asset.details.isMinted
		const uri = asset.details.uri
		const order = getOrder({
			asset: {
				...asset,
				owner: { ...asset.owner, address: asset.bestOffer.address },
				details: { ...asset.details, price: asset.bestOffer.price }
			},
			assetType: 'erc1155',
			orderType: 'buy',
			expiry: asset.bestOffer.expiry
		})
		const sig = asset.bestOffer.signTuple

		try {
			if (asset.details.chain === 'binance' && chain.id !== 97) {
				await switchNetworkAsync(97)
			} else if (
				asset.details.chain === 'ethereum' &&
				chain.id !== 11155111
			) {
				await switchNetworkAsync(11155111)
			} else if (
				asset.details.chain === 'polygon' &&
				chain.id !== 80001
			) {
				await switchNetworkAsync(80001)
			}

			const isApprovedForAll = await nftContract.methods
				.isApprovedForAll(account, marketplaceContractAddress)
				.call()

			if (!isApprovedForAll) {
				const response = await nftContract.methods
					.setApprovalForAll(marketplaceContractAddress, true)
					.send({ from: account })

				if (!response.status) {
					throw new Error('Something went wrong.')
				}
			}

			const res = await axios.post('asset/buyer-fee-signature', {
				orderTuple: order,
				royalty: royaltyFee,
				royaltyAddress: royaltyReceipt,
				network: asset.details.chain
			})

			if (!res.status) throw new Error(res.message)

			const buyerFeeSig = [res.data.v, res.data.r, res.data.s]

			console.log({ order, sig, buyerFeeSig, royaltyFee, royaltyReceipt })
			const response = await marketplaceContract.methods
				.buy(
					[order],
					sig,
					[buyerFeeSig],
					[royaltyFee],
					[royaltyReceipt],
					[isStore],
					[uri],
					1
				)
				.send({
					from: account
				})

			if (!response.status) {
				throw new Error('Something went wrong.')
			}

			const params = {
				orderId: asset.bestOffer.offerId,
				address: account
			}
			const approveRes = await approveOffer(params)

			if (approveRes.status) {
				closePurchaseLoadingModal()
			} else {
				throw new Error(approveRes.message)
			}
		} catch (error) {
			console.error(error)
			openSomethingWentWrongModal()
		} finally {
			closePurchaseLoadingModal()
		}
	}

	return (
		<>
			{!!account &&
				account.toLowerCase() === asset.owner.address &&
				asset.details.hasOffers && (
					<div className='d-flex align-items-center w-100 p-1'>
						<div className='d-flex flex-column'>
							<span className='cardLabel'>Best Offer</span>
							<div className='d-flex align-items-center mb-2'>
								<h3 className='mb-0 mr-2'>{`${asset.bestOffer.price} ${asset.bestOffer.currency}`}</h3>
								<span className='cardLabel'>
									$
									{parseFloat(
										asset.bestOffer.priceInUsd.toFixed(2)
									)}
								</span>
							</div>
						</div>
					</div>
				)}
			<div className='w-100 d-flex align-items-center flex-nowrap'>
				{!!account && account.toLowerCase() === asset.owner.address ? (
					<>
						<button
							className={`btn gradientBtn btn160 mr-2 my-1 ${
								!asset.details.hasOffers ? 'w-100' : ''
							}`}
							onClick={() =>
								navigate(
									`/listforsale/${asset.collection.address}/${asset.details.nftId}/${asset.details.supplyId}?ref=assets`
								)
							}
							style={
								asset.details.hasOffers
									? { flexShrink: '1', minWidth: '220px' }
									: {}
							}
						>
							{t('List For Sale')}
						</button>

						{asset.details.hasOffers && (
							<button
								className='btn darkBtn my-1 w-100'
								onClick={handleAcceptOffer}
								style={{ flexGrow: '1' }}
							>
								<svg
									className='mr-2'
									width='19'
									height='8'
									viewBox='0 0 19 8'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
										fill='white'
									/>
								</svg>
								Accept Offer
								<span class='fw-400 pl-1'>{`${parseFloat(
									asset.bestOffer.price.toFixed(4)
								)} ${asset.bestOffer.currency}`}</span>
							</button>
						)}
					</>
				) : (
					<button
						className='btn gradientBtn btn160 mr-2 my-1 flex-grow-1'
						onClick={handleMakeOffer}
					>
						{t('Make Offer')}
					</button>
				)}
			</div>
		</>
	)
}
