import { useEffect } from 'react'
import { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { Items } from './Items'
import CollectionAnalytics from './components/CollectionAnalytics'
import { Activity } from './Activity'
import { Offers } from './Offers'
import { useMakeCollectionOfferModalActions } from '../../components/modals/collection-offer/store'
import { useConnectWalletModalActions } from '../../components/modals/connect-wallet/store'
import AnalyticsCollectionMain from '../../views/collection/collectionAnalyticsMain'

export default function Tabs({ collectionAddress, collectionDetails }) {
	const { t } = useTranslation()

	const [activeTab, setActiveTab] = useState('1')
	const [searchParams, setSearchParams] = useSearchParams()
	const { address: account } = useAccount()

	const { toggleMakeCollectionOfferModal, setCollection } =
		useMakeCollectionOfferModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()

	const isCreator =
		account &&
		account.toLowerCase() ===
		collectionDetails?.data?.creator.address.toLowerCase()

	useEffect(() => {
		setSearchParams((params) => {
			params.delete('traitName')
			params.delete('traitValue')
			return params
		})
	}, [activeTab])

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-lg-6'>
					<Nav
						tabs
						className='mycollectiontabs'
						style={{ cursor: 'pointer' }}
					>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '1'
								})}
								onClick={() => {
									setActiveTab('1')
								}}
							>
								{t('Items')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '2'
								})}
								onClick={() => {
									setActiveTab('2')
								}}
							>
								{t('Offers')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '3'
								})}
								onClick={() => {
									setActiveTab('3')
								}}
							>
								{t('Activity')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '4'
								})}
								onClick={() => {
									setActiveTab('4')
								}}
							>
								{t('Analytics')}
							</NavLink>
						</NavItem>
					</Nav>
				</div>
				{!isCreator && (
					<div className='col-lg-6 text-right'>
						<button
							className='mb-4 collectOfferBtn'
							onClick={() => {
								setCollection(collectionDetails?.data)
								if (!account) {
									toggleConnectWalletModal()
									setConnectWalletModalState({
										callback: toggleMakeCollectionOfferModal
									})
									return
								}
								toggleMakeCollectionOfferModal()
							}}
						>
							{t('Make Collection Offer')}
						</button>
					</div>
				)}
			</div>
			<div className='row'>
				<div className='col-12'>
					<TabContent activeTab={activeTab}>
						<TabPane tabId='1'>
							{activeTab === '1' && (
								<Items
									collectionAddress={collectionAddress}
									collectionDetails={collectionDetails}
								/>
							)}
						</TabPane>

						<TabPane tabId='2'>
							{activeTab === '2' && <Offers collectionAddress={collectionAddress}
								collectionDetails={collectionDetails}
								tab={activeTab} />}
						</TabPane>
						<TabPane tabId='3'>
							{activeTab === '3' && (
								<Activity
									collectionAddress={collectionAddress}
									collectionDetails={collectionDetails}
									tab={activeTab}
								/>
							)}
						</TabPane>
						<TabPane tabId='4'>
							{activeTab === '4' && <AnalyticsCollectionMain />}
						</TabPane>
					</TabContent>
				</div>
			</div>
		</div>
	)
}
