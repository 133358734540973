import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

export async function fetchActivity(
	collectionAddress,
	nftId,
	supplyId,
	activeTab,
	pageParam
) {
	const url = new URL(BACKEND_URL + 'asset/activity')
	url.searchParams.set('collection', collectionAddress)
	url.searchParams.set('id', nftId)
	url.searchParams.set('supplyId', supplyId)
	url.searchParams.set('type', activeTab)
	url.searchParams.set('page', pageParam)

	const response = await axios.get(url)
	return response.data
}
