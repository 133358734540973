import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import structuredClone from '@ungap/structured-clone'

import { fetchCollectionNfts } from '../apis/fetchCollectionNfts'
import { NftList } from './NftList'
import { Chip, PriceChip, TraitChip } from './NftsInGridView'
import { INITIAL_FILTER_STATE } from '../../../utils/parseQueryStringToObject'
import { flattenFilters } from '../../../utils/flattenFilters'
import { NoItemsFound } from '../Items'
import { useCollectionActions, useCollectionState } from '../store'

export default function NftsInListView({
	view,
	showFilters,
	filters,
	handleFiltersChange
}) {
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useSearchParams()
	const params = useParams()
	const navigate = useNavigate()

	const { noOfItems } = useCollectionState()
	const { setNoOfItems } = useCollectionActions()

	const flattenedFilters = filters && flattenFilters(filters)
	const filterEntries = filters && Object.entries(flattenedFilters)

	const chips = filterEntries
		?.filter(
			([key, value]) =>
				!(
					key.includes('stringTraits') ||
					key.includes('priceFilter') ||
					key.includes('sort')
				)
		)
		.map(([key, value], i) => {
			return (
				<Chip
					key={key}
					filters={filters}
					filter={{ key, value }}
					onClearChip={handleFiltersChange}
				/>
			)
		})

	const a = filters?.search.stringTraits ?? {}
	const b = Object.values(a).reduce((acc, cur) => {
		if (!acc.hasOwnProperty(cur.name)) {
			acc[cur.name] = Object.values(cur.values)
		}
		return acc
	}, {})

	const traitChips = Object.entries(b ?? {}).map(([key, value]) => {
		return value.map((v) => {
			return (
				<TraitChip
					key={v}
					filters={filters}
					filter={{ key, value: v }}
					onClearChip={handleFiltersChange}
				/>
			)
		})
	})

	const priceFilters = filters?.search.priceFilter ?? {}
	const priceFilter = Object.entries(priceFilters).reduce(
		(acc, [key, value], i, arr) => {
			if (key === 'symbol' && !acc.hasOwnProperty(value)) {
				acc[value] = { min: arr[1][1], max: arr[2][1] }
			}
			return acc
		},
		{}
	)

	const priceChip = Object.entries(priceFilter ?? {}).map(([key, value]) => {
		return (
			<PriceChip
				key={key}
				filters={filters}
				filter={{ key, value }}
				onClearChip={handleFiltersChange}
			/>
		)
	})

	const handleClearAll = () => {
		handleFiltersChange(structuredClone(INITIAL_FILTER_STATE))
	}

	const showClearAllButton =
		filterEntries?.filter(
			([key, value]) =>
				!!value && !key.includes('owner') && !key.includes('sort')
		).length !== 0

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				'nfts',
				params.collectionAddress,
				searchParams.toString(),
				filters,
				view
			],
			queryFn: ({ pageParam = 1 }) =>
				fetchCollectionNfts(
					params.collectionAddress,
					filters,
					pageParam,
					(noOfItems) => {
						setNoOfItems(noOfItems)
					}
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.length ? allPages.length + 1 : undefined
			},
			enabled: !!params.collectionAddress
		})

	const intersectionObserverListRef = useRef()
	const lastListNftRef = useCallback(
		(nft) => {
			if (isFetchingNextPage) {
				return
			}

			if (intersectionObserverListRef.current) {
				intersectionObserverListRef.current.disconnect()
			}

			intersectionObserverListRef.current = new IntersectionObserver(
				(nfts) => {
					if (
						nfts[0].isIntersecting &&
						hasNextPage &&
						!isFetchingNextPage
					) {
						fetchNextPage()
					}
				}
			)

			if (nft) {
				intersectionObserverListRef.current.observe(nft)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const nftLists = data?.pages.map((page) => {
		return page.map((nft, i) => {
			if (page.length === i + 1) {
				return (
					<NftList
						ref={lastListNftRef}
						key={nft.details._id}
						item={nft}
						onClick={() =>
							navigate(
								`/assets/${nft.details.chain}/${nft.details.contractAddress}/${nft.details.nftId}/${nft.details.supplyId}`
							)
						}
					/>
				)
			}
			return (
				<NftList
					key={nft.details._id}
					item={nft}
					onClick={() =>
						navigate(
							`/assets/${nft.details.chain}/${nft.details.contractAddress}/${nft.details.nftId}/${nft.details.supplyId}`
						)
					}
				/>
			)
		})
	})

	const showChips =
		Object.values(flattenedFilters).filter(
			(f) => f !== null && f !== 'true'
		).length > 0
	const hasNoData =
		nftLists &&
		searchParams.toString().length > 0 &&
		data?.pages?.[0].length === 0
	const hasNoItems =
		nftLists && searchParams.toString().length === 0 && noOfItems === 0

	return (
		<>
			<div className='table-responsive'>
				<span class='cmntxt mt-3'>
					{noOfItems} {t('Items')}
				</span>

				{showChips && (
					<div className='fiterSelectlabel mt-4 mb-4'>
						{chips &&
							traitChips &&
							priceChip && [
								...chips,
								...traitChips,
								...priceChip
							]}
						{showClearAllButton && (
							<button
								className='clearAllBtn'
								onClick={handleClearAll}
							>
								{t('Clear All')}
							</button>
						)}
					</div>
				)}
				{hasNoData ? (
					<NoItemsFound onClick={() => setSearchParams('')} />
				) : hasNoItems ? (
					<NoItemsFound displayText='No items' />
				) : (
					<table className='table table-borderless listViewTable'>
						<thead>
							<tr>
								<th>{t('Items')}</th>
								<th className='text-right'>
									{t('Highest Bid')}
								</th>
								<th className='text-right'>{t('Last Sale')}</th>
								<th className='text-right'>{t('Owner')}</th>
								<th>
									<svg
										width='21'
										height='20'
										viewBox='0 0 21 20'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M10.6665 4.6875C13.8774 4.6875 16.479 7.28906 16.479 10.5C16.479 13.7109 13.8774 16.3125 10.6665 16.3125C7.45557 16.3125 4.854 13.7109 4.854 10.5C4.854 7.28906 7.45557 4.6875 10.6665 4.6875ZM10.6665 15.1875C13.2446 15.1875 15.354 13.1016 15.354 10.5C15.354 7.92188 13.2446 5.8125 10.6665 5.8125C8.06494 5.8125 5.979 7.92188 5.979 10.5C5.979 13.1016 8.06494 15.1875 10.6665 15.1875ZM12.0962 12.75L10.104 11.2969C10.0337 11.25 10.0103 11.1562 10.0103 11.0859V7.21875C10.0103 7.07812 10.1274 6.9375 10.2915 6.9375H11.0415C11.1821 6.9375 11.3228 7.07812 11.3228 7.21875V10.5469L12.8696 11.6953C13.0103 11.7891 13.0337 11.9531 12.9399 12.0938L12.4946 12.6797C12.4009 12.8203 12.2368 12.8438 12.0962 12.75Z'
											fill='#595F6A'
										/>
									</svg>
								</th>
								<th className='text-right'></th>
							</tr>
						</thead>
						<tbody>{nftLists}</tbody>
					</table>
				)}
			</div>
		</>
	)
}
