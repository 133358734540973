import { useState } from 'react'
import { Modal } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { AssetPlaceholder } from './loader/AssetPlaceholder'
import useCountdown from '../../../hooks/useCountdown'
import { formatRoundTime } from '../../../utils/formatRoundTime'

import timerIcon from '../../../assets/images/timerIcon.svg'
import likeIcon from '../../../assets/images/likeIcon.svg'
import viewIcon from '../../../assets/images/viewIcon.svg'

export function AssetCard({ assetDetails }) {
	const { t } = useTranslation()

	const [isOpen, setIsOpen] = useState(false)
	const [isImageLoaded, setIsImageLoaded] = useState(false)
	const { secondsRemaining } = useCountdown(assetDetails.saleExpiry)
	const countdown = formatRoundTime(secondsRemaining)
	const isVideo = assetDetails.asset.includes('.mp4')

	const toggle = () => setIsOpen((current) => !current)

	return (
		<>
			<div
				className={`nftBannerCnt ${isImageLoaded ? 'hover' : ''}`}
				onClick={!isVideo ? toggle : () => {}}
			>
				{!isVideo ? (
					<>
						{isImageLoaded ? null : <AssetPlaceholder />}
						<img
							src={assetDetails.asset}
							className='nftBanner'
							style={isImageLoaded ? {} : { display: 'none' }}
							onLoad={() => setIsImageLoaded(true)}
						/>
						{isImageLoaded ? (
							<div className='counterCnt d-flex align-items-center flex-wrap'>
								<div
									className='counter d-flex align-items-center ml-auto cursor-default'
									onClick={(e) => e.stopPropagation()}
								>
									<img src={likeIcon} className='mr-1' />
									<span className='mr-1'>
										{assetDetails.favoritesCount}{' '}
										{t('Favorites')}
									</span>
									<img src={viewIcon} className='mr-1' />
									<span>{assetDetails.viewsCount} Views</span>
								</div>
							</div>
						) : null}
					</>
				) : (
					<>
						<video
							id='video-asset'
							controls
							loop
							autoplay=''
							playsinline=''
							preload='metadata'
							controlslist='nodownload'
							src={assetDetails.asset}
							style={{
								objectFit: 'contain',
								height: '530px',
								width: '535px'
							}}
						/>
						<div className='counterCnt d-flex align-items-center flex-wrap'>
							{assetDetails.isMarketplace && (
								<div className='counter d-flex align-items-center cursor-default'>
									<img src={timerIcon} className='mr-1' />
									<span>{countdown}</span>
								</div>
							)}
							<div className='counter d-flex align-items-center ml-auto cursor-default'>
								<img src={likeIcon} className='mr-1' />
								<span className='mr-1'>
									{assetDetails.favoritesCount}{' '}
									{t('Favorites')}
								</span>
								<img src={viewIcon} className='mr-1' />
								<span>{assetDetails.viewsCount} Views</span>
							</div>
						</div>
					</>
				)}
			</div>

			<Modal
				isOpen={isOpen}
				toggle={toggle}
				centered='true'
				className='curMdl lgMdl'
				backdropClassName='selCurBp'
			>
				<img src={assetDetails.asset} />
			</Modal>
		</>
	)
}
