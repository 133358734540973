import {
   Input,
   InputGroup,
   TabContent,
   TabPane,
   Nav,
   NavItem,
   NavLink,
   UncontrolledCollapse,
   UncontrolledTooltip
} from 'reactstrap'
import React, { useContext, useEffect, useState } from 'react'
import './HomeCollection.scss'
import classnames from 'classnames'

import xnd from './assets/xnd.png'
import updown from './assets/updown.png'
import tb1 from './assets/tb1.png'
import tb2 from './assets/tb2.png'
import tb3 from './assets/tb3.png'
import tb4 from './assets/tb4.png'
import tb5 from './assets/tb5.png'
import tb6 from './assets/tb6.png'
import tb7 from './assets/tb7.png'
import tb8 from './assets/tb8.png'
import tb9 from './assets/tb9.png'
import tb10 from './assets/tb10.png'
import tb11 from './assets/tb11.png'
import sm4 from './assets/sm4.png'
import sm3 from './assets/sm3.png'
import sm2 from './assets/sm2.png'
import sm1 from './assets/sm1.png'
import angleDown from './assets/angle-down.png'
import tether from './assets/tether.png'
import { axios } from '../../lib/axios'
import ScaleLoader from 'react-spinners/ScaleLoader'
import PaginationComponent from 'react-reactstrap-pagination'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'

import { useAccount } from 'wagmi'
import { useToast } from '../../utils/toast/customSuccess'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../context/context'
import { useNavigate } from 'react-router-dom'
import loader from '../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';
import nodatayet from "./assets/nodatayet.svg";

const landPerRow = 6

const Bridge = () => {
   const { t } = useTranslation()
   const [activeTab, setActiveTab] = useState(
      sessionStorage.getItem('tab') ? sessionStorage.getItem('tab') : '1'
   )
   const { activeTab4, setActiveTab4, watchlistTab, setWatchlistTab } =
      useContext(ThemeContext)

   // const [activeTab4, setActiveTab4] = useState('1');
   const toggle = tab => {
      if (activeTab4 !== tab) setActiveTab4(tab)
   }

   const [activeTab1, setActiveTab1] = useState('4')
   const toggle1 = tab => {
      if (activeTab1 !== tab) setActiveTab1(tab)
   }

   const { address, isConnected } = useAccount()

   const [loading, setLoading] = useState(false)
   let [color, setColor] = useState('#ffffff')
   const [next, setNext] = useState(landPerRow)
   const [page, setPage] = useState(1)

   const navigate = useNavigate()

   const Chains = [
      {
         id: 1,
         name: t('BNB'),
         value: 'binance'
      },
      {
         id: 2,
         name: t('ETH'),
         value: 'ethereum'
      },
      {
         id: 3,
         name: t('MATIC'),
         value: 'polygon'
      }
   ]

   const toast = useToast()
   const [checkcollectedChain, setcollectedCheckChain] = useState([])
   const [checkcategory, setCheckCategory] = useState([])

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })

      fetchdata()
      getCategory()
   }, [
      activeTab4,
      page,
      address,
      checkcollectedChain,
      activeTab1,
      checkcategory
   ])

   const [ranking, setRanking] = useState('')
   const [count, setCount] = useState('')


   function handlePageChange(page) {
      setPage(page);
      // ... do something with `page`
   }

   console.log("checkcategory", checkcategory.length > 0 || checkcollectedChain.length > 0 ? 1 : page)

   const fetchdata = async () => {
      setLoading(true)
      const fetch = await axios.get(
         `home/ranking?page=${checkcategory.length > 0 || checkcollectedChain.length > 0 ? 1 : page}&size=15&type=${activeTab4 == '1' ? 'trending' : activeTab4 == '2' ? 'top' : 'watchlist'
         }&categoryName=${checkcategory}&filter=${activeTab1 == '1'
            ? 1
            : activeTab1 == '2'
               ? 6
               : activeTab1 == 3
                  ? '24'
                  : 7
         }&chains=${checkcollectedChain}&address=${address}`
      )
      if (fetch.status == true) {
         setRanking(fetch.data)
         setCount(Math.ceil(fetch.count / 15))
         setLoading(false)
      } else if (fetch.status == false) {
         setRanking('')
         setCount('')
         setLoading(false)
      }
   }

   const [categories, setCategories] = useState('')

   const getCategory = async () => {
      // setLoading(true)
      const fetch = await axios.get('home/categories')
      if (fetch.status == true) {
         // setLoading(false)
         setCategories(fetch.data)
      } else {
         // setLoading(false)
         setCategories('')
      }
   }

   const handleCheckboxChange = item => {
      let a = checkcollectedChain.includes(item.value)

      if (a == true) {
         const index = checkcollectedChain.indexOf(item.value)
         if (index > -1) {
            // only splice array when item is found
            checkcollectedChain.splice(index, 1) // 2nd parameter means remove one item only
            const nerArr = [...checkcollectedChain]
            setcollectedCheckChain(nerArr)
         }
      } else if (a == false) {
         setcollectedCheckChain([...checkcollectedChain, item.value])
      }
   }

   const litstedChain =
      checkcollectedChain &&
      checkcollectedChain.map(item => {
         return (
            <>
               {item == '' ? (
                  ''
               ) : (
                  <>
                     <div className='tagLabel'>
                        <span>{item}</span>
                        <i
                           className='fas fa-times ml-2'
                           style={{ cursor: 'pointer' }}
                           onClick={() => closecollectedchain(item)}
                        ></i>
                     </div>
                  </>
               )}
            </>
         )
      })

   const closecollectedchain = item => {
      let a = checkcollectedChain.includes(item)

      if (a == true) {
         const index = checkcollectedChain.indexOf(item)
         if (index > -1) {
            // only splice array when item is found
            checkcollectedChain.splice(index, 1) // 2nd parameter means remove one item only
            const nerArr = [...checkcollectedChain]
            setcollectedCheckChain(nerArr)
         }
      }
   }

   const handleCheckboxCollection = item => {
      let a = checkcategory.includes(item.name)

      if (a == true) {
         const index = checkcategory.indexOf(item.name)
         if (index > -1) {
            // only splice array when item is found
            checkcategory.splice(index, 1) // 2nd parameter means remove one item only
            const nerArr = [...checkcategory]
            setCheckCategory(nerArr)
         }
      } else if (a == false) {
         setCheckCategory([...checkcategory, item.name])
      }
   }

   const litstedCategory =
      checkcategory &&
      checkcategory.map(item => {
         return (
            <>
               {item == '' ? (
                  ''
               ) : (
                  <>
                     <div className='tagLabel'>
                        <span>{item}</span>
                        <i
                           className='fas fa-times ml-2'
                           style={{ cursor: 'pointer' }}
                           onClick={() => closecategory(item)}
                        ></i>
                     </div>
                  </>
               )}
            </>
         )
      })

   const closecategory = item => {
      let a = checkcategory.includes(item)

      if (a == true) {
         const index = checkcategory.indexOf(item)
         if (index > -1) {
            // only splice array when item is found
            checkcategory.splice(index, 1) // 2nd parameter means remove one item only
            const nerArr = [...checkcategory]
            setCheckCategory(nerArr)
         }
      }
   }

   const watchlist = async (data) => {
      setLoading(true)
      if (address) {
         const a = data.watchlistedBy.includes(
            address ? address.toLowerCase() : ''
         )
         if (a == true) {
            const fetch = await axios.get(
               `home/watchlist-mutation?id=${data._id}&userAddress=${address ? address.toLowerCase() : ''
               }&isWatchlist=${false}`
            )
            if (fetch.status == true) {
               toast.error('Removed from Watchlist')
               setActiveTab4('3')
               fetchdata()
               setLoading(false)
            }
         } else {
            const fetch = await axios.get(
               `home/watchlist-mutation?id=${data._id}&userAddress=${address ? address.toLowerCase() : ''
               }&isWatchlist=${true}`
            )
            if (fetch.status == true) {
               toast.star(<p>Added to Watchlist  <span style={{ color: "#FFC300", cursor: "pointer" }} onClick={view}>View</span></p>)
               setActiveTab4('3')
               fetchdata()
               setLoading(false)
            }
         }
      } else {
         toast.error('Please connect wallet')
      }
   }

   const view = () => {
      setActiveTab4('3')
   }

   const clearAll = () => {
      setcollectedCheckChain([])
      setCheckCategory([])
   }

   return (
      <>
         <div className={loading ? 'spinner' : 'd-none'}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>

         <div className='withoutBannerHeaderPage'>

            <section class='TraSec'>
               <div class='container-fluid'>
                  <div class='d-flex align-items-center justify-content-between flex-wrap'>
                     <div class='mb-36'>
                        <Nav tabs className='trad_tab_hd'>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab4 === '1' })}
                                 onClick={() => {
                                    toggle('1')
                                 }}
                              >
                                 {t('Trending')}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab4 === '2' })}
                                 onClick={() => {
                                    toggle('2')
                                 }}
                              >
                                 {t('Top')}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab4 === '3' })}
                                 onClick={() => {
                                    toggle('3')
                                    setWatchlistTab('Watchlist')
                                 }}
                              >
                                 {t('Watchlist')}
                              </NavLink>
                           </NavItem>
                        </Nav>
                        {/* <ul class="nav trad_tab_hd " role="tablist">
                           <li class="nav-item">
                              <a class="nav-link btn active mr-2" data-toggle="tab" href="#treitem">
                                 Trending
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link  btn  mr-2" data-toggle="tab" href="#topitem">
                                 Top
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link  btn" data-toggle="tab" href="#watchitem">
                                 Watchlist
                              </a>
                           </li>
                        </ul> */}
                     </div>
                     <div class='mb-36'>
                        <div class=' mt-2 '>
                           <Nav tabs className='hrs_tab_hd'>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab1 === '1' })}
                                    onClick={() => {
                                       toggle1('1')
                                    }}
                                    style={{ cursor: "pointer" }}
                                 >
                                    {t('1h')}
                                 </NavLink>
                              </NavItem>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab1 === '2' })}
                                    onClick={() => {
                                       toggle1('2')
                                    }}
                                    style={{ cursor: "pointer" }}
                                 >
                                    {t('6h')}
                                 </NavLink>
                              </NavItem>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab1 === '3' })}
                                    onClick={() => {
                                       toggle1('3')
                                    }}
                                    style={{ cursor: "pointer" }}
                                 >
                                    {t('24h')}
                                 </NavLink>
                              </NavItem>
                              <NavItem>
                                 <NavLink
                                    className={classnames({ active: activeTab1 === '4' })}
                                    onClick={() => {
                                       toggle1('4')
                                    }}
                                    style={{ cursor: "pointer" }}
                                 >
                                    {t('7d')}
                                 </NavLink>
                              </NavItem>
                           </Nav>
                        </div>
                     </div>
                  </div>
                  <div>
                     <div class='row'>
                        <div class='col-xl-9 order-xl-1 order-2 mb-3'>
                           <TabContent activeTab={activeTab4}>
                              <TabPane tabId='1'>
                                 <div>
                                    <div class='mb-2 d-flex flexwrap'>
                                       <div className='fiterSelectlabel mb-4'>
                                          {/* <label class="artcrd mr-1"><span>Art</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label>
                                    <label class="artcrd mr-1"><span>Music</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}

                                          {/* <label class="artcrd mr-1"><span>Binance</span> <span class="crs"><img
                                       src={xnd} alt="" /></span></label> */}
                                          {litstedChain}{litstedCategory}
                                          {checkcollectedChain?.length > 0 || checkcategory.length > 0 ?
                                             <label class="artcrd mr-1" onClick={clearAll}>{t("Clear All")}</label>
                                             :
                                             ""
                                          }
                                       </div>
                                    </div>
                                    <div class="table-responsive">
                                       <table class="table table-borderless mb-0 hmecollection">
                                          {ranking && ranking.length > 0 ?
                                             <thead class="trd_head">
                                                <tr>
                                                   <th></th>
                                                   <th>{t("Collections")} </th>
                                                   <th></th>
                                                   <th>{t("Volume")} <span><img src={updown} alt="" /></span>
                                                   </th>
                                                   <th>{t("Volume Change")} <span><img src={updown}
                                                      alt="" /></span></th>
                                                   <th>{t("Floor Price")} <span><img src={updown}
                                                      alt="" /></span></th>
                                                   <th>{t("Floor Change")} <span><img src={updown}
                                                      alt="" /></span> </th>
                                                   <th>{t("Sales")}</th>
                                                   <th>
                                                      {t("Owners")}
                                                   </th>
                                                   <th>{t("Items")} </th>
                                                </tr>
                                             </thead>
                                             :
                                             ""
                                          }

                                          {ranking && ranking.length > 0 ?
                                             ranking && ranking.map((data, index) => (
                                                <tbody class="trdtable_body" >
                                                   <tr>
                                                      <td class="gld">
                                                         {index + 1}
                                                      </td>
                                                      <td
                                                         onClick={() => navigate(`/collection/${data.contractAddress}`)} style={{ cursor: "pointer" }}
                                                      >
                                                         <div class="d-flex align-items-center">
                                                            <span class="mr-2">
                                                               <img src={data.logoImage} alt="" height="40px" width="40px" style={{ objectFit: "cover", borderRadius: "50%" }} />
                                                            </span>
                                                            <h1 class="mr-2"> {data.collectionName}</h1>
                                                            <span class="mr-2">
                                                               <img src={data.verification == "Pending" ? "" : sm4} alt="" />
                                                            </span>
                                                            <span>
                                                               <img src={data.chain == "binance" ? sm1 : data.chain == "ethereum" ? sm2 : data.chain == "polygon" ? sm3 : ""} alt="" />
                                                            </span>
                                                         </div>
                                                      </td>
                                                      <td>
                                                         {data.watchlistedBy.includes(address ? address.toLowerCase() : "") == true ?

                                                            <>
                                                               <svg id="tooltip2" width="19" height="18" viewBox="0 0 19 18"
                                                                  fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => watchlist(data)} style={{ cursor: "pointer" }}>
                                                                  <path
                                                                     d="M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z"
                                                                     fill="#FFA900" />

                                                               </svg>
                                                               <UncontrolledTooltip placement="top" target="tooltip2" className="tooltipCnt red-tooltip">
                                                                  {t("Remove to Watchlist")}
                                                               </UncontrolledTooltip>
                                                            </>

                                                            :
                                                            <>
                                                               <svg id="tooltip1" width="19" height="18" viewBox="0 0 19 18"
                                                                  fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => watchlist(data)} style={{ cursor: "pointer" }}>
                                                                  <path
                                                                     d="M16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375ZM12.5537 10.7812L15.71 7.71875L11.3662 7.09375L9.42871 3.15625L7.45996 7.09375L3.11621 7.71875L6.27246 10.7812L5.52246 15.0938L9.42871 13.0625L13.3037 15.0938L12.5537 10.7812Z"
                                                                     fill="#595F6A" />
                                                               </svg>
                                                               <UncontrolledTooltip placement="top" target="tooltip1" className="tooltipCnt red-tooltip">
                                                                  {t("Add to Watchlist")}
                                                               </UncontrolledTooltip>
                                                            </>
                                                         }

                                                      </td>
                                                      <td>
                                                         {data.volume == "0" ? 0 : data?.volume?.toFixed(5)} {data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""}
                                                      </td>
                                                      {/* <td class="red" > */}

                                                      {data.isVolumeUp == true ?
                                                         <td class="green" style={{ justifyContent: "center" }}>
                                                            {data.volumeChange ? data.volumeChange : "0"}
                                                         </td>
                                                         :
                                                         <td class="red" style={{ justifyContent: "center" }}>
                                                            {data.volumeChange ? data.volumeChange : "0"}
                                                         </td>
                                                      }
                                                      {/* </td> */}
                                                      <td>
                                                         {data.floorPrice == "0" ? 0 : data.floorPrice.toFixed(5)} {data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""}
                                                      </td>

                                                      {data.isFloorUp == true ?
                                                         <td class="green" style={{ justifyContent: "center" }}>
                                                            {data.floorChange ? data.floorChange : "0"}
                                                         </td>
                                                         :
                                                         <td class="red" style={{ justifyContent: "center" }}>
                                                            {data.floorChange ? data.floorChange : "0"}
                                                         </td>
                                                      }
                                                      <td>{data.soldItems}</td>
                                                      <td>
                                                         {data.owners}
                                                      </td>
                                                      <td>
                                                         {data.totalItems}
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             ))
                                             :

                                             <div className='noItemFound justify-content-center'>
                                                <div className='text-center'>
                                                   <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                                </div>
                                                <div className='nofounttxt mb-4'>
                                                   {t("No items were found.Try to broaden your search.")}
                                                </div>

                                             </div>
                                          }



                                       </table>
                                    </div>
                                    <div className='row justify-content-center'>
                                       <div className='col-lg-4 col-md-6 col-sm-8'>
                                          <div class="bri_pagenation2">

                                             {ranking && ranking.length > 0 ?
                                                <div class=''>
                                                   <nav aria-label="Page ">
                                                      <ResponsivePagination
                                                         total={count}
                                                         current={page}
                                                         onPageChange={page => handlePageChange(page)}
                                                         previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                         nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                         previousClassName="page-link-1"
                                                         nextClassName="page-link-1"
                                                      />
                                                   </nav>

                                                   {/* <PaginationComponent
                                                size='sm'
                                                defaultActivePage={1}
                                                totalItems={count}
                                                maxPaginationNumbers={3}
                                                pageSize={30}
                                                onSelect={(page) => setPage(page)}
                                             /> */}
                                                </div>
                                                :
                                                ""
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </TabPane>
                              <TabPane tabId="2">
                                 <div class="mb-2 d-flex flexwrap" >
                                    <div className='fiterSelectlabel mb-4'>
                                       {litstedChain}{litstedCategory}
                                       {checkcollectedChain?.length > 0 || checkcategory.length > 0 ?
                                          <label class="artcrd mr-1" onClick={clearAll}>{t("Clear All")}</label>
                                          :
                                          ""
                                       }
                                    </div>
                                 </div>
                                 <div class="table-responsive">
                                    <table class="table table-borderless mb-0 hmecollection">
                                       {ranking && ranking.length > 0 ?
                                          <thead class="trd_head">
                                             <tr>
                                                <th></th>
                                                <th>{t("Collections")} </th>
                                                <th></th>
                                                <th>{t("Volume")} <span><img src={updown} alt="" /></span>
                                                </th>
                                                <th>{t("Volume Change")} <span><img src={updown}
                                                   alt="" /></span></th>
                                                <th>{t("Floor Price")} <span><img src={updown} alt="" /></span>
                                                </th>
                                                <th>{t("Floor Change")} <span><img src={updown}
                                                   alt="" /></span> </th>
                                                <th>{t("Sales")}</th>
                                                <th>
                                                   {t("Owners")}
                                                </th>
                                                <th>{t("Items")} </th>
                                             </tr>
                                          </thead>
                                          :
                                          ""
                                       }
                                       {ranking && ranking.length > 0 ?
                                          ranking && ranking.map((data, index) => (
                                             <tbody class="trdtable_body" >
                                                <tr>
                                                   <td class="gld">
                                                      {index + 1}
                                                   </td>
                                                   <td onClick={() => navigate(`/collection/${data.contractAddress}`)} style={{ cursor: "pointer" }}>
                                                      <div class="d-flex align-items-center">
                                                         <span class="mr-2">
                                                            <img src={data.logoImage} alt="" height="40px" width="40px" style={{ objectFit: "cover", borderRadius: "50%" }} />
                                                         </span>
                                                         <h1 class="mr-2"> {data.collectionName}</h1>
                                                         <span class="mr-2">
                                                            <img src={data.verification == "Pending" ? "" : sm4} alt="" />
                                                         </span>
                                                         <span>
                                                            <img src={data.chain == "binance" ? sm1 : data.chain == "ethereum" ? sm2 : data.chain == "polygon" ? sm3 : ""} alt="" />
                                                         </span>
                                                      </div>
                                                   </td>
                                                   <td>
                                                      {data.watchlistedBy.includes(address ? address.toLowerCase() : "") == true ?
                                                         <div onClick={() => watchlist(data)} style={{ cursor: "pointer" }}>
                                                            <a href="#">
                                                               <svg id="tooltip2" width="19" height="18" viewBox="0 0 19 18"
                                                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path
                                                                     d="M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z"
                                                                     fill="#FFA900" />
                                                               </svg>
                                                               <UncontrolledTooltip placement="top" target="tooltip2" className="tooltipCnt red-tooltip">
                                                                  {t("Remove to Watchlist")}
                                                               </UncontrolledTooltip>
                                                            </a>

                                                         </div>
                                                         :
                                                         <div onClick={() => watchlist(data)} style={{ cursor: "pointer" }}>
                                                            <a href="#">
                                                               <svg id="tooltip1" width="19" height="18" viewBox="0 0 19 18"
                                                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path
                                                                     d="M16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375ZM12.5537 10.7812L15.71 7.71875L11.3662 7.09375L9.42871 3.15625L7.45996 7.09375L3.11621 7.71875L6.27246 10.7812L5.52246 15.0938L9.42871 13.0625L13.3037 15.0938L12.5537 10.7812Z"
                                                                     fill="#595F6A" />
                                                               </svg>
                                                               <UncontrolledTooltip placement="top" target="tooltip1" className="tooltipCnt red-tooltip">
                                                                  {t("Add to Watchlist")}
                                                               </UncontrolledTooltip>
                                                            </a>

                                                         </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      {data.volume == "0" ? 0 : data?.volume?.toFixed(5)} {data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""}

                                                   </td>
                                                   {/* <td class="red"> */}
                                                   {/* {data.volumeChange[0] == "" ? 0 : data.volumeChange[0]} {data.volumeChange[0] == "" ? "" : data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""} */}
                                                   {data.isVolumeUp == true ?
                                                      <td class="green" style={{ justifyContent: "center" }}>
                                                         {data.volumeChange ? data.volumeChange : "0"}
                                                      </td>
                                                      :
                                                      <td class="red" style={{ justifyContent: "center" }}>
                                                         {data.volumeChange ? data.volumeChange : "0"}
                                                      </td>
                                                   }
                                                   {/* </td> */}
                                                   <td>
                                                      {data.floorPrice == "0" ? 0 : data.floorPrice.toFixed(5)} {data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""}
                                                   </td>
                                                   {/* <td class="green">
                                                      {data.floorChange[0].price ? data.floorChange[0].price : "-"} {data.floorChange[0].price ? data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : "" : ""}
                                                   </td> */}
                                                   {data.isFloorUp == true ?
                                                      <td class="green" style={{ justifyContent: "center" }}>
                                                         {data.floorChange ? data.floorChange : "0"}
                                                      </td>
                                                      :
                                                      <td class="red" style={{ justifyContent: "center" }}>
                                                         {data.floorChange ? data.floorChange : "0"}
                                                      </td>
                                                   }
                                                   <td>{data.soldItems}</td>
                                                   <td>
                                                      {data.owners}
                                                   </td>
                                                   <td>
                                                      {data.totalItems}
                                                   </td>
                                                </tr>
                                             </tbody>
                                          ))
                                          :
                                          <div className='noItemFound justify-content-center'>
                                             <div className='text-center'>
                                                <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                             </div>
                                             <div className='nofounttxt mb-4'>
                                                {t("No items were found.Try to broaden your search.")}
                                             </div>
                                             {/* <div>
                                             <button className='applyBtn'>{t("Apply")}</button>
                                          </div> */}
                                          </div>
                                       }
                                    </table>
                                 </div>
                                 <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-8'>
                                       <div class="bri_pagenation2">

                                          {ranking && ranking.length > 0 ?
                                             <div class=''>
                                                <nav aria-label="Page ">
                                                   <ResponsivePagination
                                                      total={count}
                                                      current={page}
                                                      onPageChange={page => handlePageChange(page)}
                                                      previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                      nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                      previousClassName="page-link-1"
                                                      nextClassName="page-link-1"
                                                   />
                                                </nav>
                                                {/* <PaginationComponent
                                                size='sm'
                                                defaultActivePage={1}
                                                totalItems={count}
                                                maxPaginationNumbers={3}
                                                pageSize={30}
                                                onSelect={(page) => setPage(page)}
                                             /> */}
                                             </div>
                                             :
                                             ""
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </TabPane>
                              <TabPane tabId="3">
                                 <div class="mb-2 d-flex flexwrap" >
                                    <div className='fiterSelectlabel mb-4'>
                                       {litstedChain}{litstedCategory}
                                       {checkcollectedChain?.length > 0 || checkcategory.length > 0 ?
                                          <label class="artcrd mb-0 mr-1" onClick={clearAll}>{t("Clear All")}</label>
                                          :
                                          ""
                                       }
                                    </div>
                                 </div>
                                 <div class="table-responsive">
                                    <table class="table table-borderless mb-0 hmecollection">
                                       {ranking && ranking.length > 0 ?
                                          <thead class="trd_head">
                                             <tr>
                                                <th></th>
                                                <th>{t("Collections")} </th>
                                                <th></th>
                                                <th>{t("Volume")} <span><img src={updown} alt="" /></span>
                                                </th>
                                                <th>{t("Volume Change")} <span><img src={updown}
                                                   alt="" /></span></th>
                                                <th>{t("Floor Price")} <span><img src={updown} alt="" /></span>
                                                </th>
                                                <th>{t("Floor Change")} <span><img src={updown}
                                                   alt="" /></span> </th>
                                                <th>{t("Sales")}</th>
                                                <th>
                                                   {t("Owners")}
                                                </th>
                                                <th>{t("Items")} </th>
                                             </tr>
                                          </thead>
                                          :
                                          ""
                                       }
                                       {ranking && ranking.length > 0 ?

                                          ranking && ranking.map((data, index) => (
                                             <tbody class="trdtable_body" >

                                                <tr>
                                                   <td class="gld">
                                                      {index + 1}
                                                   </td>
                                                   <td onClick={() => navigate(`/collection/${data.contractAddress}`)} style={{ cursor: "pointer" }}>
                                                      <div class="d-flex align-items-center">
                                                         <span class="mr-2">
                                                            <img src={data.logoImage} alt="" height="40px" width="40px" style={{ objectFit: "cover", borderRadius: "50%" }} />
                                                         </span>
                                                         <h1 class="mr-2"> {data.collectionName}</h1>
                                                         <span class="mr-2">
                                                            <img src={data.verification == "Pending" ? "" : sm4} alt="" />
                                                         </span>
                                                         <span>
                                                            <img src={data.chain == "binance" ? sm1 : data.chain == "ethereum" ? sm2 : data.chain == "polygon" ? sm3 : ""} alt="" />
                                                         </span>
                                                      </div>
                                                   </td>
                                                   <td>
                                                      {data.watchlistedBy.includes(address ? address.toLowerCase() : "") == true ?
                                                         <div onClick={() => watchlist(data)} style={{ cursor: "pointer" }}>
                                                            <a href="#">
                                                               <svg id="tooltip2" width="19" height="18" viewBox="0 0 19 18"
                                                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path
                                                                     d="M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z"
                                                                     fill="#FFA900" />
                                                               </svg>
                                                               <UncontrolledTooltip placement="top" target="tooltip2" className="tooltipCnt red-tooltip">
                                                                  {t("Remove to Watchlist")}
                                                               </UncontrolledTooltip>
                                                            </a>

                                                         </div>
                                                         :
                                                         <div onClick={() => watchlist(data)} style={{ cursor: "pointer" }}>
                                                            <a href="#">
                                                               <svg id="tooltip1" width="19" height="18" viewBox="0 0 19 18"
                                                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path
                                                                     d="M16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375ZM12.5537 10.7812L15.71 7.71875L11.3662 7.09375L9.42871 3.15625L7.45996 7.09375L3.11621 7.71875L6.27246 10.7812L5.52246 15.0938L9.42871 13.0625L13.3037 15.0938L12.5537 10.7812Z"
                                                                     fill="#595F6A" />
                                                               </svg>
                                                               <UncontrolledTooltip placement="top" target="tooltip1" className="tooltipCnt red-tooltip">
                                                                  {t("Add to Watchlist")}
                                                               </UncontrolledTooltip>
                                                            </a>

                                                         </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      {data.volume == "0" ? 0 : data?.volume?.toFixed(5)} {data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""}
                                                   </td>
                                                   {/* <td class="red"> */}
                                                   {/* {data.volumeChange[0] == "" ? 0 : data.volumeChange[0]} {data.volumeChange[0] == "" ? "" : data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""} */}
                                                   {data.isVolumeUp == true ?
                                                      <td class="green" style={{ justifyContent: "center" }}>
                                                         {data.volumeChange ? data.volumeChange : "0"}
                                                      </td>
                                                      :
                                                      <td class="red" style={{ justifyContent: "center" }}>
                                                         {data.volumeChange ? data.volumeChange : "0"}
                                                      </td>
                                                   }
                                                   {/* </td> */}
                                                   <td>
                                                      {data.floorPrice == "0" ? 0 : data.floorPrice.toFixed(5)} {data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : ""}
                                                   </td>
                                                   {/* <td class="green">
                                                      {data.floorChange[0].price ? data.floorChange[0].price : "-"} {data.floorChange[0].price ? data.chain == "binance" ? "BNB" : data.chain == "ethereum" ? "ETH" : data.chain == "polygon" ? "MATIC" : "" : ""}

                                                   </td> */}
                                                   {data.isFloorUp == true ?
                                                      <td class="green" style={{ justifyContent: "center" }}>
                                                         {data.floorChange ? data.floorChange : "0"}
                                                      </td>
                                                      :
                                                      <td class="red" style={{ justifyContent: "center" }}>
                                                         {data.floorChange ? data.floorChange : "0"}
                                                      </td>
                                                   }
                                                   <td>{data.soldItems}</td>
                                                   <td>
                                                      {data.owners}
                                                   </td>
                                                   <td>
                                                      {data.totalItems}
                                                   </td>
                                                </tr>
                                             </tbody>
                                          ))

                                          :
                                          <div class="nthhkj text-center">
                                             <img class="img-fluid" src={nodatayet} alt="nodatayet" />
                                             <h1>
                                                No data yet.
                                             </h1>
                                             <p>Select a collection and click on <span><svg width="19" height="18"
                                                viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                   d="M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z"
                                                   fill="#FFA900" />
                                             </svg>
                                             </span> star to add it to watchlist.</p>
                                          </div>
                                       }
                                    </table>
                                 </div>

                                 <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-8'>
                                       <div class="bri_pagenation2">
                                          {ranking && ranking.length > 0 ?
                                             <div class=''>
                                                <nav aria-label="Page ">
                                                   <ResponsivePagination
                                                      total={count}
                                                      current={page}
                                                      onPageChange={page => handlePageChange(page)}
                                                      previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                      nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                      previousClassName="page-link-1"
                                                      nextClassName="page-link-1"
                                                   />
                                                </nav>
                                                {/* <PaginationComponent
                                          size='sm'
                                          defaultActivePage={1}
                                          totalItems={count}
                                          maxPaginationNumbers={3}
                                          pageSize={30}
                                          onSelect={(page) => setPage(page)}
                                       /> */}
                                             </div>
                                             :
                                             ""
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </TabPane>
                           </TabContent>
                        </div>
                        <div class='col-xl-3 col-md-6 order-xl-2 order-1 mb-3'>
                           <div class='filtl'>
                              <h1>{t('Filter')}</h1>
                              <div
                                 class='d-flex align-items-center justify-content-between hdfil mb-20'
                                 id='collapse1'
                                 style={{ cursor: 'pointer' }}
                              >
                                 <h2>{t('Categories')}</h2>
                                 <img class='rntar' src={angleDown} alt='' />
                              </div>
                              <UncontrolledCollapse toggler='#collapse1' className='mb-20'>
                                 <div class='bxnflt'>
                                    {categories && categories.length > 0 ? (
                                       categories &&
                                       categories.map((data, index) => (
                                          <div
                                             class='d-flex align-items-center mb-1'
                                             key={data.id}
                                          >
                                             <label
                                                class='cuschk mr-2'
                                                For={`checkbox${index + 1}`}
                                             >
                                                <input
                                                   type='checkbox'
                                                   id={`checkbox${index + 1}`}
                                                   name='collectionchoose'
                                                   checked={
                                                      checkcategory.length > 0
                                                         ? checkcategory.includes(data.name)
                                                         : false
                                                   }
                                                   onChange={() => handleCheckboxCollection(data)}
                                                />
                                                <span class='checkmark'></span>
                                             </label>
                                             <h3>{data.name}</h3>
                                          </div>
                                       ))
                                    ) : (
                                       <p
                                          style={{ justifyContent: 'center', display: 'flex' }}
                                       >
                                          No Data Found
                                       </p>
                                    )}

                                 </div>
                              </UncontrolledCollapse>
                              <div
                                 class='d-flex align-items-center justify-content-between hdfil mb-20'
                                 id='collapse2'
                                 style={{ cursor: 'pointer' }}
                              >
                                 <h2>{t('Chains')}</h2>
                                 <img class='rntar' src={angleDown} alt='' />
                              </div>
                              <UncontrolledCollapse toggler='#collapse2' className='mb-20'>
                                 <div class='bxnflt'>
                                    {Chains &&
                                       Chains.map((data, index) => (
                                          <div class='d-flex align-items-center mb-1 justify-content-between'>
                                             <div class='d-flex align-items-center '>
                                                <label
                                                   class='cuschk mr-2'
                                                   For={`checkbx1${index + 1}`}
                                                >
                                                   <input
                                                      type='checkbox'
                                                      id={`checkbx1${index + 1}`}
                                                      name='chains'
                                                      checked={
                                                         checkcollectedChain?.length > 0
                                                            ? checkcollectedChain.includes(data.value)
                                                            : false
                                                      }
                                                      onChange={() => handleCheckboxChange(data)}
                                                   />
                                                   <span class='checkmark'></span>
                                                </label>
                                                <h3 style={{ textTransform: "capitalize" }}>
                                                   {' '}
                                                   <span class='mr-1'>
                                                      <img
                                                         src={
                                                            data.value == 'binance'
                                                               ? sm1
                                                               : data.value == 'ethereum'
                                                                  ? sm2
                                                                  : data.value == 'polygon'
                                                                     ? sm3
                                                                     : ''
                                                         }
                                                         className='mr-1'
                                                         width='20px'
                                                      />
                                                   </span>
                                                   {data.value}
                                                </h3>
                                             </div>
                                             <h3>{data.name}</h3>
                                          </div>
                                       ))}

                                 </div>
                              </UncontrolledCollapse>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section >

         </div >
      </>
   )
}
export default Bridge
