import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, UncontrolledPopover, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import angleDown from "../../assets/images/angleDown.svg";
import aiPfpClub from '../../assets/images/itemIcons/aiPfpClub.png';
import IconModal from '../../assets/images/IconModal.png';
import walletLoaderCnt from "../../assets/images/walletLoader.png";
import yellowTick from "../../assets/images/collection/yellow-tick_20px.svg";
import copyIcon from '../../assets/images/copyIcon.svg';
import logoImg from '../../assets/images/logoImg.png';
import coverImg from '../../assets/images/coverImg.png';
import Pencil from '../../assets/images/Pencil.svg';
import twitter from '../../assets/images/twitterNew.svg';
import instagram from '../../assets/images/instagram.svg';
import globe from '../../assets/images/globe.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import facebook from '../../assets/images/facebook.svg';
import twitterNew from '../../assets/images/twitterNew.svg';
import telegram from '../../assets/images/telegram-plane.svg';
import checkIcon from '../../assets/images/toast/checkIcon.svg';
import yellowTick20px from '../../assets/images/collection/yellow-tick_20px.svg';

import '../MyCollection/myCollection.scss';
import '../../assets/scss/mycollecion_mint.scss'
import './Settings.scss';
import { axios } from '../../lib/axios'
import {
  useAccount,
} from 'wagmi'
import copy from "copy-to-clipboard";
import { useToast } from '../../utils/toast/customSuccess'
import validator from 'validator'
import ScaleLoader from "react-spinners/ScaleLoader";
import reject from './decline.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import loader from '../../assets/images/Loader.png'

export const SettingsProfile = (props) => {

  const { t } = useTranslation()

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const CheckTick = () => {
    return (
      <>
        <svg className="ml-auto" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
        </svg>
      </>
    )
  }

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const toast = useToast()

  const { address, isConnected } = useAccount()

  const [copyText, setCopyText] = useState(address ? address : "");

  const copyToClipboard = () => {
    copy(copyText);
    toast.success("Address Copied");
  }

  const [profileimage, setProfileIamge] = useState()
  const [profilecoverimage, setProfileCoverIamge] = useState()
  const [profileimgtype, setProfileImgtype] = useState("")
  const [coverimgtype, setCoverImgtype] = useState("")
  const [username, setUsername] = useState("")
  const [bio, setBio] = useState("")
  const [email, setEmail] = useState("")
  const [weburl, setWebUrl] = useState("")
  const [emailverify, setEmailVerify] = useState("")
  const [Logoverify, setLogoVerify] = useState("")
  const [twitterverify, setTwitterVerify] = useState("")
  const [nameVerify, setnameVerify] = useState("")
  const [wholeVerify, setWholeVerify] = useState("")

  useEffect(() => {
    GetProfile()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [address])

  const [getprofile, setGetProfile] = useState("")

  const GetProfile = async () => {
    setLoading(true)
    const update = await axios.post("user/getSingleProfile", { "address": address ? address : "" })
    if (update.status == true) {
      setGetProfile(update.data)
      setProfileIamge(update.data.logo)
      props.setProfilePic(update.data.logo)
      setProfileCoverIamge(update.data.cover)
      setCoverImgtype(update.data.coverImageType)
      setProfileImgtype(update.data.logoImageType)
      setUsername(update.data.name)
      setBio(update.data.bio)
      setEmail(update.data.email)
      setWebUrl(update.data.website)
      setEmailVerify(update.data.emailVerify)
      setLogoVerify(update.data.logoVerify)
      setTwitterVerify(update.data.twitterVerify)
      setnameVerify(update.data.nameVerify)
      setWholeVerify(update.data.verification)
      setTwitter(update.data.twitter)
      setInstagram(update.data.instagram)
      setLoading(false)
    }
    else {
      setLoading(false)
      setGetProfile("")
      setProfileIamge("")
      setProfileCoverIamge("")
      setCoverImgtype("")
      setProfileImgtype("")
      setUsername("")
      setBio("")
      setEmail("")
      setWebUrl("")
      setTwitter("")
      setInstagram("")
      setEmailVerify("")
      setLogoVerify("")
      setTwitterVerify("")
      setnameVerify("")
      setWholeVerify("")
    }
  }


  let imageFile;
  let imageFile1;

  //Profile Image

  const imageupload = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(webp|jpg|jpeg|png|gif)$/)) {
      document.getElementById('uploadimage').innerHTML = 'Please select valid image for WEBP,PNG,GIF,JPEG'
    }
    else if (imageFile == "" || imageFile == undefined) {
      setProfileIamge('')
      document.getElementById('uploadimage').innerHTML = 'Please choose profile image'
    }
    else {
      setProfileIamge(imageFile);
      setProfileImgtype(imageFile.type)
      document.getElementById('uploadimage').innerHTML = ''
    }
  }

  //Cover Image
  const imageupload1 = (e) => {
    imageFile1 = e.target.files[0];
    if (!imageFile1.name.match(/\.(webp|png|gif|mp4)$/)) {
      document.getElementById('uploadcoverimage').innerHTML = 'Please select valid image for WEBP,GIF,PNG,MP4'
    }
    else if (imageFile1 == "" || imageFile1 == undefined) {
      setProfileCoverIamge('')
      document.getElementById('uploadcoverimage').innerHTML = 'Please choose cover image'
    }
    else {
      setProfileCoverIamge(imageFile1);
      setCoverImgtype(imageFile1.type)
      document.getElementById('uploadcoverimage').innerHTML = ''
    }
  }


  const setUsername1 = (e) => {

    if (e.target.value == "" || e.target.value == undefined) {
      setUsername("")
      document.getElementById('username1').innerHTML = 'Please enter user name'
    }
    else if (e.target.value.length < 3) {
      setUsername(e.target.value)
      document.getElementById('username1').innerHTML = 'User Name atleast 3 characters'
    }
    else {
      setUsername(e.target.value)
      document.getElementById('username1').innerHTML = ''
    }
  }
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  const setEmail1 = (e) => {
    // if (e.target.value == "" || e.target.value == undefined) {
    //   document.getElementById('email1').innerHTML = 'Please enter email address'
    // }
    // else
    //  if (!regex.test(e.target.value)) {
    //   setEmail(e.target.value)
    //   document.getElementById('email1').innerHTML = 'Invalid email address'
    // }
    // else {
    setEmail(e.target.value)
    //   document.getElementById('email1').innerHTML = ''

    // }
  }

  const setWebUrl1 = (e) => {
    // if (e.target.value == "" || e.target.value == undefined) {
    //   setWebUrl("")
    //   document.getElementById('weburl1').innerHTML = 'Please enter url'
    // }
    // else if (!validator.isURL(e.target.value)) {
    //   setWebUrl(e.target.value)
    //   document.getElementById('weburl1').innerHTML = 'Enter url in correct format'
    // }
    // else {
    setWebUrl(e.target.value)
    // document.getElementById('weburl1').innerHTML = ''

    // }
  }

  const [Instagram, setInstagram] = useState("")
  const [Twitter, setTwitter] = useState("")

  let twireg = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/

  const setTwitter1 = (e) => {
    // if (e.target.value == "" || e.target.value == undefined) {
    //   setTwitter(e.target.value)
    //   document.getElementById('twit').innerHTML = 'Please enter twitter url'
    // }
    // else 
    // if (!twireg.test(e.target.value)) {
    //   setTwitter(e.target.value)
    //   document.getElementById('twit').innerHTML = 'Invalid twitter url'
    // }
    // else {
    setTwitter(e.target.value)
    //   document.getElementById('twit').innerHTML = ''
    // }
  }





  const navigate = useNavigate()

  const updateprofile = async () => {
    setLoading(true)
    if (profileimage == undefined && profilecoverimage == undefined && username == "" && weburl == "") {
      document.getElementById('uploadimage').innerHTML = 'Please choose profile image'
      document.getElementById('uploadcoverimage').innerHTML = 'Please choose cover image'
      document.getElementById('username1').innerHTML = 'Please enter user name'
      document.getElementById('weburl1').innerHTML = 'Please enter url'
      setLoading(false)
    }
    else if (profileimage == undefined || profileimage == "") {
      document.getElementById('uploadimage').innerHTML = 'Please choose profile image'
      setLoading(false)
    }
    else if (profilecoverimage == undefined || profilecoverimage == "") {
      document.getElementById('uploadcoverimage').innerHTML = 'Please choose cover image'
      setLoading(false)
    }
    else if (username == "" || username == undefined) {
      document.getElementById('username1').innerHTML = 'Please enter user name'
      setLoading(false)
    }
    else if (username < 3) {
      document.getElementById('username1').innerHTML = 'User Name atleast 3 characters'
      setLoading(false)
    }
    // else if (email == "" || email == undefined) {
    //   document.getElementById('email1').innerHTML = 'Please enter email address'
    //   setLoading(false)
    // }
    // else if (!regex.test(email)) {
    //   document.getElementById('email1').innerHTML = 'Invalid email address'
    //   setLoading(false)
    // }
    // else if (Twitter == "" || Twitter == undefined) {
    //   setLoading(false)
    //   document.getElementById('twit').innerHTML = 'Please enter twitter url'
    // }
    // else if (!twireg.test(Twitter)) {
    //   setLoading(false)
    //   document.getElementById('twit').innerHTML = 'Invalid twitter url'
    // }
    // else if (weburl == "" || weburl == undefined) {
    //   document.getElementById('weburl1').innerHTML = 'Please enter url'
    //   setLoading(false)
    // }
    // else if (!validator.isURL(weburl)) {
    //   document.getElementById('weburl1').innerHTML = 'Enter url in correct format'
    //   setLoading(false)
    // }
    else {
      const formData = new FormData()
      formData.append('address', address.toLowerCase())
      formData.append('name', username)
      formData.append('logo', profileimage)
      formData.append('cover', profilecoverimage)
      formData.append('bio', bio)
      formData.append('email', email)
      formData.append('twitter', Twitter)
      formData.append('instagram', Instagram)
      formData.append('website', weburl)
      formData.append('verification', wholeVerify)
      formData.append('twitterVerify', twitterverify)
      formData.append('nameVerify', nameVerify)
      formData.append('logoVerify', Logoverify)
      formData.append('emailVerify', emailverify)
      formData.append('coverImageType', coverimgtype)
      formData.append('logoImageType', profileimgtype)

      const update = await axios.post("user/update-profile", formData)
      if (update.status == true) {
        toast.success(update.message)
        props.setProfilePic(update.data.logo)
        setLoading(false)
        setTimeout(() => {
          navigate("/profile")
        })
      }
      else {
        toast.error(update.message)
        setLoading(false)
      }
    }

  }

  const Emailverify = async () => {
    setLoading(true)

    // if (email == "" || email == undefined) {
    //   document.getElementById('email1').innerHTML = 'Please enter email address'
    //   setLoading(false)
    // }
    // else if (!regex.test(email)) {
    //   document.getElementById('email1').innerHTML = 'Invalid email address'
    //   setLoading(false)
    // }
    // else {
    const emailVerify = await axios.post("user/get_email_url", { "email": email, "address": address })
    if (emailVerify.status == true) {
      toast.success(emailVerify.message)
      setLoading(false)
      const formData = new FormData()
      formData.append('address', address.toLowerCase())
      formData.append('name', username)
      formData.append('logo', profileimage)
      formData.append('cover', profilecoverimage)
      formData.append('bio', bio)
      formData.append('email', email)
      formData.append('twitter', Twitter)
      formData.append('instagram', Instagram)
      formData.append('website', weburl)
      formData.append('verification', wholeVerify)
      formData.append('twitterVerify', twitterverify)
      formData.append('nameVerify', nameVerify)
      formData.append('logoVerify', Logoverify)
      formData.append('emailVerify', emailverify)
      formData.append('coverImageType', coverimgtype)
      formData.append('logoImageType', profileimgtype)
      const update = await axios.post("user/update-profile", formData)
    }
    else {
      toast.error(emailVerify.message)
      setLoading(false)
    }
    // }
  }


  const Verify = () => {
    if (Twitter == "" || Twitter == undefined) {
      setTwitterVerify("")
    }
    else {
      setTwitterVerify(getprofile.twitterVerify == "" ? "Pending" : getprofile.twitterVerify == "Rejected" ? "Pending" : getprofile.twitterVerify == "Verified" ? "Verified" : getprofile.twitterVerify == "Pending" ? "Pending" : "")
    }
    setnameVerify(getprofile.nameVerify == "" ? "Pending" : getprofile.nameVerify == "Rejected" ? "Pending" : getprofile.nameVerify == "Verified" ? "Verified" : getprofile.nameVerify == "Pending" ? "Pending" : "")
    setLogoVerify(getprofile.logoVerify == "" ? "Pending" : getprofile.logoVerify == "Rejected" ? "Pending" : getprofile.logoVerify == "Verified" ? "Verified" : getprofile.logoVerify == "Pending" ? "Pending" : "")
    setWholeVerify(getprofile.verification == "" ? "Pending" : getprofile.verification == "Pending" ? "Pending" : getprofile.verification == "Verified" ? "Verified" : getprofile.verification == "Pending" ? "Pending" : "")
  }


  return (
    <>
      {/* <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div> */}
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">{t("Profile")}</h3>
        {getprofile && getprofile.verification == "Verified" ?
          <button className="btn gradientBtn ml-auto" disabled>{t("Save")}</button>
          :
          username && profileimage && profilecoverimage
            // && weburl && validator.isURL(weburl)
            ?
            <button className="btn gradientBtn ml-auto" onClick={updateprofile}>{t("Save")}</button>
            :
            <button className="btn gradientBtn ml-auto" disabled>{t("Save")}</button>
        }
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <div className="walletCnt d-flex align-items-center flex-wrap mb-3">
            <h3 >{address ? address.slice(0, 5) + "..." + address.slice(38, 42) : ""}</h3>
            <span className="walletLabel successLabel ml-auto">{address ? t("Wallet Connected") : t("Connect Wallet")}</span>
            <a href="#"><img src={copyIcon} className="ml-2" onClick={copyToClipboard} /></a>
          </div>
          <div className='form-row'>
            <div className="col-12 mb-3 d-flex align-items-center flex-wrap">
              <div className="imgContainer mr-4">
                <span className="formLabel">Logo Image <span style={{ color: "red" }}>*</span></span>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="imgCnt">
                    {typeof profileimage == "string" && profileimage !== undefined ?
                      profileimage !== "undefined" ?
                        <img src={profileimage} className="logoImg" />
                        :
                        ""
                      :
                      profileimage ?
                        <img src={URL.createObjectURL(profileimage)} className="logoImg" />
                        :
                        ""
                    }

                    {getprofile.logoVerify == "Verified" ?
                      ""
                      :
                      profileimage !== "undefined" ?
                        <div className="justify-content-center align-items-center editIcon">
                          <input type="file" id="chooseBtn" onChange={(e) => imageupload(e)} />
                          <label for="chooseBtn"><img src={Pencil} /></label>
                        </div>
                        :
                        <div className="d-flex flex-column " >
                          <p >PNG,GIF,JPEG,WEBP.</p>
                          <p className="fs-12 greyTxt text-center">Max 100mb.</p>
                          <div className="chooseFileBtn mt-2">
                            <input type="file" id="chooseBtn" onChange={(e) => imageupload(e)} />
                            <label for="chooseBtn">Choose File</label>
                          </div>
                        </div>

                    }
                  </div>
                </div>
                <label className="text-danger errLabel ml-auto"><span id='uploadimage' style={{ color: "red" }}></span></label>
              </div>



              <div className="coverContainer">
                <span className="formLabel">Cover Image <span style={{ color: "red" }}>*</span></span>
                <div className="coverCnt d-flex flex-column">

                  {typeof profilecoverimage == "string" && profilecoverimage !== undefined ?

                    coverimgtype.startsWith('video') ?
                      <video

                        className='vitag'
                        src={profilecoverimage && profilecoverimage}
                        alt="Uploaded"
                        muted
                        autoPlay

                      />
                      :
                      profilecoverimage !== 'undefined' ?
                        <img className="coverImg" src={profilecoverimage} />
                        :
                        ""

                    :
                    typeof profilecoverimage == "object" ?
                      coverimgtype.startsWith('video') ?
                        <video

                          className='vitag'
                          src={URL.createObjectURL(profilecoverimage)}
                          alt="Uploaded"
                          muted
                          autoPlay
                        />
                        :
                        profilecoverimage ?
                          < img className="coverImg" src={URL.createObjectURL(profilecoverimage)} />
                          :
                          ""
                      :
                      ""
                  }


                  {/* <img className="coverImg" src={coverImg} /> */}
                  {getprofile && getprofile.verification == "Verified" ?
                    ""
                    :
                    profilecoverimage !== "undefined" ?
                      <div className="justify-content-center align-items-center editIcon">
                        <input type="file" id="chooseBtn1" onChange={(e) => imageupload1(e)} />
                        <label for="chooseBtn1"><img src={Pencil} /></label>
                      </div>
                      :
                      <div className="d-flex flex-column align-items-center">
                        <span className='imgTxt'>PNG, GIF, WEBP, MP4.</span>
                        <span className="fs-12 greyTxt">Max 100mb. At least 1400*360 pixels.</span>
                        <div className="chooseFileBtn mt-2">
                          <input type="file" id="chooseBtn1" onChange={(e) => imageupload1(e)} />
                          <label for="chooseBtn1">Choose File</label>
                        </div>
                      </div>
                  }
                </div>
                <label className="text-danger errLabel ml-auto "><span id='uploadcoverimage' style={{ color: "red" }} className='ml-5'></span></label>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">User Name <span style={{ color: "red" }}>*</span></span>
                <label className="text-danger errLabel ml-auto"> <span id='username1' style={{ color: "red" }}></span></label>
              </div>
              {getprofile && getprofile.nameVerify == "Verified" ?
                <input type="text" placeholder="Enter Name" className="form-control" name="username" defaultValue={username} disabled />
                :
                <input type="text" placeholder="Enter Name" className="form-control" name="username" onKeyUp={(e) => setUsername1(e)} defaultValue={username} />
              }

            </div>

            <div className="col-12 mb-3">
              <span className="formLabel">Bio</span>
              {getprofile && getprofile.verification == "Verified" ?
                <textarea className="form-control" placeholder="Type Bio" name="bio" defaultValue={bio} disabled></textarea>

                :
                <textarea className="form-control" placeholder="Type Bio" name="bio" onKeyUp={(e) => setBio(e.target.value)} defaultValue={bio}></textarea>
              }
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">Email Address</span>
                <label className="text-danger errLabel ml-auto">
                  {/* <span id='email1' style={{ color: "red" }}>
                  </span> */}
                  {getprofile && getprofile.emailVerify == "Verified" ?
                    ""
                    :
                    <>
                      <img src={infoIcon} /> <span style={{ color: "red" }}>
                        Email address is not updated, then you will not be able to receive any
                        notification</span>
                    </>

                  }
                </label>
              </div>
              <div className="input-group verified">
                {getprofile && getprofile.emailVerify == "Verified" ?
                  <input type="text" placeholder="Enter Email" className="form-control nd-formcontr" name="email" defaultValue={email} disabled />
                  :
                  <input type="text" placeholder="Enter Email" className="form-control nd-formcontr" name="email" onChange={(e) => setEmail1(e)} defaultValue={email} />
                }
                <div className="input-group-append">
                  {email && regex.test(email) ?
                    getprofile && getprofile.emailVerify == "" ?
                      <div className="input-group-text errLabel" style={{ cursor: "pointer" }} onClick={Emailverify}>Verify<i className="fas fa-chevron-right ml-2"></i></div>
                      :
                      <div className="input-group-text"><img src={checkIcon} /></div>
                    :
                    ""
                  }
                </div>
              </div>
            </div>
            <div>
              {getprofile && getprofile.emailVerify == "Verified" ?
                ""
                :
                <>
                  <img src={infoIcon} /> <span style={{ color: "red" }}>
                    Email address is not updated, then you will not be able to receive any
                    notification</span>
                </>

              }
            </div>
          </div>
          <hr className="w-100" />
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">Social Media</span>
              </div>
              <div className="d-flex urlFieldCnt flex-wrap align-items-center mb-2">
                <div className="d-flex w-100 mb-3">
                  <div className="socilaMediaIconCnt mr-2"><img src={twitter} /></div>
                  {getprofile && getprofile.twitterVerify == "Verified" ?
                    <input type="text" placeholder="Enter Twitter Url" className="form-control" name="twitter" defaultValue={Twitter} disabled />
                    :
                    <input type="text" placeholder="Enter Twitter Url" className="form-control" name="twitter" onKeyUp={(e) => setTwitter1(e)} defaultValue={Twitter} />
                  }
                </div>
                <label className="text-danger errLabel ml-auto"> <span id='twit' style={{ color: "red" }}></span></label>

              </div>
              <div className="d-flex urlFieldCnt flex-wrap align-items-center mb-2">
                <div className="d-flex w-100 mb-3">
                  <div className="socilaMediaIconCnt mr-2"><img src={instagram} /></div>
                  <div className=" align-items-center flex-wrap">
                  </div>
                  {getprofile && getprofile.verification == "Verified" ?
                    <input type="text" placeholder="Enter Instagram Url" className="form-control" name="instagram" defaultValue={Instagram} disabled />
                    :
                    <input type="text" placeholder="Enter Instagram Url" className="form-control" name="instagram" onKeyUp={(e) => setInstagram(e.target.value)} defaultValue={Instagram} />
                  }
                </div>


              </div>
              <hr />
            </div>
          </div>



          <div className="row">
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">Website
                  {/* <span style={{ color: "red" }}>*</span> */}
                </span>
                <label className="text-danger errLabel ml-auto">
                  {/* <span id='weburl1' style={{ color: "red" }} /> */}
                </label>
              </div>
              <div className="d-flex urlFieldCnt">
                <div className="socilaMediaIconCnt mr-2"><img src={globe} /></div>
                {getprofile && getprofile.verification == "Verified" ?
                  <input type="text" className="form-control" placeholder="Enter URL" name="weburl" defaultValue={weburl} disabled />
                  :
                  <input type="text" className="form-control" placeholder="Enter URL" name="weburl" onKeyUp={(e) => setWebUrl1(e)} defaultValue={weburl} />
                }
              </div>
              <hr />
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel mb-0">Verification</span>

                <div className="dark-primary ml-auto">
                  Requirements < img src={infoIcon} id="popoverLegacy" />
                </div>


                <UncontrolledPopover trigger="legacy" placement="bottom" target="popoverLegacy" className="verifyPopOver">
                  <PopoverBody>

                    <div>
                      {getprofile.logoVerify == "Verified" ?
                        <label className="custCheck">Profile Image.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.logoVerify == "Rejected" ?
                          <>
                            <img src={reject} width="25px" height="25px" />
                            <label>Profile Image.</label>
                          </>
                          :
                          <div>

                            <label className="custCheck">Proile Image.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }


                    </div>
                    <div>
                      {getprofile.nameVerify == "Verified" ?
                        <label className="custCheck">User Name.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.nameVerify == "Rejected" ?
                          <>
                            <img src={reject} width="25px" height="25px" />
                            <label>User Name.</label>
                          </>
                          :
                          <div>
                            <label className="custCheck">User Name.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }
                    </div>
                    <div>
                      {getprofile.emailVerify == "Verified" ?
                        <label className="custCheck">Verified email.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.emailVerify == "Rejected" ?
                          <>
                            <img src={reject} width="25px" height="25px" />
                            <label>Verified email.</label>
                          </>
                          :
                          <div>

                            <label className="custCheck">Verified email.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }
                    </div>
                    <div>
                      {getprofile.twitterVerify == "Verified" ?
                        <label className="custCheck">Connected Twitter account.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.twitterVerify == "Rejected" ?
                          <>
                            <img src={reject} width="25px" height="25px" />
                            <label>Connected Twitter account.</label>
                          </>
                          :
                          <div>
                            <label className="custCheck">Connected Twitter account.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
                {/* <div className="d-flex align-items-center ml-auto fs-14">
                      Verified <img src={yellowTick} id="popoverLegacy" />
                    </div> */}
              </div>


              {getprofile.logoVerify == "" && getprofile.nameVerify == "" && username.length >= 3 ?
                <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}>{wholeVerify == "" ? "Get Verified" : wholeVerify == "Rejected" ? "Get Verified" : wholeVerify}</div>

                :
                getprofile.logoVerify == "Verified" && getprofile.twitterVerify == "Verified" && getprofile.nameVerify == "Verified" && getprofile.emailVerify == "Verified" ?
                  <p style={{ justifyContent: "end", display: "flex" }}>{wholeVerify} <img src={yellowTick20px} /></p>
                  :
                  getprofile && getprofile.logoVerify == "Rejected" ?
                    <div className="getVerified  ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{Logoverify == "" ? "Get Verified" : Logoverify == "Rejected" ? "Get Verified" : Logoverify}</p></div>
                    :
                    getprofile && getprofile.nameVerify == "Rejected" && username && username.length >= 3 ?
                      <div className="getVerified  ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{nameVerify == "" ? "Get Verified" : nameVerify == "Rejected" ? "Get Verified" : nameVerify}</p></div>
                      :
                      getprofile && getprofile.twitterVerify == "Rejected" && Twitter && twireg.test(Twitter) ?
                        <div className="getVerified  ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{twitterverify == "" ? "Get Verified" : twitterverify == "Rejected" ? "Get Verified" : twitterverify}</p></div>
                        :
                        getprofile && getprofile.twitterVerify == "" && username && username.length >= 3 && profileimage && Twitter ?
                          <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{twitterverify == "" ? "Get Verified" : twitterverify == "Pending" ? "Pending" : twitterverify == "Verified" ? "Verified" : ""}</p></div>
                          :
                          getprofile && getprofile.logoVerify == "Pending" ?
                            // <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }}>
                            <div style={{ justifyContent: "end", display: "flex" }}>
                              <p class="dot"></p><span style={{ color: "var(--dark-primary-1, #ffa900)", fontSize: "15px" }}>{wholeVerify == "" ? "Get Verified" : wholeVerify == "Rejected" ? "Get Verified" : wholeVerify}</span> </div>
                            :
                            getprofile && getprofile.twitterVerify == "Pending" ?
                              // <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }}>
                              <div style={{ justifyContent: "end", display: "flex" }}> <p class="dot"></p><span style={{ color: "var(--dark-primary-1, #ffa900)", fontSize: "15px" }}>{wholeVerify == "" ? "Get Verified" : wholeVerify == "Rejected" ? "Get Verified" : wholeVerify}</span></div>
                              :
                              getprofile && getprofile.nameVerify == "Pending" ?
                                // <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }}>
                                <div style={{ justifyContent: "end", display: "flex" }}> <p class="dot"></p><span style={{ color: "var(--dark-primary-1, #ffa900)", fontSize: "15px" }}>{wholeVerify == "" ? "Get Verified" : wholeVerify == "Rejected" ? "Get Verified" : wholeVerify}</span></div>
                                :
                                email && regex.test(email) && username && username.length >= 3 && Logoverify !== "" && twitterverify !== "" && nameVerify !== "" && profileimage !== "" && emailverify !== "" && Twitter !== "" && twireg.test(Twitter) ?
                                  < div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{wholeVerify == "" ? "Get Verified" : wholeVerify == "Rejected" ? "Get Verified" : wholeVerify}</p></div>
                                  :
                                  getprofile && getprofile.logoVerify == "" && email && regex.test(email) && username && username.length >= 3 && Twitter && twireg.test(Twitter) && profileimage ?
                                    <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{Logoverify == "" ? "Pending" : Logoverify == "Verified" ? "Verified" : ""}</p></div>
                                    :

                                    getprofile && getprofile.nameVerify == "" && email && regex.test(email) && Twitter && twireg.test(Twitter) && profileimage && username && username.length >= 3 ?
                                      <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{nameVerify == "" ? "Pending" : nameVerify == "Verified" ? "Verified" : ""}</p></div>
                                      :
                                      getprofile && getprofile.emailVerify == "" && email && regex.test(email) && Twitter && twireg.test(Twitter) && profileimage && username && username.length >= 3 ?
                                        <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify}><p>{emailverify == "" ? "Pending" : emailverify == "Verified" ? "Verified" : ""}</p></div>
                                        :
                                        ""
              }

            </div>

          </div>
        </form >
      </div >


      < Modal isOpen={modal1} toggle={toggle1} centered="true" className="curMdl createScsMdl" backdropClassName="selCurBp" >
        <div className="createContent">
          <button className="btn closeBtn" onClick={toggle}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
            </svg>
          </button>

          <div className="w-100">
            <div className="d-flex justify-content-center">
              <img src={IconModal} />
            </div>
            <h3 className="walletHeading my-2">Are you sure?</h3>
            <h3 className="walletSubHeading mb-3 ">You are about to leave this page. All unsaved changes will be lost. Are you sure?</h3>
            <button type="button" className="btn btn-block gradientBtn mb-2" >Continue Editing</button>
            <button type="button" className="btn btn-block darkBtn">Discard Changes</button>
          </div>
        </div>
      </Modal >

    </>
  );
};

export default SettingsProfile;