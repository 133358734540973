import React from 'react'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Signature } from 'ethers'
import Web3 from 'web3'

import { Spinner } from '../../../components/loader/spinner/Spinner'
import { useFetchOfferList, useApproveOffer } from '../apis/fetchAssetDetails'
import cndds from '../../../assets/images/cndds.png'
import contracts from '../../../config/constants/contracts'
import marketPlaceAbi from '../../../config/abi/marketplace.json'
import ApprovalABI from '../../../views/AbiArray/Approvalcollection/Bnbapproval.json'
import { chainId } from '../../../config/constants/chains'
import { axios } from '../../../lib/axios'
import { getOrder } from '../../../utils/getOrder'
import { usePurchaseLoadingModalActions } from '../../../components/modals/purchase-loading/store'
import { useSomethingWentWrongModalActions } from '../../../components/modals/error/store'
import { formatAddress } from '../../../utils/formatAddress'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import { useContractProvider } from '../../../utils/useContractProvider'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export function OfferList({ asset }) {
	const { t } = useTranslation()

	const params = useParams()
	const { address: account } = useAccount()
	const { pro } = useContractProvider()


	const { chain } = useNetwork()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()


	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset?.details?.chain]]
		: null

	const web3 = new Web3(pro)
	const tetherContract = new web3.eth.Contract(
		marketPlaceAbi,
		marketplaceContractAddress
	)

	const approvalTetherContract = new web3.eth.Contract(
		ApprovalABI,
		asset?.details?.contractAddress
	)

	const { data, isLoading, isError, error } = useFetchOfferList(
		params.collectionAddress,
		params.nftId,
		params.supplyId
	)

	const walletType = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const { mutateAsync: approveOffer } = useApproveOffer(account)

	const { togglePurchaseLoadingModal } = usePurchaseLoadingModalActions()
	const { openSomethingWentWrongModal } = useSomethingWentWrongModalActions()
	const navigate = useNavigate()

	const handleAcceptOffer = async (item) => {
		togglePurchaseLoadingModal()
		const royaltyFee = asset.details.royalty
		const royaltyReceipt = asset.details.creator
		const isStore = !asset.details.isMinted
		const uri = asset.details.uri
		const order = getOrder({
			asset: {
				...asset,
				owner: { ...asset.owner, address: item.fromAddress },
				details: { ...asset.details, price: item.price }
			},
			assetType: 'erc1155',
			orderType: 'buy',
			expiry: item.expiry
		})
		const sig = item.signTuple
		try {
			if (walletType === 'okx') {
				if (asset?.details?.chain !== chainMap[parseInt(window.okxwallet.chainId)]) {
					await switchNetwork(chainIdMap[asset?.details?.chain])
				}
			} else {
				if (asset?.details?.chain === 'binance' && chain.id !== 97) {
					await switchNetworkAsync(97)
				} else if (
					asset?.details?.chain === 'ethereum' &&
					chain.id !== 11155111
				) {
					await switchNetworkAsync(11155111)
				} else if (
					asset?.details?.chain === 'polygon' &&
					chain.id !== 80001
				) {
					await switchNetworkAsync(80001)
				}
			}
		} catch (error) {
			console.error(error)
			togglePurchaseLoadingModal()
			openSomethingWentWrongModal()
			return
		}

		const approvalResponse = await approvalTetherContract.methods
			.isApprovedForAll(account, marketplaceContractAddress)
			.call()

		if (approvalResponse) {
			try {
				const res = await axios.post('asset/buyer-fee-signature', {
					orderTuple: order,
					royalty: royaltyFee,
					royaltyAddress: royaltyReceipt,
					network: asset?.details?.chain
				})

				if (!res.status) throw new Error(res.message)

				const buyerFeeSig = [res.data.v, res.data.r, res.data.s]

				try {
					console.log({ order, sig, buyerFeeSig, royaltyFee, royaltyReceipt })
					await tetherContract.methods
						.buy(
							[order],
							sig,
							[buyerFeeSig],
							[royaltyFee],
							[royaltyReceipt],
							[isStore],
							[uri],
							item.totalQuantity
						)
						.send({
							from: account.toString()
						})
						.on('transactionHash', async (hash) => { })
						.on('receipt', async (approveresult) => {
							const params = {
								orderId: item.orderId,
								address: account
							}
							const approveRes = await approveOffer(params)
							if (approveRes.status) {
								togglePurchaseLoadingModal()
							} else {
								togglePurchaseLoadingModal()
								openSomethingWentWrongModal()
							}
						})
						.on('error', () => {
							togglePurchaseLoadingModal()
							openSomethingWentWrongModal()
						})
				} catch (error) {
					console.error(error)
					openSomethingWentWrongModal()
				} finally {
					togglePurchaseLoadingModal()
				}
			} catch (error) {
				console.error(error)
				openSomethingWentWrongModal()
			} finally {
				togglePurchaseLoadingModal()
			}
		} else {
			try {
				await approvalTetherContract.methods
					.setApprovalForAll(marketplaceContractAddress, true)
					.send({ from: account.toString() })
					.on('transactionHash', async (hash) => { })
					.on('receipt', async (approveresult) => { })
					.on('error', () => {
						togglePurchaseLoadingModal()
						openSomethingWentWrongModal()
					})

				const res = await axios.post('asset/buyer-fee-signature', {
					orderTuple: order,
					royalty: royaltyFee,
					royaltyAddress: royaltyReceipt,
					network: asset?.details?.chain
				})

				if (!res.status) throw new Error(res.message)

				const buyerFeeSig = [res.data.v, res.data.r, res.data.s]

				try {
					await tetherContract.methods
						.buy(
							[order],
							sig,
							[buyerFeeSig],
							[royaltyFee],
							[royaltyReceipt],
							[isStore],
							[uri],
							item.totalQuantity
						)
						.send({
							from: account.toString()
						})
						.on('transactionHash', async (hash) => { })
						.on('receipt', async (approveresult) => {
							const params = {
								orderId: item.orderId,
								address: account
							}
							const approveRes = await approveOffer(params)
							if (approveRes.status) {
								togglePurchaseLoadingModal()
							} else {
								togglePurchaseLoadingModal()
								openSomethingWentWrongModal()
							}
						})
						.on('error', () => {
							togglePurchaseLoadingModal()
							openSomethingWentWrongModal()
						})
				} catch (error) {
					console.error(error)
					openSomethingWentWrongModal()
				} finally {
					togglePurchaseLoadingModal()
				}
			} catch (error) {
				console.error(error)
				openSomethingWentWrongModal()
			} finally {
				togglePurchaseLoadingModal()
			}
		}
	}

	if (isLoading) {
		return (
			<div className='col-12'>
				<div class='noResf text-center'>
					<Spinner />
				</div>
			</div>
		)
	}

	if (!data?.data?.length) {
		return (
			<div className='col-12 my-auto'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={cndds} alt='' />
					<h1>{t('No Data')}</h1>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className='table-responsive'>
				<table className='table table-borderless table-hover mb-0 hmecollection nftTables_offers'>
					<thead className='trd_head'>
						<tr>
							<th>{t('Price')}</th>
							<th>{t('USD Price')}</th>
							<th>{t('Quantity')}</th>
							<th>{t('Floor Difference')}</th>
							<th>{t('Expiration')}</th>
							<th>{t('From')}</th>
							<th></th>
						</tr>
					</thead>
					<tbody className='trdtable_body'>
						{data?.data?.map((item) => (
							<tr>
								<td>
									{parseFloat(item.price)} {item.currency}
								</td>
								<td>${parseFloat(item.usdPrice).toFixed(2)}</td>
								<td>{item.quantity}</td>
								<td>{item.floorDifference[0]?.floordifference}% {item.floorDifference[0]?.up ? 'above' : 'below'}</td>
								<td>{moment(item.saleExpiry).fromNow()}</td>
								<td className='yellowTxt hover'>
									<Link
										className='yellowTxt'
										to={`/profile/${item.fromAddress}`}
									>
										{item.from !== 'Unnamed'
											? item.from
											: formatAddress(item.fromAddress)}
									</Link>
								</td>
								{!!account &&
									account.toLowerCase() ===
									asset?.owner?.address ? (
									<td>
										<button
											className='btn btnAccept'
											onClick={() =>
												handleAcceptOffer(item)
											}
										>
											<span>{t('Accept')}</span>
										</button>
									</td>
								) : null}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	)
}
