import React from 'react';
import listIcon from '../../../assets/images/collection/listIcon.svg';
import structuredClone from '@ungap/structured-clone'
import { useTranslation } from 'react-i18next';
import { flattenFilters } from '../../../utils/flattenFilters';
import { INITIAL_FILTER_STATE } from '../../../utils/parseQueryStringToObject';
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg'
import { NoItemsFound } from '../Items';

const filterValueMap = {
    USDT: 'USDT',
    ETH: 'ETH',
    MATIC: 'MATIC',
    BNB: 'BNB',
    WOR: 'WOR',
    BUSD: 'BUSD',
    LISTINGS: 'Listings',
    SALES: 'Sales',
    OFFERS: 'Offers',
    COLLECTION_OFFERS: 'Collection offers'
}

export function Chip({ filters, filter, onClearChip }) {
    if (!filter.value || filter.key.includes('owner')) return

    const handleClearChip = () => {
        const flattenedFilters = flattenFilters(filters)
        const filterKey = filter.key.split(/\]\[|\[|\]/).filter(Boolean)[1]
        const arr = Object.entries(flattenedFilters)
            .filter((f) => f[0].includes(filterKey))
            .filter((f) => f[1] !== filter.value)
        const updatedFilters = {
            ...filters,
            search: {
                ...filters.search,
                [filterKey]:
                    arr.length === 0
                        ? null
                        : arr.reduce((acc, cur, curIndex) => {
                            acc = { ...acc, [curIndex]: cur[1] }
                            return acc
                        }, {})
            }
        }
        onClearChip(updatedFilters)
    }

    return (
        <div className='tagLabel'>
            <span>
                {filter.key.includes('query')
                    ? filter.value
                    : filterValueMap[filter.value]}
            </span>
            <i
                className='fas fa-times ml-2 hover'
                onClick={handleClearChip}
            ></i>
        </div>
    )
}

export function TraitChip({ filters, filter, onClearChip }) {
    if (!filter.value) return

    const handleClearChip = () => {
        const flattenedFilters = flattenFilters(filters)
        let i = 0
        const o = Object.entries(flattenedFilters)
            .filter(([, v]) => v !== filter.value)
            .reduce((acc, [key, value], index, arr) => {
                if (key.includes('name') && !acc.hasOwnProperty(value)) {
                    acc[value] = arr.filter(([k]) =>
                        k.includes(`[stringTraits][${i}][values]`)
                    )
                    i++
                }
                return acc
            }, {})

        const updatedFilters = {
            ...filters,
            search: {
                ...filters.search,
                stringTraits:
                    Object.entries(o).find((k) => k[0] === filter.key)[1]
                        .length === 0
                        ? Object.entries(o)
                            .filter((k) => k[0] !== filter.key)
                            .reduce((acc, [k, v], i) => {
                                acc = {
                                    ...acc,
                                    [i]: {
                                        name: k,
                                        values: v.reduce((a, [, v], i) => {
                                            a = {
                                                ...a,
                                                [i]: v
                                            }
                                            return a
                                        }, {})
                                    }
                                }
                                return acc
                            }, {})
                        : Object.entries(o).reduce((acc, [k, v], i) => {
                            acc = {
                                ...acc,
                                [i]: {
                                    name: k,
                                    values: v.reduce((a, [, v], i) => {
                                        a = {
                                            ...a,
                                            [i]: v
                                        }
                                        return a
                                    }, {})
                                }
                            }
                            return acc
                        }, {})
            }
        }

        onClearChip(updatedFilters)
    }

    return (
        <div className='tagLabel'>
            <span className='background'>{filter.key}: </span>
            <span>{filter.value}</span>
            <i
                className='fas fa-times ml-2 hover'
                onClick={handleClearChip}
            ></i>
        </div>
    )
}

function PriceChip({ filter, filters, onClearChip }) {
    const handleClearChip = () => {
        const updatedFilters = {
            ...filters,
            search: {
                ...filters.search,
                priceFilter: null
            }
        }
        onClearChip(updatedFilters)
    }

    return (
        <div className='tagLabel'>
            <span className='background'>
                {`${filter.value.min} - ${filter.value.max}`}{' '}
            </span>
            <span>{filter.key}</span>
            <i
                className='fas fa-times ml-2 hover'
                onClick={handleClearChip}
            ></i>
        </div>
    )
}

const CollectionActivity = ({ showFilters,
    nfts,
    collectionDetails,
    noOfItems,
    hasNoData,
    resetFilters,
    filters,
    handleFiltersChange, collectionActivityList, ref }) => {

    const { t } = useTranslation()

    const filterEntries = filters && Object.entries(flattenFilters(filters))


    const chips = filterEntries
        ?.filter(
            ([key, value]) =>
                !(
                    key.includes('stringTraits') ||
                    key.includes('priceFilter') ||
                    key.includes('sort')
                )
        )
        .map(([key, value], i) => {
            return (
                <Chip
                    key={key}
                    filters={filters}
                    filter={{ key, value }}
                    onClearChip={handleFiltersChange}
                />
            )
        })

    const a = filters?.search.stringTraits ?? {}
    const b = Object.values(a).reduce((acc, cur) => {
        if (!acc.hasOwnProperty(cur.name)) {
            acc[cur.name] = Object.values(cur.values)
        }
        return acc
    }, {})

    const traitChips = Object.entries(b ?? {}).map(([key, value]) => {
        return value.map((v) => {
            return (
                <TraitChip
                    key={v}
                    filters={filters}
                    filter={{ key, value: v }}
                    onClearChip={handleFiltersChange}
                />
            )
        })
    })

    const priceFilters = filters?.search.priceFilter ?? {}
    const priceFilter = Object.entries(priceFilters).reduce(
        (acc, [key, value], i, arr) => {
            if (key === 'symbol' && !acc.hasOwnProperty(value)) {
                acc[value] = { min: arr[1][1], max: arr[2][1] }
            }
            return acc
        },
        {}
    )

    const priceChip = Object.entries(priceFilter ?? {}).map(([key, value]) => {
        return (
            <PriceChip
                key={key}
                filters={filters}
                filter={{ key, value }}
                onClearChip={handleFiltersChange}
            />
        )
    })

    const handleClearAll = () => {
        handleFiltersChange(structuredClone(INITIAL_FILTER_STATE))
    }

    const showClearAllButton =
        filterEntries?.filter(
            ([key, value]) =>
                !!value && !key.includes('owner') && !key.includes('sort')
        ).length !== 0

    const listViewrow = collectionActivityList?.pages.map((page) => {
        return page?.map((item, i) => {
            if (page?.length === i + 1) {
                return (
                    <tr ref={ref ? ref : null}>
                        <td>
                            <button className='btnBuynow'><img src={listIcon} alt='offerIcon' /> {item.type}</button>
                        </td>
                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='tblImg'><img src={item.asset} alt={`item.imgsrc`} /></div>
                                <div className='fw-600'>{item.name}</div>
                            </div>
                        </td>
                        <td className='text-right'>
                            <div>{item.price} {item.currency}</div>
                            <div className='fs-12 dark-text-secondary'>$13.71</div>
                        </td>
                        {/* <td><span className="dark-text-grey">--</span></td> */}
                        <td className='text-right'>{item.quantity}</td>
                        <td className='text-right'><span className="dark-primary ellipsisTxt">{item.from === null || "" ? '-' : item.from === 'Unnamed' ? item.fromAddress : item.from}</span></td>
                        <td className='text-right dark-text-grey'><span className="dark-primary ellipsisTxt">{item.to === null || "" ? '-' : item.to === 'Unnamed' ? item.toAddress : item.to}</span></td>
                        <td><span className="dark-text-secondary">{item.saleExpiry}</span></td>
                    </tr>
                )
            }
            return (
                <tr ref={ref ? ref : null}>
                    <td>
                        <button className='btnBuynow'><img src={listIcon} alt='offerIcon' /> {item.type}</button>
                    </td>
                    <td>
                        <div className='d-flex align-items-center'>
                            <div className='tblImg'><img src={item.asset} alt={`item.imgsrc`} /></div>
                            <div className='fw-600'>{item.name}</div>
                        </div>
                    </td>
                    <td className='text-right'>
                        <div>{item.price} {item.currency}</div>
                        <div className='fs-12 dark-text-secondary'>$13.71</div>
                    </td>
                    {/* <td><span className="dark-text-grey">--</span></td> */}
                    <td className='text-right'>{item.quantity}</td>
                    <td className='text-right'><span className="dark-primary ellipsisTxt">{item.from === null || "" ? '-' : item.from === 'Unnamed' ? item.fromAddress : item.from}</span></td>
                    <td className='text-right dark-text-grey'><span className="dark-primary ellipsisTxt">{item.to === null || "" ? '-' : item.to === 'Unnamed' ? item.toAddress : item.to}</span></td>
                    <td><span className="dark-text-secondary">{item.saleExpiry}</span></td>
                </tr>
            )
        })
    })
    return (
        <>
            <div className='py-3'>
                <div className='fiterSelectlabel mb-4'>
                    {chips &&
                        traitChips &&
                        priceChip && [...chips, ...traitChips, ...priceChip]}
                    {showClearAllButton && (
                        <button className='clearAllBtn' onClick={handleClearAll}>
                            {t('Clear All')}
                        </button>
                    )}
                </div>
                <div className='table-responsive'>
                    <table className='table table-borderless activityTable'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t("Items")}</th>
                                <th>{t("Price")}</th>
                                {/* <th className='text-right'>Rarity</th> */}
                                <th className='text-right'>{t("Quantity")}</th>
                                <th className='text-right'>{t("From")}</th>
                                <th className='text-right'>{t("To")}</th>
                                <th>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6665 4.6875C13.8774 4.6875 16.479 7.28906 16.479 10.5C16.479 13.7109 13.8774 16.3125 10.6665 16.3125C7.45557 16.3125 4.854 13.7109 4.854 10.5C4.854 7.28906 7.45557 4.6875 10.6665 4.6875ZM10.6665 15.1875C13.2446 15.1875 15.354 13.1016 15.354 10.5C15.354 7.92188 13.2446 5.8125 10.6665 5.8125C8.06494 5.8125 5.979 7.92188 5.979 10.5C5.979 13.1016 8.06494 15.1875 10.6665 15.1875ZM12.0962 12.75L10.104 11.2969C10.0337 11.25 10.0103 11.1562 10.0103 11.0859V7.21875C10.0103 7.07812 10.1274 6.9375 10.2915 6.9375H11.0415C11.1821 6.9375 11.3228 7.07812 11.3228 7.21875V10.5469L12.8696 11.6953C13.0103 11.7891 13.0337 11.9531 12.9399 12.0938L12.4946 12.6797C12.4009 12.8203 12.2368 12.8438 12.0962 12.75Z" fill="#595F6A" />
                                    </svg>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {nfts}
                        </tbody>
                    </table>
                </div>
                {hasNoData && <NoItemsFound onClick={resetFilters} />}
            </div>
        </>
    )
}

export default CollectionActivity;