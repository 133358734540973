import { useQuery } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'
import { flattenFilters } from '../../../utils/flattenFilters'

export async function fetchCollectionNfts(
	collectionAddress,
	filters,
	pageParam,
	callback
) {
	const url = new URL(BACKEND_URL + 'collection/assets')
	url.searchParams.set('address', collectionAddress)
	url.searchParams.set('page', pageParam)
	const flattenedFilters = flattenFilters(filters)
	const queryString = convertObjectToQueryString(flattenedFilters)
	url.search += queryString

	const response = await axios.get(url)
	callback(response.count ?? 0)
	return response.data
}

export function useFetchCollectionNfts(collectionAddress) {
	return useQuery({
		queryKey: ['nfts', collectionAddress],
		queryFn: () => fetchCollectionNfts(collectionAddress),
		enabled: !!collectionAddress
	})
}
