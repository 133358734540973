import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'

function fetchAssetDetails(collectionAddress, nftId, supplyId) {
	const url = new URL(BACKEND_URL + 'asset/details')
	url.searchParams.set('collection', collectionAddress)
	url.searchParams.set('id', nftId)
	url.searchParams.set('supplyId', supplyId)

	return axios.get(url)
}

export function useFetchAssetDetails(collectionAddress, nftId, supplyId) {
	return useQuery({
		queryKey: ['asset', collectionAddress, nftId, supplyId],
		queryFn: () => fetchAssetDetails(collectionAddress, nftId, supplyId),
		enabled: !!(collectionAddress && nftId && supplyId)
	})
}

function fetchOfferList(collectionAddress, nftId, supplyId) {
	const url = new URL(BACKEND_URL + 'asset/offers')
	url.searchParams.set('collection', collectionAddress)
	url.searchParams.set('id', nftId)
	url.searchParams.set('supplyId', supplyId)

	return axios.get(url)
}

export function useFetchOfferList(collectionAddress, nftId, supplyId) {
	return useQuery({
		queryKey: ['makeOffer', collectionAddress, nftId, supplyId],
		queryFn: () => fetchOfferList(collectionAddress, nftId, supplyId),
		enabled: !!(collectionAddress && nftId && supplyId)
	})
}

function approveOffer(data) {
	return axios.post('asset/approve-offer', { ...data })
}

export function useApproveOffer() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: approveOffer,
		onSuccess: () => {
			queryClient.invalidateQueries(['asset'])
			queryClient.invalidateQueries(['makeOffer'])
		}
	})
}
