import React, { useState, useCallback, useRef } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, UncontrolledPopover, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import angleDown from "../../assets/images/angleDown.svg";
import aiPfpClub from '../../assets/images/itemIcons/aiPfpClub.png';
import IconModal from '../../assets/images/IconModal.png';
import walletLoaderCnt from "../../assets/images/walletLoader.png";
import yellowTick from "../../assets/images/collection/yellow-tick_20px.svg";
import copyIcon from '../../assets/images/copyIcon.svg';
import logoImg from '../../assets/images/logoImg.png';
import coverImg from '../../assets/images/coverImg.png';
import Pencil from '../../assets/images/Pencil.svg';
import twitter from '../../assets/images/twitterNew.svg';
import instagram from '../../assets/images/instagram.svg';
import globe from '../../assets/images/globe.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import facebook from '../../assets/images/facebook.svg';
import twitterNew from '../../assets/images/twitterNew.svg';
import telegram from '../../assets/images/telegram-plane.svg';
import checkIcon from '../../assets/images/toast/checkIcon.svg';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import userImage from '../../assets/images/collection/userImage.png';
import deGods from '../../assets/images/itemIcons/deGods.png';
import tick from '../../assets/images/collection/yellow-tick_20px.svg';
import eth from '../../assets/images/ethIconSm.png';
import bnb from '../../assets/images/bnbIconSm.png';
//import '../MyCollection/myCollection.scss';
//import '../../assets/scss/mycollecion_mint.scss';
import './Settings.scss';
import matic from '../../assets/images/maticIconSm.png';

import tblUserImg1 from '../../assets/images/collection/tblUserImg1.png';
import tblUserImg2 from '../../assets/images/collection/tblUserImg2.png';
import tblUserImg3 from '../../assets/images/collection/tblUserImg3.png';
import tblUserImg4 from '../../assets/images/collection/tblUserImg4.png';
import tblUserImg5 from '../../assets/images/collection/tblUserImg5.png';
import tblUserImg6 from '../../assets/images/collection/tblUserImg6.png';
import tblUserImg7 from '../../assets/images/collection/tblUserImg7.png';
import tblUserImg8 from '../../assets/images/collection/tblUserImg8.png';
import tblUserImg9 from '../../assets/images/collection/tblUserImg9.png';
import tblUserImg10 from '../../assets/images/collection/tblUserImg10.png';
import tblUserImg11 from '../../assets/images/collection/tblUserImg11.png';
import tblUserImg12 from '../../assets/images/collection/tblUserImg12.png';
// import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';

import { axios } from '../../lib/axios'
import {
  useAccount,
} from 'wagmi'
import copy from "copy-to-clipboard";
import { useToast } from '../../utils/toast/customSuccess'
import { useNavigate } from 'react-router-dom';
import loader from '../../assets/images/Loader.png'
import { useTranslation } from 'react-i18next';
import { GetFetchEarnings } from './api';

const tableImg = [
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg1,
    ownername: 'Ahmad Dokidis'
  },
  {
    imgsmIcon: bnb,
    imgsrc: tblUserImg2,
    ownername: 'Allison Curtis'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg3,
    ownername: 'Marcus Botosh'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg4,
    ownername: 'Cheyenne Rosser'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg5,
    ownername: 'Davis Passaquindi...'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg6,
    ownername: 'Davis Passaquindi...'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg7,
    ownername: 'Anika Saris'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg8,
    ownername: 'Ahmad Dokidis'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg9,
    ownername: 'Allison Curtis'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg10,
    ownername: 'Marcus Botosh'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg11,
    ownername: 'Cheyenne Rosser'
  },
  {
    imgsmIcon: eth,
    imgsrc: tblUserImg12,
    ownername: 'Davis Passaquindi...'
  },

]

export const SettingsEarnings = () => {

  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);

  //   const CheckTick = () => {
  //     return (    
  //       <svg className="ml-auto" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  //         <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900"/>
  //       </svg>    
  //   )
  // }

  const chainImageMap = {
    binance: bnb,
    polygon: matic,
    ethereum: eth
  }

  const coinMap = {
    binance: 'BNB',
    polygon: 'MATIC',
    ethereum: 'ETH'
  }

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const toast = useToast()

  const { address, isConnected } = useAccount()

  const [copyText, setCopyText] = useState(address ? address : "");

  const copyToClipboard = () => {
    copy(copyText);
    toast.success("Address Copied");
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = GetFetchEarnings({
    address: address
  })

  const intersectionObserverRef = useRef()
  const lastNftRef = useCallback(
    (nft) => {
      if (isFetchingNextPage) {
        return
      }

      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect()
      }

      intersectionObserverRef.current = new IntersectionObserver(
        (nfts) => {
          if (
            nfts[0].isIntersecting &&
            hasNextPage &&
            !isFetchingNextPage
          ) {
            fetchNextPage()
          }
        }
      )

      if (nft) {
        intersectionObserverRef.current.observe(nft)
      }
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage]
  )

  // const listViewrow =
  //   earningsMutation && earningsMutation?.data?.data?.length > 0 ? (
  //     earningsMutation?.data?.data?.map((item, index) =>
  //       <>
  //         <tr >
  //           <td>
  //             <img src={chainImageMap[item.chain]} alt='coimImg' />
  //           </td>
  //           <td>
  //             <div className='d-flex align-items-center'>
  //               <div className='tblImg'><img src={item.asset} alt={`item.asset`} /></div>
  //               <div>
  //                 <div className='fw-600'>{item.nftName}
  //                   {/* #1249 */}
  //                 </div>
  //                 <div className='smTxt dark-text-secondary'>{item.collectionName}
  //                   <img src={item.verification === 'Verified' ? yellowTick : ' '}
  //                     height="18" className="ml-1" />
  //                 </div>
  //               </div>
  //             </div>
  //           </td>
  //           <td className='text-right'>{parseFloat(item.previousPrice.toFixed(8))} {coinMap[item.chain]}</td>
  //           <td className='text-right'>{parseFloat(item.currentPrice.toFixed(8))} {coinMap[item.chain]}</td>
  //           <td className='text-right'>{parseFloat(item.profit.toFixed(8))} {coinMap[item.chain]}</td>
  //         </tr>
  //       </>
  //     )

  //   ) : (

  //     <tr>
  //       <td colSpan={4}>
  //         <div className='noItemFound'>
  //           <div className='text-center'>
  //             <img
  //               src={noItemfoundImage}
  //               alt='noItemfoundImage'
  //               className='img-fluid'
  //             />
  //           </div>
  //           <div className='nofounttxt mb-2'>
  //             No Earnings.
  //           </div>
  //           <p className="greyTxt mb-4">This wallet address does not have any creator earnings from sales using WARRIOR in 2023.</p>
  //         </div>
  //       </td>
  //     </tr>

  //   )


  const listViewrow = data?.pages?.map((page) => {
    return page?.data?.map((item, i) => {
      if (page?.data?.length === i + 1) {
        return (
          <tr ref={lastNftRef}>

            <td>
              <img src={chainImageMap[item.chain]} alt='coimImg' />
            </td>
            <td>
              <div className='d-flex align-items-center'>
                <div className='tblImg'>
                  {item.imageFormat &&
                    item.imageFormat.startsWith('image') ? (
                    <img
                      src={item.asset}
                      className='itemIcon mr-3'
                      style={{
                        height: '60px',
                        aspectRatio: '1',
                        borderRadius: '12px'
                      }}
                    />
                  ) : (
                    <video
                      src={item.asset}
                      className='itemIcon mr-3'
                      controlsList='nodownload'
                      muted
                      loop
                      autoPlay
                      style={{
                        height: '60px',
                        aspectRatio: '1',
                        borderRadius: '12px'
                      }}
                    />
                  )}
                  {/* <img src={item.asset} alt={`item.asset`} /> */}

                </div>
                <div>
                  <div className='fw-600'>{item.nftName}
                    {/* #1249 */}
                  </div>
                  <div className='smTxt dark-text-secondary'>{item.collectionName}
                    <img src={item.verification === 'Verified' ? yellowTick : ' '}
                      height="18" className="ml-1" />
                  </div>
                </div>
              </div>
            </td>
            <td className='text-right'>{parseFloat(item.previousPrice.toFixed(8))} {item.currency}</td>
            <td className='text-right'>{parseFloat(item.currentPrice.toFixed(8))} {item.currency}</td>
            <td className='text-right'>{parseFloat(item.profit.toFixed(8))} {item.currency}</td>
          </tr>

        )
      }
      return (
        <tr >
          <td>
            <img src={chainImageMap[item.chain]} alt='coimImg' />
          </td>
          <td>
            <div className='d-flex align-items-center'>
              <div className='tblImg'><img src={item.asset} alt={`item.asset`} /></div>
              <div>
                <div className='fw-600'>{item.nftName}
                  {/* #1249 */}
                </div>
                <div className='smTxt dark-text-secondary'>{item.collectionName}
                  <img src={item.verification === 'Verified' ? yellowTick : ' '}
                    height="18" className="ml-1" />
                </div>
              </div>
            </div>
          </td>
          <td className='text-right'>{parseFloat(item.previousPrice.toFixed(8))} {item.currency}</td>
          <td className='text-right'>{parseFloat(item.currentPrice.toFixed(8))} {item.currency}</td>
          <td className='text-right'>{parseFloat(item.profit.toFixed(8))} {item.currency}</td>
        </tr>
      )
    })
  })


  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">Earnings</h3>
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <div className="row form-row">
            <div className="col-12 mb-3">
              <div className="walletCnt mb-3">
                <h3>{address ? address.slice(0, 5) + "..." + address.slice(38, 42) : ""}</h3>
                <span className="walletLabel successLabel ml-auto">{address ? t("Wallet Connected") : t("Connect Wallet")}</span>
                <img src={copyIcon} alt='copyIcon' className="ml-2" onClick={copyToClipboard} style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className='col-12'>
              <div className='borderInputBox mb-4'>
                <div className="d-flex justify-content-between">
                  <div className="fs-16 fw-400 dark-text">Total Earnings</div>
                  <div className="fs-16 fw-600 dark-text">
                    {data?.pages[0]?.totalProfit ? "$" + parseFloat(data?.pages[0]?.totalProfit?.toFixed(8)) : '-'}
                    {/* ETH */}
                  </div>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table table-borderless listViewTable'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Item </th>
                      <th>Obtained</th>
                      <th>Sold</th>
                      <th>Profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listViewrow}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </form >
      </div >

    </>
  );
};

export default SettingsEarnings;