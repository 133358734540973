import { forwardRef, useCallback, useRef, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { fetchActivity } from '../apis/fetchActivity'

import cart from '../../../assets/images/cart.png'
import tag from '../../../assets/images/tag.png'
import clock from '../../../assets/images/clock.svg'
import offerIcon from '../../../assets/images/collection/offerIcon.svg'
import transferIcon from '../../../assets/images/transferIcon.svg'
import collectionOfferIcon from '../../../assets/images/collection/collectionOfferIcon.svg'
import cndds from '../../../assets/images/cndds.png'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { calculateTimeSinceGivenDate } from '../../../utils/formatRoundTime'

const Activity = forwardRef(function Activity({ activity, i }, ref) {
	const navigate = useNavigate()

	return (
		<tr ref={ref ? ref : null}>
			<td>
				{' '}
				<div className='d-flex align-items-center'>
					<div className='tableTag'>
						<img src={activity.type === 'list' ? tag : activity.type === 'sale' ? cart : activity.type === 'make-collection-offer' ? collectionOfferIcon : activity.type === 'make-offer' ? offerIcon : cart} className='mr-2' />
						{activity.type === 'make-offer' ? 'Offer' : activity.type === 'make-collection-offer' ? 'Collection Offer' : activity.type}
					</div>
					{['make-collection-offer', 'make-offer'].includes(activity.type) && activity.isExpired ?
						<div className='tableTag ml-2'>
							<span className="text-danger ">Expired</span>
						</div>
						: null}
				</div>

			</td>
			<td>
				{activity.type === 'mint' ? '-' :
					`${parseFloat(activity.price.toFixed(8))} ${activity.currency}`}
			</td>
			{activity.fromAddress === '' || null ? (
				<td className='yellowTxt'>-</td>
			) : (
				<td
					className='yellowTxt hover'
					onClick={() => navigate(`/profile/${activity.fromAddress}`)}
				>
					{activity.from === 'Unnamed'
						? activity.fromAddress
						: activity.from}
				</td>
			)}
			{activity.to === null || '' ? (
				<td className='yellowTxt'>-</td>
			) : (
				<td
					className='yellowTxt hover'
					onClick={() => navigate(`/profile/${activity.toAddress}`)}
				>
					{activity.to === 'Unnamed'
						? activity.toAddress
						: activity.to}
				</td>
			)}
			<td>{calculateTimeSinceGivenDate(activity.createdAt)}</td>
		</tr>
	)
})

export function AssetActivity({ asset }) {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState('all')

	const params = useParams()
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				'activities',
				params.collectionAddress,
				params.nftId,
				params.supplyId,
				activeTab
			],
			queryFn: ({ pageParam = 1 }) =>
				fetchActivity(
					params.collectionAddress,
					params.nftId,
					params.supplyId,
					activeTab,
					pageParam
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.length ? allPages?.length + 1 : undefined
			},
			enabled: !!(
				params.collectionAddress &&
				params.nftId &&
				params.supplyId
			)
		})

	const inetrsectionObserver = useRef()
	const lastActivityRef = useCallback(
		(activity) => {
			if (isFetchingNextPage) {
				return
			}

			if (inetrsectionObserver.current) {
				inetrsectionObserver.current.disconnect()
			}

			inetrsectionObserver.current = new IntersectionObserver(
				(activities) => {
					if (activities[0].isIntersecting && hasNextPage) {
						fetchNextPage()
					}
				}
			)

			if (activity) {
				inetrsectionObserver.current.observe(activity)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const activities = data?.pages?.map((page) => {
		return page?.map((activity, i) => {
			if (page.length === i + 1) {
				return (
					<Activity
						key={activity._id}
						ref={lastActivityRef}
						activity={activity}
					/>
				)
			}
			return <Activity key={activity._id} activity={activity} i={i} />
		})
	})

	const hasNoActivities = activities?.[0]?.length === 0

	return (
		<div className='col-12'>
			<div className='nftCard'>
				<h3 className='heading'>{t('Activity')}</h3>
				<Nav tabs className='trad_tab_hd navNftDetails'>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === 'all'
							})}
							onClick={() => setActiveTab('all')}
						>
							{t('All')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === 'sales'
							})}
							onClick={() => setActiveTab('sales')}
						>
							{t('Sales')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === 'listings'
							})}
							onClick={() => setActiveTab('listings')}
						>
							{t('Listings')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === 'offers'
							})}
							onClick={() => setActiveTab('offers')}
						>
							{t('Offers')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent
					activeTab={activeTab}
					className='w-100'
					style={{
						height: '400px',
						overflowY: 'auto',
						overflowX: 'hidden'
					}}
				>
					<TabPane tabId='all'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive activityTableCnt'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											<tr>
												<th></th>
												<th>{t('Price')}</th>
												<th>{t('From')}</th>
												<th>{t('To')}</th>
												<th>
													<img src={clock} />
												</th>
											</tr>
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={cndds}
											alt=''
										/>
										<h1>{t('No Data')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
					<TabPane tabId='sales'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											<tr>
												<th></th>
												<th>{t('Price')}</th>
												<th>{t('From')}</th>
												<th>{t('To')}</th>
												<th>
													<img src={clock} />
												</th>
											</tr>
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={cndds}
											alt=''
										/>
										<h1>{t('No Data')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
					<TabPane tabId='listings'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											<tr>
												<th></th>
												<th>{t('Price')}</th>
												<th>{t('From')}</th>
												<th>{t('To')}</th>
												<th>
													<img src={clock} />
												</th>
											</tr>
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={cndds}
											alt=''
										/>
										<h1>{t('No Data')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
					<TabPane tabId='offers'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											<tr>
												<th></th>
												<th>{t('Price')}</th>
												<th>{t('From')}</th>
												<th>{t('To')}</th>
												<th>
													<img src={clock} />
												</th>
											</tr>
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={cndds}
											alt=''
										/>
										<h1>{t('No Data')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
				</TabContent>
			</div>
		</div>
	)
}
