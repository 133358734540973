import { useAccount, useNetwork } from 'wagmi'
import { UncontrolledTooltip } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useBidsModalActions } from '../../../components/modals/bids/store'
import { usePlaceBidModalActions } from '../../../components/modals/place-bid/store'
import useCountdown from '../../../hooks/useCountdown'
import { getAuctionCountdown } from '../../../utils/formatRoundTime'
import { useCancelAuction } from '../../../hooks/useCancelAuction'
import contracts from '../../../config/constants/contracts'
import { chainId } from '../../../config/constants/chains'
import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import { usePurchaseLoadingModalActions } from '../../../components/modals/purchase-loading/store'
import { useSomethingWentWrongModalActions } from '../../../components/modals/error/store'

import info from '../../../assets/images/infoIcon.svg'

const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export function AuctionStation({ asset }) {
	const { t } = useTranslation()

	const { secondsRemaining } = useCountdown(asset.details.saleExpiry)
	const { days, hours, minutes, seconds } =
		getAuctionCountdown(secondsRemaining)
	const { toggleBidsModal } = useBidsModalActions()
	const { setAsset } = usePlaceBidModalActions()
	const { address: account } = useAccount()
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null
	const { cancelAuction } = useCancelAuction(marketplaceContractAddress)
	const { chain } = useNetwork()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()

	const now = Math.floor(new Date().getTime() / 1000)
	const isAuctionExpired = now > asset.details.saleExpiry
	const isOwner =
		!!account && account?.toLowerCase() === asset?.owner?.address

	const handleCancelListing = async () => {
		setHeading('Cancel Listing')
		togglePurchaseLoadingModal()
		try {
			if (asset.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[asset.details.chain])
			}

			await cancelAuction({
				owner: asset.owner.address,
				nftAddress: asset.details.contractAddress,
				tokenId: asset.details.nftId,
				asset
			})
		} catch (error) {
			console.error(error)
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	return (
		<>
			{!isAuctionExpired && (
				<div className='d-flex algign-items-center flex-wrap w-100'>
					<div className='d-flex flex-column'>
						<span
							className='cardLabel d-flex'
							style={{ gap: '5px' }}
						>
							Minimum Bid{' '}
							<span className='yellowTxt' style={{ cursor: 'pointer' }} onClick={() => {
								setAsset(asset)
								toggleBidsModal()
							}}>View Bids</span>
							<div className='hover'>
								<img
									alt='information icon'
									id='bid-info'
									src={info}
								/>
								<UncontrolledTooltip
									placement='top'
									target='bid-info'
									className='tooltipCnt red-tooltip'
								>
									The highest bidder will win the item at the
									end of the auction
								</UncontrolledTooltip>
							</div>
						</span>
						<div className='d-flex align-items-center mb-2'>
							<h3 className='mb-0 mr-2'>{`${parseFloat(
								asset.details.price.toFixed(8)
							)} ${asset.details.currency}`}</h3>
							{/* <span className='cardLabel'>$53,941.95</span> */}
						</div>
					</div>
					<div className='ml-auto fs-14 fw-400 dark-text-secondary d-inline-flex flex-column justify-content-center align-items-end'>
						<div>
							<span>Ends in</span>
						</div>
						<div>
							<span class='fw-600 dark-text'>{days}</span> Days{' '}
							<span class='fw-600  dark-text'>{hours}</span> Hours{' '}
							<span class='fw-600  dark-text'>{minutes}</span>{' '}
							Mins <span class='fw-600 dark-text'>{seconds}</span>{' '}
							Secs
						</div>
					</div>
				</div>
			)}
			<div className='d-flex align-items-center flex-wrap w-100'>
				{!isAuctionExpired && (
					<button
						className='btn gradientBtn btn-block btn160 mr-2 my-1'
						onClick={() => {
							setAsset(asset)
							toggleBidsModal()
						}}
					>
						{isOwner ? t('View Bids') : t('Place a Bid')}
					</button>
				)}
				{isAuctionExpired ? (
					isOwner ? (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={handleCancelListing}
						>
							{t('Cancel Listing')}
						</button>
					) : (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={() => {
								setAsset(asset)
								toggleBidsModal()
							}}
						>
							{t('View Bids')}
						</button>
					)
				) : null}
			</div>
		</>
	)
}
