import { useMutation, useQuery } from '@tanstack/react-query'
import { axios } from '../../../lib/axios'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '../../../lib/react-query'

export const fetchNotable = () => {
	return axios.get('/home/get_Notable_Collections')
}

export const UsefetchNotable = () => {
	return useQuery({
		queryKey: ['notable_list'],
		queryFn: fetchNotable
	})
}

export const fetchViewCount = (data) => {
	return axios.post('home/update_view_count', data)
}

export const GetFetchViewCount = () => {
	const navigate = useNavigate()

	return useMutation(fetchViewCount, {
		onMutate: () => {},
		onError: (error) => {},
		onSuccess: (ViewCountData) => {
			if (ViewCountData.data.status === true) {
				navigate('/')
				queryClient.refetchQueries(['notable_list'])
			}
		}
	})
}

export const fetchFeatured = () => {
	return axios.get('/home/get_Feature_Collections')
}

export const UsefetchFeatured = () => {
	return useQuery({
		queryKey: ['featured_list'],
		queryFn: fetchFeatured
	})
}
