import React, { useContext, useEffect, useState } from 'react';


import './profileActivity.scss';

import tblUserImg1 from '../../../assets/images/collection/tblUserImg1.png';
import tblUserImg2 from '../../../assets/images/collection/tblUserImg2.png';
import tblUserImg3 from '../../../assets/images/collection/tblUserImg3.png';
import tblUserImg4 from '../../../assets/images/collection/tblUserImg4.png';
import tblUserImg5 from '../../../assets/images/collection/tblUserImg5.png';
import tblUserImg6 from '../../../assets/images/collection/tblUserImg6.png';
import tblUserImg7 from '../../../assets/images/collection/tblUserImg7.png';
import tblUserImg8 from '../../../assets/images/collection/tblUserImg8.png';
import tblUserImg9 from '../../../assets/images/collection/tblUserImg9.png';
import tblUserImg10 from '../../../assets/images/collection/tblUserImg10.png';
import tblUserImg11 from '../../../assets/images/collection/tblUserImg11.png';
import tblUserImg12 from '../../../assets/images/collection/tblUserImg12.png';
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import saleIcon from '../../../assets/images/collection/saleIcon.svg';
import transferIcon from '../../../assets/images/collection/transferIcon.svg';
import listIcon from '../../../assets/images/collection/listIcon.svg';
import collectionOfferIcon from '../../../assets/images/collection/collectionOfferIcon.svg';
import offerIcon from '../../../assets/images/collection/offerIcon.svg';
import mintIcon from '../assets/mintIcon.svg';
import dealsIcon from '../assets/dealsIcon.svg';
import PaginationComponent from 'react-reactstrap-pagination'
import { ThemeContext } from '../../context/context';
import {
    useAccount,
} from 'wagmi'
import { axios } from '../../../lib/axios'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import loader from '../../../assets/images/Loader.png'
import { hoursToGo } from '../../../utils/formatRoundTime';
import ResponsivePagination from 'react-responsive-pagination';

const landPerRow = 6

const ActivityCollection = () => {

    const { t } = useTranslation()

    const { activitystatus, setActivityStatus } = useContext(ThemeContext)
    const { checkactivityChain, setactivityCheckChain } = useContext(ThemeContext)
    const { checkactivitycollection, setCheckactivityCollection } = useContext(ThemeContext)
    const { address, isConnected } = useAccount()

    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState('#ffffff')

    const clearAll = () => {
        setActivityStatus(["all"])
        setactivityCheckChain([])
        setCheckactivityCollection([])
    }

    const navigate = useNavigate()

    const { id } = useParams()


    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

        GetActivity()
    }, [address, page, activitystatus, checkactivityChain, checkactivitycollection, id])

    const [getactivity, setGetActivity] = useState('')
    const [totalactivitycount, setTotalActivity] = useState("")

    function handlePageChange(page) {
        setPage(page);
        // ... do something with `page`
    }

    console.log("checkactivitycollection", checkactivitycollection.length > 0 || checkactivityChain.length > 0 || activitystatus[0] != ['all'] ? 1 : page)

    const GetActivity = async () => {
        setLoading(true)
        const fetch = await axios.get(
            `user/get_activity?address=${id ? id : address}&page=${checkactivitycollection.length > 0 || checkactivityChain.length > 0 || activitystatus[0] != ['all'] ? 1 : page}&limit=15&collectionName=${checkactivitycollection}&chain=${checkactivityChain}&type=${activitystatus}`
        )
        if (fetch.status == true) {
            setGetActivity(fetch.data)
            setTotalActivity(Math.ceil(fetch.count / 15))
            setLoading(false)
        }
        else {
            setGetActivity('')
            setTotalActivity('')
            setLoading(false)
        }
    }


    const closechain = (item) => {
        let a = checkactivityChain.includes(item)

        if (a == true) {

            const index = checkactivityChain.indexOf(item);
            if (index > -1) { // only splice array when item is found
                checkactivityChain.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...checkactivityChain]
                setactivityCheckChain(nerArr);
            }
        }
    }


    const closecollection = (item) => {
        let a = checkactivitycollection.includes(item)

        if (a == true) {

            const index = checkactivitycollection.indexOf(item);
            if (index > -1) { // only splice array when item is found
                checkactivitycollection.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...checkactivitycollection]
                setCheckactivityCollection(nerArr);
            }

        }
    }

    const closestatus = (data) => {
        // setActivityStatus("")

        let a = activitystatus.includes(data)
        if (a == true) {
            const index = activitystatus.indexOf(data);
            if (index > -1) { // only splice array when item is found
                activitystatus.splice(index, 1); // 2nd parameter means remove one item only
                const nerArr = [...activitystatus]
                setActivityStatus(nerArr);
                if (nerArr.length == 0) {
                    setActivityStatus(["all"]);
                }
            }

        }
    }


    const litstedTagLoop3 = activitystatus && activitystatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const litstedTagLoop1 = checkactivitycollection && checkactivitycollection.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = checkactivityChain && checkactivityChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closechain(item)}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const listViewrow = getactivity && getactivity.map((item, index) =>
        <>
            <tr onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}/${item.supplyId}`)} style={{ cursor: "pointer" }}>
                <td>
                    <button className='btnBuynow'>
                        <img src={item.type == "sale" ?
                            saleIcon : item.type == "list" ?
                                listIcon : item.type == "offer" ?
                                    offerIcon : item.type == "collectionoffer" ?
                                        collectionOfferIcon : item.type == "transfer" ?
                                            transferIcon : item.type == "mint" ?
                                                mintIcon : item.type == "midealsentnt" ?
                                                    dealsIcon : item.type == "dealaccepted" ?
                                                        dealsIcon : offerIcon}
                            alt='' />
                        {item.type === 'make-offer' ? 'Offer' : item.type === 'make-collection-offer' ? 'Collection Offer' : item.type}
                    </button>
                </td>
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='tblImg'>
                            {/* <img src={item.asset} alt={`item.imgsrc`} /> */}
                            {typeof item.asset == "string" && item.asset !== undefined ?

                                item.imageFormat.startsWith('video') ?
                                    <video

                                        src={item.asset}
                                        alt="Uploaded"
                                        muted
                                        autoPlay
                                        style={{ width: "60px", height: "70px", borderRadius: "12px" }}
                                    />
                                    :
                                    item.asset !== 'undefined' ?
                                        <img src={item.asset} alt={`item.imgsrc`} />

                                        :
                                        ""
                                :
                                ""
                            }
                        </div>
                        <div className='fw-600 ml-2'>{item.nftName} #{item.supplyId}</div>
                    </div>
                </td>
                <td className='text-right'>
                    {item.price !== 0 ?
                        <div>{item.price.toFixed(6)} {item.currency}</div>
                        :
                        <div style={{ opacity: "0.5" }}>--</div>
                    }
                    <div className='fs-12 dark-text-secondary'>{item.type == "list" ? "$" : ""}{item.type == "list" ? (item.price * item.usdPrice).toFixed(8) : ""}</div>
                </td>
                {/* <td><span className="dark-text-grey">--</span></td> */}
                <td className='text-right'>{item.quantity}</td>
                <td><span className="dark-primary ellipsisTxt">{item.fromAddress ? item.fromAddress : <p style={{ justifyContent: "center", display: "flex" }}>-</p>}</span></td>
                <td><span className='dark-primary ellipsisTxt'>{item.toAddress ? item.toAddress : <p style={{ justifyContent: "center", display: "flex" }}>-</p>}</span></td>
                <td><span className="dark-text-secondary">
                    {moment(item.createdAt).fromNow()}
                    {/* {hoursToGo(new Date(item.createdAt).valueOf()/1000)} */}
                </span></td>
            </tr>
        </>
    )

    return (
        <>
            <div className={loading ? 'spinner' : 'd-none'}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>

            <div className='fiterSelectlabel mb-4'>

                {activitystatus.includes("all") ? "" : litstedTagLoop3}
                {litstedTagLoop1}{litstedTagLoop2}
                {!activitystatus.includes("all") || checkactivityChain.length > 0 || checkactivitycollection.length > 0 ?
                    <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                    :
                    ""
                }
            </div>
            <div className='table-responsive'>
                <table className='table table-borderless activityTable'>
                    {getactivity && getactivity.length > 0 ?
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t("Items")}</th>
                                <th className='text-right'>{t("Price")}</th>
                                {/* <th>{t("Rarity")}</th> */}
                                <th className='text-right'>{t("Quantity")}</th>
                                <th>{t("From")}</th>
                                <th className='text-right'>{t("To")}</th>
                                <th>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6665 4.6875C13.8774 4.6875 16.479 7.28906 16.479 10.5C16.479 13.7109 13.8774 16.3125 10.6665 16.3125C7.45557 16.3125 4.854 13.7109 4.854 10.5C4.854 7.28906 7.45557 4.6875 10.6665 4.6875ZM10.6665 15.1875C13.2446 15.1875 15.354 13.1016 15.354 10.5C15.354 7.92188 13.2446 5.8125 10.6665 5.8125C8.06494 5.8125 5.979 7.92188 5.979 10.5C5.979 13.1016 8.06494 15.1875 10.6665 15.1875ZM12.0962 12.75L10.104 11.2969C10.0337 11.25 10.0103 11.1562 10.0103 11.0859V7.21875C10.0103 7.07812 10.1274 6.9375 10.2915 6.9375H11.0415C11.1821 6.9375 11.3228 7.07812 11.3228 7.21875V10.5469L12.8696 11.6953C13.0103 11.7891 13.0337 11.9531 12.9399 12.0938L12.4946 12.6797C12.4009 12.8203 12.2368 12.8438 12.0962 12.75Z" fill="#595F6A" />
                                    </svg>
                                </th>
                            </tr>
                        </thead>
                        :
                        ""
                    }
                    <tbody>

                        {getactivity && getactivity.length > 0 ?
                            listViewrow
                            :
                            <div className='noItemFound justify-content-center'>
                                <div className='text-center'>
                                    <img src={noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                </div>
                                <div className='nofounttxt mb-4'>
                                    {t("No items were found.Try to broaden your search.")}
                                </div>
                                {/* <div>
                                    <button className='applyBtn'>{t("Apply")}</button>
                                </div> */}
                            </div>
                        }


                    </tbody>
                </table>
            </div>

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                        {getactivity && getactivity.length > 0 ?
                            <div class=''>
                                <nav aria-label="Page ">
                                    <ResponsivePagination
                                        total={totalactivitycount}
                                        current={page}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                        nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                        previousClassName="page-link-1"
                                        nextClassName="page-link-1"
                                    />
                                </nav>
                                {/* <PaginationComponent
                        size='sm'
                        defaultActivePage={1}
                        totalItems={totalactivitycount}
                        maxPaginationNumbers={3}
                        pageSize={15}
                        onSelect={(page) => setPage(page)}
                    /> */}
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivityCollection;